import React, { useImperativeHandle, useRef } from 'react';

export interface BidirectionalScrollRef {
  scrollToBottom: () => void;
  scrollToItem: (messageId: string) => void;
}

interface Props {
  className?: string;
  onStartReached: (scrollTop: number, scrollHeight: number) => void;
  onEndReached: (scrollTop: number, scrollHeight: number) => void;
  onShowScrollButton: (show: boolean) => void;
  children: React.ReactNode;
}

const BidirectionalScroll: React.ForwardRefRenderFunction<
  BidirectionalScrollRef,
  Props
> = (
  { className, children, onStartReached, onEndReached, onShowScrollButton },
  ref,
) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const prevScrollTop = useRef<number>(0);

  const handleScroll = () => {
    const { current } = scrollRef;
    if (current) {
      const { scrollTop, scrollHeight, clientHeight } = current;
      const currScrollTop = scrollTop;
      const currdirection =
        currScrollTop < prevScrollTop.current ? 'up' : 'down';
      const scrollTotal = scrollHeight - clientHeight;
      const currScroll = currScrollTop / scrollTotal;
      prevScrollTop.current = currScrollTop;
      if (currdirection === 'up' && currScroll < 0.2) {
        onStartReached(scrollTop, scrollHeight);
      }
      onShowScrollButton(scrollHeight > clientHeight && currScroll < 0.95);

      if (currdirection === 'down' && currScroll > 0.8) {
        onEndReached(scrollTop, scrollHeight);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    scrollToBottom: () => {
      setTimeout(() => {
        const { current } = scrollRef;
        if (current) {
          current.scrollTop = current.scrollHeight;
        }
      }, 100);
    },
    scrollToItem: (messageId: string) => {
      const { current } = scrollRef;
      if (current) {
        const element = current.querySelector(
          `[data-message-id="${messageId}"]`,
        );
        if (element) {
          // scroll item into view at center
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
          setTimeout(() => {
            element.classList.add('blink');
            setTimeout(() => {
              element.classList.remove('blink');
            }, 1000);
          }, 300);
        }
      }
    },
  }));

  return (
    <div
      ref={scrollRef}
      className={className}
      id="bidirectional-scroll"
      onScroll={handleScroll}>
      {children}
    </div>
  );
};

export default React.forwardRef(BidirectionalScroll);
