import React, {
  useRef,
  useState,
} from 'react';

import {
  Button,
  message,
} from 'antd';
import {
  MdAdd,
  MdSend,
} from 'react-icons/md';

import CourseAPI from '../../../api/CourseAPI';
import CloseModal from '../../../assets/svg/CloseModal';
import CustomImage from '../../../components/CustomImage/CustomImage';
import Loader from '../../../components/Loader';
import { useTheme } from '../../../context/ThemeProvider';
import showAppError from '../../../shared/error';
import { useAppSelector } from '../../../shared/hooks';
import { IQNAReply } from '../../../types/qnaTypes';

interface Props {
  chapterId: string;
  qnaId: string;
  noMedia?: boolean;
  onAddReply: (arg0: IQNAReply) => void;
}

const MAX_FILES_ALLOWED = 5;

const InputBox: React.FC<Props> = ({
  chapterId,
  qnaId,
  noMedia,
  onAddReply,
}) => {
  const { colors } = useTheme();
  const userDetails = useAppSelector((state) => state.user);
  const inputRef = useRef<HTMLDivElement>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let fileList = e.target.files;
    if (fileList && fileList.length > 0) {
      let files: File[] = [];
      for (let i = 0; i < fileList.length; i++) {
        files.push(fileList[i]);
      }
      if (selectedFiles.length >= MAX_FILES_ALLOWED) {
        message.error(`Maximum ${MAX_FILES_ALLOWED} images are allowed`);
        return;
      }
      if (files.length > MAX_FILES_ALLOWED - selectedFiles.length) {
        message.error(`Maximum ${MAX_FILES_ALLOWED} images are allowed.`);
        files = [...files.slice(0, MAX_FILES_ALLOWED - selectedFiles.length)];
      }

      setSelectedFiles((prev) => [...prev, ...files]);
    }
  };

  const onRemove = (index: number) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const onSend = async () => {
    const text = inputRef.current?.innerText;
    if (text && text.trim().length > 0) {
      const data = new FormData();
      data.append('description', text.trim());
      if (selectedFiles.length > 5) {
        message.error('You can upload maximum 5 files!!');
        return;
      }
      if (selectedFiles.length > 0) {
        selectedFiles.forEach((file) => {
          data.append('files', file);
        });
      }
      try {
        setIsSaving(true);
        const resp = await CourseAPI.createReply(chapterId, qnaId, data);
        if (resp.status === 200) {
          message.success('Reply added successfully');
          inputRef.current!.innerText = '';
          setSelectedFiles([]);
          onAddReply({
            ...resp.data.result,
            userId: userDetails.id || '',
            userName: userDetails.name || '',
            imgUrl: userDetails.profilePic || '',
          });
        } else {
          showAppError(resp.data);
        }
      } catch (error) {
        showAppError(error);
      } finally {
        setIsSaving(false);
      }
    } else {
      message.error('Please enter some text');
    }
  };

  return (
    <div className="replyInputBox">
      {selectedFiles.length > 0 && (
        <div className="uploadWrapper">
          {selectedFiles.map((file, index) => (
            <div key={index} className="uploadWrapper__imageWrapper">
              <CustomImage src={URL.createObjectURL(file)} />
              <div
                className="uploadWrapper__imageWrapper__removeIcon"
                onClick={() => onRemove(index)}>
                <CloseModal />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="replyInputBox__content">
        <div className="replyInputBox__content__inputWrapper" tabIndex={-1}>
          {!noMedia && (
            <>
              <label
                htmlFor="repliesFileUpload"
                className="replyInputBox__content__inputWrapper__addIcon">
                <MdAdd size={20} color={colors.BACKGROUND} />
              </label>
              <input
                id="repliesFileUpload"
                type="file"
                accept="image/*"
                multiple
                max={MAX_FILES_ALLOWED - selectedFiles.length}
                onChange={handleFileChange}
                disabled={isSaving}
              />
            </>
          )}
          <div
            ref={inputRef}
            className="replyInputBox__content__inputWrapper__input slimScroll"
            role="textbox"
            contentEditable
            suppressContentEditableWarning
            spellCheck
            dir="ltr"></div>
        </div>
        <Button
          disabled={isSaving}
          className="replyInputBox__content__sendIcon"
          onClick={onSend}>
          {isSaving ? (
            <Loader size="small" />
          ) : (
            <MdSend size={20} color={colors.BACKGROUND} />
          )}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(InputBox);
