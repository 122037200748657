import { message } from 'antd';
import React, { memo } from 'react';

import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoLogoYoutube,
} from 'react-icons/io5';

interface ISocialMediaLinkProps {
  type: string;
  url: string;
}

const SocialMediaLink: React.FC<ISocialMediaLinkProps> = ({ type, url }) => {
  const iconSize = 20;
  const iconColor = '#fff';

  const iconMap: {
    [key: string]: {
      color: string;
      icon: React.ReactNode;
    };
  } = {
    facebook: {
      color: '#3b5998',
      icon: <IoLogoFacebook size={iconSize} color={iconColor} />,
    },
    instagram: {
      color: '#e1306c',
      icon: <IoLogoInstagram size={iconSize} color={iconColor} />,
    },
    linkedin: {
      color: '#0077b5',
      icon: <IoLogoLinkedin size={iconSize} color={iconColor} />,
    },
    twitter: {
      color: '#1da1f2',
      icon: <IoLogoTwitter size={iconSize} color={iconColor} />,
    },
    youtube: {
      color: '#ff0000',
      icon: <IoLogoYoutube size={iconSize} color={iconColor} />,
    },
  };
  return (
    <div
      onClick={() => {
        try {
          window.open(url, '_blank');
        } catch (err) {
          console.log(err);
          message.error('Invalid URL');
        }
      }}
      style={{
        cursor: 'pointer',
      }}>
      <span
        style={{
          backgroundColor: iconMap[type].color,
          borderRadius: 50,
          width: 30,
          height: 30,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {iconMap[type].icon}
      </span>
    </div>
  );
};

export default memo(SocialMediaLink);
