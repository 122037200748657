import './styles.scss';

import * as React from 'react';

import { message } from 'antd';

import UserAPI from '../../../../api/UserAPI';
import showAppError from '../../../../shared/error';
import {
  useAppNavigate,
  useAppSelector,
} from '../../../../shared/hooks';
import { ROUTES } from '../../../../types/routes';
import AccountWarningItem from './AccountWarningItem';

interface Props {}

enum Status {
  NULL,
  SENT,
  LOADING,
}

const AccountWarningCard: React.FC<Props> = () => {
  const navigate = useAppNavigate();

  const {
    hostMetadata: { sendGridDomainVerified },
    isTagMango,
  } = useAppSelector((state) => state.app);
  const { isEmailVerified, requiresPostMigrationVerification, email } =
    useAppSelector((state) => state.user);

  const [emailStatus, setEmailStatus] = React.useState<Status>(Status.NULL);

  const sendEmailVerification = React.useCallback(async () => {
    setEmailStatus(Status.LOADING);
    try {
      const resp = await UserAPI.sendEmailVerificationLink();
      if (resp.status === 200) {
        message.success('Verification link has been sent to your email.');
        setEmailStatus(Status.SENT);
      } else {
        showAppError(resp.data);
        setEmailStatus(Status.NULL);
      }
    } catch (err) {
      showAppError(err);
      setEmailStatus(Status.NULL);
    }
  }, []);

  const cardItems: JSX.Element[] = React.useMemo(() => {
    const items = [];
    if (
      !isEmailVerified &&
      (isTagMango || sendGridDomainVerified) &&
      emailStatus !== Status.SENT
    ) {
      items.push(
        <AccountWarningItem
          key="email"
          title="Your email is not verified!"
          // description={
          //   emailStatus === Status.SENT ? (
          //     <span>
          //       Verification link has been sent to <b>{email}</b>
          //     </span>
          //   ) : (
          //     'Please click on the link that will be sent to your registered email to verify your email address.'
          //   )
          // }
          // buttonTitle={
          //   emailStatus === Status.SENT ? 'Resend' : 'Send verification link'
          // }
          description={
            <span>
              Please click on the link that will be sent to your registered
              email to verify your email address.
            </span>
          }
          buttonTitle={'Send verification link'}
          onPress={sendEmailVerification}
          loading={emailStatus === Status.LOADING}
        />,
      );
    }
    if (requiresPostMigrationVerification) {
      items.push(
        <AccountWarningItem
          key="phone"
          title="Your mobile is not verified!"
          description={
            <span>
              Please click on the link below or go to{' '}
              <b>My Profile &gt; Account &gt; Change registered number</b> to
              verify your mobile.
            </span>
          }
          buttonTitle="Verify mobile"
          onPress={() => {
            navigate(ROUTES.CHANGE_NUMBER);
          }}
        />,
      );
    }
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    email,
    emailStatus,
    isEmailVerified,
    sendEmailVerification,
    requiresPostMigrationVerification,
  ]);

  return cardItems.length > 0 ? (
    <div className="feedCard account-warning-card">
      <div className="warning__title">Warning</div>
      {cardItems.map((item, index) => (
        <React.Fragment key={item.key}>
          {item}
          {index !== cardItems.length - 1 && <div className="divider" />}
        </React.Fragment>
      ))}
    </div>
  ) : null;
};

export default React.memo(AccountWarningCard);
