/* eslint-disable no-case-declarations */
import { AxiosResponse } from 'axios';

/* eslint-disable no-underscore-dangle */
import {
  PayloadAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';

import ChatAPI from '../../api/ChatAPI';
import { IResponseType } from '../../types/baseTypes';
import { IMessage } from '../../types/chatModels/ChatMessagesResponse';
import {
  DMRoomListResult,
  MangoRoomListResult,
} from '../../types/chatModels/MangoRoomListResponse';
import { SendMessageResult } from '../../types/chatModels/SendMessage';
import { RoomTypes } from '../../types/messageTypes';
import {
  RoomListDetails,
  setShowScrollDown,
  UnreadCount,
  updateChatRoomData,
  updateDMRoomData,
  updateMangoRoomData,
  updateUnreadCount,
} from '../chatReducer';
import { RootState } from '../store';

const pageSize = 10;
export const fetchMoreDMRooms =
  (reset?: boolean) =>
  async (
    dispatch: ThunkDispatch<any, unknown, PayloadAction<any>>,
    getState: () => RootState,
  ) => {
    const state = getState();
    const dmRoomState = state.chat.dmRoomList;
    if (['complete', 'loading'].includes(dmRoomState.status) && !reset) return;
    const pageToLoad = reset ? 1 : dmRoomState.pageNumber + 1;
    dispatch(updateDMRoomData({ ...dmRoomState, status: 'loading' }));
    // console.log('loading data for page', pageToLoad);
    const rooms = (
      await ChatAPI.getDMRoomList(pageToLoad, pageSize * 2)
    ).data.result.map((room) => {
      const newRoom = { ...room };
      if (room.lastMessage.senderId === room.otherParticipant?._id) {
        newRoom.lastMessage.senderName = room.otherParticipant.name;
        newRoom.lastMessage.senderProfilePic =
          room.otherParticipant.profilePicUrl;
      } else {
        newRoom.lastMessage.senderName = state.user.name as string;
        newRoom.lastMessage.senderProfilePic = state.user.profilePic as string;
      }
      return newRoom;
    });
    // console.log('existing rooms');
    // console.log(dmRoomState.roomList.map((r) => r._id));
    // console.log('new rooms');
    // console.log(rooms);
    // console.log(rooms.map((r) => r._id));

    dispatch(
      updateDMRoomData({
        pageNumber: pageToLoad,
        roomList: reset ? rooms : [...dmRoomState.roomList, ...rooms],
        status: rooms.length === 0 ? 'complete' : 'idle',
      }),
    );
  };

export const fetchMoreMangoRooms =
  (reset?: boolean) =>
  async (
    dispatch: ThunkDispatch<any, unknown, PayloadAction<any>>,
    getState: () => RootState,
  ) => {
    const mangoRoomState = getState().chat.mangoRoomList;
    if (['complete', 'loading'].includes(mangoRoomState.status) && !reset)
      return;
    const pageToLoad = reset ? 1 : mangoRoomState.pageNumber + 1;

    dispatch(updateMangoRoomData({ ...mangoRoomState, status: 'loading' }));
    const data = await ChatAPI.getMangoRoomList(pageToLoad, pageSize);
    dispatch(
      updateMangoRoomData({
        pageNumber: pageToLoad,
        roomList: reset
          ? data.data.result
          : [...mangoRoomState.roomList, ...data.data.result],
        status: data.data.result.length === 0 ? 'complete' : 'idle',
      }),
    );
  };

const messagePageSize = 20;
export const fetchMoreMessages =
  (roomId: string, direction: 'up' | 'down' = 'up', callback?: () => void) =>
  async (
    dispatch: ThunkDispatch<any, unknown, PayloadAction<any>>,
    getState: () => RootState,
  ) => {
    // console.log('fetching more messages', direction);
    const chatRoomState = getState().chat.chatRoomData;
    let data: AxiosResponse<IResponseType<IMessage[]>>;

    if (chatRoomState.roomId === roomId) {
      // this room might already have some data,
      if (chatRoomState.allMessagesLoaded && direction === 'up') {
        // console.log('all messages are already loaded');
        return;
      }
      dispatch(
        updateChatRoomData({
          ...chatRoomState,
        }),
      );
      data = await ChatAPI.getChatsForRoom(
        roomId,
        chatRoomState.messages[
          direction === 'down' ? 0 : chatRoomState.messages.length - 1
        ]._id,
        messagePageSize,
        direction,
      );
    } else {
      data = await ChatAPI.getChatsForRoom(roomId, undefined, messagePageSize);
    }

    const scrollElement = document.getElementById('bidirectional-scroll');
    let scrollPosition = 0;
    let oldScroll = 0;
    if (scrollElement) {
      scrollPosition = scrollElement.scrollTop;
      oldScroll = scrollElement.scrollHeight - scrollElement.clientHeight;
    }

    if (direction === 'up') {
      dispatch(
        updateChatRoomData({
          messages: [
            ...(chatRoomState.roomId === roomId ? chatRoomState.messages : []),
            ...data.data.result.reverse(),
          ],
          roomId,
          allMessagesLoaded: data.data.result.length < messagePageSize,
          hasMore: {
            ...chatRoomState.hasMore,
            previous: data.data.result.length === messagePageSize,
          },
        }),
      );
    } else {
      dispatch(
        updateChatRoomData({
          messages: [
            ...data.data.result.reverse(),
            ...(chatRoomState.roomId === roomId ? chatRoomState.messages : []),
          ],
          roomId,
          allMessagesLoaded: chatRoomState.allMessagesLoaded,
          hasMore: {
            ...chatRoomState.hasMore,
            next: data.data.result.length === messagePageSize,
          },
        }),
      );
    }

    if (direction === 'up' && scrollElement) {
      setTimeout(() => {
        const newScroll =
          scrollElement.scrollHeight - scrollElement.clientHeight;
        scrollElement.scrollTop = scrollPosition + (newScroll - oldScroll);
      }, 50);
    }

    if (callback) callback();
  };

export const getMessagesAroundParentMessage =
  (parentMessage: string, roomId: string) =>
  async (dispatch: ThunkDispatch<any, unknown, PayloadAction<any>>) => {
    try {
      const data = await ChatAPI.getMessagesAroundParentId(
        roomId,
        parentMessage,
      );
      dispatch(
        updateChatRoomData({
          allMessagesLoaded: false,
          messages: data.data.result.reverse(),
          roomId,
          hasMore: {
            previous: true,
            next: true,
          },
        }),
      );
      setTimeout(() => dispatch(setShowScrollDown(true)), 500);
    } catch (err) {
      console.log('err', err);
    }
  };
export const updateUnreadBadgeCount = (
  dispatch: ThunkDispatch<any, unknown, PayloadAction<any>>,
  roomId: string,
  roomType: RoomTypes,
  unreadCount: UnreadCount,
  roomDetails: RoomListDetails<MangoRoomListResult | DMRoomListResult>,
) => {
  const room = roomDetails.roomList.find(
    (r) => r._id === roomId && r.totalUnreadMessages !== 0,
  );
  if (room) {
    // reduce the unread count
    dispatch(
      updateUnreadCount({
        dm: {
          ...unreadCount.dm,
          unreadConversations: Math.max(
            unreadCount.dm.unreadConversations - +(roomType === 'dm'),
            0,
          ),
        },
        mango: {
          ...unreadCount.mango,
          unreadConversations: Math.max(
            unreadCount.mango.unreadConversations - +(roomType === 'mango'),
            0,
          ),
        },
      }),
    );
  }
};
export const markMessagesAsRead =
  (roomId: string, roomType: RoomTypes, messageId?: string) =>
  async (
    dispatch: ThunkDispatch<any, unknown, PayloadAction<any>>,
    getState: () => RootState,
  ) => {
    // call the api here
    try {
      ChatAPI.updateMessageReadStatus(roomId, messageId);
    } catch (err) {
      console.log('Failed to update read status!', JSON.stringify(err));
    }
    const {
      chat: { dmRoomList, mangoRoomList, unreadCount },
    } = getState();
    updateUnreadBadgeCount(
      dispatch,
      roomId,
      roomType,
      unreadCount,
      roomType === 'dm' ? dmRoomList : mangoRoomList,
    );
    if (roomType === 'dm') {
      dispatch(
        updateDMRoomData({
          ...dmRoomList,
          roomList: dmRoomList.roomList.map((room) => {
            if (room._id !== roomId) return room;
            return {
              ...room,
              lastMessage: { ...room.lastMessage, seen: true },
              totalUnreadMessages: 0,
            };
          }),
        }),
      );
    } else if (roomType === 'mango') {
      dispatch(
        updateMangoRoomData({
          ...mangoRoomList,
          roomList: mangoRoomList.roomList.map((room) => {
            if (room._id !== roomId) return room;
            return {
              ...room,
              messages: room.messages.map((msg) => ({ ...msg, seen: true })),
              totalUnreadMessages: 0,
            };
          }),
        }),
      );
    }
  };

export const addNewMessage =
  (
    newMessage: SendMessageResult,
    type: 'sending' | 'sent' | 'received' = 'sent',
  ) =>
  (
    dispatch: ThunkDispatch<any, unknown, PayloadAction<any>>,
    getState: () => RootState,
  ) => {
    const {
      chatRoomData: chatState,
      dmRoomList,
      mangoRoomList,
      unreadCount,
    } = getState().chat;
    const userId = getState().user.id;
    if (newMessage.room === chatState.roomId) {
      const existingMessage = chatState.messages.find((message) =>
        message.extraData?.referenceId
          ? message.extraData?.referenceId === newMessage.extraData?.referenceId
          : message._id === newMessage._id,
      );
      let newMessages: IMessage[] = [];
      if (!existingMessage) {
        newMessages = [
          {
            ...newMessage,
            seenUsers: [userId as string],
            sending: type === 'sending',
            messageRef: newMessage.messageRef || {},
            parentMessageSender: (newMessage.parentMessageSender as any) || {
              _id: '',
              name: '',
              profilePicUrl: '',
            },
          },
          ...chatState.messages,
        ];
      } else {
        // remove the old sending message and insert this new message in its place
        newMessages = chatState.messages.map((msg) => {
          if (
            msg.extraData?.referenceId ===
            existingMessage.extraData?.referenceId
          ) {
            return {
              ...msg,
              ...newMessage,
              seenUsers: [userId as string],
              sending: type === 'sending',
            };
          }
          return msg;
        });
      }
      dispatch(
        updateChatRoomData({
          ...chatState,
          messages: newMessages,
        }),
      );
      dispatch(setShowScrollDown(true));
    }
    if (newMessage.roomDetails.roomType) {
      switch (newMessage.roomDetails.roomType) {
        case 'dm':
          const dmRoomIndex = dmRoomList.roomList.findIndex(
            (room) => room._id === newMessage.roomDetails._id,
          );
          if (dmRoomIndex !== -1) {
            const newRoom: DMRoomListResult = {
              ...dmRoomList.roomList[dmRoomIndex],
              lastMessage: {
                ...newMessage,
                seen: newMessage.room === chatState.roomId,
              },
              totalUnreadMessages:
                dmRoomList.roomList[dmRoomIndex].totalUnreadMessages +
                +(newMessage.room !== chatState.roomId),
            };
            const newArray = [...dmRoomList.roomList];
            newArray.splice(dmRoomIndex, 1);
            dispatch(
              updateDMRoomData({
                ...dmRoomList,
                roomList: [newRoom, ...newArray],
              }),
            );
          } else {
            dispatch(fetchMoreDMRooms(true));
          }
          break;
        case 'mango':
          const mangoRoomIndex = mangoRoomList.roomList.findIndex(
            (room) => room._id === newMessage.roomDetails._id,
          );
          if (mangoRoomIndex !== -1) {
            const newRoom: MangoRoomListResult = {
              ...mangoRoomList.roomList[mangoRoomIndex],
              messages: [
                { ...newMessage, seen: newMessage.room === chatState.roomId },
                ...mangoRoomList.roomList[mangoRoomIndex].messages.filter(
                  (i) => i._id !== newMessage._id,
                ),
              ],
              totalUnreadMessages:
                mangoRoomList.roomList[mangoRoomIndex].totalUnreadMessages +
                +(chatState.roomId !== newMessage.room),
            };
            const newMangoArray = [...mangoRoomList.roomList];
            newMangoArray.splice(mangoRoomIndex, 1);
            dispatch(
              updateMangoRoomData({
                ...mangoRoomList,
                roomList: [newRoom, ...newMangoArray],
              }),
            );
          } else {
            dispatch(fetchMoreMangoRooms(true));
          }
          break;
        default:
      }
    }
    if (newMessage.room === chatState.roomId) {
      // mark these messages as seen
      dispatch(
        markMessagesAsRead(
          chatState.roomId,
          newMessage.roomDetails.roomType,
          newMessage._id,
        ),
      );
    }

    // update notification badge count
    if (newMessage.roomDetails.roomType === 'mango') {
      const index = mangoRoomList.roomList.findIndex(
        (room) => room._id === newMessage.room,
      );
      if (
        index !== -1 &&
        mangoRoomList.roomList[index].totalUnreadMessages === 0
      ) {
        dispatch(
          updateUnreadCount({
            dm: {
              unreadConversations: unreadCount.dm.unreadConversations,
              unreadMessages: 0,
            },
            mango: {
              unreadConversations:
                unreadCount.mango.unreadConversations +
                +(chatState.roomId !== newMessage.room),
              unreadMessages: 0,
            },
          }),
        );
      }
    } else if (newMessage.roomDetails.roomType === 'dm') {
      const index = dmRoomList.roomList.findIndex(
        (room) => room._id === newMessage.room,
      );
      if (
        index !== -1 &&
        dmRoomList.roomList[index].totalUnreadMessages === 0
      ) {
        dispatch(
          updateUnreadCount({
            dm: {
              unreadConversations:
                unreadCount.dm.unreadConversations +
                +(chatState.roomId !== newMessage.room),
              unreadMessages: 0,
            },
            mango: {
              unreadConversations: unreadCount.mango.unreadConversations,
              unreadMessages: 0,
            },
          }),
        );
      }
    }
  };

interface ReadReceipt {
  messageIds: string[];
  room: string;
  roomType: string;
}
export const updateReadReceiptData =
  (readMessages: ReadReceipt) =>
  async (
    dispatch: ThunkDispatch<any, unknown, PayloadAction<any>>,
    getState: () => RootState,
  ) => {
    // currently only updating DM rooms, mango room support will be added in future
    if (readMessages.roomType === 'dm') {
      // find if this room is available in list
      const { chat } = getState();
      const newMessages = chat.chatRoomData.messages.map((message) => {
        if (readMessages.messageIds.includes(message._id)) {
          return {
            ...message,
            seenUsers: [message.senderId, message.senderId],
          };
        }
        return message;
      });
      dispatch(
        updateChatRoomData({ ...chat.chatRoomData, messages: newMessages }),
      );
    }
  };
export const updateUnreadMessagesData =
  () => async (dispatch: ThunkDispatch<any, unknown, PayloadAction<any>>) => {
    try {
      const data = await ChatAPI.getUnreadChatCount();
      dispatch(
        updateUnreadCount({
          dm: {
            unreadConversations: data.data.result.dm,
            unreadMessages: 0,
          },
          mango: {
            unreadConversations: data.data.result.mango,
            unreadMessages: 0,
          },
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

export const updateMangoRoomPicture =
  (roomId: string, picUrl: string) =>
  async (
    dispatch: ThunkDispatch<any, unknown, PayloadAction<any>>,
    getState: () => RootState,
  ) => {
    const { chat } = getState();
    const index = chat.mangoRoomList.roomList.findIndex(
      (room) => room._id === roomId,
    );
    const updatedRoomList = [...chat.mangoRoomList.roomList];
    updatedRoomList[index] = { ...updatedRoomList[index], picUrl };
    if (index !== -1) {
      dispatch(
        updateMangoRoomData({
          ...chat.mangoRoomList,
          roomList: updatedRoomList,
        }),
      );
    }
  };
