import { CurrencyType } from '../types/baseTypes';

export const CURRENCY_SYMBOLS_MAP: Record<CurrencyType, string> = {
  INR: '₹',
  USD: '$',
  EUR: '€',
};

export const EUR_COUNTRIES = [
  'MT',
  'LU',
  'CY',
  'EE',
  'LV',
  'SI',
  'LT',
  'IE',
  'SK',
  'FI',
  'AT',
  'PT',
  'GR',
  'BE',
  'NL',
  'ES',
  'IT',
  'FR',
  'DE',
];

export const ErrorStatusMessages = {
  400: 'Oops! Something went wrong. Please try again later.',
  401: 'You are not signed in. Please log in to continue.',
  403: 'Access denied. You do not have permission to perform this action.',
  404: 'We couldn’t find what you are looking for.',
  405: 'This action is not allowed. Please check and try again.',
  408: 'The request took too long. Please try again.',
  409: 'This action is not allowed at the moment. Please check and try again later.',
  410: 'This content is no longer available.',
  413: 'The file size is too large. Please use a smaller file and try again.',
  415: 'Unsupported file format. Please use a different file type.',
  429: 'Too many requests. Please wait and try again later.',
  500: 'Something went wrong on our end. Please try again later.',
  501: 'This request is not supported. Please check and try again.',
  502: 'There’s an issue with the server. Please try again later.',
  503: 'We’re experiencing high traffic. Please try again soon.',
  504: 'The server took too long to respond. Please try again in a moment.',
} as const;

export const SEARCH_THRESHOLD = 3;
