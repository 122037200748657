import './styles.scss';

import React, { useState } from 'react';

import { Avatar, Typography } from 'antd';
import HtmlParser from 'html-react-parser';
import { BottomSheet } from 'react-spring-bottom-sheet';

import DeleteSvg from '../../../assets/svg/Delete';
import BottomSheetTile from '../../../components/BottomSheetTile/BottomSheetTile';
import ImageAttachment from '../../../components/ImageAttachment/ImageAttachment';
import MenuButton from '../../../components/MenuButton/MenuButton';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal/ConfirmationModal';
import { timeAgo } from '../../../shared/utils';

interface Props {
  attachments?: string[];
  description: string;
  createdAt: Date;
  imgUrl?: string;
  userName: string;
  isCreator: boolean;
  containerStyle?: React.CSSProperties;
  showMenu?: boolean;
  onRemoveItem?: () => void;
}

const QNAReplyItem: React.FC<Props> = ({
  attachments,
  description,
  createdAt,
  imgUrl,
  userName,
  isCreator,
  containerStyle = {},
  showMenu = false,
  onRemoveItem = () => {},
}) => {
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  return (
    <>
      <div className="qna__reply__item" style={containerStyle}>
        <Avatar size={29} src={imgUrl} />
        <div style={{ marginLeft: 12, width: '100%' }}>
          <div className="qna__reply__item__header">
            <Typography.Title
              className="qna__reply__item__header__title"
              level={4}>
              {userName}{' '}
              {isCreator ? (
                <Typography.Text className="qna__reply__item__header__title__creator">
                  INSTRUCTOR
                </Typography.Text>
              ) : null}
              <Typography.Text className="qna__reply__item__header__title__time">
                • {timeAgo(createdAt)}
              </Typography.Text>
            </Typography.Title>
            {showMenu ? (
              <div className="qna__reply__item__header__menu__wrapper">
                <MenuButton
                  className="qna__reply__item__header__menu"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowBottomSheet(true);
                  }}
                />
              </div>
            ) : null}
          </div>
          {description ? (
            <div
              style={{
                marginTop: 6,
              }}>
              <Typography.Paragraph
                ellipsis={{ rows: 3, expandable: true, symbol: 'See more' }}
                className="qna__reply__item__description">
                {HtmlParser(description)}
              </Typography.Paragraph>
            </div>
          ) : null}
          {attachments && attachments?.length > 0 ? (
            <ImageAttachment
              containerStyle={{
                marginTop: 12,
              }}
              images={attachments}
              maxImagesAllowed={2}
            />
          ) : null}
        </div>
      </div>
      <BottomSheet
        open={showBottomSheet}
        onDismiss={() => setShowBottomSheet(false)}>
        <BottomSheetTile
          key={1}
          icon={<DeleteSvg width={20} height={20} />}
          warning
          alignIcon="flex-start"
          title="Remove question"
          description="The question and all of its replies will be removed from this thread"
          onClick={() => {
            setShowBottomSheet(false);
            setIsDialogVisible(true);
          }}
        />
      </BottomSheet>
      <ConfirmationModal
        open={isDialogVisible}
        message="Do you really want to delete the question?"
        title="Remove"
        okayButtonText="Delete"
        cancelButtonText="Cancel"
        handleOk={() => {
          setIsDialogVisible(false);
          onRemoveItem();
        }}
        handleCancel={() => {
          setIsDialogVisible(false);
        }}
      />
    </>
  );
};

export default React.memo(QNAReplyItem);
