export const getFileName = (name: string): string => {
  const fullName = name.split('/').pop();
  if (fullName) {
    const split = fullName.split('-');
    split.pop();
    const ext = fullName.split('.').pop();
    return `${split.join('-')}.${ext}`;
  }
  return name;
};
