import * as React from 'react';

import { useTheme } from '../../context/ThemeProvider';

const EditPostSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg
      width={21}
      height={21}
      viewBox="0 0 15.992 15.991"
      {...props}>
      <g id="edit" transform="translate(0 -5.501)">
        <path
          id="Path_778"
          data-name="Path 778"
          d="M14.578,91.955a.767.767,0,0,0-.767.767V98.86a.768.768,0,0,1-.767.767H2.3a.768.768,0,0,1-.767-.767V88.118a.768.768,0,0,1,.767-.767H8.44a.767.767,0,0,0,0-1.535H2.3a2.3,2.3,0,0,0-2.3,2.3V98.86a2.3,2.3,0,0,0,2.3,2.3H13.044a2.3,2.3,0,0,0,2.3-2.3V92.722A.767.767,0,0,0,14.578,91.955Zm0,0"
          transform="translate(0 -79.67)"
          strokeWidth="0.5"
          fill={colors.ICON}
        />
        <path
          id="Path_779"
          data-name="Path 779"
          d="M182.463,74.332a.388.388,0,0,0-.105.2l-.542,2.713a.383.383,0,0,0,.377.459.366.366,0,0,0,.075-.008l2.712-.542a.382.382,0,0,0,.2-.105l6.071-6.071-2.712-2.712Zm0,0"
          transform="translate(-177.692 -60.324)"
          strokeWidth="0.5"
          fill={colors.ICON}
        />
        <path
          id="Path_780"
          data-name="Path 780"
          d="M395.22,1.062a1.919,1.919,0,0,0-2.712,0l-1.062,1.062,2.712,2.712,1.062-1.062a1.918,1.918,0,0,0,0-2.712Zm0,0"
          transform="translate(-379.79 5)"
          strokeWidth="0.5"
          fill={colors.ICON}
        />
      </g>
    </svg>
  );
};

export default EditPostSvg;
