/* eslint-disable @typescript-eslint/no-use-before-define */
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { message } from 'antd';
import moment from 'moment';

import API from '../api';
import FeedAPI from '../api/FeedAPI';
import showAppError from '../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../shared/hooks';
import { setLocalData } from '../shared/utils';
import {
  CustomRouteConfig,
  InitialRoute,
} from '../types/baseTypes';
import {
  IMangoExtended,
  IWebinar,
} from '../types/feedTypes';
import {
  HomeRoutes,
  PATH_MAP,
  ROUTES,
} from '../types/routes';
import {
  IUser,
  OnboardingStatus,
} from '../types/userTypes';
import {
  clearActivity,
  getPosts,
  resetAllFilters,
  setPostState,
  setSelected,
  setSubscriptions,
} from './activityReducer';
import {
  setCustomRouter,
  setDeferredPrompt,
  setLoggedIn,
  setTags,
} from './appReducer';
import { updateSubscribedMangoes } from './thunks/userThunks';
import {
  clearUserDetails,
  setCreatorMangoes,
  setUserDetails,
} from './userReducer';

interface IUseApp {
  onMeetingJoin: (slot: IWebinar) => void;
  handleLogout: () => void;
  getRouteTitle: (routeName: HomeRoutes) => string;
}

const AppContext = createContext<IUseApp>({
  onMeetingJoin: () => {},
  handleLogout: () => {},
  getRouteTitle: () => '',
});

export const useAppProvider = () => useContext(AppContext) as IUseApp;

interface IProps {
  children: React.ReactNode;
}

const BOTTOM_ITEMS_COUNT = 5;

const AppProvider: React.FC<IProps> = ({ children }) => {
  // const location = useLocation();

  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const {
    selected,
    filters,
    filteredMangoes,
    sort,
    postTags,
    isUserProfilePage,
  } = useAppSelector((state) => state.activity);
  const {
    token,
    mDeeplinkUrl,
    isTagMango,
    isLoggedIn,
    customRouter,
    hostMetadata: { routingConfig, creator },
  } = useAppSelector((state) => state.app);
  const userDetails = useAppSelector((state) => state.user);

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [intervalTokenId, setIntervalTokenId] = useState<NodeJS.Timeout | null>(
    null,
  );

  const reloadPosts = () => {
    const newState = {
      posts: [],
      hasMore: true,
      page: 1,
      loading: false,
      refreshing: false,
    };

    dispatch(
      setPostState({
        posts: [],
        hasMore: true,
        page: 1,
      }),
    );

    if (selected) {
      dispatch(
        getPosts({
          pageCount: 1,
          postList: [],
          hasMore: true,
          loading: false,
          currState: newState,
        }),
      );
    }
  };

  const fetchCookies = async (
    accessNewToken: string | null = null,
    count = 0,
  ) => {
    try {
      if (token || accessNewToken) {
        await fetch(`${mDeeplinkUrl || 'https://tagmango.com'}get-cookie`, {
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessNewToken || token}`,
          },
        });
      } else {
        await fetch(`${mDeeplinkUrl || 'https://tagmango.com'}get-cookie`, {
          credentials: 'include',
        });
      }
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status === 401 && count <= 3) {
        API.refreshToken({
          callback: fetchCookies,
          count,
        });
        return false;
      }
    }
  };

  useEffect(() => {
    if (!token) {
      if (intervalTokenId) clearInterval(intervalTokenId);
      setIntervalTokenId(null);
      const cookiesFetchTime = 120 * 1000;
      fetchCookies();
      const id = setInterval(() => {
        fetchCookies();
      }, cookiesFetchTime);
      setIntervalId(id);
    } else {
      loadTags();
      if (intervalId) clearInterval(intervalId);
      setIntervalId(null);
      const cookiesFetchTime = 120 * 1000;
      fetchCookies();
      const id = setInterval(() => {
        fetchCookies();
      }, cookiesFetchTime);
      setIntervalTokenId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    // Do not load posts if user is on profile page as user profile page loads posts by the user
    if (!isUserProfilePage) {
      reloadPosts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, filteredMangoes, sort, postTags, isUserProfilePage]);

  useEffect(() => {
    // Do not load posts if user is on profile page as user profile page loads posts by the user
    if (!isUserProfilePage) {
      dispatch(resetAllFilters());
      reloadPosts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, isUserProfilePage]);

  const handleDeferredPrompt = (e: any) => {
    // if app can be installed, assign the event to deferred prompt variable
    dispatch(setDeferredPrompt(e));
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleDeferredPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleDeferredPrompt);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTags = async () => {
    try {
      const response = await FeedAPI.getTags();
      if (response.status === 200 && response.data.result.length > 0) {
        dispatch(
          setTags(
            response.data.result.map((t) => ({
              key: t._id,
              value: t.tagName,
              creatorId: t.creator,
            })),
          ),
        );
      }
    } catch (err: any) {
      console.log(err?.response);
    }
  };

  const timeDiffinMin = (data: any) => {
    const fromTime = data?.fromTime;
    if (fromTime) {
      const now = moment(new Date());
      const end = moment(fromTime);
      return moment.duration(end.diff(now)).asMinutes();
    }
    return 20;
  };

  const meetingRedirect = async (slot: IWebinar) => {
    if (slot.platform === 'custom') {
      if (slot.meetingUrl) window.open(slot.meetingUrl, '_blank');
      else message.error('Meeting url not found');
    } else if (slot.creator === userDetails.id && slot.zoomMeetingLinkCreator) {
      window.open(slot.zoomMeetingLinkCreator);
    } else if (slot.zoomMeetingLink) {
      window.open(slot.zoomMeetingLink);
    } else if (slot.zoomMeetingNumber) {
      try {
        const resp = await API.fetchNewToken(undefined, 6 * 3600);
        if (resp?.data?.result?.accessToken) {
          const sessionToken = resp.data.result.accessToken;
          window.open(
            `https://videocall.tagmango.com/zoom/?meetId=${slot._id}&sessionToken=${sessionToken}`,
            '_blank',
          );
        } else {
          showAppError(resp?.data);
        }
      } catch (err: any) {
        showAppError(err);
      }
    } else {
      message.error(
        'Meeting details are unavailable. Please refresh the page and try again.',
      );
    }
  };

  const onMeetingJoin = async (slot: IWebinar) => {
    if (slot?.creator === userDetails.id) {
      if (timeDiffinMin(slot) > 30) {
        message.error(
          'Group video call link will be activated 30 minutes prior to the allotted time.',
        );
      } else {
        meetingRedirect(slot);
      }
    } else {
      if (timeDiffinMin(slot) > 15) {
        message.error(
          'Group video call link will be activated 15 minutes prior to the allotted time.',
        );
      } else {
        meetingRedirect(slot);
      }
    }
  };

  const handleLogout = () => {
    setLocalData(null);
    dispatch(setLoggedIn(false));
    dispatch(clearActivity());
    dispatch(clearUserDetails());
    setTimeout(() => {
      navigate(ROUTES.LOGIN);
    }, 100);
  };

  const getRouteTitle = (routeName: HomeRoutes) => {
    const defaultTitles: Record<HomeRoutes, string> = {
      [ROUTES.FEED]: 'Feed',
      [ROUTES.COURSES]: 'Courses',
      [ROUTES.MESSAGES]: 'Messages',
      [ROUTES.CUSTOM_APPS]: 'Tools',
      [ROUTES.VIDEO_CALL]: 'Video Calls',
      [ROUTES.LEADERBOARD]: 'Leaderboard',
      [ROUTES.STOREFRONT]: 'Storefront',
    };
    const { homeRoutes, menuRoutes } = customRouter;
    const allRoutes = [...homeRoutes, ...menuRoutes];
    const route = allRoutes.find((r) => r.name === routeName);
    if (route) {
      return route.config.title;
    } else {
      return defaultTitles[routeName];
    }
  };

  useEffect(() => {
    if (routingConfig) {
      const { routes, initialRoutes } = routingConfig;
      let appRoutes: {
        name: HomeRoutes;
        config: CustomRouteConfig;
        key: string;
      }[] = routes.map((route) => {
        let routeName: HomeRoutes;
        if (PATH_MAP[route.path]) {
          routeName = PATH_MAP[route.path];
        } else {
          routeName = ROUTES.CUSTOM_APPS;
        }
        return {
          name: routeName.replace(':key', route.key) as HomeRoutes,
          config: route,
          key: route.key,
        };
      });

      const homeRoutes = appRoutes.slice(0, BOTTOM_ITEMS_COUNT);
      const menuRoutes = appRoutes.slice(BOTTOM_ITEMS_COUNT, appRoutes.length);

      const customInitialRoutes: Partial<
        Record<OnboardingStatus | 'default', InitialRoute>
      > =
        initialRoutes && Object.keys(initialRoutes).length > 0
          ? (
              Object.keys(initialRoutes) as (OnboardingStatus | 'default')[]
            ).reduce((acc, item) => {
              if (initialRoutes[item]) {
                if (PATH_MAP[initialRoutes[item].path]) {
                  acc[item] = {
                    route: PATH_MAP[initialRoutes[item].path],
                    path: initialRoutes[item].path,
                    isTMProject: initialRoutes[item].isTMProject ?? true,
                  };
                } else {
                  let route = appRoutes.find(
                    (r) => r?.key === initialRoutes[item].key,
                  );
                  if (
                    route &&
                    route.config.apps &&
                    route.config.apps.length > 0
                  ) {
                    const app = route.config.apps[0];
                    const path = ROUTES.CUSTOM_APPS.replace(
                      ':key',
                      `${app.slug}/${app.entryPath}`,
                    );
                    acc[item] = {
                      route: ROUTES.CUSTOM_APPS.replace(
                        ':key',
                        initialRoutes[item].key,
                      ) as HomeRoutes,
                      path,
                      isTMProject: initialRoutes[item].isTMProject ?? true,
                    };
                  }
                }
              }
              return acc;
            }, {} as Partial<Record<OnboardingStatus | 'default', InitialRoute>>)
          : {};

      if (!customInitialRoutes.default) {
        customInitialRoutes.default = {
          route: appRoutes[0].name,
          path: appRoutes[0].config.path,
          isTMProject: true,
        };
      }

      dispatch(
        setCustomRouter({
          homeRoutes,
          menuRoutes,
          initialRoutes: customInitialRoutes,
        }),
      );
      // dispatch(setCustomRouter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routingConfig]);

  useEffect(() => {
    if (
      userDetails.type &&
      customRouter.initialRoutes.default &&
      !customRouter.initialRoutes.default.isTMProject
    ) {
      window.open(
        `${mDeeplinkUrl}web${customRouter.initialRoutes.default.path}`,
        '_self',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.type]);

  const handleApi = async () => {
    try {
      const newApi: any = (await FeedAPI.newActivityApi(userDetails.id)).data;

      let newUserDetails: IUser = {
        name: null,
        id: null,
        email: null,
        phone: null,
        profilePic: null,
        type: null,
        userSlug: null,
        showInbox: null,
        approveStatus: null,
        shortUrl: null,
        currency: 'INR',
        tmCommission: 0,
        country: undefined,
        UAMParentCreator: undefined,
        userAccessPermissions: undefined,
        isEmailVerified: true,
        requiresPostMigrationVerification: false,
      };
      let user: any;
      if (newApi?.result?.userDetails) {
        user = newApi?.result?.userDetails;
        newUserDetails = {
          ...newUserDetails,
          name: user?.name,
          id: user?._id,
          email: user?.email,
          phone: user?.phone,
          type: user?.onboarding,
          profilePic:
            user?.profilePicUrl ||
            user?.profilePicUrlUncompressed ||
            'https://tagmango.com/staticassets/avatar-placeholder.png-1612857612139.png',
          userSlug: user?.userSlug,
          showInbox: user?.showInbox || false,
          approveStatus: user?.creatorAccessRequested || null,
          shortUrl: user?.shortUrl,
          currency: user?.currency || 'INR',
          tmCommission: user?.tmCommission || 0,
          country: user?.country,
          UAMParentCreator: user?.creatorId,
          userAccessPermissions: user?.userAccessPermissions,
          isEmailVerified: user?.isEmailVerified,
          requiresPostMigrationVerification:
            user?.requiresPostMigrationVerification,
          isBlockedFromCommunityEngagement:
            user?.isBlockedFromCommunityEngagement,
          score: user?.score,
        };
        if (
          user.onboarding === 'dual' ||
          user.onboarding === 'creator_completed' ||
          user.onboarding === 'creator_restricted'
        ) {
          if (newApi?.result?.activeMangoes)
            dispatch(
              setCreatorMangoes(
                (newApi?.result?.activeMangoes || []).filter(
                  (item: IMangoExtended) =>
                    !item.isDeleted && !item.isStopTakingPayment,
                ),
              ),
            );
        }
      }
      if (user && user?.onboarding) {
        const allSubscriptions =
          newApi?.result?.activeSubscriptions.sort(
            (a: any, b: any) => b.updatedAt - a.updatedAt,
          ) || [];
        const subscriptionMap = allSubscriptions.reduce(
          (acc: any, item: any) => {
            let newItem = {
              ...item,
              mangoes: [
                ...(acc[item.mango.creator]?.mangoes || []),
                {
                  ...item.mango,
                },
              ],
              creatorId: item.mango.creator,
            };
            delete newItem.mango;
            acc[item.mango.creator] = newItem;
            return acc;
          },
          {},
        );
        const newSubscriptions: any[] = Object.values(subscriptionMap);
        dispatch(
          updateSubscribedMangoes(
            newSubscriptions.reduce((acc: any, item: any) => {
              return [...acc, ...item.mangoes];
            }, []),
          ),
        );
        if (!isTagMango) {
          let val = [...newSubscriptions];
          dispatch(setSubscriptions([...val]));
          dispatch(setSelected(creator._id));
        } else if (
          user?.onboarding === 'dual' ||
          user?.onboarding === 'creator_completed' ||
          user?.onboarding === 'creator_restricted'
        ) {
          let val = [...newSubscriptions];
          val = val.filter((s: any) => s.creatorId !== user?._id);
          val.unshift({
            name: user?.name,
            profilePicUrl: user?.profilePicUrl,
            creatorId: user?._id,
          });
          dispatch(setSubscriptions([...val]));
          if (val.length > 1) {
            dispatch(setSelected('all'));
          } else {
            dispatch(setSelected(user?._id));
          }
        } else {
          dispatch(setSubscriptions([...newSubscriptions]));
          if (newSubscriptions.length > 1) {
            dispatch(setSelected('all'));
          } else if (newSubscriptions.length === 1) {
            dispatch(setSelected(newSubscriptions[0]?.creatorId));
          } else {
            dispatch(setSelected(user?._id));
          }
        }
      }
      dispatch(setUserDetails(newUserDetails));
    } catch (err) {
      showAppError(err);
    }
  };

  useEffect(() => {
    if (!selected && isLoggedIn) handleApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, isLoggedIn]);

  return (
    <AppContext.Provider
      value={{
        onMeetingJoin,
        handleLogout,
        getRouteTitle,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
