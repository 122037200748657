import './styles.scss';

import React from 'react';

interface Props {
  title?: string;
}

const ChatInputDisabled: React.FC<Props> = ({
  title = 'You are not allowed to message in this room.',
}) => {
  return (
    <div className="chatInputDisableWrapper">
      <span>🚫</span> {title}
    </div>
  );
};

export default React.memo(ChatInputDisabled);
