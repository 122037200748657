import './styles.scss';

import React from 'react';

import { useAppNavigate, useAppSelector } from '../../shared/hooks';
import { navigateToOnboardingScreen } from '../../shared/utils';

interface Props {
  title: string;
  handleBack?: () => void;
  canGoBack?: boolean;
  noBorder?: boolean;
  actionItems?: React.ReactNode[];
  backType?: 'arrow' | 'cross';
  children?: React.ReactNode;
}

const Header: React.FC<Props> = ({
  title,
  handleBack,
  canGoBack = true,
  actionItems,
  noBorder = false,
  backType = 'arrow',
  children,
}) => {
  const navigate = useAppNavigate();

  const userDetails = useAppSelector((state) => state.user);

  const customHandleBack = () => {
    if (navigate.canGoBack()) {
      navigate.goBack();
    } else {
      navigateToOnboardingScreen(userDetails.type, navigate);
    }
  };

  handleBack = handleBack || customHandleBack;

  // useBackButton(() => {
  //   return canGoBack ? handleBack : () => {};
  // });

  return (
    <div
      className={`default-header__container ${noBorder ? 'borderless' : ''}`}>
      <div className="default-header">
        <button
          className="default-header__button"
          onClick={canGoBack ? handleBack : () => {}}>
          {canGoBack ? (
            backType === 'arrow' ? (
              <svg width="20" height="20" viewBox="0 0 9.893 17.109">
                <g>
                  <g>
                    <path
                      fill="currentColor"
                      stroke="currentColor"
                      d="M126.023 14.9l-7.041-6.88 7.041-6.879a.657.657 0 0 0 0-.945.7.7 0 0 0-.967 0l-7.525 7.352a.657.657 0 0 0 0 .945l7.525 7.352a.7.7 0 0 0 .967 0 .657.657 0 0 0 0-.945z"
                      transform="translate(-116.83 0.568) translate(117.33 0) translate(-117.33 0)"
                    />
                  </g>
                </g>
              </svg>
            ) : (
              <svg
                width="16.066"
                height="16"
                viewBox="0 0 16.066 16"
                style={{
                  height: '16px',
                  width: '16px',
                }}>
                <g id="close" transform="translate(0.566 0.499)">
                  <g
                    id="Group_33"
                    data-name="Group 33"
                    transform="translate(0 0.001)">
                    <path
                      fill="currentColor"
                      stroke="currentColor"
                      id="Path_98"
                      data-name="Path 98"
                      d="M8.328,7.5l6.5-6.5A.586.586,0,1,0,14,.172l-6.5,6.5L1,.172A.586.586,0,0,0,.171,1l6.5,6.5L.171,14A.586.586,0,0,0,1,14.829l6.5-6.5,6.5,6.5A.586.586,0,0,0,14.828,14Z"
                      transform="translate(0 -0.001)"
                      strokeWidth="1"
                    />
                  </g>
                </g>
              </svg>
            )
          ) : null}
        </button>
        <div className="default-header__title">
          <h2 className="default-header__title__text">{title}</h2>
        </div>
        <div className="header--activity__actions">{actionItems}</div>
      </div>
      {children}
    </div>
  );
};

export default React.memo(Header);
