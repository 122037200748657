import React from 'react';

import { Button } from 'antd';

interface Props {
  title?: string;
  onClick: () => void;
}

const ViewMoreComment: React.FC<Props> = ({
  onClick,
  title = 'View more comments',
}) => (
  <div className="viewMoreCommentWrapper">
    <Button className="siteBtn siteBtnLink dark" onClick={onClick}>
      {title}
    </Button>
  </div>
);

export default React.memo(ViewMoreComment);
