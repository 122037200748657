import React from 'react';

import crownIcon from '../../../../../../assets/img/crown.png';
import { useTheme } from '../../../../../../context/ThemeProvider';
import { useAppSelector } from '../../../../../../shared/hooks';
import { tintColor } from '../../../../../../shared/utils';
import ChatAvatar from '../../../../../Messages/components/ChatAvatar/ChatAvatar';
import { ILeaderboardItem } from '../../types';
import PointsPill from '../PointsPill/PointsPill';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  type?: 'primary' | 'highlight';
  medal?: string;
  item: ILeaderboardItem;
}

const WinnerCard: React.FC<Props> = ({ type = 'primary', medal, item }) => {
  const { colors } = useTheme();

  const {
    hostMetadata: { pointsConfig },
  } = useAppSelector((state) => state.app);

  return (
    <div
      className={`winner-card ${type}`}
      style={{
        backgroundColor:
          type === 'highlight' ? tintColor(colors.PRIMARY, 80) : colors.BORDER,
      }}>
      <div className="avatar">
        <div className="avatar__icon">
          <ChatAvatar
            url={item.profilePicUrl}
            name={item.name}
            size={type === 'highlight' ? 100 : 68}
            border={0}
          />
        </div>

        {medal && <img src={medal} alt="Medal" className="medal" />}

        {type === 'highlight' && (
          <img src={crownIcon} alt="Crown" className="crown" />
        )}
      </div>
      <div className="details">
        <div className="name">{item.name}</div>

        <PointsPill
          points={item.score || 0}
          icon={pointsConfig?.pointsImage}
          name={pointsConfig?.pointsName}
        />
      </div>
    </div>
  );
};

export default React.memo(WinnerCard);
