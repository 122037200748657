import './styles.scss';

import React, { useEffect, useState } from 'react';

import { Typography } from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';

import NoDataFound from '../../screens/Feed/components/NoDataFound/NoDataFound';
import { useAppSelector } from '../../shared/hooks';
import { IMango } from '../../types/feedTypes';
import HeaderTextAction from '../Header/actions/HeaderTextAction';
import Header from '../Header/Header';
import MangoSelectorCard from './MangoSelectorCard';

interface Props {
  type?: 'single' | 'multiple';
  mangoes: IMango[];
  show: boolean;
  closeModal: () => void;
  selectedMangoes: IMango[];
  handleSave: (mangoes: IMango[]) => void;
  customTitle?: string;
  customDescription?: string;
  noMangoComponent?: React.ReactNode;
}

const MangoSelectionModal: React.FC<Props> = ({
  show,
  closeModal,
  mangoes,
  selectedMangoes,
  handleSave,
  type = 'multiple',
  customTitle,
  customDescription,
  noMangoComponent,
}) => {
  const {
    hostMetadata: { offeringTitle, offeringTitles },
  } = useAppSelector((state) => state.app);

  const [selected, setSelected] = useState<string[]>([]);

  function toggle(val: string) {
    if (type === 'single') {
      if (selected.indexOf(val) !== -1) {
        setSelected([]);
      } else {
        setSelected([val]);
      }
    } else {
      if (selected.indexOf(val) !== -1) {
        setSelected((prev) => [...prev].filter((x) => x !== val));
      } else {
        setSelected((prev) => [...prev, val]);
      }
    }
  }

  useEffect(() => {
    setSelected(selectedMangoes.map((mango) => mango._id) || []);
  }, [selectedMangoes]);

  return (
    <BottomSheet
      open={show}
      onDismiss={closeModal}
      className="mango__selection__modal"
      header={
        <Header
          title={`Select ${offeringTitle}`}
          canGoBack={false}
          noBorder
          actionItems={[
            <HeaderTextAction
              text="Done"
              onClick={() =>
                handleSave(
                  selected
                    ? mangoes.filter(
                        (mango) => selected.indexOf(mango._id) !== -1,
                      )
                    : [],
                )
              }
            />,
          ]}
        />
      }>
      <div className="mango__selection__modal__content__wrapper">
        <Typography.Title level={5} className="mango__selection__modal__title">
          {customTitle || 'Who can see your post?'}
        </Typography.Title>
        <Typography.Paragraph className="mango__selection__modal__description">
          {customDescription ||
            `Choose ${offeringTitle} in which you want to publish your post`}
        </Typography.Paragraph>
        {mangoes?.length > 0 ? (
          <div className="mango__selection__modal__content">
            {[...mangoes.filter((mango) => !mango.isPublic)].map((item) => (
              <MangoSelectorCard
                key={item._id}
                name={item.title}
                onClick={() => {
                  toggle(item._id);
                }}
                selected={selected.indexOf(item._id) !== -1}
              />
            ))}
          </div>
        ) : (
          noMangoComponent || (
            <NoDataFound title={`No ${offeringTitles} found`} />
          )
        )}
      </div>
    </BottomSheet>
  );
};

export default React.memo(MangoSelectionModal);
