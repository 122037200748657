/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

type MediaTypes = 'audio' | 'video';
type PlayerStates = 'playing' | 'stopped' | 'paused';

export interface Media {
    mediaId: string;
    mediaType: MediaTypes;
    playerState: PlayerStates;
}

interface IMediaSliceState {
    media: Media;
}

const initialState: IMediaSliceState = {
    media: { mediaId: '', mediaType: 'video', playerState: 'stopped' },
};

const mediaSlice = createSlice({
    name: 'media',
    initialState,
    reducers: {
        updateMedia: (state, action: PayloadAction<Media>) => {
            if (action.payload.mediaId === state.media.mediaId || action.payload.playerState === 'playing')
                state.media = action.payload;
        }
    },
});

export const {
    updateMedia,
} = mediaSlice.actions;

export default mediaSlice.reducer;
