import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import anchorme from 'anchorme';
import {
  Button,
  Form,
  message,
  Typography,
  Upload,
  UploadFile,
} from 'antd';
import {
  RcFile,
  UploadChangeParam,
} from 'antd/lib/upload';
import {
  ContentState,
  EditorState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { MdUploadFile } from 'react-icons/md';
import sanitizeHtml from 'sanitize-html';

import UserAPI from '../../../../api/UserAPI';
import HeaderTextAction
  from '../../../../components/Header/actions/HeaderTextAction';
import Header from '../../../../components/Header/Header';
import Loader from '../../../../components/Loader';
import { setUserDetails } from '../../../../context/userReducer';
import { toolbarMango } from '../../../../shared/data';
import showAppError from '../../../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
} from '../../../../shared/hooks';
import {
  getFileExtension,
  getFileNameFromPath,
} from '../../../../shared/utils';
import { IUserDetails } from '../../../../types/userTypes';

const title = 'About You';

const AboutYou: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [creator, setCreator] = useState<IUserDetails>();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [aboutYou, setAboutYou] = useState('');
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [uploadTriggered, setUploadTriggered] = useState(false);

  const handleSave = async () => {
    if (!(await form.validateFields())) {
      return;
    }
    if (!creator?.name) {
      message.error('Name cannot be blank');
      return;
    }
    const data = {
      about: aboutYou?.replace(/<[^>]+>/g, '')
        ? aboutYou?.replace(/<[^>]+>/g, '').trim()
        : '',
      styledAbout: aboutYou
        ? sanitizeHtml(aboutYou, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['ins']),
          })?.trim()
        : '',
    };
    try {
      setSaving(true);
      if (fileList.length > 0 && fileList[0].originFileObj) {
        const formData = new FormData();
        formData.append('file', fileList[0].originFileObj);
        formData.append('about', aboutYou);
        UserAPI.uploadIntroVideo(formData)
          .then((resp) => {
            if (resp.status === 200) {
              message.success('Video Updated Successfully');
            } else {
              showAppError(resp.data);
            }
          })
          .catch((err) => {
            showAppError(err);
          });
      }
      const resp = await UserAPI.updateAccountDetails(data);
      message.success('Successfully updated account details');
      dispatch(setUserDetails({ name: resp.data.result.name }));
      navigate.goBack();
    } catch (err: any) {
      showAppError(err);
    } finally {
      setSaving(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const resp = await UserAPI.getLatestUserDetails();
      if (resp.data.result) {
        setCreator(resp.data.result);
        setAboutYou(
          resp.data.result.styledAbout || resp.data.result.about || '',
        );
        if (resp.data.result?.styledAbout || resp.data.result?.about) {
          const blocksFromHtml = htmlToDraft(
            resp.data.result?.styledAbout || resp.data.result?.about || '',
          );
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap,
          );
          const newEditorState = EditorState.createWithContent(contentState);
          setEditorState(newEditorState);
        }
        setLoading(false);
      } else {
        showAppError(resp.data);
      }
    } catch (error) {
      showAppError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onContentStateChange = (contentState: any) => {
    const content = draftToHtml(contentState);
    if (content) {
      setAboutYou(anchorme(content));
    }
  };

  const onEditorStateChange = (state: EditorState) => {
    setEditorState(state);
  };

  const isValidVideoFile = (inputFile: UploadFile<any>) => {
    const type = inputFile?.type
      ? inputFile?.type
      : (getFileExtension(inputFile?.name) || ['', ''])[1].toLowerCase();

    const isVideo =
      type === 'video/mp4' ||
      type === 'mp4' ||
      type === 'video/webm' ||
      type === 'webm' ||
      type === 'video/quicktime' ||
      type === 'quicktime' ||
      type === 'video/mov' ||
      type === 'mov';

    return isVideo;
  };

  const onChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
    setUploadTriggered(true);
    if (info.file.size && info.file.size / 1024 / 1024 > 100) {
      message.error('Video size must be less than 100MB');
      setFileList([]);
      return false;
    }

    if (!isValidVideoFile(info?.file)) {
      setFileList([]);
      return false;
    }

    let inputFileList = [...info?.fileList];
    inputFileList = inputFileList.slice(-1);
    inputFileList = inputFileList.map((inputfile) => {
      if (inputfile?.response) {
        // Component will show inputfile.url as link
        // eslint-disable-next-line no-param-reassign
        inputfile.url = inputfile?.response?.url;
      }
      return inputfile;
    });
    setFileList(inputFileList);

    if (info?.file?.status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }
  };

  const beforeUpload = (inputFile: RcFile) => {
    if (!isValidVideoFile(inputFile)) {
      message.error('You can only upload valid video file!');
    }

    return false;
  };

  const canShowCurrentVideo = () => {
    const profileVideo = creator && creator?.videoUrl;
    const fileListLength = fileList && fileList?.length;
    return !!(!uploadTriggered && profileVideo && fileListLength === 0);
  };

  return (
    <div className="pageRoot">
      <Header
        title={title}
        actionItems={[
          <HeaderTextAction
            text="Save"
            onClick={handleSave}
            loadingText="Saving"
            loading={saving}
          />,
        ]}
      />
      <div className="pageContent" style={{ padding: 12 }}>
        {loading || !creator ? (
          <Loader
            size="large"
            style={{
              width: '100%',
              margin: '30px auto',
            }}
          />
        ) : (
          <Form form={form}>
            <div className="floatingLabelInputWrapper">
              <div className="floatingLabelWrapper">
                <Typography.Text className="floatingLabel">
                  About You
                </Typography.Text>
              </div>
              <Form.Item>
                <Editor
                  editorState={editorState}
                  toolbar={toolbarMango}
                  stripPastedStyles
                  placeholder="Add description"
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="emailBodyEditorWrapper"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                  onContentStateChange={onContentStateChange}
                />
              </Form.Item>
            </div>

            <Upload
              fileList={fileList}
              onChange={onChangeFile}
              beforeUpload={beforeUpload}>
              <Button>
                <MdUploadFile style={{ marginRight: 6 }} />
                Upload Video
              </Button>

              {canShowCurrentVideo() && (
                <div>{getFileNameFromPath(creator?.videoUrl)}</div>
              )}
            </Upload>
          </Form>
        )}
      </div>
    </div>
  );
};

export default AboutYou;
