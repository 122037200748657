import * as React from 'react';

import { useTheme } from '../../context/ThemeProvider';

const TickSvg: React.FC<
  React.SVGProps<SVGSVGElement> & {
    circleColor?: string;
    backgroundColor?: string;
  }
> = ({ circleColor, backgroundColor, ...props }) => {
  const { colors } = useTheme();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g id="Group_4150" data-name="Group 4150" transform="translate(-309 -12)">
        <circle
          id="Ellipse_113"
          data-name="Ellipse 113"
          cx="12"
          cy="12"
          r="12"
          transform="translate(309 12)"
          fill={circleColor || `${colors.PRIMARY}10`}
        />
        <g
          id="tick_2_"
          data-name="tick (2)"
          transform="translate(315.001 -47.997)">
          <g
            id="Group_4420"
            data-name="Group 4420"
            transform="translate(0 67.997)">
            <path
              id="Path_830"
              data-name="Path 830"
              d="M11.426,68.17a.573.573,0,0,0-.82,0L3.659,75.239.989,72.522a.573.573,0,0,0-.82,0,.6.6,0,0,0,0,.834l3.08,3.134a.573.573,0,0,0,.82,0L11.426,69A.6.6,0,0,0,11.426,68.17Z"
              transform="translate(0 -67.997)"
              fill={backgroundColor || colors.PRIMARY}
              stroke={backgroundColor || colors.PRIMARY}
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default React.memo(TickSvg);
