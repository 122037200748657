import * as React from 'react';

import { useTheme } from '../../../../context/ThemeProvider';

const AddPostSvg: React.FC<React.SVGAttributes<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg
      id="add_1_"
      data-name="add (1)"
      width="79.821"
      height="72"
      viewBox="0 0 79.821 72"
      {...props}>
      <path
        id="Path_856"
        data-name="Path 856"
        d="M72.194,16.239c0,15.851,8.211,26.735,8.211,34.373S76.014,74.29,40.877,74.29.583,52.711.583,44.31c0-39.339,71.611-56.143,71.611-28.071Z"
        transform="translate(-0.583 -2.29)"
        fill="#efefef"
      />
      <path
        id="Path_857"
        data-name="Path 857"
        d="M31.437,16.875a3.437,3.437,0,1,1,3.437-3.437A3.442,3.442,0,0,1,31.437,16.875Zm0-4.583a1.146,1.146,0,1,0,1.146,1.146A1.148,1.148,0,0,0,31.437,12.292Z"
        transform="translate(34.827 7.668)"
        fill="#a4afc1"
      />
      <path
        id="Path_858"
        data-name="Path 858"
        d="M38.009,51.435H11.083A4.582,4.582,0,0,1,6.5,46.852V11.333A4.582,4.582,0,0,1,11.083,6.75H38.009a4.582,4.582,0,0,1,4.583,4.583V46.852A4.582,4.582,0,0,1,38.009,51.435Z"
        transform="translate(7.059 3.47)"
        fill="#f3f3f1"
      />
      <circle
        id="Ellipse_171"
        data-name="Ellipse 171"
        cx="13.176"
        cy="13.176"
        r="13.176"
        transform="translate(39.339 35.427)"
        fill={`${colors.PRIMARY}50`}
      />
      <path
        id="Path_859"
        data-name="Path 859"
        d="M22.906,30.926a13.177,13.177,0,0,1,10.6-12.92,13.175,13.175,0,1,0-2.578,26.1,13.1,13.1,0,0,0,2.578-.257A13.177,13.177,0,0,1,22.906,30.926Z"
        transform="translate(21.589 17.677)"
        fill={colors.PRIMARY}
      />
      <path
        id="Path_860"
        data-name="Path 860"
        d="M11.656,46.852V11.333A4.582,4.582,0,0,1,16.239,6.75H11.083A4.582,4.582,0,0,0,6.5,11.333V46.852a4.582,4.582,0,0,0,4.583,4.583h5.156A4.582,4.582,0,0,1,11.656,46.852Z"
        transform="translate(7.059 3.47)"
        fill="#d5dbe1"
      />
      <path
        id="Path_861"
        data-name="Path 861"
        d="M31.895,46.79A14.895,14.895,0,1,1,46.79,31.895,14.912,14.912,0,0,1,31.895,46.79Zm0-26.353A11.458,11.458,0,1,0,43.353,31.895,11.471,11.471,0,0,0,31.895,20.437Z"
        transform="translate(20.62 16.708)"
        fill="#000"
      />
      <path
        id="Path_862"
        data-name="Path 862"
        d="M22.75,20.75h3.437v12.6H22.75Z"
        transform="translate(28.046 21.552)"
        fill="#000"
      />
      <path
        id="Path_863"
        data-name="Path 863"
        d="M20.75,22.75h12.6v3.437H20.75Z"
        transform="translate(25.463 24.135)"
        fill="#000"
      />
      <path
        id="Path_864"
        data-name="Path 864"
        d="M27.059,54.122H12.3a6.307,6.307,0,0,1-6.3-6.3V12.3A6.307,6.307,0,0,1,12.3,6H38.654a6.307,6.307,0,0,1,6.3,6.3V26.257H41.519V12.3a2.868,2.868,0,0,0-2.864-2.864H12.3A2.868,2.868,0,0,0,9.437,12.3V47.821A2.868,2.868,0,0,0,12.3,50.685H27.059Z"
        transform="translate(6.413 2.502)"
        fill="#000"
      />
      <path
        id="Path_865"
        data-name="Path 865"
        d="M9.75,14H31.52v3.437H9.75Z"
        transform="translate(11.257 12.834)"
        fill="#000"
      />
      <path
        id="Path_866"
        data-name="Path 866"
        d="M9.75,18h12.6v3.437H9.75Z"
        transform="translate(11.257 18)"
        fill="#000"
      />
      <path
        id="Path_867"
        data-name="Path 867"
        d="M9.75,10H20.062v3.437H9.75Z"
        transform="translate(11.257 7.668)"
        fill="#000"
      />
    </svg>
  );
};

export default React.memo(AddPostSvg);
