import './styles.scss';

import React from 'react';

import { Typography } from 'antd';
import { IoNotificationsOff } from 'react-icons/io5';

import { useTheme } from '../../../../context/ThemeProvider';
import { DELETED_USER } from '../../../../shared/data';
import {
  getDisplayMessage,
  getTimeDifferenceInDays,
} from '../../../../shared/messageUtils';
import { Message } from '../../../../types/chatModels/MangoRoomListResponse';
import ChatAvatar from '../ChatAvatar/ChatAvatar';

interface Props {
  noBorder?: boolean;
  name: string;
  imageUrl: string;
  isCreator: boolean;
  lastMessage: Message;
  unread: number;
  onClick?: () => void;
  isNotificationMuted?: boolean;
}

const DMCard: React.FC<Props> = ({
  noBorder = false,
  name,
  imageUrl,
  isCreator,
  lastMessage,
  unread,
  onClick,
  isNotificationMuted = false,
}) => {
  const { colors } = useTheme();
  return (
    <div className="dm-card" onClick={onClick}>
      <ChatAvatar
        size={48}
        selected={unread > 0}
        name={name}
        url={imageUrl}
        selectedColor={colors.LINK}
      />
      <div
        className="dm-card__content"
        style={{
          borderWidth: noBorder ? 0 : 1,
        }}>
        <div className="dm-card__header">
          <div className="dm-card__header__title-container">
            <Typography.Title
              level={4}
              ellipsis={{ rows: 1, expandable: false }}
              className="dm-card__header__title">
              {name || DELETED_USER}
            </Typography.Title>
            {isCreator && (
              <Typography.Text className="creator-tag">CREATOR</Typography.Text>
            )}
          </div>
          <Typography.Text
            className="dm-card__header__time"
            style={{
              color: unread > 0 ? colors.LINK : colors.TEXT2,
            }}>
            {getTimeDifferenceInDays(lastMessage.createdAt)}
          </Typography.Text>
        </div>
        <div className="dm-card__message">
          <Typography.Title
            level={5}
            className="dm-card__message__text"
            ellipsis={{ rows: 1, expandable: false }}>
            {getDisplayMessage(lastMessage)}
          </Typography.Title>
          {isNotificationMuted && (
            <IoNotificationsOff
              color={colors.TEXT3}
              size={20}
              style={{
                flexShrink: 0,
              }}
            />
          )}
          {unread > 0 && (
            <div
              className="dm-card__message__unread-count"
              style={{
                marginLeft: isNotificationMuted ? 6 : 0,
              }}>
              <Typography.Text className="dm-card__message__unread-count__text">
                {unread}
              </Typography.Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(DMCard);
