import React from 'react';

import AudioPlayer from '../../../../components/AudioPlayer/AudioPlayer';
import CustomImage from '../../../../components/CustomImage/CustomImage';
import Zoom from '../../../../components/ImageViewer/Zoom';
import BitmovinPlayer from '../../../../components/VideoPlayer/BitmovinPlayer/BitmovinPlayer';
import VdoCipherPlayer from '../../../../components/VideoPlayer/VdoCipherPlayer/VdoCipherPlayer';

type Props = {
  url: string;
  type: 'audio' | 'video' | 'image';
  id: string;
  isCompressDone: boolean;
  index: number;
  thumbnail?: string;
  contentId?: string;
  isDrmEnabled?: boolean;
  // drmType: DRMTypes;
  hlsUrl?: string;
  dashUrl?: string;
  compressedUrl?: string;
  vdoCipherId?: string;
  typeOfPost?: 'activity' | 'post';
  hasError?: boolean;
  errorDescription?: string;
};

const PostMedia: React.FC<Props> = ({
  url,
  type,
  id,
  isCompressDone,
  thumbnail,
  contentId = '',
  isDrmEnabled = false,
  hlsUrl,
  dashUrl,
  compressedUrl,
  vdoCipherId,
  typeOfPost = 'activity',
  hasError,
  errorDescription,
}) => {
  const handleVideoPlayer = () => {
    if (vdoCipherId)
      return (
        <VdoCipherPlayer
          mediaId={`${id}_${typeOfPost}`}
          vdoCipherId={vdoCipherId}
          mediaThumb={thumbnail}
        />
      );
    return (
      <BitmovinPlayer
        mediaId={`${id}_${typeOfPost}`}
        isCompressDone={isCompressDone}
        mediaUrl={url}
        key={id}
        mediaThumb={thumbnail}
        hasError={hasError}
        errorDescription={errorDescription}
        isDRMEnabled={isDrmEnabled}
        isCompressedUrl={!!compressedUrl}
        contentId={contentId}
        compressedUrl={compressedUrl}
        hlsUrl={hlsUrl}
        dashUrl={dashUrl}
      />
    );
  };
  return (
    <div className="creatorPostMediaWrapper">
      {type === 'image' ? (
        <div className="postImageWrapper">
          <Zoom src={url}>
            <CustomImage src={url} alt="" className="mainImage" />
          </Zoom>
          <div className="blurImg">
            <CustomImage src={url} alt="" />
          </div>
        </div>
      ) : null}
      {type === 'video' ? (
        <div className="postVideoWrapper">{handleVideoPlayer()}</div>
      ) : null}
      {type === 'audio' ? (
        <AudioPlayer url={url} id={`${id}_${typeOfPost}`} />
      ) : null}
    </div>
  );
};

export default React.memo(PostMedia);
