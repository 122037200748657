import React from 'react';

import { useTheme } from '../../context/ThemeProvider';

const ReplySvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.861"
      height="13.601"
      viewBox="0 0 16.861 13.601"
      style={{ marginTop: -6 }}
      {...props}>
      <g id="right-arrow" transform="translate(0.5 -52.783)">
        <path
          id="Path_790"
          data-name="Path 790"
          d="M15.824,61.412a.33.33,0,0,0-.072-.108L11.79,57.342a.33.33,0,0,0-.467.467l3.4,3.4H8.255A7.6,7.6,0,0,1,.66,53.613a.33.33,0,1,0-.66,0,8.264,8.264,0,0,0,8.255,8.255h6.467l-3.4,3.4a.33.33,0,0,0,.459.475l.008-.008,3.962-3.962a.33.33,0,0,0,.072-.36Z"
          transform="translate(0 0)"
          fill={colors.ICON}
          stroke={colors.ICON}
          strokeWidth="1"
        />
        <path
          id="Path_791"
          data-name="Path 791"
          d="M362.978,190a.33.33,0,0,1-.234-.564l3.729-3.728-3.729-3.728a.33.33,0,0,1,.467-.467l3.962,3.962a.33.33,0,0,1,0,.467l-3.962,3.962A.331.331,0,0,1,362.978,190Z"
          transform="translate(-351.421 -124.175)"
          fill={colors.ICON}
          stroke={colors.ICON}
          strokeWidth="1"
        />
        <path
          id="Path_792"
          data-name="Path 792"
          d="M15.519,61.868H8.255A8.264,8.264,0,0,1,0,53.613a.33.33,0,1,1,.66,0,7.6,7.6,0,0,0,7.594,7.594h7.264a.33.33,0,1,1,0,.66Z"
          transform="translate(0 0)"
          fill={colors.ICON}
          stroke={colors.ICON}
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default ReplySvg;
