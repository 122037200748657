import React from 'react';

import { useTheme } from '../../context/ThemeProvider';

const CloseModal: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...props}>
      <g id="modalclose" transform="translate(-1473 -292)">
        <circle
          id="Ellipse_147"
          data-name="Ellipse 147"
          cx="16"
          cy="16"
          r="16"
          transform="translate(1473 292)"
          fill={colors.GRAY_LIGHT}
        />
        <path
          id="close"
          d="M7.394,6.385l4.867-4.867A.81.81,0,0,0,11.116.373L6.249,5.24,1.383.373A.81.81,0,0,0,.238,1.518L5.1,6.385.238,11.252A.81.81,0,1,0,1.383,12.4L6.249,7.53,11.116,12.4a.81.81,0,0,0,1.145-1.145Zm0,0"
          transform="translate(1482.751 301.615)"
          fill={colors.ICON}
        />
      </g>
    </svg>
  );
};

export default React.memo(CloseModal);
