import './styles.scss';

import React, { useEffect, useMemo, useState } from 'react';

import { Button, Typography } from 'antd';

import UserAPI from '../../api/UserAPI';
import DynamicWrapper from '../../components/DynamicWrapper/DynamicWrapper';
import DynamicHeader from '../../components/Header/DynamicHeader';
import { checkForPermissions } from '../../context/userReducer';
import { useAppSelector } from '../../shared/hooks';
import { CourseTypes } from '../../types/courseTypes';
import { ROUTES } from '../../types/routes';
import { ISubscription, UAMPermissions } from '../../types/userTypes';
import MyCourses from './MyCourses';
import PurchasedCourses from './PurchasedCourses';

const Courses: React.FC = () => {
  const {
    type: userType,
    id: userId,
    ...userDetails
  } = useAppSelector((state) => state.user);
  const { isTagMango, hostMetadata } = useAppSelector((state) => state.app);

  const [activeTab, setActiveTab] = useState<CourseTypes | null>('my');
  const [subscriberMangoes, setSubscriberMangoes] = useState<ISubscription[]>(
    [],
  );

  const loadSubscriberMangoes = async () => {
    try {
      const res = (await UserAPI.getAllSubscriptions()).data;
      if (res.result) {
        const subs = res.result.filter((sub) => sub.status === 'active');
        const mangoes: ISubscription[] =
          subs.length > 0 ? subs.map((sub: any) => sub.mango) : [];

        setSubscriberMangoes(mangoes);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      isTagMango
        ? userType === 'creator_completed' || userType === 'dual'
        : userId === hostMetadata.creator._id ||
          (userType === 'creator_restricted' &&
            checkForPermissions(
              [UAMPermissions.COURSE_ACCESS],
              userDetails.userAccessPermissions,
            ))
    ) {
      setActiveTab('my');
    } else {
      setActiveTab('purchased');
    }
  }, [
    hostMetadata.creator._id,
    isTagMango,
    userDetails.userAccessPermissions,
    userId,
    userType,
  ]);

  useEffect(() => {
    loadSubscriberMangoes();
  }, []);

  const selectorItem = (
    value: CourseTypes,
    name: string,
    style?: React.CSSProperties,
  ): JSX.Element => {
    const selected = value === activeTab;
    return (
      <Button
        key={value}
        className={`siteBtn tab__selector__item ${selected ? 'selected' : ''}`}
        onClick={() => setActiveTab(value)}
        style={{
          ...style,
        }}>
        <Typography.Text className="tab__selector__item__text">
          {name}
        </Typography.Text>
      </Button>
    );
  };

  const getActiveTab = useMemo((): JSX.Element => {
    switch (activeTab) {
      case 'my':
        return (
          <MyCourses
            userId={
              userType === 'creator_restricted'
                ? userDetails.UAMParentCreator || userId
                : userId
            }
          />
        );
      case 'purchased':
        return (
          <PurchasedCourses
            userId={userId}
            subscriberMangoes={subscriberMangoes}
          />
        );
      default:
        return <div />;
    }
  }, [
    activeTab,
    userDetails.UAMParentCreator,
    userId,
    userType,
    subscriberMangoes,
  ]);

  return (
    <DynamicWrapper path={ROUTES.COURSES}>
      <div className="courses__container">
        <DynamicHeader path={ROUTES.COURSES}>
          {/* {(
            isTagMango
              ? userType === 'dual' || userType === 'creator_completed'
              : userId === hostMetadata?.creator._id
          ) ? (
            <div className="tab__selector">
              {selectorItem('my', 'Courses by me')}
              {selectorItem('purchased', 'Purchased Courses', {
                marginLeft: 8,
              })}
            </div>
          ) : null} */}

          {userType === 'dual' ? (
            <div className="tab__selector">
              {selectorItem('my', 'Courses by me')}
              {selectorItem('purchased', 'Purchased Courses', {
                marginLeft: 8,
              })}
            </div>
          ) : null}
        </DynamicHeader>

        {getActiveTab}
      </div>
    </DynamicWrapper>
  );
};

export default Courses;
