import React, { memo } from 'react';

import TMIcon from 'tm-icons-library';

import { useTheme } from '../context/ThemeProvider';
import { copyToClipboard } from '../shared/utils';

interface Props {
  url: string;
  size?: number;
  color?: string;
  onShare?: () => void;
  style?: React.CSSProperties;
}

const ShareButton: React.FC<Props> = ({
  url,
  size = 16,
  color,
  onShare,
  style = {},
}) => {
  const { colors: COLORS } = useTheme();

  const handleShare = () => {
    if (onShare) {
      onShare();
      return;
    }

    copyToClipboard(url);
  };

  return (
    <TMIcon
      name="arrow-redo"
      size={size}
      color={color || COLORS.ICON}
      onClick={handleShare}
      style={{ cursor: 'pointer', ...style }}
    />
  );
};

export default memo(ShareButton);
