import '../../../../components/MangoSelectionModal/styles.scss';

import React, { memo, useEffect, useMemo, useState } from 'react';

import { Typography } from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';

import HeaderTextAction from '../../../../components/Header/actions/HeaderTextAction';
import Header from '../../../../components/Header/Header';
import MangoSelectorCard from '../../../../components/MangoSelectionModal/MangoSelectorCard';
import { useAppSelector } from '../../../../shared/hooks';
import { ITags } from '../../../../types/feedTypes';

type Props = {
  selectedTags?: string[];
  showTags?: ITags[];
  handleSave: (tags: string[]) => void;
  noButton?: boolean;
  show: boolean;
  closeModal: () => void;
};

const TagSelectionModal: React.FC<Props> = ({
  selectedTags,
  showTags,
  handleSave,
  noButton = false,
  show,
  closeModal,
}) => {
  const {
    tags,
    hostMetadata: { creator },
    isCommunityEnabled,
  } = useAppSelector((state) => state.app);
  const { id: userId, type: userType } = useAppSelector((state) => state.user);
  const [selected, setSelected] = useState<string[]>([]);

  function toggle(val: string) {
    if (selected.indexOf(val) !== -1) {
      setSelected((prev) => [...prev].filter((x) => x !== val));
    } else {
      setSelected((prev) => [...prev, val]);
    }
  }

  useEffect(() => {
    setSelected(selectedTags || []);
  }, [selectedTags]);

  const actualTags = useMemo(
    () =>
      Object.entries(tags).filter(
        ([, value]) =>
          value.creatorId === userId ||
          (userType === 'creator_restricted' &&
            value.creatorId === creator._id) ||
          (userType === 'fan_completed' &&
            isCommunityEnabled &&
            value.creatorId === creator._id),
      ),
    [tags, userId, userType, creator, isCommunityEnabled],
  );

  const data = noButton
    ? showTags?.map((x) => ({
        key: x._id,
        value: x.tagName,
      })) || []
    : actualTags.map(([key, value]) => ({
        key,
        ...value,
      }));
  return (
    <BottomSheet
      open={show}
      onDismiss={closeModal}
      className="mango__selection__modal"
      header={
        <Header
          title={noButton ? 'Tags' : 'Select Tags'}
          canGoBack={false}
          noBorder
          actionItems={[
            !noButton && (
              <HeaderTextAction
                text="Done"
                onClick={() => handleSave(selected || [])}
              />
            ),
          ]}
        />
      }>
      <div className="mango__selection__modal__content__wrapper">
        {actualTags.length === 0 && !noButton ? (
          <Typography.Paragraph className="mango__selection__modal__description">
            You have not created any tags yet. Please visit the website to
            create tags.
          </Typography.Paragraph>
        ) : null}
        {data.map((item) => (
          <MangoSelectorCard
            key={item.key}
            name={item.value}
            onClick={
              noButton
                ? undefined
                : () => {
                    toggle(item.key);
                  }
            }
            selected={noButton ? false : selected.indexOf(item.key) !== -1}
            noButton={noButton}
          />
        ))}
      </div>
    </BottomSheet>
  );
};

export default memo(TagSelectionModal);
