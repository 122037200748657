import './styles.scss';

import React from 'react';

import { Typography } from 'antd';

type Props = {
  label: string;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  focused?: boolean;
  message?: {
    type: 'error' | 'success' | 'info';
    text: string;
  };
  disabled?: boolean;
};

const FalseInput: React.FC<Props> = ({
  label,
  message,
  onClick,
  children,
  focused = true,
  disabled = false,
}) => (
  <div
    className="customInput clickable"
    onClick={disabled ? undefined : onClick}>
    <div
      className={`input__container ${
        focused ? 'input__container--filled' : ''
      } ${disabled ? 'input__container--disabled' : ''}`}>
      <label className="input__label">{label}</label>
      <div className="input__content">{children}</div>
    </div>
    {message ? (
      <Typography.Paragraph
        className={`input__message input__message--${message.type}`}>
        {message.text}
      </Typography.Paragraph>
    ) : null}
  </div>
);

export default React.memo(FalseInput);
