import React, { useState } from 'react';

import { Button, Dropdown, Slider } from 'antd';
import { MdVolumeUp } from 'react-icons/md';

interface Props {
  duration: string;
  curTime: string;
  curPercentage: number;
  onTimeUpdate: (val: number) => void;
  numberDuration: number;
  volume: number;
  setVolume: (val: number) => void;
  setPlayBack: (val: number) => void;
  playback: number;
  setIsHovered: (val: boolean) => void;
}

const Bar: React.FC<Props> = ({
  duration,
  curTime,
  curPercentage,
  onTimeUpdate,
  numberDuration,
  volume,
  setVolume,
  setPlayBack,
  playback,
  setIsHovered,
}) => {
  const [isVolumeVisible, setIsVolumeVisible] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  function updateVal(val: number) {
    onTimeUpdate((val * numberDuration) / 100);
  }

  const dropdownStyle: React.CSSProperties = {
    borderRadius: '6px',
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: 'max-content',
    padding: '8px 0',
    marginBottom: '-20px',
    WebkitBackdropFilter: 'saturate(180%) blur(20px)',
    backdropFilter: 'saturate(180%) blur(20px)',
    transition: 'opacity 0.25s ease',
    zIndex: '2000',
  };

  const volumeDropdownStyle: React.CSSProperties = {
    ...dropdownStyle,
    borderRadius: '50px',
    padding: '0',
  };

  const spanStyle: React.CSSProperties = {
    padding: 0,
    margin: '0 auto',
    textAlign: 'center',
    maxWidth: '100%',
  };
  const volumeBar = (
    <div
      style={{
        height: '100px',
        margin: '15px 0',
      }}>
      <Slider
        min={0}
        max={100}
        value={volume}
        tooltip={{ open: false }}
        onChange={setVolume}
        vertical
      />
    </div>
  );

  const postDropdown = (
    <>
      <ul className="callBackList">
        <li className="callBackListItem">
          <Button
            className="siteBtn noStyle"
            block
            onClick={() => {
              setIsHovered(false);
              setIsDropdownVisible(false);
              setPlayBack(2);
            }}>
            <span style={spanStyle}>x2</span>
          </Button>
        </li>
        <li className="callBackListItem">
          <Button
            className="siteBtn noStyle"
            block
            onClick={() => {
              setIsHovered(false);
              setIsDropdownVisible(false);
              setPlayBack(1.5);
            }}>
            <span style={spanStyle}>x1.5</span>
          </Button>
        </li>
        <li className="callBackListItem">
          <Button
            className="siteBtn noStyle"
            block
            onClick={() => {
              setIsHovered(false);
              setIsDropdownVisible(false);
              setPlayBack(1);
            }}>
            <span style={spanStyle}>Normal</span>
          </Button>
        </li>
        <li className="callBackListItem">
          <Button
            className="siteBtn noStyle"
            block
            onClick={() => {
              setIsHovered(false);
              setIsDropdownVisible(false);
              setPlayBack(0.75);
            }}>
            <span style={spanStyle}>x0.75</span>
          </Button>
        </li>
        <li className="callBackListItem">
          <Button
            className="siteBtn noStyle"
            block
            onClick={() => {
              setIsHovered(false);
              setIsDropdownVisible(false);
              setPlayBack(0.5);
            }}>
            <span style={spanStyle}>x0.5</span>
          </Button>
        </li>
      </ul>
    </>
  );

  return (
    <div className="bar">
      <span className="bar__time">{`${curTime} / ${duration}`}</span>
      <Slider
        min={0}
        max={100}
        value={curPercentage * 100}
        tooltip={{ open: false }}
        onChange={(val) => updateVal(val)}
      />
      <Dropdown
        overlay={postDropdown}
        trigger={['click']}
        open={isDropdownVisible}
        onOpenChange={(val) => {
          setIsDropdownVisible(val);
          setIsHovered(val);
        }}
        placement="top"
        overlayClassName="postDropdown"
        overlayStyle={dropdownStyle}>
        <span
          className="bar__time"
          style={{
            minWidth: 'fit-content',
            margin: '0 10px',
          }}>
          x{playback}
        </span>
      </Dropdown>
      <Dropdown
        overlay={volumeBar}
        trigger={['click']}
        open={isVolumeVisible}
        onOpenChange={(val) => {
          setIsVolumeVisible(val);
          setIsHovered(val);
        }}
        placement="top"
        overlayClassName="volumeDropdown"
        overlayStyle={volumeDropdownStyle}>
        <MdVolumeUp color="white" size={35} />
      </Dropdown>
    </div>
  );
};

export default Bar;
