import './styles.scss';

import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Input,
  message,
} from 'antd';
import {
  IoInformationCircle,
  IoPencil,
  IoPricetag,
} from 'react-icons/io5';

import API from '../../api';
import FeedAPI from '../../api/FeedAPI';
import HeaderTextAction from '../../components/Header/actions/HeaderTextAction';
import Header from '../../components/Header/Header';
import { updatePost } from '../../context/activityReducer';
import showAppError from '../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../shared/hooks';
import {
  convertImage,
  getFileExtension,
} from '../../shared/utils';
import {
  IMango,
  ITags,
  ITagsBase,
} from '../../types/feedTypes';
import CreatePostAttachment from './components/CreatePostAttachment';
import CreatePostHeader, {
  CreatePostHeaderRef,
} from './components/CreatePostHeader';

interface ISelFile {
  file: File;
  name: string;
  ext: string;
  url: string;
}

interface Props {
  post: any;
  onEditCallback?: (newPost: any) => void;
  handleBack?: () => void;
  canEditSubscriberPost?: boolean;
}

const EditPost: React.FC<Props> = ({
  post,
  onEditCallback,
  handleBack,
  canEditSubscriberPost = false,
}) => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const {
    tags,
    hostMetadata: { offeringTitle, communityEnabledMangoes },
  } = useAppSelector((state) => state.app);
  const { creatorMangoes, subscribedMangoes, ...userDetails } = useAppSelector(
    (state) => state.user,
  );

  const createPostHeaderRef = React.useRef<CreatePostHeaderRef>(null);

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedMangoes, setSelectedMangoes] = useState<IMango[]>([
    ...(post.mangoArr || []),
  ]);
  const [description, setDescription] = useState(post.caption || '');
  const [selectedImage, setSelectedImage] = useState<ISelFile | null>(null);
  const [saving, setSaving] = useState(false);

  const isMyPost = post?.creatorId === userDetails.id;

  const mangoesToDisplay = useMemo(() => {
    if (
      ['creator_completed', 'dual', 'creator_restricted'].includes(
        userDetails.type || '',
      )
    ) {
      return creatorMangoes;
    } else if (
      subscribedMangoes.length > 0 &&
      communityEnabledMangoes &&
      communityEnabledMangoes.length > 0 &&
      ['fan_completed'].includes(userDetails.type || '')
    ) {
      const subscribedMangoesWithCommunityEnabled = subscribedMangoes.filter(
        (mango) => communityEnabledMangoes.includes(mango._id),
      );
      return subscribedMangoesWithCommunityEnabled;
    }
    return [];
  }, [
    creatorMangoes,
    userDetails.type,
    subscribedMangoes,
    communityEnabledMangoes,
  ]);

  useEffect(() => {
    if (
      selectedMangoes.length === 0 &&
      mangoesToDisplay &&
      mangoesToDisplay.length === 1
    ) {
      setSelectedMangoes([mangoesToDisplay[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mangoesToDisplay]);

  useEffect(() => {
    if (
      post &&
      post.contentType === 'video' &&
      post.isCompressDone &&
      post.thumbnail
    ) {
      setSelectedImage({
        file: new File([], ''),
        name: '',
        ext: '',
        url: post.thumbnail,
      });
    }
    if (post && post.tags && post.tags.length > 0) {
      const appTags = [...Object.keys(tags)];
      const postTags = (post.tags as ITags[])
        .filter((elem) => appTags.includes(elem._id))
        .map((elem) => elem._id);
      setSelectedTags(postTags);
    }
  }, [post, tags]);

  useEffect(() => {
    let list = [...(document.getElementsByTagName('audio') ?? [])];
    list = [...list, ...(document.getElementsByTagName('video') ?? [])];
    list.forEach((element) => {
      element.pause();
    });
    return () => {
      list.forEach((element) => {
        element.pause();
      });
    };
  }, []);

  const submit = async () => {
    setSaving(true);
    let thumbnail: string | null = post?.thumbnail ?? null;
    if (selectedImage && selectedImage.url !== post.thumbnail) {
      const resp = await API.uploadPicture('private', selectedImage.file);
      if (resp.status === 200 && resp.data.result.compressed) {
        thumbnail = resp.data.result.compressed;
      }
    } else if (!selectedImage) {
      thumbnail = null;
    }
    try {
      const res = await FeedAPI.editPost(
        post._id,
        selectedMangoes,
        description,
        post.scheduleTime,
        thumbnail,
        selectedTags,
      );
      if (res.status === 200) {
        const newPost = { ...post };
        newPost.mangoArr = selectedMangoes;
        newPost.caption = description;
        newPost.thumbnail = thumbnail || undefined;
        const newTags: ITagsBase[] = Object.entries(tags)
          .filter(([key]) => selectedTags.includes(key))
          .map(([key, value]) => ({
            _id: key,
            tagName: value.value,
            creator: value.creatorId,
          }));
        newPost.tags = newTags as any;
        dispatch(updatePost(newPost));
        if (onEditCallback) {
          onEditCallback(newPost);
        }
        setSaving(false);
        if (handleBack) {
          handleBack();
        } else {
          navigate.goBack();
        }
      } else {
        showAppError(res.data);
      }
    } catch (err) {
      showAppError(err);
    }
  };

  const submitPost = () => {
    if (
      (description.length > 0 || post.contentUrl) &&
      selectedMangoes.length > 0
    ) {
      submit();
    } else {
      if (selectedMangoes.length === 0) {
        message.error(`Please select ${offeringTitle} to publish`);
        return;
      }
      // setProgressData((oldState) => ({ ...oldState, showProgress: false }));
      message.error(
        'Please add some description as original post has no media',
      );
    }
  };

  const setFileAndThumbnail = (imageFile: File, url: string) => {
    const ext = getFileExtension(imageFile.name)?.[1].toLowerCase() || '';
    const name = imageFile.name.substring(
      0,
      imageFile.name.length - ext.length - 1,
    );
    setSelectedImage({
      file: imageFile,
      name,
      ext,
      url,
    });
  };

  const beforeUpload = async (fileData: File) => {
    const type = getFileExtension(fileData.name)?.[1].toLowerCase();
    const isImage =
      type === 'jpg' ||
      type === 'png' ||
      type === 'gif' ||
      type === 'webp' ||
      type === 'tiff' ||
      type === 'jpeg' ||
      type === 'heic' ||
      type === 'svg';

    if (!isImage) {
      message.error('You can only put a valid image here!');
      return false;
    }

    const isImageLt2M = fileData.size / 1024 / 1024 < 1024;

    if (!isImageLt2M) {
      message.error('Image must smaller than 1024MB!');
      return false;
    }

    convertImage(type, fileData, setFileAndThumbnail);
    return;
  };

  const selectImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute(
      'accept',
      '.jpg, .jpeg, .png, .svg, .gif, .bmp, .webp, .tiff, .heic',
    );
    input.click();
    input.addEventListener('change', (e: any) => {
      let evt: React.ChangeEvent<HTMLInputElement> = e as any;
      if (!evt.target?.files) return;
      beforeUpload(evt.target.files[0]);
    });
  };

  const postContentOptions: {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
  }[] = useMemo(() => {
    const iconSize = 24;
    const items = [
      {
        icon: <IoPricetag size={iconSize} />,
        label: 'Tags',
        onClick: () => {
          createPostHeaderRef.current?.openTagModal();
        },
      },
    ];
    return items;
  }, []);

  const isDisabled = useMemo(
    (): boolean =>
      !(description || post.contentUrl) ||
      saving ||
      selectedMangoes.length === 0,
    [description, post.contentUrl, saving, selectedMangoes.length],
  );

  return (
    <>
      <div className="createpost__container">
        <Header
          title="Edit Post"
          backType="cross"
          handleBack={handleBack}
          actionItems={[
            <HeaderTextAction
              text="Post"
              disabled={isDisabled}
              onClick={submitPost}
              loading={saving}
              loadingText="Posting..."
            />,
          ]}
        />
        <div className="scroll__container">
          <CreatePostHeader
            ref={createPostHeaderRef}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            selectedMangoes={selectedMangoes}
            setSelectedMangoes={setSelectedMangoes}
            profilePicUrl={
              isMyPost
                ? userDetails.profilePic
                : post.imgUrl || post.creatorImage
            }
            creatorName={post?.creatorName}
            canEditSubscriberPost={canEditSubscriberPost}
          />
          <div className="createpost__description__container">
            <Input.TextArea
              className="createpost__description"
              bordered={false}
              placeholder="Add a caption..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              autoSize={{ minRows: 3 }}
              disabled={canEditSubscriberPost}
              style={{
                opacity: canEditSubscriberPost ? 0.25 : 1,
              }}
            />
          </div>
          {post.contentType === 'video' && (
            <div>
              <div className="createpost__attachment createpost__attachment__image">
                <div className="createpost__attachment__actions">
                  <Button
                    className="createpost__attachment__actions__button"
                    onClick={() => {
                      selectImage();
                    }}>
                    <IoPencil size={24} />
                  </Button>
                </div>
                <img src={selectedImage?.url} alt={selectedImage?.name} />
              </div>
              <div className="createpost__attachment__image__edit__info">
                <IoInformationCircle size={16} />
                We recommend using a photo that is 1200 x 900px
              </div>
            </div>
          )}
        </div>
        <CreatePostAttachment options={postContentOptions} />
      </div>
    </>
  );
};

export default React.memo(EditPost);
