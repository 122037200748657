import React from 'react';

import { Typography } from 'antd';
import { MdError } from 'react-icons/md';
import Lottie from 'react-lottie';

import processingJson from '../../assets/json/Processing.lottie.json';
import { useTheme } from '../../context/ThemeProvider';

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface Props {
  hasError?: boolean;
  errorDescription?: string;
  errorSubtitle?: string;
  title?: string;
  subtitle?: string;
}

const CompressingVideo: React.FC<Props> = ({
  hasError = false,
  errorDescription = 'Something went wrong',
  errorSubtitle = 'Sorry for the inconvenience. Please wait while we fix this.',
  title = 'Your video is cooking',
  subtitle = 'The video is processing and will soon be updated.',
}) => {
  const { colors } = useTheme();
  return (
    <div className="videoProgress">
      {hasError ? (
        <>
          <MdError
            size={50}
            color={colors.DANGER}
            style={{
              marginBottom: 12,
            }}
          />
          <Typography.Text className="videoProgressTitle">
            {errorDescription}
          </Typography.Text>
          <Typography.Text className="videoProgressText">
            {errorSubtitle}
          </Typography.Text>
        </>
      ) : (
        <>
          <Lottie
            options={{ ...defaultOptions, animationData: processingJson }}
            width={256}
            height={115}
          />
          <Typography.Text className="videoProgressTitle">
            {title}
          </Typography.Text>
          <Typography.Text className="videoProgressText">
            {subtitle}
          </Typography.Text>
        </>
      )}
    </div>
  );
};

export default React.memo(CompressingVideo);
