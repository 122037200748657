import * as React from 'react';

import { IoChevronForward } from 'react-icons/io5';

import Rupee from '../../../../components/Rupee/Rupee';
import { useTheme } from '../../../../context/ThemeProvider';
import { IRenewMango } from './types';

interface Props {
  renewMango: IRenewMango;
  onClick: () => void;
}

const RenewMangoTile: React.FC<Props> = ({ renewMango, onClick }) => {
  const { colors } = useTheme();

  return (
    <div
      className={`feed-card danger`}
      onClick={onClick}
      style={{
        border: `1px solid ${colors.DANGER}`,
      }}>
      <div className="feed-card__details-wrapper">
        <div className="feed-card-details">
          <div className="feed-card__title">
            <b>{renewMango.mango.title.toTitleCase()}</b>
          </div>

          <div className="feed-card__subtitle">
            Renew now for <Rupee />
            {renewMango.mango.price}
          </div>
        </div>

        <div className="feed-card__arrow">
          <IoChevronForward size={24} color={colors.ICON} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(RenewMangoTile);
