import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

import React, { useMemo, useState } from 'react';

import { Button, Typography } from 'antd';
import moment from 'moment';
import Slider from 'react-slick';

import CustomImage from '../../../components/CustomImage/CustomImage';
import Zoom from '../../../components/ImageViewer/Zoom';
import ShareBottomSheet from '../../../components/Modals/ShareBottomSheet/ShareBottomSheet';
import BitmovinPlayer from '../../../components/VideoPlayer/BitmovinPlayer/BitmovinPlayer';
import { useAppSelector } from '../../../shared/hooks';
import { ActiveMango } from '../../../types/userTypes';

interface Props {
  mangoDetails: ActiveMango;
  isMe: boolean;
  subscriberMangoes: any[];
  onBtnPress: () => void;
}

const MangoCard: React.FC<Props> = ({
  mangoDetails,
  isMe,
  subscriberMangoes,
  onBtnPress,
}) => {
  const {
    hostMetadata: { offeringTitle },
    shortUrl,
    deeplinkUrl,
    isTagMango,
  } = useAppSelector((state) => state.app);
  const [shareBottomSheetVisible, setShareBottomSheetVisible] = useState(false);

  const mangoMediaStyle: React.CSSProperties = {
    position: 'relative',
    backgroundColor: '#000',
    paddingTop: '56.25%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const currencyPrefixSymbol = useMemo(() => {
    if (mangoDetails?.currency === 'INR') {
      return <span>&#8377;</span>;
    }
    if (mangoDetails?.currency === 'EUR') {
      return <span>&#x20AC;</span>;
    }
    if (mangoDetails?.currency === 'USD') {
      return <span>&#x24;</span>;
    }
    return <span>&#8377;</span>;
  }, [mangoDetails?.currency]);

  const getPurchaseText = () => {
    const subscription = subscriberMangoes?.filter(
      (item) => item.mango === mangoDetails._id,
    );
    if (mangoDetails?.maxSubscriber) {
      if (mangoDetails?.remainingSlot === 0) return 'Slots Full';
    }
    if (subscription?.length) {
      return 'Already Purchased';
    }
    return 'Purchase Now';
  };

  const onSharePressed = () => {
    setShareBottomSheetVisible(true);
  };

  const shareUrl = mangoDetails?.shortUrl
    ? `${shortUrl}${mangoDetails.shortUrl}`
    : isTagMango
    ? `${deeplinkUrl}mangocheckout/${mangoDetails._id}`
    : `${deeplinkUrl}web/checkout/${mangoDetails._id}`;

  const videoExtensions = [
    'webm',
    'mpg',
    'mp2',
    'mpeg',
    'mpe',
    'mpv',
    'ogg',
    'mp4',
    'm4p',
    'm4v',
    'avi',
    'wmv',
    'mov',
    'qt',
    'flv',
    'swf',
    'avchd',
  ];

  const checkVideo = (url: string) => {
    return videoExtensions.includes(url.split('.').pop()!.toLowerCase());
  };

  return (
    <>
      <div className="creatorMangoWrapper">
        <div className="creatorMangoWrapperInner">
          {(mangoDetails?.imgUrl ||
            mangoDetails?.videoUrl ||
            mangoDetails.additionalCoverContent) && (
            <div className="creatorMangoMedia">
              <Slider
                className="mangoMediaCarousel"
                easing="linear"
                arrows
                swipeToSlide
                dots={false}
                slidesToShow={1}
                infinite={false}>
                {mangoDetails.additionalCoverContent &&
                  mangoDetails.additionalCoverContent.map((url, index) => (
                    <div key={url + index}>
                      {checkVideo(url) ? (
                        <div style={mangoMediaStyle} className="mangoMedia">
                          <BitmovinPlayer
                            mediaId={`${mangoDetails?._id}_videoUrl_${index}}`}
                            mediaUrl={url}
                            isCompressDone
                            key={`${mangoDetails?._id}_videoUrl_${index}}`}
                          />
                        </div>
                      ) : (
                        <Zoom src={url}>
                          <div style={mangoMediaStyle} className="mangoMedia">
                            <CustomImage src={url} alt="" />
                          </div>
                        </Zoom>
                      )}
                    </div>
                  ))}
              </Slider>
            </div>
          )}
          <div className="creatormangoDetails">
            {new Date(mangoDetails.start).getTime() >= Date.now() && (
              <Typography.Text className="mangoStartDate">
                Starting on {moment(mangoDetails.start).format("DD MMM 'YY")}
              </Typography.Text>
            )}
            <Typography.Title
              level={3}
              ellipsis={{ rows: 3, expandable: false }}
              className="creatorMangoTitle">
              {mangoDetails?.title}
            </Typography.Title>
            <Typography.Paragraph
              className="creatorMangoDesc"
              ellipsis={{ rows: 3, expandable: false }}>
              {mangoDetails?.description}
            </Typography.Paragraph>
          </div>
          <div className="creatorMangoPriceWrapper">
            <Typography.Title className="mangoPrice">
              {currencyPrefixSymbol}
              {parseFloat(mangoDetails?.price.toFixed(1) || '0')}
              {mangoDetails?.includegst && (
                <Typography.Text className="mangoPriceGST">
                  +GST
                </Typography.Text>
              )}
            </Typography.Title>
            <Typography.Text className="mangoPaymentCycle">
              {mangoDetails.recurringType === 'onetime'
                ? 'one time'
                : mangoDetails.recurringType}
            </Typography.Text>
          </div>
          <hr className="mangoDivider" />
          <div className="creatorMangoActionButtons">
            {!isMe ? (
              <>
                <Button
                  className="siteBtn siteBtnPrimary"
                  block
                  disabled={Boolean(
                    subscriberMangoes?.filter(
                      (item) => item?.mango === mangoDetails._id,
                    )?.length ||
                      (mangoDetails?.maxSubscriber &&
                        mangoDetails?.remainingSlot === 0),
                  )}
                  onClick={onBtnPress}>
                  {getPurchaseText()}
                </Button>
                <Button
                  className="siteBtn siteBtnSecondary shareMango"
                  onClick={onSharePressed}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.588"
                    height="22.297"
                    viewBox="0 0 21.588 22.297">
                    <path
                      id="share_1_"
                      data-name="share (1)"
                      d="M21,10.5,11.426,0V6.288H10.314A10.314,10.314,0,0,0,0,16.6v4.4l1.837-2.013a13.185,13.185,0,0,1,9.589-4.286V21ZM1.231,17.842V16.6a9.083,9.083,0,0,1,9.083-9.083h2.343V3.177L19.334,10.5l-6.677,7.321V13.477h-1.1A14.419,14.419,0,0,0,1.231,17.842Zm0,0"
                      transform="translate(0.25 0.645)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="0.5"
                    />
                  </svg>
                </Button>
              </>
            ) : (
              <Button
                className="siteBtn siteBtnSecondary shareMangoBtn"
                block
                onClick={onSharePressed}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.5"
                  height="20.604"
                  viewBox="0 0 15.5 20.604">
                  <g
                    id="export_1_"
                    data-name="export (1)"
                    transform="translate(0.25 0.354)">
                    <path
                      id="Path_682"
                      data-name="Path 682"
                      d="M83.266,172.228H81.391v1.25h1.25v11.25h-12.5v-11.25h1.25v-1.25H69.516a.625.625,0,0,0-.625.625v12.5a.625.625,0,0,0,.625.625h13.75a.625.625,0,0,0,.625-.625v-12.5A.625.625,0,0,0,83.266,172.228Z"
                      transform="translate(-68.891 -165.978)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="0.5"
                    />
                    <path
                      id="Path_683"
                      data-name="Path 683"
                      d="M163.618,2.393V11.25h1.25V2.393l2.683,2.683.884-.884L164.243,0l-4.192,4.192.884.884Z"
                      transform="translate(-156.743 0)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="0.5"
                    />
                  </g>
                </svg>
                Share this mango
              </Button>
            )}
          </div>
        </div>
      </div>
      <ShareBottomSheet
        showModal={shareBottomSheetVisible}
        closeModal={() => setShareBottomSheetVisible(false)}
        shareLink={shareUrl}
        title={`Check out this ${offeringTitle}`}
      />
    </>
  );
};

export default React.memo(MangoCard);
