import './styles.scss';

import React from 'react';

import { Result, Typography } from 'antd';
import { Link } from 'react-router-dom';

import notFound from '../../assets/img/notfound.jpg';
import { ROUTES } from '../../types/routes';

const NotFound: React.FC = () => {
  return (
    <div className="notFoundWrapper">
      <Result
        icon={<img src={notFound} alt="Not Found" />}
        subTitle={
          <Typography.Text className="siteText">
            We can&apos;t seem to find the page that you&apos;re looking for
          </Typography.Text>
        }
        extra={
          <Link to={ROUTES.FEED} className="siteBtn siteBtnSecondary">
            Back to home
          </Link>
        }
      />
    </div>
  );
};

export default NotFound;
