import * as React from 'react';

import { useTheme } from '../../context/ThemeProvider';

const DeleteSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 17.191 13"
      {...props}>
      <g
        id="_001-delete"
        data-name="001-delete"
        transform="translate(0.277 -39.868)">
        <path
          id="Path_754"
          data-name="Path 754"
          d="M15.1,40.118H5.7a1.558,1.558,0,0,0-1.21.573L.116,46.038a.521.521,0,0,0,0,.659l4.375,5.348a1.557,1.557,0,0,0,1.209.573h9.4a1.564,1.564,0,0,0,1.562-1.562V41.68A1.564,1.564,0,0,0,15.1,40.118Zm.521,10.936a.521.521,0,0,1-.521.521H5.7a.52.52,0,0,1-.4-.191l-4.1-5.015,4.1-5.018a.52.52,0,0,1,.4-.192h9.4a.521.521,0,0,1,.521.521l0,9.374Z"
          transform="translate(0)"
          fill={colors.DANGER}
          stroke={colors.DANGER}
          strokeWidth="0.5"
        />
        <path
          id="Path_755"
          data-name="Path 755"
          d="M128.5,103.232l-2.236,2.236-2.236-2.236-.736.736,2.236,2.236-2.236,2.236.736.736,2.236-2.236,2.236,2.236.736-.736L127,106.2l2.236-2.236Z"
          transform="translate(-116.89 -59.836)"
          fill={colors.DANGER}
          stroke={colors.DANGER}
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};

export default DeleteSvg;
