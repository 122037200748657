import React, { useEffect, useMemo, useRef, useState } from 'react';

import { updateMedia } from '../../../context/mediaReducer';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { getModifiedURL } from '../../../shared/utils';
import Bar from './ChatAudioBar';

export function minTwoDigits(num: number) {
  return (num < 10 ? '0' : '') + num;
}

interface IState {
  audioData: any;
  audioControls: {
    songPercent: number;
    songTime: string;
    songDuration: string;
    playbackRate: number;
  };
  volumeLevel: number;
  isPlaying: boolean;
}

interface Props {
  url: string;
  id: string;
}

const ChatAudioPlayer: React.FC<Props> = ({ url, id }) => {
  const { mDeeplinkUrl } = useAppSelector((state) => state.app);
  const { media } = useAppSelector((state) => state.media);
  const dispatch = useAppDispatch();

  const reactAudioPlayer = useRef<HTMLAudioElement>(null);
  const [state, setState] = useState<IState>({
    audioData: null,
    audioControls: {
      songPercent: 0,
      songTime: '0:00',
      songDuration: '',
      playbackRate: 1,
    },
    volumeLevel: 100,
    isPlaying: false,
  });

  function setAnalyser() {
    if (!reactAudioPlayer.current) return;
    const audioContext = new AudioContext();
    const source = audioContext.createMediaElementSource(
      reactAudioPlayer.current,
    );
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 64;
    source.connect(analyser);
    analyser.connect(audioContext.destination);
    setState((prev) => ({
      ...prev,
      audioData: analyser,
    }));
  }
  function playSong() {
    if (!state.audioData) setAnalyser();
    if (url && reactAudioPlayer.current) {
      let list = [...(document.getElementsByTagName('audio') ?? [])];
      list = [...list, ...(document.getElementsByTagName('video') ?? [])];
      list.forEach((element) => {
        element.pause();
      });
      reactAudioPlayer.current.play();
      setState((prev) => ({ ...prev, isPlaying: true }));
      dispatch(
        updateMedia({
          mediaId: id,
          mediaType: 'audio',
          playerState: 'playing',
        }),
      );
    }
  }

  function onTimeUpdateListener() {
    if (!reactAudioPlayer.current) return;
    const { currentTime } = reactAudioPlayer.current;
    const currentDuration = reactAudioPlayer.current.duration;
    const percent = currentTime / currentDuration;
    const audioControls = { ...state.audioControls };
    if (Number.isNaN(percent)) {
      audioControls.songPercent = 0;
    } else {
      audioControls.songPercent = percent;
      const formattedCurrentTime = Math.floor(currentTime);
      const formattedCurrentDuration = Math.floor(currentDuration);
      audioControls.songTime = `${Math.floor(formattedCurrentTime / 60)}:${
        formattedCurrentTime % 60
          ? minTwoDigits(formattedCurrentTime % 60)
          : '00'
      }`;
      audioControls.songDuration = `${Math.floor(
        formattedCurrentDuration / 60,
      )}:${
        formattedCurrentDuration % 60
          ? minTwoDigits(formattedCurrentDuration % 60)
          : '00'
      }`;
    }
    setState((prev) => ({ ...prev, audioControls }));
  }

  function setInitDuration() {
    if (reactAudioPlayer.current) {
      const currentDuration = reactAudioPlayer.current.duration;
      const formattedCurrentDuration = Math.floor(currentDuration);
      const audioControls = { ...state.audioControls };
      audioControls.songDuration = `${Math.floor(
        formattedCurrentDuration / 60,
      )}:${
        formattedCurrentDuration % 60
          ? minTwoDigits(formattedCurrentDuration % 60)
          : '00'
      }`;
      setState((prev) => ({ ...prev, audioControls }));
    }
  }

  function pauseSong() {
    if (media.mediaId === id)
      dispatch(
        updateMedia({
          mediaId: id,
          mediaType: 'audio',
          playerState: 'paused',
        }),
      );
    reactAudioPlayer.current?.pause();
    setState({ ...state, isPlaying: false });
  }

  useEffect(() => {
    if (media && media.mediaId !== id && state.isPlaying) {
      reactAudioPlayer.current?.pause();
      setState({ ...state, isPlaying: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media, id]);

  function updateAudioTime(time: number) {
    if (url && reactAudioPlayer.current) {
      const currentDuration = reactAudioPlayer.current.duration;
      reactAudioPlayer.current.currentTime =
        time < 0 || time > currentDuration ? 0 : time;
      const audioControls = { ...state.audioControls };
      audioControls.songPercent =
        time < 0 || time > currentDuration ? 0 : time / currentDuration;
      onTimeUpdateListener();
      setState({ ...state, audioControls });
    }
  }

  function togglePlayState() {
    if (state.isPlaying) pauseSong();
    else playSong();
  }

  useEffect(() => {
    if (url && reactAudioPlayer.current) {
      reactAudioPlayer.current.volume = state.volumeLevel / 100;
      reactAudioPlayer.current.playbackRate = state.audioControls.playbackRate;
      reactAudioPlayer.current.onloadedmetadata = () => {
        setInitDuration();
      };
    }
    return () => {
      if (state.audioData) state.audioData.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, reactAudioPlayer]);

  const modifiedUrl = useMemo(() => {
    return getModifiedURL(mDeeplinkUrl, url);
  }, [mDeeplinkUrl, url]);

  return (
    <div
      className="audioComponent audio hover"
      style={{
        opacity: 1,
      }}>
      <audio
        src={modifiedUrl}
        loop={false}
        ref={reactAudioPlayer}
        onTimeUpdate={onTimeUpdateListener}
        preload="metadata"
        onPause={() => pauseSong()}
        crossOrigin="anonymous"
      />
      <Bar
        curTime={state.audioControls.songTime}
        curPercentage={state.audioControls.songPercent}
        duration={state.audioControls.songDuration}
        numberDuration={
          reactAudioPlayer.current ? reactAudioPlayer.current.duration : 0
        }
        isPlaying={state.isPlaying}
        togglePlayState={() => togglePlayState()}
        onTimeUpdate={(val) => updateAudioTime(val)}
      />
    </div>
  );
};

export default ChatAudioPlayer;
