import './styles.scss';

import React, { useState } from 'react';

import {
  message,
  Typography,
} from 'antd';

import UserAPI from '../../../../api/UserAPI';
import HeaderTextAction
  from '../../../../components/Header/actions/HeaderTextAction';
import Header from '../../../../components/Header/Header';
import Input from '../../../../components/Input/Input';
import { useTheme } from '../../../../context/ThemeProvider';
import showAppError from '../../../../shared/error';
import {
  useAppNavigate,
  useAppSelector,
} from '../../../../shared/hooks';
import { rgx10digit } from '../../../../shared/utils';
import { ROUTES } from '../../../../types/routes';

const title = 'Change Number';
const subtitle =
  'Please enter your phone number below to generate an OTP for validation';

interface IError {
  validateStatus: 'error' | 'success' | 'info';
  errorMsg: string;
}

const ChangeNumber: React.FC = () => {
  const navigate = useAppNavigate();
  const { colors } = useTheme();
  const userDetails = useAppSelector((state) => state.user);
  const [error, setError] = useState<IError | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    userDetails.phone?.toString() || '',
  );
  const [saving, setSaving] = useState<boolean>(false);

  const validatePhone = (phone: string) => {
    const phoneNumberData: IError = {
      validateStatus: 'success',
      errorMsg: 'Valid phone number',
    };

    if (!phone) {
      phoneNumberData.validateStatus = 'error';
      phoneNumberData.errorMsg = 'Please enter mobile number.';
    }

    if (!rgx10digit.test(phone)) {
      phoneNumberData.validateStatus = 'error';
      phoneNumberData.errorMsg =
        'Please enter a valid 10-digit mobile number without country code.';
    }

    setError(phoneNumberData);

    return phoneNumberData.validateStatus === 'success';
  };

  const handleSave = async (phone: string = phoneNumber) => {
    if (validatePhone(phone)) {
      try {
        setSaving(true);
        const resp = await UserAPI.getChangeNumberOTP(phone);
        if (resp.status === 200) {
          navigate(
            ROUTES.VERIFY_OTP_ACCOUNT,
            {},
            {
              state: {
                phone,
                mode: 'phone',
                countryCode: 'IN',
                blockOtp: false,
                supportNumber: null,
                flow: 'number',
              },
            },
          );
          message.success('OTP sent successfully');
        } else {
          showAppError(resp.data);
        }
      } catch (err: any) {
        showAppError(err);
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <div className="pageRoot">
      <Header
        title={title}
        actionItems={[
          <HeaderTextAction
            text="Get OTP"
            onClick={() => handleSave()}
            loadingText="Get OTP"
            loading={saving}
          />,
        ]}
      />
      <div className="pageContent" style={{ padding: 12 }}>
        <Typography.Paragraph className="pageSubtitle">
          {subtitle}
        </Typography.Paragraph>
        <div
          className="errorBox"
          style={{
            backgroundColor: `${colors.DANGER}18`,
          }}>
          <Typography.Text className="errorText">
            Only Indian phone numbers are accepted at the moment
          </Typography.Text>
        </div>
        <Input
          label="Phone number"
          disabled={saving}
          addonBefore={<Typography.Text>+91</Typography.Text>}
          onChangeText={(text) => {
            if (text.match(/[^0-9]/g)) return;
            if (text.length <= 10) setPhoneNumber(text);
            if (text.length === 10) {
              handleSave(text);
            }
          }}
          text={phoneNumber.toString()}
          type="text"
          message={
            error
              ? {
                  text: error.errorMsg,
                  type: error.validateStatus,
                }
              : undefined
          }
        />
      </div>
    </div>
  );
};

export default ChangeNumber;
