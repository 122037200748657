import * as React from 'react';

const ImageSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.177"
    height="18.004"
    viewBox="0 0 22.177 18.004"
    {...props}>
    <g id="photo" transform="translate(0 -3.522)">
      <path
        id="Path_722"
        data-name="Path 722"
        d="M19.51,6.517H16.3V6.188a2.667,2.667,0,0,0-2.666-2.666h-5.1A2.667,2.667,0,0,0,5.873,6.188v.329H2.666A2.666,2.666,0,0,0,0,9.183V18.86a2.667,2.667,0,0,0,2.666,2.666H19.511a2.667,2.667,0,0,0,2.666-2.666V9.183A2.668,2.668,0,0,0,19.51,6.517ZM11.088,18.761a5.036,5.036,0,1,1,5.036-5.036A5.036,5.036,0,0,1,11.088,18.761Zm2.666-5.036a2.666,2.666,0,1,1-2.666-2.666,2.666,2.666,0,0,1,2.666,2.666Z"
        fill="#1281a5"
      />
    </g>
  </svg>
);

export default ImageSvg;
