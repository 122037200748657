import './index.scss';
import './antd.css';
import 'react-spring-bottom-sheet/dist/style.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import React from 'react';

import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import AppProvider from './context/AppProvider';
// import { SocketProvider } from './context/SocketProvider';
import { store } from './context/store';
import ThemeProvider from './context/ThemeProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BASENAME } from './shared/data';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <BrowserRouter basename={BASENAME}>
      <ThemeProvider>
        <Provider store={store}>
          <CookiesProvider>
            {/* <SocketProvider> */}
            <AppProvider>
              <App />
            </AppProvider>
            {/* </SocketProvider> */}
          </CookiesProvider>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
