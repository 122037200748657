import './styles.scss';

import React, { useMemo } from 'react';

import { Typography } from 'antd';

import { useAppSelector } from '../../../../../../shared/hooks';
import { nFormat } from '../../../../../../shared/utils';

interface Props {}

const PointsInfoTable: React.FC<Props> = () => {
  const {
    hostMetadata: { pointsConfig },
  } = useAppSelector((state) => state.app);

  const hasNoPointsAssigned = useMemo(() => {
    return (
      pointsConfig?.pointsMap &&
      Object.entries(pointsConfig?.pointsMap).every(([, value]) => !value.score)
    );
  }, [pointsConfig]);

  const noPointsAssignedContent = useMemo(
    () => (
      <Typography.Paragraph style={{ textAlign: 'center' }}>
        No points criteria added yet
      </Typography.Paragraph>
    ),
    [],
  );

  return (
    <>
      {!pointsConfig?.pointsMap || hasNoPointsAssigned ? (
        noPointsAssignedContent
      ) : (
        <div className="points-table">
          {Object.entries(pointsConfig?.pointsMap)
            .filter(([, value]) => value.score > 0)
            .map(([key, value]) => {
              console.log(key, value);
              if (['workshopAttendance', 'attendance'].includes(key)) {
                return null;
              }

              return (
                <div className="points-table__item">
                  <div className="points-table__item__title">
                    {value.title || key}
                  </div>
                  <div className="points-table__item__points">
                    <img
                      src={pointsConfig?.pointsImage}
                      alt={`Icon`}
                      width={14}
                      height={14}
                    />
                    {nFormat(value.score || 0, 9999, 2)}{' '}
                    {pointsConfig?.pointsName}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default React.memo(PointsInfoTable);
