import './styles.scss';

import * as React from 'react';

import { message } from 'antd';

import { useTheme } from '../../../../context/ThemeProvider';
import ProtestImg from './protest.png';

interface Props {}

const BecomeACreator: React.FC<Props> = () => {
  const { colors } = useTheme();
  return (
    <button
      type="button"
      className="becomeCreator"
      onClick={() => {
        message.info(
          'Please use the app or the desktop website to use this feature!',
        );
      }}>
      <img src={ProtestImg} alt="creator" />
      <div className="becomeCreatorContent" style={{ textAlign: 'left' }}>
        <div className="becomeCreatorTitle">Become a creator</div>
        <div className="becomeCreatorText">
          It&apos;s simple to get started and start earning
        </div>
      </div>
      <div className="becomeCreatorArrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24" />
            <path
              d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
              fill={colors.TEXT3}
              fillRule="nonzero"
              transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
            />
          </g>
        </svg>
      </div>
    </button>
  );
};

export default BecomeACreator;
