import './styles.scss';

import React, { useEffect } from 'react';

import { Button, Modal, Typography } from 'antd';
import { MdOutlineZoomIn, MdOutlineZoomOut } from 'react-icons/md';

import CloseModal from '../../assets/svg/CloseModal';
import CustomImage from '../CustomImage/CustomImage';

interface Props {
  title?: string;
  showModal: boolean;
  closeModal: () => void;
  srcSet: string[];
}

const modalMaskStyle: React.CSSProperties = {
  backgroundColor: 'rgba(244, 244, 244, 0.8)',
};

const ImageViewer: React.FC<Props> = ({
  title,
  showModal,
  closeModal,
  srcSet,
}) => {
  const [currImage, setCurrImage] = React.useState(0);
  const [scale, setScale] = React.useState(1);

  useEffect(() => {
    setScale(1);
    setCurrImage(0);
  }, [showModal]);

  return (
    <Modal
      title={
        <div className="modalHeader">
          <Typography.Title
            ellipsis={{
              rows: 1,
              expandable: false,
            }}
            level={4}
            className="title"
            style={{ textAlign: 'left' }}>
            {title || srcSet.length > 1 ? 'Images' : 'Image'}
          </Typography.Title>
          <div className="buttonWrapper">
            {srcSet.length > 1 && (
              <Typography.Text className="pageNumber">
                {currImage + 1} of {srcSet.length}
              </Typography.Text>
            )}
            <Button
              className="button"
              onClick={() => setScale((prev) => prev - 0.2)}
              disabled={scale === 1}>
              <MdOutlineZoomOut />
            </Button>
            <Button
              className="button"
              onClick={() => setScale((prev) => prev + 0.2)}
              disabled={scale >= 2}>
              <MdOutlineZoomIn />
            </Button>
            <Button className="button closeModal" onClick={closeModal}>
              <CloseModal />
            </Button>
          </div>
        </div>
      }
      open={showModal}
      onOk={closeModal}
      onCancel={closeModal}
      wrapClassName="imageViewerModal"
      maskStyle={modalMaskStyle}
      centered
      closable={false}
      closeIcon={null}
      footer={
        srcSet.length > 1 && (
          <div className="footerWrapper slimScroll">
            {srcSet.map((src, index) => (
              <div
                key={index}
                onClick={() => setCurrImage(index)}
                className={`imageWrapper ${
                  currImage === index ? 'active' : ''
                }`}>
                <CustomImage src={src} />
              </div>
            ))}
          </div>
        )
      }
      width={700}>
      {currImage < srcSet.length && (
        <div
          className="mainImageWrapper"
          style={{
            transform: `scale(${scale})`,
          }}>
          <CustomImage src={srcSet[currImage]} />
        </div>
      )}
    </Modal>
  );
};

export default React.memo(ImageViewer);
