import React from 'react';

import { Button, Slider } from 'antd';
import {
  MdOutlinePauseCircleFilled as MdOutlinePause,
  MdOutlinePlayCircleFilled as MdOutlinePlay,
} from 'react-icons/md';

interface Props {
  duration: string;
  curTime: string;
  curPercentage: number;
  onTimeUpdate: (val: number) => void;
  numberDuration: number;
  isPlaying: boolean;
  togglePlayState: () => void;
}

const Bar: React.FC<Props> = ({
  duration,
  curTime,
  curPercentage,
  onTimeUpdate,
  numberDuration,
  isPlaying,
  togglePlayState,
}) => {
  function updateVal(val: number) {
    onTimeUpdate((val * numberDuration) / 100);
  }

  return (
    <div className="bar" style={{ position: 'initial', opacity: 1 }}>
      <Button
        className="siteBtn noStyle circularBgHover pausePlay"
        onClick={() => togglePlayState()}>
        {!isPlaying ? (
          <MdOutlinePlay size={20} />
        ) : (
          <MdOutlinePause size={20} />
        )}
      </Button>
      <Slider
        min={0}
        max={100}
        value={curPercentage * 100}
        tooltipVisible={false}
        onChange={(val) => updateVal(val)}
      />
      <span className="bar__time">{`${curTime} / ${duration}`}</span>
    </div>
  );
};

export default Bar;
