import * as React from 'react';

import { useTheme } from '../../context/ThemeProvider';

const JoinSvg: React.FC<
  React.SVGProps<SVGSVGElement> & { background?: string; foreground?: string }
> = (props) => {
  const { colors } = useTheme();
  return (
    <svg width={22.177} height={14.257} viewBox="0 0 22.177 14.257" {...props}>
      <g data-name="video-camera (1)">
        <path
          d="M21.8 1.702a.792.792 0 00-.769-.035l-5.19 2.6V2.376A2.376 2.376 0 0013.465 0H2.376A2.376 2.376 0 000 2.376v9.5a2.376 2.376 0 002.376 2.381h11.089a2.376 2.376 0 002.376-2.376V9.994l5.19 2.6a.792.792 0 001.146-.713v-9.5a.792.792 0 00-.377-.679z"
          fill={props.foreground || colors.BACKGROUND}
          data-name="Group 4118"
        />
      </g>
      <path
        data-name="plus (2)"
        d="M11.489 6.755H8.512v-2.98a.373.373 0 00-.745 0v2.98H4.784a.373.373 0 000 .745h2.98v2.98a.373.373 0 10.745 0V7.503h2.98a.373.373 0 100-.745zm0 0"
        fill={props.background || colors.PRIMARY}
        stroke={props.background || colors.PRIMARY}
      />
    </svg>
  );
};

export default JoinSvg;
