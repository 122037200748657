import moment from 'moment';

import { Message } from '../types/chatModels/MangoRoomListResponse';

export const getDisplayMessage = (message: Message) => {
  if (message.isDeleted) {
    return 'This message was deleted';
  }

  if (message.message) return message.message;

  let displayText = '';

  switch (message.messageType) {
    case 'image':
      displayText = 'sent an image';
      break;

    case 'video':
      displayText = 'sent a video';
      break;

    case 'audio':
      displayText = 'sent an audio';
      break;

    case 'doc':
      displayText = 'sent a document';
      break;

    default:
      displayText = 'sent an attachment';
  }

  return displayText;
};

export type ChatAttachmentTypes = '' | 'image' | 'video' | 'audio' | 'doc';

export const getTimeDifferenceInDays = (time: string | Date) => {
  const currentDate = moment();
  const givenDate = moment(time);

  const isToday = givenDate.isSame(moment(), 'day');

  const isYesterday = givenDate.isSame(moment().subtract(1, 'days'), 'day');

  if (isToday) return givenDate.format('h:mm A');

  if (isYesterday) return 'Yesterday';

  if (currentDate.diff(givenDate, 'days') <= 6) {
    return givenDate.format('dddd');
  }

  return givenDate.format('DD/MM/YYYY');
};
