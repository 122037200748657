import React, {
  createContext,
  useContext,
  useEffect,
} from 'react';

import { changeAntdTheme } from 'mini-dynamic-antd-theme';

import {
  ICOLORS,
  LIGHT_COLORS,
} from '../shared/Styles';

interface IUseTheme {
  colors: ICOLORS;
  updateColors: (colors: Partial<ICOLORS>) => void;
}

const ThemeContext = createContext<IUseTheme>({
  colors: LIGHT_COLORS,
  updateColors: () => {},
});

export const useTheme = () => useContext(ThemeContext) as IUseTheme;

interface IProps {
  children: React.ReactNode;
}

const ThemeProvider: React.FC<IProps> = ({ children }) => {
  const [colors, setColors] = React.useState<ICOLORS>(LIGHT_COLORS);

  useEffect(() => {
    Object.keys(colors).forEach((key) => {
      document.documentElement.style.setProperty(
        `--${key.toLowerCase()}`,
        colors[key as keyof ICOLORS],
      );
    });
    if (colors.PRIMARY) {
      changeAntdTheme(colors.PRIMARY);
    }
  }, [colors]);

  return (
    <ThemeContext.Provider
      value={{
        colors,
        updateColors: (newColors) => setColors({ ...colors, ...newColors }),
      }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
