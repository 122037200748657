import './styles.scss';

import React from 'react';

import { Link } from 'react-router-dom';

import CustomImage from '../../components/CustomImage/CustomImage';

const LoginHeader: React.FC<{
  title: string;
  subtitle: string;
  logo: string;
}> = ({ title, subtitle, logo }) => {
  return (
    <div className="loginHeader">
      <Link to={{}} className="loginHeaderLogo">
        <CustomImage src={logo} alt="Brand Logo" />
      </Link>
      <h1 className="loginHeaderTitle">{title}</h1>
      <h2 className="loginHeaderSubTitle">{subtitle}</h2>
    </div>
  );
};

export default LoginHeader;
