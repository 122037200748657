import React, { useMemo, useRef } from 'react';

import { useAppSelector } from '../../shared/hooks';
import { getModifiedURL } from '../../shared/utils';

interface Props
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  fallbackSrc?: string;
  onError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

const CustomImage: React.FC<Props> = ({
  src,
  fallbackSrc,
  onError,
  ...props
}) => {
  const { mDeeplinkUrl } = useAppSelector((state) => state.app);

  const isErrored = useRef(false);

  const handleError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    if (fallbackSrc) {
      if (!isErrored.current) {
        const target = event.target as HTMLImageElement;
        target.src = fallbackSrc;
        isErrored.current = true;
      }
    }
    if (onError) {
      onError(event);
    }
  };

  const modifiedSrc = useMemo(() => {
    return getModifiedURL(mDeeplinkUrl, src);
  }, [mDeeplinkUrl, src]);

  return <img {...props} src={modifiedSrc} onError={handleError} />;
};

export default React.memo(CustomImage);
