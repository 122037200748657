import React, { createContext, useContext, useEffect } from 'react';

import { io } from 'socket.io-client';

import config from '../api/config';
import { useAppDispatch, useAppSelector } from '../shared/hooks';
import { SendMessageResult } from '../types/chatModels/SendMessage';
import {
  addNewMessage,
  updateReadReceiptData,
  updateUnreadMessagesData,
} from './thunks/chatThunks';
import { Outlet } from 'react-router-dom';

interface IUseSocket {}

const SocketContext = createContext({});

export const useSocket = () => useContext<IUseSocket>(SocketContext as any);

interface IProps {
  children?: React.ReactNode;
}

export const SocketProvider: React.FC<IProps> = ({ children }) => {
  const {
    app: { token },
    user: { id },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!token || !id) return () => {};
    const socket = io(config.SOCKET_ENDPOINT, {
      transports: ['websocket', 'polling'], // use WebSocket first, if available
      auth: {
        token: `Bearer ${token}`,
      },
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 5000,
      reconnectionDelayMax: 10000,
    });
    socket.on('connect', () => {
      socket.emit('join', id);
      console.log(socket.id);
    });
    socket.on('connect_error', (err) => {
      // revert to classic upgrade
      socket.io.opts.transports = ['polling', 'websocket'];
      console.log(JSON.stringify(err));
    });
    socket.on('greeting', (message) => {
      dispatch(addNewMessage(message as SendMessageResult));
    });
    socket.on('message_delivery', (messageReceipts) => {
      console.log('message delivery', messageReceipts);
      dispatch(updateReadReceiptData(messageReceipts));
      // if (messageIds?.roomType === 'dm') dispatch(messageIds);
    });
    socket.on('disconnect', () => {});
    dispatch(updateUnreadMessagesData());
    return () => {
      socket.close();
    };
  }, [token, id, dispatch]);

  return <SocketContext.Provider value={{}}><Outlet/>{children}</SocketContext.Provider>;
};
