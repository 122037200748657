import React, { memo } from 'react';

import { Card, Skeleton } from 'antd';

const LikedByUserSkeleton: React.FC = () => {
  return (
    <Card
      style={{
        width: '100%',
      }}
      bordered={false}
      bodyStyle={{ padding: 8 }}
      className="liked-by-user-skeleton">
      <Skeleton
        avatar={{ shape: 'circle', size: 30 }}
        title={{ width: 250 }}
        paragraph={false}
        round
        active
      />
    </Card>
  );
};

export default memo(LikedByUserSkeleton);
