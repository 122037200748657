import './styles.scss';

import React from 'react';

import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { useAppSelector } from '../../../shared/hooks';
import { copyToClipboard } from '../../../shared/utils';
import Header from '../../Header/Header';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  shareLink: string;
  title: string;
}

const ShareBottomSheet: React.FC<Props> = ({
  showModal,
  closeModal,
  shareLink,
  title,
}) => {
  const { deeplinkUrl } = useAppSelector((state) => state.app);
  return (
    <BottomSheet
      open={showModal}
      onDismiss={closeModal}
      header={<Header canGoBack={false} title="Share" noBorder />}>
      <div className="shareBottomSheetContents">
        <Typography.Paragraph>{title}</Typography.Paragraph>
        <Link to={shareLink}>{shareLink}</Link>
      </div>
      <div className="shareOptionsWrapper">
        <FacebookShareButton url={shareLink} title={title}>
          <svg
            id="_001-facebook"
            data-name="001-facebook"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25">
            <path
              id="Path_656"
              data-name="Path 656"
              d="M12.5,0A12.5,12.5,0,1,1,0,12.5,12.5,12.5,0,0,1,12.5,0Z"
              transform="translate(0 0)"
              fill="#3b5998"
            />
            <path
              id="Path_657"
              data-name="Path 657"
              d="M113.792,74.927H115.4v-2.38h-1.894v.009c-2.295.081-2.765,1.371-2.806,2.726h0V76.47h-1.562V78.8H110.7v6.247h2.355V78.8h1.929l.373-2.33h-2.3v-.718A.767.767,0,0,1,113.792,74.927Z"
              transform="translate(-99.77 -66.321)"
              fill="#fff"
            />
          </svg>
        </FacebookShareButton>
        <LinkedinShareButton
          url={shareLink}
          title={title}
          summary=""
          source={deeplinkUrl}>
          <svg
            id="_002-linkedin"
            data-name="002-linkedin"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25">
            <path
              id="Path_658"
              data-name="Path 658"
              d="M12.5,0A12.5,12.5,0,1,1,0,12.5,12.5,12.5,0,0,1,12.5,0Z"
              fill="#0e76a8"
            />
            <path
              id="Path_659"
              data-name="Path 659"
              d="M81.946,83.755h2.341v-7.8H81.946Zm9.119-8.072a3.513,3.513,0,0,0-2.873,1.33V75.928H85.842v7.827h2.349V79.522a1.858,1.858,0,0,1,1.846-1.767c1.027,0,1.28.873,1.28,1.745v4.254h2.341V79.326C93.657,76.25,92.2,75.683,91.064,75.683ZM83.1,75.174A1.172,1.172,0,1,0,81.933,74,1.172,1.172,0,0,0,83.1,75.174Z"
              transform="translate(-74.902 -66.58)"
              fill="#fff"
            />
          </svg>
        </LinkedinShareButton>
        <TwitterShareButton url={shareLink} title={title}>
          <svg
            id="_003-twitter"
            data-name="003-twitter"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25">
            <path
              id="Path_660"
              data-name="Path 660"
              d="M12.5,0A12.5,12.5,0,1,1,0,12.5,12.5,12.5,0,0,1,12.5,0Z"
              transform="translate(0 0)"
              fill="#26a6d1"
            />
            <path
              id="Path_661"
              data-name="Path 661"
              d="M78.556,83.373a6.225,6.225,0,0,1-1.75.466,2.981,2.981,0,0,0,1.34-1.637,6.176,6.176,0,0,1-1.935.718,3.086,3.086,0,0,0-2.223-.933,3,3,0,0,0-3.045,2.955,2.868,2.868,0,0,0,.079.674,8.738,8.738,0,0,1-6.278-3.089,2.872,2.872,0,0,0-.413,1.486,2.932,2.932,0,0,0,1.355,2.46,3.1,3.1,0,0,1-1.38-.37v.037a2.982,2.982,0,0,0,2.444,2.9,3.15,3.15,0,0,1-.8.1,3.072,3.072,0,0,1-.573-.053,3.038,3.038,0,0,0,2.846,2.053,6.227,6.227,0,0,1-3.784,1.266,6.4,6.4,0,0,1-.727-.041,8.816,8.816,0,0,0,4.67,1.329,8.476,8.476,0,0,0,8.667-8.413l-.009-.383A6.079,6.079,0,0,0,78.556,83.373Z"
              transform="translate(-58.241 -74.952)"
              fill="#fff"
            />
          </svg>
        </TwitterShareButton>
        <WhatsappShareButton url={shareLink} title={title}>
          <svg
            id="whatsapp_1_"
            data-name="whatsapp (1)"
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="27"
            viewBox="0 0 26 27">
            <rect
              id="Rectangle_340"
              data-name="Rectangle 340"
              width="25"
              height="25"
              fill="rgba(255,255,255,0)"
            />
            <circle
              id="Ellipse_143"
              data-name="Ellipse 143"
              cx="12.5"
              cy="12.5"
              r="12.5"
              transform="translate(1 2)"
              fill="#1bd741"
            />
            <g
              id="Group_4217"
              data-name="Group 4217"
              transform="translate(5 6)">
              <path
                id="Path_726"
                data-name="Path 726"
                d="M68.494,85.825l1.225-4.349a8.728,8.728,0,1,1,3.261,3.195Zm4.715-2.743.267.163a7.249,7.249,0,1,0-2.333-2.281l.18.28-.705,2.5Z"
                transform="translate(-68.494 -68.321)"
                fill="#fff"
              />
              <path
                id="Path_727"
                data-name="Path 727"
                d="M149.56,152.857l-.567-.031a.685.685,0,0,0-.487.167,2.581,2.581,0,0,0-.849,1.3,3.942,3.942,0,0,0,.914,3.09,9.193,9.193,0,0,0,4.955,3.6,2.415,2.415,0,0,0,2.045-.253,1.829,1.829,0,0,0,.8-1.161l.09-.423a.294.294,0,0,0-.164-.328l-1.915-.883a.294.294,0,0,0-.355.087l-.752.974a.215.215,0,0,1-.242.072,5.886,5.886,0,0,1-3.185-2.726.217.217,0,0,1,.027-.242l.718-.831a.293.293,0,0,0,.048-.307l-.825-1.931A.294.294,0,0,0,149.56,152.857Z"
                transform="translate(-143.259 -148.19)"
                fill="#fff"
              />
            </g>
          </svg>
        </WhatsappShareButton>
        <button onClick={() => copyToClipboard(shareLink)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25">
            <g id="link" transform="translate(-212 -415)">
              <circle
                id="Ellipse_146"
                data-name="Ellipse 146"
                cx="12.5"
                cy="12.5"
                r="12.5"
                transform="translate(212 415)"
                fill="#008dff"
              />
              <g id="XMLID_103_" transform="translate(218 420.964)">
                <path
                  id="XMLID_104_"
                  d="M7.255,106.765l-2.062,2.062h0A2.187,2.187,0,0,1,2.1,105.734h0l2.062-2.062a.729.729,0,0,0-1.031-1.031L1.068,104.7h0a3.646,3.646,0,0,0,5.156,5.155h0L8.286,107.8a.729.729,0,0,0-1.031-1.031Z"
                  transform="translate(0 -97.414)"
                  fill="#fff"
                />
                <path
                  id="XMLID_105_"
                  d="M110.924,3.68A3.646,3.646,0,0,0,104.7,1.1h0l-2.062,2.062A.729.729,0,1,0,103.669,4.2l2.062-2.062h0a2.187,2.187,0,0,1,3.094,3.093h0l-2.062,2.062a.729.729,0,1,0,1.031,1.031l2.062-2.062h0A3.622,3.622,0,0,0,110.924,3.68Z"
                  transform="translate(-97.446 0)"
                  fill="#fff"
                />
                <path
                  id="XMLID_106_"
                  d="M81.427,86.583a.729.729,0,0,0,1.031,0l4.125-4.125a.729.729,0,1,0-1.031-1.031l-4.125,4.125A.729.729,0,0,0,81.427,86.583Z"
                  transform="translate(-77.266 -77.231)"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>
        </button>
      </div>
    </BottomSheet>
  );
};

export default React.memo(ShareBottomSheet);
