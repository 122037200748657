export enum ROUTES {
  HOME = '/',
  NOTFOUND = '/404',
  FEED = '/activity',
  MENU = '/account',
  LOGIN = '/login',
  REGISTER = '/login/register',
  SIGNUPCREATOR = '/login/signup',
  VERIFY_OTP = '/login/verify-otp',
  REFER_N_EARN_SIGNUP = '/sign-up',
  POST_DETAILS = '/post/:postId',
  VIDEO_CALL = '/video-call',
  CREATOR_PROFILE = '/creator/profile/:id',
  CREATOR_PROFILE_SLUG = '/creator/:slug',
  NOTIFICATIONS = '/notifications',
  PERSONAL_INFO = '/account/personal-information',
  ABOUT_YOU = '/account/about',
  SUBSCRIPTIONS = '/account/subscriptions',
  CHANGE_NUMBER = '/account/change-number',
  CHANGE_EMAIL = '/account/change-email',
  DELETE_ACCOUNT = '/account/delete',
  ACCOUNT_SETTINGS = '/account/settings',
  HELP_SUPPORT = '/account/help-support',
  VERIFY_OTP_ACCOUNT = '/account/verify-otp',
  NOTIFICATION_SETTINGS = '/account/notification-settings',
  VERIFY_EMAIL = '/verify-email',
  STOREFRONT = '/storefront',
  USER_PROFILE = '/profile/:userId',
  MANGOES_PURCHASED = '/mangoes-purchased/:userId',

  CREATE_POST = '/create-post',
  CREATE_POLL = '/create-poll',

  //Courses
  COURSES = '/courses',
  COURSE_DETAILS = '/courses/:courseId',
  COURSE_OVERVIEW = '/courses/details/:courseId',

  //Messages
  MESSAGES = '/messages',
  NEW_MESSAGE = '/messages/new',
  MESSAGE_ROOM = '/messages/room/:roomId',
  MESSAGE_DM = '/messages/dm/:roomId',
  MESSAGE_DM_PARTICIPANT = '/messages/participant/:otherParticipantId',

  //Custom Apps
  CUSTOM_APPS = '/apps/:key',

  // Gamification
  LEADERBOARD = '/leaderboard',
  POINTS_LEDGER = '/points',

  // MetaData View Routes
  POST_METADATA_VIEW = '/lite/view/post/:postId',
  WEBINAR_METADATA_VIEW = '/lite/view/webinar/:webinarId',
  CHAPTER_METADATA_VIEW = '/lite/view/chapter/:chapterId',

  // Platform crash routes
  PLATFORM_CRASHED = '/platform-crashed',
}

// Tab Routes
export enum RouteQuery {
  POST_ID = 'postId',
  CHAPTER_ID = 'chapterId',
  WEBINAR_ID = 'webinarId',
}

export type HomeRoutes =
  | ROUTES.FEED
  | ROUTES.COURSES
  | ROUTES.MESSAGES
  | ROUTES.VIDEO_CALL
  | ROUTES.CUSTOM_APPS
  | ROUTES.LEADERBOARD
  | ROUTES.STOREFRONT;

export const PATH_MAP: Record<string, HomeRoutes> = {
  '/activity': ROUTES.FEED,
  '/courses': ROUTES.COURSES,
  '/messages': ROUTES.MESSAGES,
  '/dashboard': ROUTES.FEED,
  '/video-call': ROUTES.VIDEO_CALL,
  '/leaderboard': ROUTES.LEADERBOARD,
  '/storefront': ROUTES.STOREFRONT,
};
