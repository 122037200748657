import './styles.scss';

import React, { useCallback, useState } from 'react';

import { message } from 'antd';
import { NavLink } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';
import TMIcon from 'tm-icons-library';

import { useTheme } from '../../context/ThemeProvider';
import { checkForPermissions } from '../../context/userReducer';
import { useAppNavigate, useAppSelector } from '../../shared/hooks';
import { CustomRouteConfig, TMIconsType } from '../../types/baseTypes';
import { HomeRoutes, ROUTES } from '../../types/routes';
import { UAMPermissions } from '../../types/userTypes';
import BottomSheetTile from '../BottomSheetTile/BottomSheetTile';

const BottomNavbar: React.FC = () => {
  const { colors } = useTheme();
  const navigate = useAppNavigate();

  const {
    customRouter: { homeRoutes, menuRoutes },
  } = useAppSelector((state) => state.app);
  const userDetails = useAppSelector((state) => state.user);

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const getIcon = useCallback(
    (
      name: HomeRoutes | TMIconsType,
      focused: boolean,
      size: number,
      color: string,
    ) => {
      switch (name) {
        case ROUTES.FEED:
          return (
            <TMIcon
              name={focused ? 'compass' : 'compass-outline'}
              size={size}
              color={color}
            />
          );
        case ROUTES.STOREFRONT:
          return (
            <TMIcon
              name={focused ? 'storefront' : 'storefront-outline'}
              size={size}
              color={color}
            />
          );
        case ROUTES.COURSES:
          return (
            <TMIcon
              name={focused ? 'book' : 'book-outline'}
              size={size}
              color={color}
            />
          );
        case ROUTES.MESSAGES:
          return (
            <TMIcon
              name={focused ? 'chatbubbles' : 'chatbubbles-outline'}
              size={size}
              color={color}
            />
          );
        case ROUTES.VIDEO_CALL:
          return (
            <TMIcon
              name={focused ? 'videocam' : 'videocam-outline'}
              size={size}
              color={color}
            />
          );
        case ROUTES.LEADERBOARD:
          return (
            <TMIcon
              name={focused ? 'trophy' : 'trophy-outline'}
              size={size}
              color={color}
            />
          );
        default:
          return (
            <TMIcon
              name={(focused ? name : `${name}-outline`) as TMIconsType}
              size={size}
              color={color}
            />
          );
      }
    },
    [],
  );

  const checkIfRouteDisabled = useCallback(
    (route: HomeRoutes) => {
      switch (route) {
        case ROUTES.COURSES:
          return (
            userDetails.type === 'creator_restricted' &&
            !checkForPermissions(
              [UAMPermissions.COURSE_ACCESS],
              userDetails.userAccessPermissions,
            )
          );

        case ROUTES.MESSAGES:
          return (
            userDetails.type === 'creator_restricted' &&
            !checkForPermissions(
              [UAMPermissions.MESSAGES_ACCESS],
              userDetails.userAccessPermissions,
            )
          );

        default:
          return false;
      }
    },
    [userDetails],
  );

  const getTabComponent = useCallback(
    (
      route: { name: HomeRoutes; config: CustomRouteConfig },
      index: number,
      isDisabled: boolean,
    ) => {
      const label = route.config.shortTitle || route.config.title;

      if (isDisabled) {
        return (
          <a
            href="javascript:void(0)"
            key={index}
            className="disabled"
            title={label}
            style={{ cursor: 'not-allowed' }}
            onClick={() => {
              message.error('You do not have access to this feature');
            }}>
            <div className="tab-icon">
              {getIcon(route.config.icon || route.name, false, 26, colors.ICON)}
              <h5>{label}</h5>
            </div>
          </a>
        );
      }

      return (
        <NavLink
          key={index}
          to={route.name}
          title={label}
          className={({ isActive }) =>
            ['', isActive ? 'active' : null].filter(Boolean).join(' ')
          }>
          {({ isActive }) => (
            <div className="tab-icon">
              {getIcon(
                route.config.icon || route.name,
                isActive,
                26,
                isActive ? colors.PRIMARY : colors.ICON,
              )}
              <h5>{label}</h5>
            </div>
          )}
        </NavLink>
      );
    },
    [colors.ICON, colors.PRIMARY, getIcon],
  );

  return (
    <>
      {/* {userDetails.type !== 'creator_restricted' ||
      checkForPermissions(
        [UAMPermissions.COURSE_ACCESS],
        userDetails.userAccessPermissions,
      ) ? (
        <CourseSnapshot />
      ) : null} */}
      <div className="bottom--navbar__container">
        {homeRoutes.map((route, index) =>
          getTabComponent(
            route,
            index,
            checkIfRouteDisabled(route.name as HomeRoutes),
          ),
        )}
        {menuRoutes && menuRoutes.length > 0 ? (
          <a
            href="javascript:void(0)"
            key="menu-open"
            title="Menu"
            onClick={() => {
              setIsMenuVisible(true);
            }}>
            <div className="tab-icon">
              {getIcon('apps', false, 26, colors.ICON)}
              <h5>Others</h5>
            </div>
          </a>
        ) : null}
      </div>
      <BottomSheet
        open={isMenuVisible}
        onDismiss={() => {
          setIsMenuVisible(false);
        }}>
        {menuRoutes.map((route, index) => (
          <BottomSheetTile
            icon={getIcon(
              route.config.icon || route.name,
              false,
              18,
              colors.ICON,
            )}
            title={route.config.title}
            key={index}
            onClick={() => {
              setIsMenuVisible(false);
              navigate(route.name);
            }}
          />
        ))}
      </BottomSheet>
    </>
  );
};

export default React.memo(BottomNavbar);
