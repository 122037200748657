import React from 'react';

import { Typography } from 'antd';

import { ITags } from '../../../../types/feedTypes';
import TagSelectionModal from './TagSelectionModal';

interface Props {
  tags: ITags[];
}

const PostTags: React.FC<Props> = ({ tags: postTags }) => {
  const [showTagsModal, setShowTagsModal] = React.useState(false);

  const tagItem = (key: string, value: string) => (
    <div
      onClick={() => {
        // if (key === 'more') {
        setShowTagsModal(true);
        // }
      }}
      key={key}
      className={`post-tag-item ${key}`}>
      <Typography.Text className="post-tag-item-text">{value}</Typography.Text>
    </div>
  );

  return (
    <>
      {postTags.length > 0 ? (
        <div className="post-tags">
          {tagItem(postTags[0]._id, postTags[0].tagName)}
          {postTags.length > 1
            ? tagItem('more', `+${postTags.length - 1}`)
            : null}
        </div>
      ) : null}
      <TagSelectionModal
        showTags={postTags}
        show={showTagsModal}
        closeModal={() => {
          setShowTagsModal(false);
        }}
        noButton
        handleSave={() => {}}
      />
    </>
  );
};

export default PostTags;
