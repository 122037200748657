import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Typography,
} from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';
import TMIcon from 'tm-icons-library';

import GamificationAPI from '../../../../api/GamificationAPI';
import DynamicWrapper
  from '../../../../components/DynamicWrapper/DynamicWrapper';
import HeaderIcon from '../../../../components/Header/actions/HeaderIcon';
import DynamicHeader from '../../../../components/Header/DynamicHeader';
import Header from '../../../../components/Header/Header';
import Loader from '../../../../components/Loader';
import { useTheme } from '../../../../context/ThemeProvider';
import { ROUTES } from '../../../../types/routes';
import NoDataFound from '../../../Feed/components/NoDataFound/NoDataFound';
import LeaderboardList from './components/LeaderboardList/LeaderboardList';
import PointsInfoTable from './components/PointsInfoTable/PointsInfoTable';
import TopLeaderboard from './components/TopLeaderboard/TopLeaderboard';
import {
  ILeaderboardItem,
  LeaderboardType,
} from './types';

interface Props {}

enum Tabs {
  AllTime = 'lifetime',
  Week = 'week',
  Month = 'month',
}
interface IState {
  refreshing: boolean;
  loading: boolean;
  items: ILeaderboardItem[];
  page: number;
  hasMore: boolean;
}

const initialState: IState = {
  refreshing: false,
  loading: false,
  items: [],
  page: 1,
  hasMore: true,
};

const PAGE_LIMIT = 25;

const Leaderboard: React.FC<Props> = () => {
  const { colors } = useTheme();

  const [isPointsTableVisible, setIsPointsTableVisible] =
    useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<LeaderboardType>(Tabs.AllTime);
  const [state, setState] = useState<IState>(initialState);
  const [myRank, setMyRank] = useState<number | null>(null);

  const showPointsTable = () => {
    setIsPointsTableVisible(true);
  };

  const hidePointsTable = () => {
    setIsPointsTableVisible(false);
  };

  const fetchData = async (
    pageCount: number,
    items = state.items,
    hasMore = state.hasMore,
    loading = state.loading,
    currState = state,
  ) => {
    if (hasMore && !loading) {
      setState({ ...currState, loading: true });
      try {
        const response = await GamificationAPI.getLeaderboard(
          selectedTab,
          pageCount,
          PAGE_LIMIT,
        );
        let newState = { ...currState, loading: false, refreshing: false };
        if (response.status === 200) {
          const res = response.data.result;
          if (res && typeof res.myRank === 'number') {
            setMyRank(res.myRank);
          }
          if (res && res.list.length) {
            const list = res.list;
            const updatedLedger =
              pageCount > 1 ? [...(items || []), ...list] : [...list];
            newState = {
              ...newState,
              items: updatedLedger,
              page: pageCount + 1,
              hasMore: list.length === PAGE_LIMIT,
            };
          } else {
            newState = {
              ...newState,
              items: [...(items || [])],
              hasMore: false,
            };
          }
        }
        setState({ ...state, ...newState });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const loadData = () => {
    const newState: IState = {
      items: [],
      hasMore: true,
      page: 1,
      loading: false,
      refreshing: false,
    };
    setState((prev) => ({
      ...prev,
      items: [],
      hasMore: true,
      page: 1,
    }));
    setMyRank(null);
    fetchData(1, [], true, false, newState);
  };

  const loadMore = async () => {
    if (state.hasMore && !state.loading) {
      await fetchData(state.page, state.items);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const selectorItem = (
    value: LeaderboardType,
    name: string,
    style?: React.CSSProperties,
  ): JSX.Element => {
    const selected = value === selectedTab;
    return (
      <Button
        key={value}
        className={`siteBtn tab__selector__item ${selected ? 'selected' : ''}`}
        onClick={() => setSelectedTab(value)}
        style={{
          ...style,
        }}>
        <Typography.Text className="tab__selector__item__text">
          {name}
        </Typography.Text>
      </Button>
    );
  };

  console.log('Leaderboard -> state', state);

  return (
    <DynamicWrapper path={ROUTES.LEADERBOARD}>
      <div className="home__container leaderboard__container">
        <DynamicHeader
          path={ROUTES.LEADERBOARD}
          title="Leaderboard"
          actionItems={[
            <HeaderIcon
              icon="information-circle-outline"
              onClick={showPointsTable}
            />,
          ]}
        />

        <div className="home__content">
          {/* Tab options (About, Resources and QnA) */}
          <div className="tabs-wrapper">
            <div className="tab__selector">
              {selectorItem(Tabs.AllTime, 'All Time')}
              {selectorItem(Tabs.Week, 'Week')}
              {selectorItem(Tabs.Month, 'Month')}
            </div>
          </div>

          <div className="scroll__container">
            {!state.loading && !state.hasMore && state.items.length === 0 ? (
              <NoDataFound
                iconElement={
                  <TMIcon name="trophy" size={64} color={colors.TEXT4} />
                }
                subtitle="Users will appear here once they start earning points."
                hideTitle
                style={{
                  padding: '60px 16px',
                  width: '100%',
                }}
              />
            ) : null}

            {state.items.length > 0 ? (
              <>
                {/* Leaderboard */}
                <TopLeaderboard items={state.items} />
                {/* Leaderboard List with own rank */}
                <LeaderboardList
                  myRank={myRank}
                  items={state.items}
                  loadMore={loadMore}
                  hasMore={state.hasMore}
                />
              </>
            ) : null}

            {state.loading || state.hasMore ? (
              <Loader
                style={{
                  margin: '16px 0',
                  width: '100%',
                }}
              />
            ) : null}
          </div>
        </div>
      </div>

      <BottomSheet
        open={isPointsTableVisible}
        onDismiss={hidePointsTable}
        header={
          <Header canGoBack={false} title="Leaderboard Criteria" noBorder />
        }>
        <PointsInfoTable />
      </BottomSheet>
    </DynamicWrapper>
  );
};

export default Leaderboard;
