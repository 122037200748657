import React from 'react';

import { Typography } from 'antd';

import { ParticipantListItem } from '../../../../types/chatModels/ParticipantResponse';
import ChatAvatar from '../../components/ChatAvatar/ChatAvatar';

interface Props {
  onClick: () => void;
}

const ChatParticipantItem: React.FC<ParticipantListItem & Props> = ({
  name,
  profilePicUrl,
  isCreator,
  onClick,
}) => {
  return (
    <div className="chatRoomInfoModal__participant" onClick={onClick}>
      <ChatAvatar url={profilePicUrl} name={name} size={32} border={1} />
      <div className="chatRoomInfoModal__participant__inner">
        <Typography.Title
          level={5}
          className="chatRoomInfoModal__participant__inner__text"
          ellipsis={{
            rows: 1,
            expandable: false,
          }}>
          {name}
        </Typography.Title>
        {isCreator && (
          <Typography.Text className="creator-tag">CREATOR</Typography.Text>
        )}
      </div>
    </div>
  );
};

export default React.memo(ChatParticipantItem);
