import './styles.scss';

import React from 'react';

import { nFormat } from '../../../../../../shared/utils';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  points: number;
  name?: string;
  icon?: string;
  iconSize?: number | 'sm';
}

const PointsPill: React.FC<Props> = ({
  points,
  icon,
  name = 'XP',
  iconSize = 14,
  style,
  className,
  ...props
}) => {
  const size = ((iconSize === 'sm' || !iconSize) && 14) || iconSize;
  return (
    <div
      className={`points-pill ${className} ${iconSize === 'sm' ? 'sm' : ''}`}
      style={style}
      {...props}>
      {typeof icon === 'string' ? (
        <img src={icon} alt={`${name} Icon`} width={size} height={size} />
      ) : (
        icon
      )}
      {nFormat(points, 999, 2)} {name}
    </div>
  );
};

export default React.memo(PointsPill);
