import './CreatorTag.scss';

import React, { memo } from 'react';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  text?: React.ReactNode;
}

const CreatorTag: React.FC<Props> = ({ text = 'Creator', ...props }) => {
  return (
    <span className="creator-tag" {...props}>
      {text}
    </span>
  );
};

export default memo(CreatorTag);
