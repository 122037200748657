import React from 'react';

import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import bwIcon from '../../assets/img/notification-bw.png';
import coloredIcon from '../../assets/img/notification-colored.png';

interface Props {
  isRead: boolean;
  title: string;
  date: string;
  onPress: () => void;
  link: string;
}

const NotificationItem: React.FC<Props> = ({
  onPress,
  isRead,
  date,
  title,
  link,
}) => (
  <Link
    to={link}
    onClick={onPress}
    className={`notification ${isRead ? 'hasSeen' : ''}`}>
    <img
      width={45}
      height={45}
      alt="logo"
      src={isRead ? bwIcon : coloredIcon}
    />
    <div className="notificationDetails">
      <Typography.Text className="siteText">{title}</Typography.Text>
      <span className="notificationTime">{date}</span>{' '}
    </div>
  </Link>
);

export default NotificationItem;
