/* eslint-disable @typescript-eslint/no-unused-vars */
import './styles.scss';

import React, {
  useCallback,
  useEffect,
} from 'react';

import {
  Button,
  Typography,
} from 'antd';

import CourseAPI from '../../../api/CourseAPI';
import Loader from '../../../components/Loader';
import { useTheme } from '../../../context/ThemeProvider';
import showAppError from '../../../shared/error';
import {
  IAssessmentCombinedResponse,
  IAssessmentReview,
  IFetchedQuestionWithSubmissions,
} from '../../../types/assessmentTypes';
import Assessment from '../Assessment/Assessment';
import CompletedAssessmentItem from './CompletedAssesmentItem';

interface Props {
  chapterId: string;
  goToNextChapter: () => void;
  onProgress: (progress: number, duration: number) => void;
}

const AssessmentItem: React.FC<Props> = ({
  chapterId,
  goToNextChapter,
  onProgress,
}) => {
  const { colors } = useTheme();

  const [isLoading, setIsLoading] = React.useState(true);
  const [assignmentStatus, setAssignmentStatus] = React.useState<
    'finished' | 'ongoing' | 'not-started'
  >('not-started');
  const [assessmentReview, setAssessmentReview] =
    React.useState<IAssessmentReview>();

  const [showAssessmentModal, setShowAssessmentModal] = React.useState<
    'start' | 'retake' | null
  >(null);
  const [allowRetaking, setAllowRetaking] = React.useState<boolean | undefined>(
    false,
  );

  // const findResult = (
  //   questions: IFetchedQuestionWithSubmissions[],
  //   passingPercentage: number,
  // ): 'pass' | 'fail' => {
  //   let correct = 0;
  //   questions.forEach((question) => {
  //     if (question.type === 'single_select') {
  //       if (
  //         question.selectedAnswer &&
  //         question.answer &&
  //         question.selectedAnswer[0] === question.answer[0]
  //       ) {
  //         correct += 1;
  //       }
  //     } else if (question.marks) {
  //       correct += question.marks / 5;
  //     }
  //   });
  //   if ((correct / questions.length) * 100 >= passingPercentage) {
  //     return 'pass';
  //   }
  //   return 'fail';
  // };

  const findResult = (
    totalMarks: number,
    totalObtainedMarks: number,
    passingPercentage: number,
  ): 'pass' | 'fail' => {
    const passingMarks = totalMarks * (passingPercentage / 100);

    return totalObtainedMarks >= passingMarks ? 'pass' : 'fail';
  };

  const findAssessmentReview = useCallback(
    (resp: IAssessmentCombinedResponse) => {
      const answers = new Array(resp[1].length).fill({
        selectedAnswer: null,
        textAnswer: null,
        remarks: null,
        marks: 0,
        correct: false,
      });
      const questions: IFetchedQuestionWithSubmissions[] = [];

      resp[0].fetchdAnswers.forEach((answer, index) => {
        answers[answer.question.position ?? index] = {
          selectedAnswer: answer.selectedAnswer,
          textAnswer: answer.textAnswer,
          remarks: answer.remarks,
          marks: answer.marks,
          attachments: answer?.attachments || [],
        };
      });

      let totalMarks = 0;
      const totalObtainedMarks =
        (resp[3].fetchedUserAssignment?.reviewStatus === 'finished' &&
          resp[3].fetchedUserAssignment?.totalObtainedMarks) ||
        0;
      let totalAttempted = 0;
      let singleSelect = 0;

      resp[1].forEach((question, index) => {
        const questionPosition = question.position ?? index;
        totalMarks += question.marks || 0;

        if (
          (answers[questionPosition].selectedAnswer &&
            answers[questionPosition].selectedAnswer.length > 0) ||
          answers[questionPosition].textAnswer
        ) {
          totalAttempted += 1;
        }

        if (question.type === 'single_select') {
          singleSelect += 1;
        }

        questions.push({
          ...question,
          selectedAnswer: answers[questionPosition].selectedAnswer,
          textAnswer: answers[questionPosition].textAnswer,
          remarks: answers[questionPosition].remarks,
          totalMarks: question.marks || 0,
          marks: answers[questionPosition].marks,
          correct:
            answers[questionPosition].selectedAnswer &&
            question.answer &&
            answers[questionPosition].selectedAnswer[0] === question.answer[0],
          position: questionPosition,
          attachments: answers?.[questionPosition]?.attachments || [],
        });
      });

      const status = findResult(
        totalMarks,
        totalObtainedMarks,
        resp[3].fetchedAssignment.passingPercentage,
      );

      setAssessmentReview({
        fetchedAssignment: resp[3].fetchedAssignment,
        questionsAnswered: totalAttempted,
        questions,
        totalMarks,
        totalObtainedMarks,
        status,
        reviewStatus: resp[3].fetchedUserAssignment?.reviewStatus,
        // singleSelect === questions.length
        //   ? 'finished'
        //   : resp[3].fetchedUserAssignment?.reviewStatus,
      });
    },
    [],
  );

  const fetchAssignmentDetails = useCallback(
    async (id: string) => {
      try {
        setIsLoading(true);
        const resp = await CourseAPI.getAssignmentCombined(id);
        if (resp.status === 200) {
          if (
            resp.data.result[3].fetchedUserAssignment &&
            resp.data.result[3].fetchedUserAssignment.status === 'finished'
          ) {
            setAssignmentStatus(
              resp.data.result[3].fetchedUserAssignment.status,
            );
            setAllowRetaking(resp.data.result[3].allowRetaking);
          } else {
            setAssignmentStatus('not-started');
          }
          findAssessmentReview(resp.data.result);
        } else {
          showAppError(resp.data);
        }
      } catch (err) {
        showAppError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [findAssessmentReview],
  );

  useEffect(() => {
    if (chapterId) {
      fetchAssignmentDetails(chapterId);
    }
  }, [chapterId, fetchAssignmentDetails]);

  return (
    <>
      <div className="assessment-item">
        {assessmentReview && !isLoading ? (
          assignmentStatus === 'finished' ? (
            <CompletedAssessmentItem
              assessment={assessmentReview}
              allowRetaking={allowRetaking}
              onRetakeClick={() => {
                setShowAssessmentModal('retake');
              }}
            />
          ) : (
            <>
              <Typography.Title level={4} className="assessment-item__title">
                {assessmentReview.fetchedAssignment?.title?.trim() || ''}
                {'  '}
                <Typography.Text
                  style={{
                    fontWeight: 400,
                    fontSize: 13,
                    color: `${colors.TEXT1}80`,
                  }}>
                  |
                </Typography.Text>
                {'  '}
                <Typography.Text
                  style={{
                    fontWeight: 400,
                    fontSize: 16,
                    color: colors.PRIMARY,
                  }}>
                  {assessmentReview.questions?.length || 0} Questions
                </Typography.Text>
              </Typography.Title>
              <Typography.Paragraph
                ellipsis={{ rows: 3, expandable: true, symbol: 'See more' }}
                className="assessment-item__description">
                {assessmentReview.fetchedAssignment?.description || ''}
              </Typography.Paragraph>
              <div className="assessment-item__button-container">
                <Button
                  className="siteBtn siteBtnPrimary"
                  onClick={() => {
                    setShowAssessmentModal('start');
                  }}
                  disabled={!assessmentReview.questions?.length}>
                  {`${
                    assignmentStatus === 'ongoing' ? 'Continue' : 'Start'
                  } Assignment`}
                </Button>
                {assignmentStatus !== 'ongoing' ? (
                  <Button
                    className="siteBtn siteBtnSecondary"
                    onClick={goToNextChapter}>
                    Skip Assignment
                  </Button>
                ) : null}
              </div>
            </>
          )
        ) : (
          <Loader
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '45px 0',
            }}
          />
        )}
      </div>
      {assessmentReview && assessmentReview.questions?.length > 0 ? (
        <Assessment
          showModal={Boolean(showAssessmentModal)}
          retake={showAssessmentModal === 'retake'}
          closeModal={() => {
            setShowAssessmentModal(null);
            if (chapterId) {
              fetchAssignmentDetails(chapterId);
            }
          }}
          assignment={assessmentReview}
          onProgress={onProgress}
        />
      ) : null}
    </>
  );
};

export default AssessmentItem;
