import React from 'react';

import { useTheme } from '../../context/ThemeProvider';

const Scheduler: React.FC<React.SVGProps<SVGSVGElement> & { theme?: string }> = ({
  theme,
  ...props
}) => {
  const { colors } = useTheme();
  return (
    <svg width="20" height="20" viewBox="0 0 23.017 20.006" {...props}>
      <g id="calendar" transform="translate(-227.306 -762.5)">
        <path
          id="mail-inbox-app"
          d="M17.622,72.162H5.395A5.4,5.4,0,0,1,0,66.767V59.395A5.4,5.4,0,0,1,5.395,54H17.622a5.4,5.4,0,0,1,5.395,5.395v7.373A5.4,5.4,0,0,1,17.622,72.162ZM5.395,55.8a3.6,3.6,0,0,0-3.6,3.6v7.373a3.6,3.6,0,0,0,3.6,3.6H17.622a3.6,3.6,0,0,0,3.6-3.6V59.395a3.6,3.6,0,0,0-3.6-3.6Z"
          transform="translate(227.306 710.344)"
          fill={theme || colors.ICON}
        />
        <line
          id="Line_81"
          data-name="Line 81"
          x2="21.01"
          transform="translate(228.309 772.585)"
          fill="none"
          stroke={theme || colors.ICON}
          strokeWidth="2"
        />
        <line
          id="Line_82"
          data-name="Line 82"
          y1="5.883"
          transform="translate(232.511 762.5)"
          fill="none"
          stroke={theme || colors.ICON}
          strokeWidth="2"
        />
        <path
          id="Path_606"
          data-name="Path 606"
          d="M0,5.883V0"
          transform="translate(245.117 762.5)"
          fill="none"
          stroke={theme || colors.ICON}
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default React.memo(Scheduler);
