import './styles.scss';

import { useEffect, useRef, useState } from 'react';

import { Tabs } from 'antd';

import DynamicWrapper from '../../components/DynamicWrapper/DynamicWrapper';
import DynamicHeader from '../../components/Header/DynamicHeader';
import { ROUTES } from '../../types/routes';
import CompletedWorkshopsScreen, { RefProps } from './CompletedWorkshopsScreen';
import UpcomingWorkshopsScreen from './UpcomingWorkshopsScreen';

const Workshops: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<'upcoming' | 'completed'>(
    'upcoming',
  );

  const upcomingCallsRef = useRef<RefProps>(null);
  const completedCallsRef = useRef<RefProps>(null);

  useEffect(() => {
    if (selectedTab === 'upcoming') {
      upcomingCallsRef.current?.refreshPage();
    } else {
      completedCallsRef.current?.refreshPage();
    }
  }, [selectedTab]);

  return (
    <DynamicWrapper path={ROUTES.VIDEO_CALL}>
      <div className="workshops__container">
        <DynamicHeader path={ROUTES.VIDEO_CALL}>
          <Tabs
            defaultActiveKey={selectedTab}
            onChange={(newKey: string) =>
              setSelectedTab(newKey as 'upcoming' | 'completed')
            }
            items={[
              {
                label: 'Upcoming',
                key: 'upcoming',
                children: <UpcomingWorkshopsScreen ref={upcomingCallsRef} />,
              },
              {
                label: 'Completed',

                key: 'completed',
                children: <CompletedWorkshopsScreen ref={completedCallsRef} />,
              },
            ]}
          />
        </DynamicHeader>
      </div>
    </DynamicWrapper>
  );
};

export default Workshops;
