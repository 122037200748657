import './styles.scss';

import React from 'react';

import { Button, Modal, Typography } from 'antd';

import { useTheme } from '../../../context/ThemeProvider';

interface Props {
  title: string;
  message: string;
  handleOk: () => void;
  handleCancel: () => void;
  open: boolean;
  okayButtonText: string;
  cancelButtonText: string;
  loading?: boolean;
  okayButtonColor?: string;
  cancelButtonColor?: string;
  showOkayButton?: boolean;
}

const modalMaskStyle: React.CSSProperties = {
  backgroundColor: 'rgba(244, 244, 244, 0.8)',
};

const modalBodyStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 12,
  justifyContent: 'space-between',
  padding: '0 16px',
  overflow: 'hidden',
};

const ConfirmationModal: React.FC<Props> = ({
  title,
  message,
  handleOk,
  handleCancel,
  open,
  okayButtonText = 'Yes',
  cancelButtonText = 'Cancel',
  okayButtonColor,
  cancelButtonColor,
  showOkayButton = true,
  loading,
}) => {
  const { colors } = useTheme();

  return (
    <Modal
      title={
        <div className="activityPostModalTitleWrapper">
          <Typography.Title level={4} className="activityPostModalTitle">
            {title}
          </Typography.Title>
        </div>
      }
      closable={false}
      closeIcon={null}
      centered
      footer={
        <div className="confirmationModalFooter">
          {showOkayButton && (
            <Button
              className="siteBtn noStyle modalButton"
              disabled={loading}
              onClick={handleOk}
              style={{
                color: okayButtonColor || colors.DANGER,
              }}>
              {okayButtonText}
            </Button>
          )}
          <Button
            className="siteBtn noStyle modalButton"
            disabled={loading}
            onClick={handleCancel}
            style={{
              color: cancelButtonColor || colors.TEXT2,
            }}>
            {cancelButtonText}
          </Button>
        </div>
      }
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      className="confirmationModal"
      maskStyle={modalMaskStyle}
      bodyStyle={modalBodyStyle}
      maskClosable={false}
      zIndex={2000}
      width={300}>
      <div className="confirmationModalMain">
        <Typography.Paragraph className="text">
          {message || 'Are you sure you want to leave the page'}
        </Typography.Paragraph>
      </div>
    </Modal>
  );
};

export default React.memo(ConfirmationModal);
