import './styles.scss';

import React, { useEffect } from 'react';

import {
  DatePicker,
  message,
  Typography,
} from 'antd';
import HtmlParser from 'html-react-parser';
import moment from 'moment';

import UserAPI from '../../../../api/UserAPI';
import HeaderTextAction
  from '../../../../components/Header/actions/HeaderTextAction';
import Header from '../../../../components/Header/Header';
import FalseInput from '../../../../components/Input/FalseInput';
import Input from '../../../../components/Input/Input';
import Select, { SINGLE } from '../../../../components/Input/Select';
import Loader from '../../../../components/Loader';
import { setUserDetails } from '../../../../context/userReducer';
import showAppError from '../../../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
} from '../../../../shared/hooks';
import { ROUTES } from '../../../../types/routes';
import { IUserDetails } from '../../../../types/userTypes';

const title = 'Edit Personal Info';

const PersonalInfo: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [creator, setCreator] = React.useState<IUserDetails>();
  const [isUpdate, setIsUpdate] = React.useState(false);

  const handleSave = async () => {
    if (!creator?.name) {
      message.error('Name cannot be blank');
      return;
    }
    const data = {
      about: creator?.about,
      gender: creator?.gender,
      birthday: creator?.birthday,
      name: creator?.name,
    };
    try {
      setSaving(true);
      const resp = await UserAPI.updateAccountDetails(data);
      message.success('Successfully updated account details');
      setIsUpdate(false);
      dispatch(setUserDetails({ name: resp.data.result.name }));
      navigate.goBack();
    } catch (err: any) {
      showAppError(err);
    } finally {
      setSaving(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const resp = await UserAPI.getLatestUserDetails();
      if (resp.data.result) {
        setCreator(resp.data.result);
        setLoading(false);
      } else {
        showAppError(resp.data);
      }
    } catch (error) {
      showAppError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateCreator = (key: keyof IUserDetails, value: string) => {
    if (!creator || saving) return;
    setCreator({
      ...creator,
      [key]: value,
    });
    setIsUpdate(true);
  };

  return (
    <>
      <div className="pageRoot">
        <Header
          title={title}
          actionItems={[
            <HeaderTextAction
              text="Update"
              onClick={handleSave}
              loadingText="Updating"
              loading={saving}
            />,
          ]}
        />
        <div className="pageContent">
          {loading || !creator ? (
            <Loader
              size="large"
              style={{
                width: '100%',
                margin: '30px auto',
              }}
            />
          ) : (
            <div
              style={{
                padding: 12,
              }}>
              <Input
                label="Name"
                name="name"
                text={creator.name}
                onChangeText={(t) => {
                  updateCreator('name', t);
                }}
              />
              <FalseInput
                label="About you"
                onClick={() => {
                  if (!isUpdate) navigate(ROUTES.ABOUT_YOU);
                  else
                    message.error(
                      'Please click on update to save your changes first',
                    );
                }}>
                <Typography.Title
                  level={4}
                  style={{ marginBottom: 0 }}
                  ellipsis={{ rows: 3, expandable: false }}>
                  {creator.styledAbout
                    ? HtmlParser(creator.styledAbout)
                    : creator.about}
                </Typography.Title>
              </FalseInput>
              <Select<SINGLE>
                label="Gender"
                selected={creator.gender}
                onChange={(value) => {
                  updateCreator('gender', value);
                }}
                options={['Male', 'Female', 'Other']}
              />
              <FalseInput label="Birthday">
                <DatePicker
                  disabledDate={(current) => {
                    // Can not select days before 2 years ago
                    return current && current > moment().subtract(2, 'years');
                  }}
                  value={
                    creator.birthday
                      ? moment(creator.birthday)
                      : moment().subtract(2, 'years')
                  }
                  size="large"
                  onChange={(date) => {
                    if (date) {
                      updateCreator('birthday', date.toISOString());
                    }
                  }}
                  allowClear={false}
                  showToday={false}
                />
              </FalseInput>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
