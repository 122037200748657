import React from 'react';

import { useCounter } from '../../../shared/hooks';

interface Props {
  time: string | Date;
}

const Time: React.FC<Props> = ({ time }) => <strong>{useCounter(time)}</strong>;

export default React.memo(Time);
