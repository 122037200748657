import React, { memo } from 'react';

import { Skeleton } from 'antd';

const MangoItemSkeleton: React.FC = () => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <div className="mango__item" key={`mango__item-skeleton-${index}`}>
          <Skeleton
            active
            round
            title={{ width: 200, style: { marginBottom: 12 } }}
            paragraph={false}
          />
          <Skeleton.Button active size="small" />
        </div>
      ))}
    </>
  );
};

export default memo(MangoItemSkeleton);
