import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import { MdSearch } from 'react-icons/md';

import { useTheme } from '../../context/ThemeProvider';
import { useDebounce } from '../../shared/hooks';

interface Props {
  onDebounce: (value: string) => void;
  placeholder: string;
  style?: React.CSSProperties;
}

const SearchBar: React.FC<Props> = ({ onDebounce, placeholder, style }) => {
  const { colors } = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  useEffect(() => {
    onDebounce(debouncedSearchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  return (
    <div className="header--search-bar" style={style}>
      <div className="search-bar">
        <MdSearch color={colors.TEXT3} size={20} />
        <input
          className="search-input"
          value={searchValue}
          placeholder={placeholder}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
    </div>
  );
};

export default React.memo(SearchBar);
