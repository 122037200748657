import React from 'react';

import {
  Button,
  Input,
  Typography,
} from 'antd';

interface IOptionTextFieldProps {
  option: string;
  index: number;
  onOptionChange: (index: number, option: string) => void;
  onOptionDelete: (index: number) => void;
  canRemove?: boolean;
  maxLength?: number;
  style?: React.CSSProperties;
}
const OptionTextField: React.FC<IOptionTextFieldProps> = ({
  option,
  index,
  onOptionChange,
  onOptionDelete,
  canRemove = false,
  maxLength = 30,
  style,
}) => {
  return (
    <div className="createpoll__option" style={style}>
      <div className="createpoll__option__header">
        <Typography.Text className="createpoll__option__label">
          {`Option ${index + 1}${canRemove ? '' : '*'}`}
        </Typography.Text>
        {canRemove ? (
          <Button
            type="text"
            onClick={() => onOptionDelete(index)}
            disabled={!canRemove}>
            Remove
          </Button>
        ) : null}
      </div>
      <Input
        value={option}
        onChange={(e) => onOptionChange(index, e.target.value)}
        maxLength={maxLength}
        placeholder="Option"
        showCount
      />
    </div>
  );
};

export default OptionTextField;
