/* eslint-disable @typescript-eslint/naming-convention */
import './styles.scss';

import React, { useEffect, useState } from 'react';

import { Typography } from 'antd';

import ChatAPI from '../../../../api/ChatAPI';
import { useTheme } from '../../../../context/ThemeProvider';
import { CommonMangoResult } from '../../../../types/chatModels/CommonMangoesResponse';
import ChatAvatar from '../ChatAvatar/ChatAvatar';

interface Props {
  senderId: string;
  picUrl: string | undefined;
  title: string;
}

const DMChatScreenHeader: React.FC<Props> = ({ senderId, picUrl, title }) => {
  const { colors } = useTheme();

  const [commonMangoes, setCommonMangoes] = useState<CommonMangoResult>({
    creatorOf: [],
    subscriberOf: [],
  });

  const fetchMangoesInCommon = async () => {
    try {
      const data = await ChatAPI.getMangoesInCommon(senderId);
      setCommonMangoes(data.data.result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchMangoesInCommon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senderId]);

  return (
    <div className="dmChatScreenHeader">
      <ChatAvatar
        size={76}
        url={picUrl}
        name={title}
        selected
        selectedColor={colors.SECONDARY}
      />
      <Typography.Title
        level={3}
        ellipsis={{
          rows: 1,
          expandable: false,
        }}
        className="dmChatScreenHeader__title">
        {title}
      </Typography.Title>
      <div className="dmChatScreenHeader__mangoesInCommon">
        {commonMangoes.creatorOf.length > 0 && (
          <div className="dmChatScreenHeader__mangoesInCommon__inner">
            <Typography.Text>
              Creator of {commonMangoes.creatorOf[0].title}
            </Typography.Text>
            {commonMangoes.creatorOf.length > 1 && (
              <div className="dmChatScreenHeader__mangoesInCommon__inner__more">
                <Typography.Text>
                  + {commonMangoes.creatorOf.length - 1}
                </Typography.Text>
              </div>
            )}
          </div>
        )}
        {commonMangoes.subscriberOf.length > 0 && (
          <div className="dmChatScreenHeader__mangoesInCommon__inner">
            <Typography.Text>
              Subscriber of {commonMangoes.subscriberOf[0].title}
            </Typography.Text>
            {commonMangoes.subscriberOf.length > 1 && (
              <div className="dmChatScreenHeader__mangoesInCommon__inner__more">
                <Typography.Text>
                  + {commonMangoes.subscriberOf.length - 1}
                </Typography.Text>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(DMChatScreenHeader);
