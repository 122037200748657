/* eslint-disable @typescript-eslint/no-use-before-define */
import './styles.scss';

import React, { useEffect, useMemo, useState } from 'react';

import { Button, Input, message, Modal, Typography, Upload } from 'antd';
import HtmlParser from 'html-react-parser';
import { MdClose, MdOutlineFileUpload } from 'react-icons/md';
import ReactLinkify from 'react-linkify';

import CourseAPI from '../../../api/CourseAPI';
import TickSvg from '../../../assets/svg/TickSvg';
import HeaderTextAction from '../../../components/Header/actions/HeaderTextAction';
import Header from '../../../components/Header/Header';
import Loader from '../../../components/Loader';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal/ConfirmationModal';
import { useTheme } from '../../../context/ThemeProvider';
import showAppError from '../../../shared/error';
import { getFileName } from '../../../shared/file';
import { useAppSelector } from '../../../shared/hooks';
import {
  IAssessmentReview,
  IFetchedQuestionWithSubmissions,
} from '../../../types/assessmentTypes';

interface Props {
  showModal: boolean;
  retake?: boolean;
  closeModal: () => void;
  assignment: IAssessmentReview;
  onProgress: (progress: number, duration: number) => void;
}

interface IAnswerType {
  selectedAnswer?: number[] | null;
  textAnswer?: string | null;
  attachments?: string[];
  type: 'single_select' | 'text';
}

const Assessment: React.FC<Props> = ({
  showModal,
  retake,
  closeModal,
  assignment,
  onProgress,
}) => {
  const { colors } = useTheme();
  const userDetails = useAppSelector((state) => state.user);
  const [textValue, setTextValue] = useState<string | null>('');
  const { questions } = assignment;
  const [selectedOptions, setSelectedOptions] = useState<IAnswerType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAnswerSubmitting, setIsAnswerSubmitting] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [currQuestionIndex, setCurrQuestionIndex] = useState<number>(0);
  const [currQuestion, setCurrQuestion] =
    useState<IFetchedQuestionWithSubmissions | null>(
      questions.length > 0 ? questions[0] : null,
    );
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [attachedFileList, setAttachedFileList] = useState<string[]>([]);

  const isCreator = useMemo(() => {
    return (
      userDetails.type === 'creator_restricted' ||
      userDetails?.id === assignment?.fetchedAssignment?.creator
    );
  }, [
    assignment?.fetchedAssignment?.creator,
    userDetails.id,
    userDetails.type,
  ]);

  const isSavingAnswer = useMemo(() => {
    return isAnswerSubmitting || isSubmitting;
  }, [isAnswerSubmitting, isSubmitting]);

  const loadCurrentlySelectedOptions = () => {
    const selOptions: IAnswerType[] = new Array(questions.length).fill([]);
    questions.forEach((question) => {
      const { selectedAnswer, textAnswer, type, attachments } = question;
      if (retake) {
        selOptions[question.position || 0] = {
          selectedAnswer: null,
          textAnswer: null,
          type,
          attachments: [],
        };
      } else {
        selOptions[question.position || 0] = {
          selectedAnswer,
          textAnswer,
          type,
          attachments,
        };
      }
    });
    setSelectedOptions(selOptions);
    startAssessment();
  };

  const setOptionSelected = async (
    optionIndex: number,
    optionValue: IAnswerType,
  ) => {
    if (currQuestion) {
      let newSelOptions = [...selectedOptions];
      if (optionValue?.type === 'single_select') {
        newSelOptions[optionIndex] = optionValue;
        setSelectedOptions(newSelOptions);
      }
      let isUpdate = false;
      const oldData = selectedOptions[optionIndex];
      if (
        oldData?.type === 'text' &&
        oldData?.textAnswer === optionValue?.textAnswer &&
        oldData?.attachments?.length === attachedFileList?.length &&
        !file
      ) {
        return;
      }
      if (
        oldData?.type === 'text' &&
        (oldData?.textAnswer !== optionValue?.textAnswer || file)
      ) {
        isUpdate = true;
      }

      let hideLoadingStatus;

      if (!isCreator) {
        hideLoadingStatus = message.loading('Saving your answer...');
        setIsAnswerSubmitting(true);
      }
      try {
        const reqBody = new FormData();
        if (optionValue.selectedAnswer?.length) {
          reqBody.append(
            'selectedAnswer',
            optionValue.selectedAnswer?.[0].toString(),
          );
        }
        if (optionValue.textAnswer) {
          reqBody.append('textAnswer', optionValue.textAnswer as string);
        }
        if (file && optionValue.textAnswer) {
          reqBody.append('file', file);
        }
        if (
          !file &&
          isUpdate &&
          oldData?.attachments &&
          oldData?.attachments?.length
        ) {
          reqBody.append('attachedFilesURL', oldData?.attachments[0]);
        }

        if (isCreator) return;

        let assignmentSubmitAction = CourseAPI.assignmentUserSubmissionV2;
        if (isUpdate) {
          assignmentSubmitAction = CourseAPI.updateAssignmentUserSubmissionV2;
        }
        const resp = await assignmentSubmitAction(currQuestion._id, reqBody);
        if (resp.status === 200) {
          //
          setAttachedFileList(
            (resp?.data?.result?.attachments as string[]) || [],
          );
          setTimeout(() => {
            setFile(null);
          }, 500);
          const newOptionData = {
            selectedAnswer: resp?.data?.result?.selectedAnswer,
            textAnswer: resp?.data?.result?.textAnswer,
            attachments: resp?.data?.result?.attachments,
            type: currQuestion?.type,
          };
          newSelOptions[optionIndex] = newOptionData;

          setSelectedOptions(newSelOptions);
        } else {
          newSelOptions = [...selectedOptions];
          newSelOptions[optionIndex] = {
            selectedAnswer: null,
            textAnswer: null,
            type: currQuestion.type,
          };
          setSelectedOptions(newSelOptions);
          showAppError(resp.data);
        }
      } catch (err: any) {
        newSelOptions = [...selectedOptions];
        newSelOptions[optionIndex] = {
          selectedAnswer: null,
          textAnswer: null,
          type: currQuestion.type,
        };
        setSelectedOptions(newSelOptions);
        showAppError(err);
      } finally {
        if (!isCreator) {
          hideLoadingStatus?.();
          setIsAnswerSubmitting(false);
        }
      }
    }
  };

  const startAssessment = async () => {
    try {
      const resp = await CourseAPI.startAssignment(
        assignment.fetchedAssignment._id,
        retake ?? false,
      );
      if (resp.status === 200) {
        setIsLoading(false);
        setCurrQuestionIndex(0);
      } else {
        showAppError(resp.data);
      }
    } catch (err) {
      showAppError(err);
    }
  };

  const previous = () => {
    if (currQuestionIndex > 0) {
      if (currQuestion?.type === 'text' && file) {
        setFile(null);
      }
      setCurrQuestionIndex((prev) => prev - 1);
    }
  };

  const next = () => {
    if (questions && currQuestionIndex < questions.length - 1) {
      if (currQuestion?.type === 'text' && file) {
        setFile(null);
      }
      setCurrQuestionIndex((prev) => prev + 1);
    }
  };

  const beforeUploadAttachedFile = (importFile: File) => {
    if (importFile.size / 1024 / 1024 > 10) {
      message.error('File size must be less than 10MB');
      setFile(null);
      return false;
    }
    setFile(importFile);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setAttachedFileList([]);
    setVisible(false);
  };

  useEffect(() => {
    if (showModal) loadCurrentlySelectedOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, assignment]);

  useEffect(() => {
    setCurrQuestionIndex(0);
    setCurrQuestion(questions.length > 0 ? questions[0] : null);
    setFile(null);
  }, [questions]);

  useEffect(() => {
    setTextValue(selectedOptions[currQuestionIndex]?.textAnswer || null);
    setAttachedFileList(selectedOptions[currQuestionIndex]?.attachments || []);
  }, [currQuestionIndex, selectedOptions]);

  const submitAssessment = async () => {
    try {
      setIsSubmitting(true);
      const resp = await CourseAPI.submitAssignment(
        assignment.fetchedAssignment._id,
      );
      if (resp.status === 200) {
        onProgress(100, 100);
        message.info('Review assignment to view results');
        closeModal();
      } else {
        showAppError(resp.data);
      }
    } catch (err) {
      showAppError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const trySubmitAssessment = () => {
    setIsDialogVisible(true);
  };

  const attachFileUploader = useMemo(() => {
    if (file || attachedFileList?.length) {
      let fileName = '';

      if (attachedFileList?.length) {
        fileName = getFileName(attachedFileList[0]);
      } else {
        fileName = file?.name as string;
      }

      return (
        <div className="assessmentModalContent__options__attached_filename">
          <p className="assessmentModalContent__options__attached_filename_text">
            {fileName}
          </p>
          <MdClose
            className="assessmentModalContent__options__attached_close"
            onClick={() => setVisible(true)}
          />
        </div>
      );
    }
    return (
      <Upload
        fileList={file ? [file] : []}
        beforeUpload={beforeUploadAttachedFile}>
        <Button
          style={{
            borderRadius: 6,
            display: 'flex',
            alignItems: 'center',
          }}>
          <MdOutlineFileUpload style={{ fontSize: 16, marginRight: 8 }} />
          Attach file
        </Button>
      </Upload>
    );
  }, [attachedFileList, file]);

  useEffect(() => {
    setCurrQuestion(questions[currQuestionIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currQuestionIndex]);

  return (
    <>
      <Modal
        open={showModal}
        title={
          <Header
            title={assignment.fetchedAssignment.title || 'Assessment'}
            backType="cross"
            handleBack={closeModal}
            actionItems={
              isCreator
                ? []
                : [
                    <HeaderTextAction
                      text="Submit"
                      loadingText="Submitting"
                      loading={isSubmitting}
                      disabled={isLoading || isSavingAnswer}
                      onClick={trySubmitAssessment}
                    />,
                  ]
            }
          />
        }
        destroyOnClose
        className="assessmentModal"
        closable={false}
        footer={
          !isLoading && !isSubmitting ? (
            <div className="assessmentFooter">
              <Button
                className="siteBtn siteBtnSecondary"
                onClick={previous}
                disabled={currQuestionIndex === 0 || isSavingAnswer}>
                Previous
              </Button>
              <Button
                className="siteBtn siteBtnSecondary"
                onClick={next}
                disabled={
                  currQuestionIndex > questions.length - 2 || isSavingAnswer
                }>
                Next
              </Button>
            </div>
          ) : null
        }
        closeIcon={null}>
        {!isLoading && !isSubmitting && currQuestion ? (
          <div className="assessmentModalContent">
            <div className="assessmentModalContent__header">
              <Typography.Title
                level={4}
                className="assessmentModalContent__title">
                <Typography.Text className="assessmentModalContent__title__text">
                  Question{' '}
                </Typography.Text>
                {currQuestionIndex + 1}
                <Typography.Text className="assessmentModalContent__title__subtext">
                  {' '}
                  /
                  <Typography.Text className="assessmentModalContent__title__subtext__inner">
                    {' '}
                    {questions.length}
                  </Typography.Text>
                </Typography.Text>
              </Typography.Title>
              <Typography.Text className="assessmentModalContent__marks">
                Total marks:{' '}
                <span className="assessmentModalContent__marks__value">
                  {assignment.totalMarks}
                </span>
              </Typography.Text>
            </div>

            <div className="progressLinesContainer">
              {selectedOptions?.map((selOpt, index) => (
                <div
                  key={index}
                  className={`progressLines ${
                    selOpt.selectedAnswer?.length || selOpt.textAnswer?.length
                      ? 'answered'
                      : ''
                  }`}
                />
              ))}
            </div>

            <div className="assessmentModalContent__question-header">
              <Typography.Paragraph
                className="assessmentModalContent__question"
                ellipsis={{
                  rows: 3,
                  expandable: true,
                  symbol: 'See more',
                }}>
                {
                  <ReactLinkify>
                    {HtmlParser(currQuestion.question)}
                  </ReactLinkify>
                }
              </Typography.Paragraph>
              <Typography.Text className="assessmentModalContent__marks">
                <span className="assessmentModalContent__marks__value">
                  {currQuestion.totalMarks}
                </span>{' '}
                {currQuestion.totalMarks === 1 ? 'mark' : 'marks'}
              </Typography.Text>
            </div>

            <div className="assessmentModalContent__options">
              {currQuestion.type === 'single_select' ? (
                currQuestion.options.map((option, index) => (
                  <Button
                    key={index}
                    className={`assessmentModalContent__options__option
                    ${
                      selectedOptions[currQuestionIndex].selectedAnswer &&
                      selectedOptions[
                        currQuestionIndex
                      ].selectedAnswer?.includes(index)
                        ? 'selected'
                        : ''
                    }
                  `}
                    onClick={() => {
                      setOptionSelected(currQuestionIndex, {
                        selectedAnswer: [index],
                        textAnswer: null,
                        type: 'single_select',
                      });
                    }}
                    disabled={isSavingAnswer}>
                    <div className="assessmentModalContent__options__option__text">
                      <ReactLinkify>{HtmlParser(option)}</ReactLinkify>
                    </div>
                    {selectedOptions[currQuestionIndex].selectedAnswer &&
                    selectedOptions[currQuestionIndex].selectedAnswer?.includes(
                      index,
                    ) ? (
                      <TickSvg
                        circleColor={colors.PRIMARY}
                        backgroundColor={colors.BACKGROUND}
                        width={24}
                        height={24}
                      />
                    ) : (
                      <div className="assessmentModalContent__options__option__tick" />
                    )}
                  </Button>
                ))
              ) : (
                <div className="assessmentModalContent__options__text">
                  <Typography.Title
                    level={5}
                    className="assessmentModalContent__options__text__title">
                    Your answer
                  </Typography.Title>
                  <div style={{ marginTop: 6 }}>
                    <Input.TextArea
                      className="assessmentModalContent__options__text__input"
                      placeholder="Type your answer here..."
                      value={textValue || ''}
                      onChange={(e) => setTextValue(e.target.value)}
                      disabled={isSavingAnswer}
                    />
                  </div>
                  <div className="assessmentModalContent__options__text__submit">
                    {attachFileUploader}
                  </div>

                  {isCreator ? null : (
                    <div className="assessmentModalContent__options__text__submit">
                      <Button
                        className="siteBtn siteBtnPrimary"
                        loading={isAnswerSubmitting}
                        disabled={isAnswerSubmitting}
                        onClick={() => {
                          if (!textValue?.length) {
                            message.error('Please enter your answer');
                            return;
                          }
                          setOptionSelected(currQuestionIndex, {
                            selectedAnswer: null,
                            textAnswer: textValue,
                            type: 'text',
                          });
                        }}>
                        Submit Answer
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <Loader
            size="large"
            style={{
              margin: '45px 0',
              width: '100%',
              textAlign: 'center',
            }}
          />
        )}
      </Modal>

      <ConfirmationModal
        open={isDialogVisible}
        message={
          selectedOptions.filter(
            (o) => o.selectedAnswer?.length || o.textAnswer?.length,
          ).length < questions.length
            ? `You have submitted only ${
                selectedOptions.filter(
                  (o) => o.selectedAnswer?.length || o.textAnswer?.length,
                ).length
              } of ${questions.length} questions in this test. `
            : 'You have attempted all questions.'
        }
        title="Are you sure you want to submit the quiz?"
        okayButtonText={
          selectedOptions.filter(
            (o) => o.selectedAnswer?.length || o.textAnswer?.length,
          ).length < questions.length
            ? 'Submit Anyways'
            : 'Submit'
        }
        cancelButtonText="Continue Quiz"
        handleOk={() => {
          submitAssessment();
          setIsDialogVisible(false);
        }}
        handleCancel={() => {
          setIsDialogVisible(false);
        }}
      />

      <ConfirmationModal
        open={visible}
        message="Are you sure you want to remove this file from this question?"
        title="Remove File"
        okayButtonText="Remove"
        cancelButtonText="Cancel"
        handleOk={handleRemoveFile}
        handleCancel={() => {
          setVisible(false);
        }}
      />
    </>
  );
};
export default React.memo(Assessment);
