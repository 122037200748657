import { Mango } from './feedTypes';
import { IUserScore } from './gamificationTypes';

export interface IUserDetails {
  userSlug: string;
  mangoes: string[];
  showtwitter: boolean;
  showfacebook: boolean;
  showinstagram: boolean;
  showyoutube: boolean;
  showlinkedin: boolean;
  isDeleted: boolean;
  _id: string;
  email_verified: boolean;
  phone: number;
  onboarding: string;
  expireIn: Date;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  email?: string;
  name: string;
  profilePicUrl?: string;
  videoUrl: string;
  videoUrlUncompressed: string;
  accountNumber?: string;
  about?: string;
  styledAbout?: string;
  accountName?: string;
  birthday?: string;
  gender?: string;
  ifsc?: string;
  bankName?: string;
  otp: number;
  showInbox?: boolean;
  creatorAccessRequested?: ICreatorApproveStatus;
  registeredBy: 'email' | 'phone';
  country?: string;
  isDeactivated?: boolean;
  deactivationDate?: string;
  deleteRequestDate?: string;
  shortUrl?: string;
  gstNumber?: string;
  gstRegistrationAddress?: string;
  gstRegistrationName?: string;
  gstState?: string;
  pan?: string;
  panImage?: string;
  disabledChat?: boolean;
  currency?: string;
  tmCommission?: number;
  internationalBankAccount?: {
    account_number: string;
    bank_name: string;
    account_holder_name: string;
    routing_number: string;
  };
  isEmailVerified?: boolean;
  requiresPostMigrationVerification?: boolean;
  isBlockedFromCommunityEngagement?: boolean;
  score?: IUserScore;
  host?: string;
  whitelabelPlanType?: WhitelabelPlan;
  userTier?: CreatorTierType;
}

interface ICreatorCategoryBase {
  _id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface ICreatorCategory extends ICreatorCategoryBase {
  children: ICreatorCategoryChild[];
}

interface ICreatorCategoryChild extends ICreatorCategoryBase {
  parent: string;
}

export interface SelectedItemMap {
  [key: string]: { selected: boolean; item: ICreatorCategoryBase };
}

export type ICreatorApproveStatus =
  | 'approved'
  | 'requested'
  | 'notrequested'
  | null;

export enum UAMPermissions {
  'FEED_READ' = 'FEED_READ',
  'FEED_WRITE' = 'FEED_WRITE',
  'COURSE_ACCESS' = 'COURSE_ACCESS',
  'VIDEOCALL_ACCESS' = 'VIDEOCALL_ACCESS',
  'SCHEDULER_READ' = 'SCHEDULER_READ',
  'SCHEDULER_WRITE' = 'SCHEDULER_WRITE',
  'MESSAGES_ACCESS' = 'MESSAGES_ACCESS',
}

export interface IUser {
  id: string | null;
  name: string | null;
  email: string | null;
  phone: number | null;
  profilePic: string | null;
  type: OnboardingStatus | null;
  userTier?: CreatorTierType;
  userSlug: any | null;
  showInbox: boolean | null;
  approveStatus: ICreatorApproveStatus;
  shortUrl: string | null;
  disabledChat?: boolean;
  gstNumber?: string;
  currency: string;
  tmCommission?: number;
  country?: string;
  countryCode?: string;
  userAccessPermissions?: Array<UAMPermissions>;
  UAMParentCreator?: string;
  isEmailVerified?: boolean;
  requiresPostMigrationVerification?: boolean;
  isBlockedFromCommunityEngagement?: boolean;
  score?: IUserScore;
  host?: string;
  isDeactivated?: boolean;
  whitelabelPlanType?: WhitelabelPlan;
}

export interface IDetailsType {
  name: string | null;
  id: string | null;
  profilePic: string | null;
  type: string | null;
  userSlug: any | null;
  showInbox: boolean | null;
  approveStatus: ICreatorApproveStatus;
  shortUrl: string | null;
}

export interface IOrder {
  _id: string;
  refOrders: any[];
  isZapier: boolean;
  moengageCouponUsed: boolean;
  isRefunded: boolean;
  fan: string;
  creator: string;
  mango: string;
  amount: number;
  subscriberCurrency: string;
  discount?: number;
  gst: number;
  razorpayOrderId: string;
  status: string;
  discovery: boolean;
  paymentProvider: string;
  paytmOrderId: string;
  paytmTxnToken: string;
  stripeOrderId?: any;
  coupon: string;
  juspayOrderId: string;
  successRedirectionRoute: string;
  failureRedirectionRoute: string;
  afterDiscountAmount: number;
  commissionCharged: number;
  gstForCommission: number;
  gstForCreator: number;
  afterCommissionAmount: number;
  tmCommission: number;
  convenienceFee: number;
  convenienceWithoutGst: number;
  convenienceFeeGst: number;
  afterConvenienceAmount: number;
  whitelabelHost: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  subscription: string;
  invoiceNumber?: number;
  invoice?: string;
  commissionInvoice: string;
  commissionInvoiceNumber?: number;
  convenienceFeeInvoice: string;
  convenienceFeeInvoiceNumber?: number;
  gstNumber: string;
  gstRegistrationName: string;
  gstRegistrationAddress: string;
  gstState: string;
}

export interface ICreator {
  profilePicUrl: string;
  userSlug: string;
}

export interface ISubscription {
  autopayEnabled?: boolean;
  _id: string;
  creator?: ICreator;
  mango: Mango;
  expiredAt?: string;
  status: string;
  subscribedAt: string;
  latestSubscriptionDate?: string;
  orders: IOrder[];
  name?: string;
  creatorId?: string;
  country: string;
  profilePicUrl?: string;
  isPublic: boolean;
  isStopTakingPayment: boolean;
  videoCallFeature: number;
  updatedAt: number;
  isMigrated: boolean;
  fanCurrency: string;
  videocall?: number;
  nextChargeAt?: string;
  razorpayChargeAtWillToken?: string;
  razorpayChargeAtWillTokenStatus?: string;
  affiliateEnabled?: boolean;
  affiliatePercentage?: number;
}

export interface CreatorDetails {
  styledAbout?: string;
  showtwitter: boolean;
  showfacebook: boolean;
  showinstagram: boolean;
  showyoutube: boolean;
  showlinkedin: boolean;
  isDeactivated: boolean;
  fanCompleted: boolean;
  _id: string;
  email_verified: boolean;
  isDeleted?: boolean;
  phone: number;
  onboarding: string;
  otp?: any;
  expireIn?: any;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  email: string;
  name: string;
  profilePicUrl: string;
  profilePicUrlUncompressed?: string;
  accountName: string;
  accountNumber: string;
  bankPhone: number;
  ifsc: string;
  twitter?: string;
  facebook: string;
  instagram?: string;
  about: string;
  videoUrl: string;
  bankName: string;
  linkedin?: string;
  youtube?: string;
  birthday?: any;
  gender: string;
  razorpayLinkedAccountId: string;
  deviceId: string;
  userSlug: string;
  showSubscriberCount: boolean;
  shortUrl?: string;
  category?: string;
  subCategory?: string;
  videoThumbnail?: string;
  videoUrlUncompressed?: string;
}

export interface ActiveMango {
  additionalCoverContent?: string[];
  maxSubscriber?: number;
  remainingSlot?: number;
  includegst: boolean | undefined;
  currency?: string;
  shortUrl?: string;
  isDeleted: boolean;
  _id: string;
  isStopTakingPayment: boolean;
  isHidden: boolean;
  content: boolean;
  chat: boolean;
  videocall: number;
  creator: string;
  title: string;
  start: Date;
  end: Date;
  price: number;
  description: string;
  recurringType: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  razorpayPlanId: string;
  isPublic?: boolean;
  imgUrl?: string;
  videoUrl?: string;
}

export interface ICreatorDetailsResponse {
  creatordetails: CreatorDetails;
  activeMangoes: ActiveMango[];
  subscriberCount: number;
}

export interface SubscriberDetailsWithActiveMangoes {
  subscriptions: Subscription[];
  mangoes: Mango[];
}

// interface Mango {
//   isStopTakingPayment: boolean;
//   isHidden: boolean;
//   isDeleted: boolean;
//   content: boolean;
//   chat: boolean;
//   videocall: number;
//   webinar: boolean;
//   isPublic: boolean;
//   excludegst: boolean;
//   includegst: boolean;
//   activeSubscribers: number;
//   totalEarning: number;
//   playlistArr: any[];
//   shortUrl: string;
//   disableMoe: boolean;
//   disableReciept: boolean;
//   landingPagePublished: boolean;
//   _id: string;
//   creator: string;
//   title: string;
//   start: string;
//   end: string;
//   price: number;
//   currency: string;
//   description: string;
//   styledDescription: string;
//   recurringType: string;
//   whatsapp: string;
//   imgUrl: string;
//   videoUrl: string;
//   mangoSlug: string;
//   createdAt: string;
//   updatedAt: string;
//   __v: number;
// }

interface Subscription {
  orders: string[];
  hideRenew: boolean;
  _id: string;
  creator: string;
  fan: string;
  mango: string;
  __v: number;
  createdAt: string;
  expiredAt: string;
  juspayOrderId: string;
  paymentProvider: string;
  paytmOrderId?: any;
  razorpayOrderId?: any;
  status: string;
  stripeOrderId?: any;
  subscribedAt: string;
  updatedAt: string;
  totalEarning: number;
  videocall: number;
  latestSubscriptionDate: string;
}
export type CreatorTierType = `${CreatorTier}`;

export enum CreatorTier {
  TIER1 = 'tier1',
  TIER2 = 'tier2',
  TIER3 = 'tier3',
  TIER4 = 'tier4',
}

export type OnboardingStatus = `${Onboarding}`;

export enum Onboarding {
  DETAILS_ADDED = 'details_added',
  STARTED = 'started',
  CREATOR_MANGO_CREATED = 'creator_mango_created',
  CREATOR_COMPLETED = 'creator_completed',
  FAN_COMPLETED = 'fan_completed',
  DUAL = 'dual',
  CREATOR_RESTRICTED = 'creator_restricted',
}

export type OnboardingStatusTypes = `${Onboarding}`[];

export enum WhitelabelPlan {
  FREEDOM = 'freedom-plan',
  ENTERPRISE = 'enterprise-plan',
}

export type WhitelabelPlanType = `${WhitelabelPlan}`;
