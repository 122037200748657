import './styles.scss';

import React from 'react';

import { Typography } from 'antd';

import ChatAvatar from '../../screens/Messages/components/ChatAvatar/ChatAvatar';
import { useAppNavigate, useAppSelector } from '../../shared/hooks';
import { navigateToOnboardingScreen } from '../../shared/utils';

interface Props {
  title: string;
  url?: string;
  message?: string;
  onClick?: () => void;
}

const MessageHeader: React.FC<Props> = ({
  title,
  url,
  message = 'Tap here for room info',
  onClick,
}) => {
  const userDetails = useAppSelector((state) => state.user);
  const navigate = useAppNavigate();

  const customHandleBack = () => {
    if (navigate.canGoBack()) {
      navigate.goBack();
    } else {
      navigateToOnboardingScreen(userDetails.type, navigate);
    }
  };

  return (
    <div
      className="default-header message-header"
      style={{
        height: 60,
      }}>
      <button className="message-header__button" onClick={customHandleBack}>
        <svg width="20" height="20" viewBox="0 0 9.893 17.109">
          <g>
            <g>
              <path
                fill="currentColor"
                stroke="currentColor"
                d="M126.023 14.9l-7.041-6.88 7.041-6.879a.657.657 0 0 0 0-.945.7.7 0 0 0-.967 0l-7.525 7.352a.657.657 0 0 0 0 .945l7.525 7.352a.7.7 0 0 0 .967 0 .657.657 0 0 0 0-.945z"
                transform="translate(-116.83 0.568) translate(117.33 0) translate(-117.33 0)"
              />
            </g>
          </g>
        </svg>
      </button>
      <div className="message-header__content" onClick={onClick}>
        <ChatAvatar url={url} name={title} size={41} />
        <div className="message-header__title">
          <h2 className="message-header__title__text">{title}</h2>
          <Typography.Text className="message-header__subtitle__text">
            {message}
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MessageHeader);
