import {
  ILeaderboardResponse,
  LeaderboardType,
} from '../screens/Profile/screens/Leaderboard/types';
import { IPointsLedgerResponse } from '../screens/Profile/screens/PointsLedger/types';
import API from './';

export default abstract class GamificationAPI {
  static getPointsLedger = (page: number, limit: number) =>
    API.get<IPointsLedgerResponse>(
      `gamification/points/ledger?page=${page}&limit=${limit}`,
    );

  static getLeaderboard = (
    type: LeaderboardType,
    page: number,
    limit: number,
  ) =>
    API.post<ILeaderboardResponse>('gamification/leaderboard/list', {
      listType: type,
      page,
      limit,
    });
}
