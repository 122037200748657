import './App.scss';

import React, { useEffect } from 'react';

import axios from 'axios';
import { changeAntdTheme } from 'mini-dynamic-antd-theme';
import { useNavigate, useSearchParams } from 'react-router-dom';

import API from './api';
import AnalyticsEventsAPI from './api/AnalyticsEventsAPI';
import config from './api/config';
import UserAPI from './api/UserAPI';
import ScreenRotate from './assets/img/screen.png';
import LikedByUsersModal from './components/LikedByUsersModal/LikedByUsersModal';
import {
  setAppReady,
  setHostDataFailedCount,
  setHostMetadata,
  setIp,
  setLoggedIn,
  setNativeDomain,
  setUserDetailsFailedCount,
} from './context/appReducer';
import { useTheme } from './context/ThemeProvider';
import { setUserDetails } from './context/userReducer';
import Router from './Router';
import { REFRESH_TOKEN_KEY } from './shared/data';
import { useAppDispatch, useAppSelector } from './shared/hooks';
import { ICOLORS } from './shared/Styles';
import { getLocalData, hexToHSL, setLocalData } from './shared/utils';
import { ROUTES } from './types/routes';
import { CreatorTierType, OnboardingStatus } from './types/userTypes';

const App: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { updateColors } = useTheme();

  const dispatch = useAppDispatch();

  const {
    appReady,
    hostMetadata: { appName, logo },
    isTagMango,
    userDetailsFailedCount,
    hostDataFailedCount,
  } = useAppSelector((state) => state.app);

  const metaTitle = `${appName} Mobile`;
  const metaDescription = isTagMango
    ? 'TagMango helps content creators monetise better. It helps them host live workshops and launch their courses.'
    : `Check out your favourite creator ${appName}, to access your favourite workshops and courses.`;

  const fetchUser = async () => {
    try {
      const user = await UserAPI.getLatestUserDetails();
      if (user.data.result) {
        const newUserDetails = user.data.result;
        dispatch(
          setUserDetails({
            name: newUserDetails.name,
            email: newUserDetails.email,
            phone: newUserDetails.phone,
            profilePic:
              newUserDetails.profilePicUrl ||
              'https://tagmango.com/staticassets/avatar-placeholder.png-1612857612139.png',
            type: newUserDetails.onboarding as OnboardingStatus,
            id: newUserDetails._id,
            userSlug: newUserDetails.userSlug,
            showInbox: newUserDetails.showInbox || false,
            approveStatus: newUserDetails.creatorAccessRequested || null,
            shortUrl: newUserDetails.shortUrl || '',
            disabledChat: newUserDetails.disabledChat,
            gstNumber: newUserDetails.gstNumber,
            currency: newUserDetails.currency,
            tmCommission: newUserDetails?.tmCommission || 0,
            country: newUserDetails.country,
            isEmailVerified: newUserDetails.isEmailVerified,
            requiresPostMigrationVerification:
              newUserDetails.requiresPostMigrationVerification,
            isBlockedFromCommunityEngagement:
              newUserDetails.isBlockedFromCommunityEngagement,
            score: newUserDetails.score,
          }),
        );
        // set user details in events tracking
        AnalyticsEventsAPI.registerUser(newUserDetails._id as string, {
          name: newUserDetails.name as string,
          email: newUserDetails.email as string,
          phone: newUserDetails.phone as number,
          onboarding: newUserDetails.onboarding as OnboardingStatus,
          host: newUserDetails.host || window.location.host,
          whitelabelPlanType: newUserDetails.whitelabelPlanType || 'free',
          userTier: newUserDetails?.userTier as CreatorTierType,
          score: newUserDetails?.score?.lifetime || 0,
          status: newUserDetails.isDeactivated ? 'deactivated' : 'active',
        });
        AnalyticsEventsAPI.addCommonEventProperties({
          host: newUserDetails.host || window.location.host,
          name: newUserDetails.name as string,
          email: newUserDetails.email as string,
          phone: newUserDetails.phone as number,
        });
        dispatch(setLoggedIn(true));
        dispatch(setAppReady(true));
        dispatch(setUserDetailsFailedCount(0));
      }
    } catch (error) {
      console.log(error);
      // Increment failed count upto 3 times
      if (userDetailsFailedCount < 3) {
        dispatch(setUserDetailsFailedCount(userDetailsFailedCount + 1));
      } else {
        // Redirect to crash page if 3 attempts have failed
        dispatch(setUserDetailsFailedCount(0));
        navigate(ROUTES.PLATFORM_CRASHED);
      }
    }
  };

  const fetchPlatformMetadata = async () => {
    try {
      const metadata = await API.getHostDetails();
      dispatch(setHostMetadata(metadata.data.result));
      dispatch(setHostDataFailedCount(0));
      if (
        metadata.data.result?.colors &&
        Object.keys(metadata.data.result?.colors).length > 0
      ) {
        let colors: Partial<ICOLORS> = metadata.data.result.colors;
        if (colors.PRIMARY) {
          changeAntdTheme(colors.PRIMARY);
        }
        if (colors.PRIMARY && !colors.PRIMARY_LIGHT) {
          colors = Object.assign(
            {
              PRIMARY_LIGHT: colors.PRIMARY_LIGHT || hexToHSL(colors.PRIMARY),
            },
            colors,
          );
        }
        if (metadata.data.result?.creator._id) {
          API.setCreator(metadata.data.result.creator._id);
        }
        updateColors(colors);
      }
    } catch (error) {
      console.log(error);
      // Increment failed count upto 3 times
      if (hostDataFailedCount < 3) {
        dispatch(setHostDataFailedCount(hostDataFailedCount + 1));
      } else {
        // Redirect to crash page if 3 attempts have failed
        dispatch(setHostDataFailedCount(0));
        navigate(ROUTES.PLATFORM_CRASHED);
      }
    }
  };

  const init = () => {
    // get current host
    let hostName = window.location.hostname;

    hostName.replace('www.', '');

    if (hostName.startsWith('m.')) {
      hostName = hostName.substring(2);
    }

    // hostName = 'unfilteredhq.com';
    // hostName = 'app.financewsharan.com';
    // hostName = 'learn.consciousbreathinghub.com';
    // hostName = 'dikshaarora.com';
    // hostName = 'learn.divyanshudamani.xyz';

    if (
      hostName.includes('tagmango.com') ||
      hostName.includes('localhost') ||
      hostName.includes('192')
    ) {
      API.setHost(config.HOST);
      dispatch(setNativeDomain(config.HOST));
      // Fetch platform metadata only if the host is a whitelabel domain
      if (!['tagmango.com', 'testing.tagmango.com'].includes(config.HOST)) {
        fetchPlatformMetadata();
      }
    } else {
      API.setHost(hostName);
      dispatch(setNativeDomain(hostName));
      fetchPlatformMetadata();
    }

    // check if user is logged in
    // let refreshToken = searchParams.get('token');
    // if (refreshToken) {
    //   localStorage.clear();
    //   setLocalData(refreshToken);
    //   localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    //   fetchUser();
    // } else {
    const token = getLocalData();
    if (token) {
      setLocalData(token);
      fetchUser();
    } else {
      dispatch(setAppReady(true));
    }
    // }
  };

  useEffect(() => {
    console.log(window.location.host);
    console.log(window.location);
    // when redirecting from checkout page, we get refresh token in query params
    const refreshToken = searchParams.get('token');
    // if refresh token is present in query params, set token in local storage
    // and fetch access token using this refresh token
    // then call init
    if (refreshToken) {
      // clearing local storage
      localStorage.clear();

      // setting refresh token in local storage for refreshToken API call
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

      API.refreshToken({
        refreshToken,
        tempTokenTime: 6 * 60 * 60, // passing tempTokenTime as that the refresh token stays there, and not get deleted in the backend
        callback: () => {
          init();
          navigate(ROUTES.FEED);
        },
      });
    }
    // else call init
    else {
      init();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Retry loading host details upto 3 times
  useEffect(() => {
    if (hostDataFailedCount > 0 && hostDataFailedCount <= 3) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostDataFailedCount]);

  // Retry loading user details upto 3 times
  useEffect(() => {
    if (userDetailsFailedCount > 0 && userDetailsFailedCount <= 3) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetailsFailedCount]);

  useEffect(() => {
    if (appReady) {
      document.getElementById('favicon_main')?.setAttribute('href', logo);
      document.getElementById('favicon_apple')?.setAttribute('href', logo);
      document.title = metaTitle;
      document
        .getElementById('app_description')
        ?.setAttribute('content', metaDescription);
    }
  }, [metaTitle, metaDescription, logo, appReady]);

  // Store user's current public IP for use in mock vdoCipher overlay
  useEffect(() => {
    axios
      .get('https://api64.ipify.org/?format=json')
      .then((res) => dispatch(setIp(res.data.ip)))
      .catch((err) => {
        // message.error("Failed to obtain user's IP address");
        console.log(JSON.stringify(err));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appHeight = () => {
    setTimeout(() => {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
    }, 100);
  };

  useEffect(() => {
    window.addEventListener('resize', appHeight);
    appHeight();
    return () => window.removeEventListener('resize', appHeight);
  }, []);

  useEffect(() => {
    // initalize events tracking
    AnalyticsEventsAPI.init();
  }, []);

  return (
    <>
      <div className="landscape__wrapper">
        <div className="landscape__content">
          <img src={ScreenRotate} alt="logo" />
          <p className="landscape__content__text">
            Please rotate your device to portrait mode
          </p>
        </div>
      </div>
      {appReady ? (
        <>
          <Router />
          <LikedByUsersModal />
        </>
      ) : (
        <div className="loader__wrapper">
          <div className="lds-roller">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(App);
