import React, { useState } from 'react';

import { message } from 'antd';
import moment from 'moment';
import { BottomSheet } from 'react-spring-bottom-sheet';

import AnalyticsEventsAPI from '../../../api/AnalyticsEventsAPI';
import FeedAPI from '../../../api/FeedAPI';
import CloseRedSVG from '../../../assets/svg/CloseRedSVG';
import CopyLinkSvg from '../../../assets/svg/CopyLink';
import Scheduler from '../../../assets/svg/Scheduler';
import ViewMoreSvg from '../../../assets/svg/ViewMore';
import BottomSheetTile
  from '../../../components/BottomSheetTile/BottomSheetTile';
import ConfirmationModal
  from '../../../components/Modals/ConfirmationModal/ConfirmationModal';
import { useTheme } from '../../../context/ThemeProvider';
import showAppError from '../../../shared/error';
import { useAppSelector } from '../../../shared/hooks';
import { handleShareLink } from '../../../shared/utils';
import {
  IWebinar,
  IWebinarCreatorDetails,
} from '../../../types/feedTypes';
import VideoCallDetails from './VideoCallDetails';

interface Props {
  selectedWebinar: IWebinar | null;
  setSelectedWebinar: (data: IWebinar | null) => void;
  isMenuVisible: boolean;
  isDetailsVisible: boolean;
  onMenuClose: () => void;
  onDetailsClose: () => void;
  children: React.ReactNode;
  host: IWebinarCreatorDetails;
  webinarType?: 'upcoming' | 'completed';
  onJoinBtnClick: (data: IWebinar) => void;
  showDetails: () => void;
  refreshPage: () => void;
}

const VideoCallMenu: React.FC<Props> = ({
  selectedWebinar,
  setSelectedWebinar,
  isMenuVisible,
  isDetailsVisible,
  onMenuClose,
  onDetailsClose,
  children,
  webinarType = 'upcoming',
  host,
  onJoinBtnClick,
  showDetails,
  refreshPage,
}) => {
  const { colors } = useTheme();

  const userDetails = useAppSelector((state) => state.user);
  const {
    hostMetadata: { offeringTitle },
  } = useAppSelector((state) => state.app);

  const [isCancelSheetOpen, setIsCancelSheetOpen] = useState(false);
  const [isCancelAllSheetOpen, setIsCancelAllSheetOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const isMe = selectedWebinar
    ? selectedWebinar.creator === userDetails.id
    : false;

  const openCancelCallModal = () => {
    onMenuClose();
    setIsCancelSheetOpen(true);

    if (selectedWebinar) {
      // track cancel video call clicked event
      AnalyticsEventsAPI.trackWorkshopEvents('DELETE_WORKSHOP_CLICKED', {
        meetingId: selectedWebinar._id,
        date: new Date().toISOString(),
        title: selectedWebinar.title,
        platform: selectedWebinar.platform,
        createdAt: selectedWebinar.createdAt,
        fromTime: selectedWebinar.fromTime,
        toTime: selectedWebinar.toTime,
        callType: selectedWebinar.callType,
        ...(selectedWebinar.callType === 'recurring'
          ? { occurrenceDeleted: 'single' }
          : {}),
      });
    }
  };

  const openCancelAllCallsModal = () => {
    onMenuClose();
    setIsCancelAllSheetOpen(true);

    if (selectedWebinar) {
      // track cancel all video calls clicked event
      AnalyticsEventsAPI.trackWorkshopEvents('DELETE_WORKSHOP_CLICKED', {
        meetingId: selectedWebinar._id,
        date: new Date().toISOString(),
        title: selectedWebinar.title,
        platform: selectedWebinar.platform,
        createdAt: selectedWebinar.createdAt,
        fromTime: selectedWebinar.fromTime,
        toTime: selectedWebinar.toTime,
        callType: selectedWebinar.callType,
        occurrenceDeleted: 'all',
      });
    }
  };

  const timeDiffinMin = (data: any) => {
    const fromTime = data?.fromTime;

    if (fromTime) {
      const now = moment(new Date());
      const end = moment(fromTime);

      return moment.duration(end.diff(now)).asMinutes();
    }

    return 20;
  };

  const handleCopyLink = () => {
    if (selectedWebinar?.mango[0]?.shortUrl) {
      handleShareLink(`${selectedWebinar.mango[0]?.shortUrl}`, true);

      // track copy registration link event
      AnalyticsEventsAPI.trackWorkshopEvents('COPY_REGN_LINK_CLICKED', {
        meetingId: selectedWebinar._id,
        date: new Date().toISOString(),
        title: selectedWebinar.title,
        platform: selectedWebinar.platform,
        createdAt: selectedWebinar.createdAt,
        fromTime: selectedWebinar.fromTime,
        toTime: selectedWebinar.toTime,
        mango: selectedWebinar.mango?.[0]?._id,
      });
    } else {
      message.error('Link not available');
    }
  };

  const addToCalendar = async () => {
    if (!selectedWebinar) return;

    try {
      const resp = await FeedAPI.addToCalendar(selectedWebinar?._id);
      if (resp.status === 200) {
        window.open(resp?.data.result);
      } else {
        showAppError(resp.data);
      }
    } catch (err) {
      showAppError(err);
    }
  };

  const cancelVideoCallSeries = async (groupId: string) => {
    if (!groupId) return;

    // const errorMsg = 'Failed to cancel the recurring video calls';

    setLoading(true);

    try {
      FeedAPI.deleteVideoCall<{ groupId: string }>({ groupId })
        .then((res) => {
          if (res.status === 200) {
            message.success('Video calls cancelled successfully');

            if (selectedWebinar) {
              // track cancel all video calls event
              AnalyticsEventsAPI.trackWorkshopEvents('WORKSHOP_DELETED', {
                meetingId: selectedWebinar._id,
                date: new Date().toISOString(),
                title: selectedWebinar.title,
                platform: selectedWebinar.platform,
                createdAt: selectedWebinar.createdAt,
                fromTime: selectedWebinar.fromTime,
                toTime: selectedWebinar.toTime,
                callType: selectedWebinar.callType,
                occurrenceDeleted: 'all',
              });
            }

            refreshPage();
          } else {
            showAppError(res.data);
          }
        })
        .catch((error: any) => {
          showAppError(error);
        });
    } catch (err) {
      showAppError(err);
    } finally {
      setLoading(false);
    }
  };

  const cancelConsultationHandler = async (bookingId: string) => {
    if (!bookingId) return;

    const errorMsg = 'Failed to cancel the video call';

    setLoading(true);

    try {
      const res = await FeedAPI.cancelConsultationBooking([bookingId]);
      if (res.status === 200) {
        message.success('Video call cancelled successfully');
        setSelectedWebinar(null);
        setIsCancelSheetOpen(false);

        if (selectedWebinar && selectedWebinar.consultation) {
          // track cancel one-on-one call event
          AnalyticsEventsAPI.trackWorkshopEvents('WORKSHOP_DELETED', {
            meetingId: selectedWebinar.consultation,
            date: new Date().toISOString(),
            title: selectedWebinar.title,
            platform: selectedWebinar?.platform,
            createdAt: selectedWebinar?.createdAt,
            fromTime: selectedWebinar?.fromTime,
            toTime: selectedWebinar?.toTime,
            callType: selectedWebinar?.callType,
          });
        }

        refreshPage();
      } else {
        message.error(errorMsg);
        setSelectedWebinar(null);
        setIsCancelSheetOpen(false);
      }
    } catch (error: any) {
      console.log(error);
      message.error(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  const cancelWebinarHandler = async (webinarId: string) => {
    if (!webinarId) return;

    const errorMsg = 'Failed to cancel the video call';

    setLoading(true);

    try {
      const res = await FeedAPI.deleteVideoCall({ slotId: [webinarId] });
      if (res.status === 200) {
        message.success('Video call cancelled successfully');
        setSelectedWebinar(null);
        setIsCancelSheetOpen(false);

        if (selectedWebinar) {
          // track cancel one-on-one call event
          AnalyticsEventsAPI.trackWorkshopEvents('WORKSHOP_DELETED', {
            meetingId: selectedWebinar._id,
            date: new Date().toISOString(),
            title: selectedWebinar.title,
            platform: selectedWebinar.platform,
            createdAt: selectedWebinar.createdAt,
            fromTime: selectedWebinar.fromTime,
            toTime: selectedWebinar.toTime,
            callType: selectedWebinar.callType,
            ...(selectedWebinar.callType === 'recurring'
              ? { occurrenceDeleted: 'single' }
              : {}),
          });
        }

        refreshPage();
      } else {
        message.error(errorMsg);
        setSelectedWebinar(null);
        setIsCancelSheetOpen(false);
      }
    } catch (error: any) {
      console.log(error);
      message.error(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {children}
      <BottomSheet open={isMenuVisible} onDismiss={onMenuClose}>
        <BottomSheetTile
          icon={<ViewMoreSvg height={20} width={20} />}
          title="View details"
          onClick={showDetails}
        />
        {!selectedWebinar?.consultation ? (
          <BottomSheetTile
            icon={<CopyLinkSvg height={20} width={20} />}
            title="Invite People"
            description={`Copy ${offeringTitle} purchase link`}
            onClick={handleCopyLink}
          />
        ) : null}
        <BottomSheetTile
          icon={<Scheduler theme={colors.BLUE} height={20} width={20} />}
          title="Add to Calendar"
          description="Set a reminder"
          onClick={addToCalendar}
          color={colors.BLUE}
        />
        {selectedWebinar !== null &&
        timeDiffinMin(selectedWebinar) > 5 &&
        selectedWebinar.consultation ? (
          <BottomSheetTile
            warning
            icon={<CloseRedSVG height={20} width={20} />}
            title="Cancel 1-1 call"
            description={
              isMe
                ? 'Cancel this booking with the subscriber. He/she can book another slot.'
                : 'Cancel this booking with the creator. You can book another slot.'
            }
            onClick={openCancelCallModal}
          />
        ) : null}
        {isMe &&
        selectedWebinar !== null &&
        timeDiffinMin(selectedWebinar) > 15 &&
        !selectedWebinar.consultation ? (
          <BottomSheetTile
            warning
            icon={<CloseRedSVG height={20} width={20} />}
            title="Cancel video call"
            description="Delete this video call"
            onClick={openCancelCallModal}
          />
        ) : (
          <></>
        )}
        {isMe &&
        selectedWebinar !== null &&
        timeDiffinMin(selectedWebinar) > 15 &&
        selectedWebinar?.callType === 'recurring' &&
        !selectedWebinar.consultation ? (
          <BottomSheetTile
            warning
            icon={<CloseRedSVG height={20} width={20} />}
            title="Cancel all video calls"
            description="Delete all recurring calls in this series"
            onClick={openCancelAllCallsModal}
          />
        ) : (
          <></>
        )}
      </BottomSheet>

      {selectedWebinar ? (
        <VideoCallDetails
          data={selectedWebinar}
          host={host}
          webinarType={webinarType}
          onJoinBtnClick={onJoinBtnClick}
          showModal={isDetailsVisible}
          handleCopyLink={handleCopyLink}
          addToCalendar={addToCalendar}
          closeModal={onDetailsClose}
        />
      ) : null}

      <ConfirmationModal
        open={isCancelSheetOpen}
        title="Cancel"
        message="Are you sure you want to cancel this call?"
        handleOk={() => {
          if (selectedWebinar?.consultation) {
            cancelConsultationHandler(selectedWebinar.consultation);
          } else {
            cancelWebinarHandler(selectedWebinar?._id || '');
          }

          setIsCancelSheetOpen(false);
        }}
        handleCancel={() => {
          setIsCancelSheetOpen(false);
          setSelectedWebinar(null);
        }}
        loading={loading}
        okayButtonText="Cancel"
        cancelButtonText="Discard"
      />

      <ConfirmationModal
        open={isCancelAllSheetOpen}
        title="Cancel"
        message="Are you sure you want to cancel all recurring video call(s) of this series?"
        handleOk={() => {
          cancelVideoCallSeries(selectedWebinar?.groupId || '');
          setIsCancelAllSheetOpen(false);
        }}
        handleCancel={() => {
          setIsCancelAllSheetOpen(false);
          setSelectedWebinar(null);
        }}
        loading={loading}
        okayButtonText="Cancel All"
        cancelButtonText="Discard"
      />
    </>
  );
};

export default React.memo(VideoCallMenu);
