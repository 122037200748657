import React, { useEffect, useMemo, useState } from 'react';

import { Modal, Typography } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualized from 'easy-react-virtualized';

import { getPosts } from '../../../context/activityReducer';
import { checkForPermissions } from '../../../context/userReducer';
import { BASENAME } from '../../../shared/data';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { ROUTES } from '../../../types/routes';
import { UAMPermissions } from '../../../types/userTypes';
import PostDetailsScreen from '../../PostDetails/PostDetails';
import NoDataFound from '../components/NoDataFound/NoDataFound';
import PostItem from './PostItem/PostItem';
import PostSkeleton from './PostSkeleton';

const noPostsMessageStyle: React.CSSProperties = {
  width: '100%',
  textAlign: 'center',
  margin: '20px 0',
};

interface Props {
  userId?: string;
  isUserProfilePage?: boolean;
  showUserPosts?: boolean;
  noPostsMessage?: string;
}

const PostsList: React.FC<Props> = ({
  userId,
  isUserProfilePage,
  showUserPosts,
  noPostsMessage,
}) => {
  const dispatch = useAppDispatch();

  const { postState, selected } = useAppSelector((state) => state.activity);
  const userDetails = useAppSelector((state) => state.user);

  const isMe = userDetails.id === selected;

  const [selectedPostId, setSelectedPostId] = useState<string | undefined>();
  const [showModal, setShowModal] = useState(false);

  const loadMore = async () => {
    if (
      postState.hasMore &&
      (isUserProfilePage ? selected === userId : selected)
    ) {
      dispatch(
        getPosts({
          pageCount: postState.page,
          postList: postState.posts,
          showUserPosts,
        }),
      );
    }
  };

  useEffect(() => {
    if (showModal && selectedPostId) {
      window.history.replaceState(
        null,
        '',
        `${BASENAME}${ROUTES.POST_DETAILS.replace(':postId', selectedPostId)}`,
      );
    } else if (!isUserProfilePage) {
      window.history.replaceState(null, '', `${BASENAME}${ROUTES.FEED}`);
    }
  }, [showModal, selectedPostId, isUserProfilePage]);

  const noPostsContent = useMemo(() => {
    const title = 'Whoops! No posts found!';
    const subtitle =
      'Looks like your Creator has not put up any content yet. But don’t worry, they’ll be doing it soon! 🙌';

    if (noPostsMessage) {
      return (
        <Typography.Paragraph style={noPostsMessageStyle}>
          {noPostsMessage}
        </Typography.Paragraph>
      );
    }

    return isMe ? (
      <Typography.Paragraph style={noPostsMessageStyle}>
        Whoops! No posts found!
      </Typography.Paragraph>
    ) : (
      <NoDataFound title={title} subtitle={subtitle} />
    );
  }, [noPostsMessage, isMe]);

  return (
    <>
      <EasyVirtualized
        onLoadMore={loadMore}
        useParentScrollElement
        hasMore={postState.hasMore}>
        {postState.posts.map((item, index) => (
          <PostItem
            key={item._id}
            post={item}
            index={index}
            onCommentsPressed={() => {
              setSelectedPostId(item._id);
              setShowModal(true);
            }}
          />
        ))}

        {userDetails.type !== 'creator_restricted' ||
        checkForPermissions(
          [UAMPermissions.FEED_READ],
          userDetails.userAccessPermissions,
        ) ? (
          postState.loading || !selected || postState.hasMore ? (
            <>
              <PostSkeleton />
              <PostSkeleton />
            </>
          ) : postState.posts.length === 0 ? (
            noPostsContent
          ) : (
            <Typography.Paragraph style={noPostsMessageStyle}>
              That’s it! No more posts to show.
            </Typography.Paragraph>
          )
        ) : null}
      </EasyVirtualized>

      <Modal
        open={showModal}
        title={null}
        footer={null}
        closable={false}
        destroyOnClose
        className="postDetailsModal">
        <PostDetailsScreen
          postId={selectedPostId}
          handleBack={() => {
            setShowModal(false);
            setSelectedPostId(undefined);
          }}
        />
      </Modal>
    </>
  );
};

export default React.memo(PostsList);
