export const BASENAME = '/web';

export const TOKEN_KEY = 'tmmobile_accessToken';
export const REFRESH_TOKEN_KEY = 'token';

export const COURSE_NAVIGATOR_HEIGHT = 61;

/* Constants */
export const validImageExtensions = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/heic',
  'image/webp',
];

export const validVideoExtensions = [
  'video/mp4',
  'video/mkv',
  'video/mov',
  'video/wmv',
  'video/avi',
  'video/avchd',
  'video/mpeg-2',
  'video/webm',
];

export const validAudioExtensions = [
  'audio/mp3',
  'audio/mp4',
  'audio/wav',
  'audio/wma',
  'audio/mpeg',
];

export const toolbarMango = {
  options: ['inline', 'blockType'],
  inline: {
    inDropdown: false,
    className: 'inlineTool',
    component: undefined,
    dropdownClassName: 'toolbarDropdown',
    options: ['bold', 'italic', 'underline'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3'],
    className: 'blockTool',
    component: undefined,
    dropdownClassName: 'toolbarDropdown',
  },
};

export const DEFAULT_AVATAR =
  'https://tagmango.com/staticassets/avatar-placeholder.png-1612857612139.png';

export const DELETED_USER = 'DELETED USER';
export const UNKNOWN_USER = 'UNKNOWN USER';
