import './styles.scss';

import React from 'react';

import { Button } from 'antd';

import ChatAudioPlayer from '../../../../components/AudioPlayer/ChatAudioPlayer/ChatAudioPlayer';
import CustomImage from '../../../../components/CustomImage/CustomImage';
import Zoom from '../../../../components/ImageViewer/Zoom';
import BitmovinPlayer from '../../../../components/VideoPlayer/BitmovinPlayer/BitmovinPlayer';
import { ChatAttachmentTypes } from '../../../../shared/messageUtils';
import { formatBytes } from '../../../../shared/utils';
import { ExtraData } from '../../../../types/chatModels/ChatMessagesResponse';

interface Props {
  type: ChatAttachmentTypes;
  contentUrl?: string;
  fileName?: string;
  extraData?: ExtraData;
  id: string;
  mediaColor?: string;
  downloadMedia: () => void;
}

const ChatMedia: React.FC<Props> = ({
  type,
  contentUrl,
  id,
  mediaColor,
  fileName,
  extraData,
  downloadMedia,
}) => {
  const content = () => {
    switch (type) {
      case 'image':
        return (
          <div className="chatImageWrapper">
            <Zoom src={contentUrl || ''}>
              <CustomImage src={contentUrl} alt="" className="mainImage" />
            </Zoom>
          </div>
        );
      case 'video':
        return (
          <div className="chatVideoWrapper" key={id}>
            <BitmovinPlayer
              mediaId={id}
              mediaUrl={contentUrl || ''}
              key={id}
              isCompressDone
            />
          </div>
        );
      case 'audio':
        return (
          <div
            className="chatAudioWrapper"
            key={id}
            style={{ backgroundColor: mediaColor }}>
            <ChatAudioPlayer id={id} url={contentUrl || ''} />
          </div>
        );
      case 'doc':
        return (
          <div className="docWrapper" style={{ backgroundColor: mediaColor }}>
            <div className="docIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30.837"
                height="35.977"
                viewBox="0 0 30.837 35.977">
                <g
                  id="Layer_10"
                  data-name="Layer 10"
                  transform="translate(-4 1.988)">
                  <path
                    id="Path_1103"
                    data-name="Path 1103"
                    d="M27.128,9.709v-6.9l7.015,8.185H28.413A1.285,1.285,0,0,1,27.128,9.709Zm1.285,3.855a3.855,3.855,0,0,1-3.855-3.855V2H7.855A3.855,3.855,0,0,0,4,5.855V34.122a3.855,3.855,0,0,0,3.855,3.855H30.982a3.855,3.855,0,0,0,3.855-3.855V13.564Z"
                    transform="translate(0 -3.988)"
                    fill="rgba(255,255,255,0.5)"
                  />
                </g>
              </svg>
            </div>
            <div className="docDetails">
              <h6 className="docTitle">{fileName}</h6>
              <div className="docInfo">
                <div className="docType">{extraData?.ext}</div>
                <div className="bulletSeparator" />
                <div className="docSize">
                  {formatBytes(parseInt(extraData?.fileSize || '0'))}
                </div>
              </div>
            </div>
            <Button
              className="siteBtn noStyle circularBgHover download"
              onClick={downloadMedia}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.266"
                height="18.266"
                viewBox="0 0 18.266 18.266">
                <g id="download" transform="translate(-19 -19)">
                  <path
                    id="Path_1104"
                    data-name="Path 1104"
                    d="M42.344,19a.844.844,0,0,0-.844.844v9.632l-3.229-3.229a.844.844,0,1,0-1.194,1.194l4.67,4.669a.845.845,0,0,0,1.194,0l4.67-4.67a.844.844,0,1,0-1.194-1.194l-3.229,3.23V19.844A.844.844,0,0,0,42.344,19Z"
                    transform="translate(-14.211)"
                    fill="#fff"
                  />
                  <path
                    id="Path_1105"
                    data-name="Path 1105"
                    d="M19.844,74a.844.844,0,0,0-.844.844v2.782a3.48,3.48,0,0,0,3.476,3.476H33.79a3.48,3.48,0,0,0,3.476-3.476V74.849a.844.844,0,0,0-1.689,0v2.782a1.789,1.789,0,0,1-1.787,1.787H22.476a1.789,1.789,0,0,1-1.787-1.787V74.849A.844.844,0,0,0,19.844,74Z"
                    transform="translate(0 -43.842)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </Button>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div
      className="chatMediaWrapper"
      style={{
        pointerEvents: 'visiblePainted',
      }}>
      {content()}
    </div>
  );
};

export default React.memo(ChatMedia);
