import * as React from 'react';

import { useTheme } from '../../../../context/ThemeProvider';

const MangoSvg: React.FC<React.SVGAttributes<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg id="mango" width="33" height="33" viewBox="0 0 33 33" {...props}>
      <circle
        id="Ellipse_169"
        data-name="Ellipse 169"
        cx="16.5"
        cy="16.5"
        r="16.5"
        fill={colors.PRIMARY}
      />
      <path
        id="TagMango_Icon"
        data-name="TagMango Icon"
        d="M32.655,25.4a7.994,7.994,0,0,1,.832-.282,2.679,2.679,0,0,1,1.69.214,6.32,6.32,0,0,1,1.849,1.3.355.355,0,0,1,.041.51,5.565,5.565,0,0,1-2.3,1.612,2.479,2.479,0,0,1-1.642-.006.442.442,0,0,0-.061-.013c.134.247.276.48.391.724a4.411,4.411,0,0,1-.151,4.226,10.065,10.065,0,0,1-4.776,4.471,7.525,7.525,0,0,1-3.6.625,4.873,4.873,0,0,1-1.67-.341,2.12,2.12,0,0,1-1.329-2.15,2.719,2.719,0,0,1,.8-1.776,2.347,2.347,0,0,0,.606-1.239c.143-.741.255-1.49.426-2.227A5.748,5.748,0,0,1,26.639,27.2a4.715,4.715,0,0,1,4.162-.261c.115.043.163,0,.225-.072a4.469,4.469,0,0,0,.945-1.843c.094-.349.148-.709.214-1.066a.389.389,0,0,1,.411-.347.353.353,0,0,1,.33.416c-.061.4-.148.8-.226,1.2C32.687,25.292,32.666,25.351,32.655,25.4Z"
        transform="translate(-12.69 -14.371)"
        fill={colors.BACKGROUND}
      />
    </svg>
  );
};

export default React.memo(MangoSvg);
