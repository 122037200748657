import './LikedByUsersModal.scss';

import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  message,
  Modal,
  Typography,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

import FeedAPI from '../../api/FeedAPI';
import { setLikedByUsersModalState } from '../../context/activityReducer';
import { useTheme } from '../../context/ThemeProvider';
import ChatAvatar
  from '../../screens/Messages/components/ChatAvatar/ChatAvatar';
import { SEARCH_THRESHOLD } from '../../shared/constants';
import { DEFAULT_AVATAR } from '../../shared/data';
import showAppError from '../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../shared/hooks';
import { ILikedByUser } from '../../types/feedTypes';
import { ROUTES } from '../../types/routes';
import CreatorTag from '../CreatorTag/CreatorTag';
import Header from '../Header/Header';
import SearchBar from '../Header/SearchBar';
import LikedByUserSkeleton from './LikedByUserSkeleton';

interface ILikedByUsersState {
  loading: boolean;
  hasMore: boolean;
  users: ILikedByUser[];
  page: number;
  limit: number;
}

const initialUsersState: ILikedByUsersState = {
  loading: false,
  hasMore: true,
  users: [],
  page: 1,
  limit: 25,
};

const LikedByUsersModal: React.FC = () => {
  const navigate = useAppNavigate();

  const { colors } = useTheme();

  const dispatch = useAppDispatch();

  const {
    hostMetadata: {
      creator: { _id: hostCreatorId },
    },
  } = useAppSelector((state) => state.app);
  const { likedByUsersModalState } = useAppSelector((state) => state.activity);

  const [state, setState] = useState<ILikedByUsersState>(initialUsersState);
  const [searchValue, setSearchValue] = useState<string>('');

  const scrollContainerRef = useRef<HTMLElement | null>(null);

  const closeLikedByUsersModal = () => {
    dispatch(
      setLikedByUsersModalState({
        visible: false,
        postId: '',
        pollId: '',
        commentId: '',
      }),
    );
  };

  const goToUserProfile = (userId: string) => {
    if (userId) {
      closeLikedByUsersModal();
      navigate(ROUTES.USER_PROFILE, {
        userId,
      });
    }
  };

  const fetchLikedByUsers = async (page: number = 1) => {
    if (
      !(
        likedByUsersModalState.postId ||
        likedByUsersModalState.pollId ||
        likedByUsersModalState.commentId
      )
    ) {
      message.error('No post or comment ID found');
    }

    if (state.loading || !state.hasMore) return;

    const errorMsg = 'Failed to fetch liked by users';

    const newState = { ...initialUsersState, ...state };

    if (page === 1) {
      newState.users = [];
    }

    newState.loading = true;

    setState({ ...newState });

    try {
      const response = await FeedAPI.getAllLikedByUsers({
        page,
        limit: state.limit,
        search: searchValue,
        postId: likedByUsersModalState.postId,
        pollId: likedByUsersModalState.pollId,
        commentId: likedByUsersModalState.commentId,
      });
      const res = response.data;
      if (response.status === 200) {
        const list =
          (Array.isArray(res.result) && [...res.result]) ||
          (res.result &&
            'users' in res.result &&
            Array.isArray(res.result.users) && [...res.result.users]) ||
          [];
        const newUsers = [...list];

        newState.users = [...newState.users, ...newUsers];
        newState.hasMore = newUsers.length >= newState.limit;

        if (newState.hasMore) {
          newState.page += 1;
        }
      } else {
        showAppError(res, errorMsg);
      }
    } catch (err) {
      showAppError(err, errorMsg);
    } finally {
      newState.loading = false;

      setState({ ...newState });
    }
  };

  const reloadLikedByUsers = () => {
    setState({ ...initialUsersState });
    fetchLikedByUsers();
  };

  // const loadMore = async () => {
  //   if (state.hasMore) await fetchLikedByUsers(state.page);
  // };

  useEffect(() => {
    if (likedByUsersModalState.visible) {
      reloadLikedByUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, likedByUsersModalState]);

  useEffect(() => {
    const element = document.getElementById('liked-by-users-modal__card');

    if (element) {
      scrollContainerRef.current = element;
    }
  }, []);

  const sortedUsers: ILikedByUser[] = useMemo(() => {
    const creator = state.users.find((user) => user._id === hostCreatorId);

    if (creator) {
      return [
        creator,
        ...state.users.filter((user) => user._id !== hostCreatorId),
      ];
    }

    return state.users;
  }, [hostCreatorId, state.users]);

  const content = useMemo(() => {
    const { postId, commentId, pollId } = likedByUsersModalState;

    if (commentId) {
      return {
        modalTitle: 'Comment likes',
        listHeaderTitle: 'LIKED BY',
        noUsersText: 'NO LIKES',
      };
    }

    if (postId) {
      return {
        modalTitle: 'Post likes',
        listHeaderTitle: 'LIKED BY',
        noUsersText: 'NO LIKES',
      };
    }

    if (pollId) {
      return {
        modalTitle: 'Poll participants',
        listHeaderTitle: 'VOTED BY',
        noUsersText: 'NO VOTES',
      };
    }

    return {
      modalTitle: '',
      listHeaderTitle: '',
      noUsersText: '',
    };
  }, [likedByUsersModalState]);

  return (
    <Modal
      key={
        likedByUsersModalState.postId ||
        likedByUsersModalState.pollId ||
        likedByUsersModalState.commentId
      }
      open={
        likedByUsersModalState.visible &&
        Boolean(
          likedByUsersModalState.postId ||
            likedByUsersModalState.pollId ||
            likedByUsersModalState.commentId,
        )
      }
      title={
        <Header
          title={content.modalTitle}
          handleBack={closeLikedByUsersModal}
        />
      }
      destroyOnClose
      className="messageInfoModal liked-by-users-modal"
      closable={false}
      closeIcon={null}
      footer={null}>
      <div className="liked-by-users-modal__search-wrapper">
        <SearchBar
          onDebounce={(value) => {
            if (value.length >= SEARCH_THRESHOLD) {
              setSearchValue(value);
            } else {
              setSearchValue('');
            }
          }}
          placeholder="Search by name"
          style={{
            borderBottomWidth: 1,
            borderColor: colors.BORDER,
          }}
        />
      </div>

      <div className="messageInfoModal__card liked-by-users-modal__card">
        <InfiniteScroll
          pageStart={0}
          loadMore={() => fetchLikedByUsers(state.page)}
          hasMore={state.hasMore}
          loader={
            (state.loading ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 12,
                  paddingTop: 0,
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    // gap: 12,
                    paddingTop: 0,
                  }}>
                  {Array(3)
                    .fill(0)
                    .map((_, index) => (
                      <LikedByUserSkeleton key={index} />
                    ))}
                </div>
              </div>
            ) : null) as JSX.Element
          }
          useWindow={false}
          getScrollParent={() => scrollContainerRef.current as HTMLElement}>
          {sortedUsers.length > 0 ? (
            <>
              <Typography.Title
                level={5}
                className="messageInfoModal__card__title">
                {content.listHeaderTitle}
              </Typography.Title>
              <div className="liked-by-users-modal__card__users-list">
                {sortedUsers.map((user) => (
                  <div
                    className="liked-by-users-modal__card__users-list__item"
                    onClick={() => goToUserProfile(user._id)}>
                    <ChatAvatar
                      url={user.profilePicUrl || DEFAULT_AVATAR}
                      size={32}
                      style={{ flexShrink: 0 }}
                    />

                    <div className="liked-by-users-modal__card__users-list__item__text-wrapper">
                      <Typography.Title
                        level={5}
                        ellipsis={{ rows: 1, expandable: false }}
                        className="text">
                        {user.name}
                      </Typography.Title>
                      {user._id === hostCreatorId && <CreatorTag />}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <Typography.Title
              level={5}
              className="messageInfoModal__card__title">
              {state.loading
                ? 'LOADING...'
                : searchValue
                ? `${content.noUsersText} FOUND`
                : `${content.noUsersText} YET`}
            </Typography.Title>
          )}
        </InfiniteScroll>
      </div>
    </Modal>
  );
};

export default LikedByUsersModal;
