import React, { SVGProps } from 'react';

import { useTheme } from '../../context/ThemeProvider';

const DownloadSVG: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg width={15.718} height={15.718} viewBox="0 0 15.718 15.718" {...props}>
      <g id="download" transform="translate(0.25 0.25)">
        <g id="Group_4335" data-name="Group 4335" transform="translate(5.073)">
          <g id="Group_4334" data-name="Group 4334">
            <path
              id="Path_788"
              data-name="Path 788"
              d="M175.68,7.326a.635.635,0,0,0-.568-.351h-1.268V.634a.634.634,0,1,0-1.268,0V6.975h-1.268A.634.634,0,0,0,170.8,7.99l1.9,2.536c.008.011.021.015.03.025a.619.619,0,0,0,.146.122.652.652,0,0,0,.065.044.595.595,0,0,0,.532,0,.645.645,0,0,0,.065-.044.619.619,0,0,0,.146-.122c.008-.01.022-.014.03-.025l1.9-2.536A.633.633,0,0,0,175.68,7.326Z"
              transform="translate(-170.674)"
              fill={colors.ICON}
              stroke={colors.ICON}
              strokeWidth={0.5}
            />
          </g>
        </g>
        <g
          id="Group_4337"
          data-name="Group 4337"
          transform="translate(0 9.511)">
          <g id="Group_4336" data-name="Group 4336">
            <path
              id="Path_789"
              data-name="Path 789"
              d="M14.584,320a.634.634,0,0,0-.634.634v1.9a1.9,1.9,0,0,1-1.9,1.9H3.17a1.9,1.9,0,0,1-1.9-1.9v-1.9a.634.634,0,0,0-1.268,0v1.9a3.174,3.174,0,0,0,3.17,3.171h8.877a3.174,3.174,0,0,0,3.17-3.171v-1.9A.634.634,0,0,0,14.584,320Z"
              transform="translate(0 -320)"
              fill={colors.ICON}
              stroke={colors.ICON}
              strokeWidth={0.5}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default React.memo(DownloadSVG);
