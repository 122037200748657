import React, { useState } from 'react';

import { Typography } from 'antd';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import Linkify from 'react-linkify';

import { useTheme } from '../../context/ThemeProvider';

interface Props extends ParagraphProps {
  text: string;
  truncatedLength?: number;
  seeMoreText?: string;
  seeLessText?: string;
}

const CustomReadMoreText: React.FC<Props> = ({
  text,
  truncatedLength = 660,
  className = 'message',
  seeMoreText = 'See more',
  seeLessText = 'See less',
  ...props
}) => {
  const { style, ...restProps } = props;

  const { colors } = useTheme();

  // eslint-disable-next-line prefer-destructuring
  const length = text.length;

  const truncationNeeded = length >= truncatedLength;
  const [truncated, setTruncated] = useState(truncationNeeded);

  const truncatedText = text.substring(0, truncatedLength - 1);

  return (
    <Linkify>
      <Typography.Paragraph
        className={className}
        style={{ whiteSpace: 'pre-wrap', marginBottom: 0, ...style }}
        {...restProps}>
        {truncationNeeded && truncated ? (
          <>
            {/* <Linkify>
              {ReactHtmlParser(truncatedText.replaceAll('\n', '<br />'))}
            </Linkify> */}

            {truncatedText}

            {truncationNeeded && (
              <span
                style={{
                  color: colors.LINK,
                  fontWeight: 600,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setTruncated(false);
                }}
                role="button"
                tabIndex={0}>
                {` ...${seeMoreText}`}
              </span>
            )}
          </>
        ) : (
          <>
            {/* <Linkify>
              {ReactHtmlParser(text.replaceAll('\n', '<br />'))}
            </Linkify> */}

            {text}

            {truncationNeeded && (
              <span
                style={{
                  color: colors.LINK,
                  fontWeight: 600,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setTruncated(true);
                }}
                role="button"
                tabIndex={0}>
                {` ${seeLessText}`}
              </span>
            )}
          </>
        )}
      </Typography.Paragraph>
    </Linkify>
  );
};

export default React.memo(CustomReadMoreText);
