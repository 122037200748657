import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { IMessage } from '../types/chatModels/ChatMessagesResponse';
import {
  DMRoomListResult,
  MangoRoomListResult,
} from '../types/chatModels/MangoRoomListResponse';

export interface RoomListDetails<T> {
  roomList: T[];
  pageNumber: number;
  status: 'idle' | 'loading' | 'complete';
}

interface UnreadStats {
  unreadConversations: number;
  unreadMessages: number;
}

export interface UnreadCount {
  dm: UnreadStats;
  mango: UnreadStats;
}

export interface OpenRoomAction {
  roomType: 'dm' | 'mango';
  roomId: string;
  messageId?: string;
}

export interface IChatSliceState {
  dmRoomList: RoomListDetails<DMRoomListResult>;
  mangoRoomList: RoomListDetails<MangoRoomListResult>;
  chatRoomData: ChatRoomData;
  unreadCount: UnreadCount;
  showScrollDown: boolean;
}

export interface ChatRoomData {
  roomId: string;
  messages: IMessage[];
  allMessagesLoaded: boolean;
  hasMore: {
    previous: boolean;
    next: boolean;
  };
}

const defaultRoomList: RoomListDetails<any> = {
  status: 'idle',
  pageNumber: 0,
  roomList: [],
};

export const DefaultRoomId = '0000';

const defaultChatRoomData: ChatRoomData = {
  messages: [],
  roomId: DefaultRoomId,
  allMessagesLoaded: false,
  hasMore: {
    previous: true,
    next: false,
  },
};

const initialState: IChatSliceState = {
  dmRoomList: defaultRoomList,
  mangoRoomList: defaultRoomList,
  chatRoomData: defaultChatRoomData,
  unreadCount: {
    dm: { unreadConversations: 0, unreadMessages: 0 },
    mango: { unreadConversations: 0, unreadMessages: 0 },
  },
  showScrollDown: false,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    updateUnreadCount: (state, action: PayloadAction<UnreadCount>) => {
      if (action.payload) {
        Object.assign(state.unreadCount, action.payload);
      }
    },
    updateChatRoomData: (state, action: PayloadAction<ChatRoomData>) => {
      if (action.payload) {
        Object.assign(state.chatRoomData, action.payload);
      }
    },
    updateDMRoomData: (
      state,
      action: PayloadAction<RoomListDetails<DMRoomListResult>>,
    ) => {
      if (action.payload) {
        Object.assign(state.dmRoomList, action.payload);
      }
    },
    updateMangoRoomData: (
      state,
      action: PayloadAction<RoomListDetails<MangoRoomListResult>>,
    ) => {
      if (action.payload) {
        Object.assign(state.mangoRoomList, action.payload);
      } else {
        console.log(`no payload found for upadate service room data`);
      }
    },
    removeChatRoomData: (state) => {
      state.chatRoomData = defaultChatRoomData;
    },
    setShowScrollDown: (state, action: PayloadAction<boolean>) => {
      state.showScrollDown = action.payload;
    },
  },
});

export const {
  updateDMRoomData,
  updateMangoRoomData,
  updateChatRoomData,
  removeChatRoomData,
  updateUnreadCount,
  setShowScrollDown,
} = chatSlice.actions;

export default chatSlice.reducer;
