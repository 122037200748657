import React from 'react';

import {
  Spin,
  SpinProps,
} from 'antd';

interface Props extends SpinProps {}

const Loader: React.FC<Props> = (props) => <Spin {...props} />;

export default Loader;
