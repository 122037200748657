import React from 'react';

import {
  Button,
  Typography,
} from 'antd';

interface Props {
  onClick: () => void;
  userLoggedIn: boolean;
}

const PostBlocked: React.FC<Props> = ({ onClick, userLoggedIn }) => (
  <div className="lockedContent">
    <div className="lockedContentInner">
      <Typography.Title level={4} className="loackedContentTitle">
        Private content,{' '}
        {userLoggedIn ? 'purchase required' : 'log in required!'}
      </Typography.Title>
      <Typography.Text className="loackedContentText">
        The content that you&apos;re trying to access is private,{' '}
        {userLoggedIn
          ? 'please purchase to access the content.'
          : 'please login to access the content.'}
      </Typography.Text>
      <Button className="siteBtn siteBtnPrimary" onClick={onClick}>
        {userLoggedIn ? `Purchase` : `Login`}
      </Button>
    </div>
  </div>
);

export default PostBlocked;
