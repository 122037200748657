import * as React from 'react';

import { Typography } from 'antd';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { useTheme } from '../../context/ThemeProvider';

interface CourseNavigatorProps {
  isPreviousChapterAvailable: boolean;
  isNextChapterAvailable: boolean;
  onPreviousClick: () => void;
  onNextClick: () => void;
}

const CourseNavigator: React.FC<CourseNavigatorProps> = ({
  isPreviousChapterAvailable,
  isNextChapterAvailable,
  onPreviousClick,
  onNextClick,
}) => {
  const { colors } = useTheme();

  return (
    <div className="course-navigator__header">
      <div
        onClick={isPreviousChapterAvailable ? onPreviousClick : () => undefined}
        className={`course-navigator__header__item ${
          !isPreviousChapterAvailable ? 'disabled' : ''
        }`}>
        <MdChevronLeft
          className="course-navigator__icon previous"
          size={24}
          color={colors.ICON}
        />
        <div className="course-navigator__header__content previous">
          <Typography.Text className="course-navigator__header__content__title">
            Previous
          </Typography.Text>
        </div>
      </div>
      <div className="separator" />
      <div
        onClick={isNextChapterAvailable ? onNextClick : () => undefined}
        className={`course-navigator__header__item ${
          !isNextChapterAvailable ? 'disabled' : ''
        }`}>
        <div className="course-navigator__header__content">
          <Typography.Text className="course-navigator__header__content__title">
            Next
          </Typography.Text>
        </div>

        <MdChevronRight
          className="course-navigator__icon"
          size={24}
          color={colors.ICON}
        />
      </div>
    </div>
  );
};

export default CourseNavigator;
