import React from 'react';

import DynamicWrapper from '../../components/DynamicWrapper/DynamicWrapper';
import { useAppSelector } from '../../shared/hooks';
import { ROUTES } from '../../types/routes';
import StorefrontPreview from './StorefrontPreview/StorefrontPreview';

const Storefront: React.FC = () => {
  const { hostMetadata, isTagMango } = useAppSelector((state) => state.app);
  const userId = useAppSelector((state) => state.user.id);

  return (
    <DynamicWrapper path={ROUTES.STOREFRONT}>
      <StorefrontPreview
        creatorId={(isTagMango ? userId : hostMetadata?.creator._id) || ''}
      />
    </DynamicWrapper>
  );
};

export default Storefront;
