import './styles.scss';

import React from 'react';

import { Typography } from 'antd';

type Props = {
  label: string;
  text: string;
  onChangeText: (text: string) => void;
  type?: React.HTMLInputTypeAttribute;
  maxLength?: number;
  minLength?: number;
  message?: {
    type: 'error' | 'success' | 'info';
    text: string;
  };
  name?: string;
  disabled?: boolean;
  addonBefore?: React.ReactNode;
};

const Input: React.FC<Props> = ({
  label,
  text,
  onChangeText,
  type = 'text',
  maxLength,
  minLength,
  message,
  name,
  disabled = false,
  addonBefore,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [focused, setFocused] = React.useState(false);

  return (
    <div className="customInput">
      <div
        onClick={() => {
          if (!disabled) {
            setFocused(true);
          }
          inputRef.current?.focus();
        }}
        className={`input__container ${
          focused ? 'input__container--focused' : ''
        } ${text.length > 0 ? 'input__container--filled' : ''} ${
          disabled ? 'input__container--disabled' : ''
        }`}>
        <label className="input__label">{label}</label>
        {addonBefore ? (
          <div className="input__addonBefore">{addonBefore}</div>
        ) : null}
        <input
          ref={inputRef}
          name={name}
          disabled={disabled}
          className="input__field"
          type={type}
          value={text}
          onChange={(e) => onChangeText(e.target.value)}
          maxLength={maxLength}
          minLength={minLength}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        {maxLength ? (
          <div className="input__counter">
            <Typography.Text className="input__counter__text">
              {text.length}/{maxLength}
            </Typography.Text>
          </div>
        ) : null}
      </div>
      {message ? (
        <Typography.Paragraph
          className={`input__message input__message--${message.type}`}>
          {message.text}
        </Typography.Paragraph>
      ) : null}
    </div>
  );
};

export default React.memo(Input);
