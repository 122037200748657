import React, { memo } from 'react';

import { Typography } from 'antd';
import { BsPinFill } from 'react-icons/bs';

import { updatePost } from '../../../../context/activityReducer';
import { useTheme } from '../../../../context/ThemeProvider';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../../../shared/hooks';
import { ROUTES } from '../../../../types/routes';
import PostContent from './PostContent';
import PostHeader from './PostHeader';
import PostMedia from './PostMedia';
import PostPoll from './PostPoll';
import PostStats from './PostStats';
import PostTags from './PostTags';

type Props = {
  post: any;
  index: number;
  onCommentsPressed?: () => void;
};

const Post: React.FC<Props> = ({ post, index, onCommentsPressed }) => {
  const { colors } = useTheme();
  const userDetails = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  return (
    <>
      {post ? (
        <div className="creatorPostsWrapper feedPost">
          {post.isPinned ? (
            <div className="pinnedPost">
              <BsPinFill color={colors.ICON} size={15} />
              <Typography.Text>Pinned Post</Typography.Text>
            </div>
          ) : null}
          <PostTags tags={post.tags || []} />
          <PostHeader post={post} />
          {post.caption ? (
            <PostContent
              caption={post.caption}
              maxLines={post.contentUrl ? 2 : 5}
              onCaptionPressed={
                onCommentsPressed
                  ? onCommentsPressed
                  : () => {
                      navigate(ROUTES.POST_DETAILS, {
                        postId: post._id,
                      });
                    }
              }
            />
          ) : null}
          {(post.contentUrl ||
            (!post.isCompressDone &&
              ['image', 'audio', 'video'].includes(post.contentType))) && (
            <PostMedia
              isDrmEnabled={post.isDrmEnabled}
              url={post.contentUrl}
              type={post.contentType}
              vdoCipherId={post.videoCipherId}
              id={post._id}
              isCompressDone={post?.isCompressDone}
              index={index}
              thumbnail={post.thumbnail}
              compressedUrl={post.compressedVideoUrl}
              dashUrl={post.compressedVideoUrlDash}
              hlsUrl={post.compressedVideoUrlHls}
              contentId={post.qencodePostId}
              hasError={post.isCompressError}
              errorDescription={post.compressErrorDescription}
            />
          )}
          {post.poll && post.contentType === 'poll' ? (
            <PostPoll
              pollId={post.poll._id}
              isOwnPost={post.creatorId === userDetails.id}
              isAnswered={post.poll.hasVoted}
              answerOption={post.poll.voteDetails?.pollOption}
              question={post.poll.question}
              options={post.poll.pollOptions}
              individualVotes={post.poll.voteCounts}
              totalVotes={post.poll.totalVotes}
              endDate={post.poll.pollEndAt}
              updatePost={(newPoll: any) => {
                dispatch(
                  updatePost({
                    ...post,
                    poll: newPoll,
                  }),
                );
              }}
            />
          ) : null}
          <PostStats
            post={post}
            onCommentsPressed={
              onCommentsPressed
                ? onCommentsPressed
                : () => {
                    navigate(ROUTES.POST_DETAILS, {
                      postId: post._id,
                    });
                  }
            }
          />
        </div>
      ) : null}
    </>
  );
};

export default memo(Post);
