import * as React from 'react';

import { useTheme } from '../../context/ThemeProvider';

const PlusSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g id="plus-circle" transform="translate(-309 -12)">
        <circle
          id="Ellipse_113"
          data-name="Ellipse 113"
          cx="12"
          cy="12"
          r="12"
          transform="translate(309 12)"
          fill={colors.TERTIARY}
        />
        <path
          id="plus"
          d="M10.769,5.1H6.235V.567A.567.567,0,0,0,5.1.567V5.1H.567a.567.567,0,0,0,0,1.134H5.1v4.534a.567.567,0,1,0,1.134,0V6.235h4.534a.567.567,0,1,0,0-1.134Zm0,0"
          transform="translate(315.332 18.332)"
          fill={colors.ICON}
          stroke={colors.ICON}
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};

export default React.memo(PlusSvg);
