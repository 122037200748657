import * as React from 'react';

import { Typography } from 'antd';

import Loader from '../../../../components/Loader';

interface Props {
  title: string;
  description: string | JSX.Element;
  buttonTitle: string;
  onPress: () => void;
  loading?: boolean;
}

const AccountWarningItem: React.FC<Props> = ({
  title,
  description,
  buttonTitle,
  onPress,
  loading,
}) => {
  return (
    <div className="account-warning-item">
      <Typography.Title level={5} className="account-warning-item__title">
        {title}
      </Typography.Title>
      <Typography.Paragraph className="account-warning-item__description">
        {description}
      </Typography.Paragraph>
      {loading ? (
        <Loader size="small" />
      ) : (
        <Typography.Link
          onClick={onPress}
          className="account-warning-item__button">
          {buttonTitle}
        </Typography.Link>
      )}
    </div>
  );
};

export default React.memo(AccountWarningItem);
