import './ErrorBoundary.scss';

import React, {
  Component,
  ReactNode,
} from 'react';

import {
  Button,
  Space,
} from 'antd';
import { FaFaceFrown } from 'react-icons/fa6';
import TMIcon from 'tm-icons-library';

import NoDataFound from '../../screens/Feed/components/NoDataFound/NoDataFound';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Optionally log the error to an external service
    console.error('Error caught in error boundary: ', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI when there is an error
      return (
        <div className="error-boundary__container">
          <NoDataFound
            iconElement={<FaFaceFrown size={64} color="#616161" />}
            title="Oops! Something went wrong on our end."
            // subtitle="We apologize for the inconvenience. Please try reloading the page or maybe come back later."
            // subtitle2="Our team is working on fixing this issue as soon as possible. Thank you for your patience and understanding."
            subtitle="Please reload the page or try again later. Our team is on it and will fix this issue as soon as possible."
            subtitle2="Thank you for your patience and understanding."
            extra={
              <Space align="center" size={12} style={{ marginTop: 8 }}>
                <Button
                  className="siteBtn grayBtn"
                  onClick={() =>
                    window.open(`${window.location.origin}/web`, '_self')
                  }>
                  Back to Home
                </Button>
                <Button
                  className="siteBtn grayBtn darkBtn"
                  onClick={() => window.location.reload()}>
                  <TMIcon name="reload" size={16} color="currentColor" />
                  Reload Page
                </Button>
              </Space>
            }
            style={{
              width: '100%',
            }}
          />
        </div>
      );
    }

    // Render children if no error
    return this.props.children;
  }
}

export default ErrorBoundary;
