import React from 'react';

import { message } from 'antd';
import { useSearchParams } from 'react-router-dom';

import UserAPI from '../../../../api/UserAPI';
import Header from '../../../../components/Header/Header';
import Loader from '../../../../components/Loader';
import { setUserDetails } from '../../../../context/userReducer';
import showAppError from '../../../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
} from '../../../../shared/hooks';
import { ROUTES } from '../../../../types/routes';

const title = 'Verify Email';

const VerifyEmailScreen: React.FC = () => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const verifyEmail = async (verifyToken: string) => {
    try {
      const resp = await UserAPI.verifyEmailToken(verifyToken);
      if (resp.status === 200) {
        message.success('Email verified successfully!');
        dispatch(
          setUserDetails({
            isEmailVerified: true,
          }),
        );
      } else {
        showAppError(resp.data);
      }
    } catch (error) {
      showAppError(error);
    }

    navigate.goBack();
  };

  React.useEffect(() => {
    const verifyToken = searchParams.get('verifyToken');

    if (!verifyToken) {
      message.error('Invalid token');
      navigate(ROUTES.HOME);
    } else verifyEmail(verifyToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <div className="pageRoot">
      <Header title={title} />
      <div className="pageContent pageContentList">
        <Loader size="large" />
      </div>
    </div>
  );
};

export default VerifyEmailScreen;
