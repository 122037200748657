import React, {
  memo,
  useEffect,
  useState,
} from 'react';

import {
  Tag,
  Typography,
} from 'antd';
import { useParams } from 'react-router-dom';

import UserAPI from '../../../api/UserAPI';
import Header from '../../../components/Header/Header';
import showAppError from '../../../shared/error';
import { useAppSelector } from '../../../shared/hooks';
import NoDataFound from '../../Feed/components/NoDataFound/NoDataFound';
import { IProfileSubscription } from '../types';
import MangoItemSkeleton from './MangoItemSkeleton';

interface Props {
  handleBack?: () => void;
}

const MangoesPurchased: React.FC<Props> = ({ handleBack }) => {
  const { userId } = useParams();

  const {
    hostMetadata: { offeringTitles: mangoesTitle },
  } = useAppSelector((state) => state.app);

  const [subscriptions, setSubscriptions] = useState<IProfileSubscription[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchUserSubscriptions = async () => {
    if (!userId) return;

    setIsLoading(true);
    try {
      const response = await UserAPI.getProfileDetails(userId);
      setSubscriptions(response.data.result.subs ?? []);
    } catch (error) {
      showAppError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserSubscriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const renderItem = (item: IProfileSubscription) => {
    if (item) {
      const status = ['active', 'manualactive'].includes(item.status)
        ? 'active'
        : 'expired';
      const { mango } = item;

      return (
        <div className="mango__item" key={item._id}>
          <Typography.Title level={4} className="title">
            {mango.title}
          </Typography.Title>
          <Tag color={status === 'active' ? 'green' : 'red'}>
            {status.toUpperCase()}
          </Tag>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="home__container subscriptions__container">
      <Header
        title={`${mangoesTitle.toTitleCase()} Purchased`}
        handleBack={handleBack}
      />

      <div className="home__content subscriptions__content">
        <div className="scroll__container">
          {isLoading ? (
            <MangoItemSkeleton />
          ) : (
            <>
              {subscriptions && subscriptions.length > 0 ? (
                subscriptions.map((item: IProfileSubscription) =>
                  renderItem(item),
                )
              ) : (
                <NoDataFound
                  title={`This user has not purchased any ${mangoesTitle.toLowerCase()}`}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(MangoesPurchased);
