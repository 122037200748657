import React from 'react';

import { useTheme } from '../../context/ThemeProvider';

const ArrowSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg width="14.644" height="8" viewBox="0 0 14.644 8" {...props}>
      <g id="layer1" transform="translate(-0.257 -292.234)">
        <path
          id="path9413"
          d="M.953,296.9H12.63l-2.188,2.188a.667.667,0,0,0,.943.943l1.992-2,1.33-1.332a.665.665,0,0,0,0-.937l-3.322-3.326a.667.667,0,1,0-.943.943l2.193,2.188H.918A.666.666,0,1,0,.953,296.9Z"
          transform="translate(0 0)"
          fill={colors.BACKGROUND}
        />
      </g>
    </svg>
  );
};

export default React.memo(ArrowSvg);
