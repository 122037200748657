import './styles.scss';

import React from 'react';

import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

import BottomNavbar from '../../components/BottomNavbar/BottomNavbar';
import { useAppSelector } from '../../shared/hooks';

interface Props {
  children?: React.ReactNode;
}

const Home: React.FC<Props> = ({ children }) => {
  const userDetails = useAppSelector((state) => state.user);

  const title = userDetails.name;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="home__container">
        <div className="home__content">
          <Outlet />
          {children}
        </div>
        <BottomNavbar />
      </div>
    </>
  );
};

export default Home;
