import React from 'react';

// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';

import {
  useAppNavigate,
  useAppSelector,
} from '../../../../../../shared/hooks';
import { ROUTES } from '../../../../../../types/routes';
import ChatAvatar
  from '../../../../../Messages/components/ChatAvatar/ChatAvatar';
import { ILeaderboardItem } from '../../types';
import PointsPill from '../PointsPill/PointsPill';

interface Props {
  items: ILeaderboardItem[];
  myRank?: number | null;
  loadMore: () => Promise<void>;
  hasMore: boolean;
}

const LeaderboardList: React.FC<Props> = ({
  items,
  myRank = null,
  loadMore,
  hasMore,
}) => {
  const navigate = useAppNavigate();

  const {
    hostMetadata: { pointsConfig },
  } = useAppSelector((state) => state.app);
  return (
    <>
      {typeof myRank === 'number' ? (
        <div className="leaderboard-list__item accent">
          <div className="name">Your ranking</div>
          <div className="rank">{myRank}</div>
        </div>
      ) : null}

      <EasyVirtualizedScroller
        onLoadMore={loadMore}
        useParentScrollElement
        hasMore={hasMore}>
        {items.map((item) => (
          <div
            className="leaderboard-list__item"
            onClick={() =>
              navigate(ROUTES.USER_PROFILE, {
                userId: item._id,
              })
            }>
            <ChatAvatar
              url={item.profilePicUrl}
              name={item.name}
              size={48}
              border={0}
            />
            <div className="name">
              {item.name}
              <PointsPill
                iconSize="sm"
                icon={pointsConfig?.pointsImage}
                name={pointsConfig?.pointsName}
                points={item.score || 0}
              />
            </div>
            <div className="rank">{item.leaderboardRank}</div>
          </div>
        ))}
      </EasyVirtualizedScroller>
    </>
  );
};

export default React.memo(LeaderboardList);
