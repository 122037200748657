import './styles.scss';

import React from 'react';

import {
  Avatar,
  Typography,
} from 'antd';

import { useTheme } from '../../../../context/ThemeProvider';

interface Props {
  name?: string;
  url?: string;
  size?: number;
  style?: any;
  selectedColor?: string;
  defaultColor?: string;
  selected?: boolean;
  border?: number;
}

const ChatAvatar: React.FC<Props> = (props) => {
  const { colors } = useTheme();
  const {
    name,
    url,
    size = 50,
    style,
    selectedColor = colors.PRIMARY,
    defaultColor = colors.SECONDARY,
    selected = false,
    border = 1.5,
  } = props;
  return (
    <div
      className="chat-avatar__container"
      style={{
        ...style,
        width: size,
        height: size,
        borderColor: selected ? selectedColor : defaultColor,
        borderWidth: border,
        padding: border,
      }}>
      {url ? (
        <Avatar
          size={size - border * 4}
          style={{
            borderRadius: size,
            backgroundColor: selected ? selectedColor : defaultColor,
          }}
          src={url}
        />
      ) : name ? (
        <div
          className="chat-avatar__initials"
          style={{
            width: size - border * 4,
            height: size - border * 4,
            borderRadius: size,
            backgroundColor: `${selected ? selectedColor : defaultColor}90`,
          }}>
          <Typography.Text
            className="chat-avatar__initials-text"
            style={{
              textAlign: 'center',
              textTransform: 'uppercase',
              color: selected ? selectedColor : defaultColor,
              fontSize: size / 2,
            }}>
            {name[0]}
          </Typography.Text>
        </div>
      ) : (
        <Avatar
          size={size - border * 4}
          style={{
            borderRadius: size,
            backgroundColor: selected ? selectedColor : defaultColor,
          }}
          src="https://tagmango.com/staticassets/avatar-placeholder.png-1612857612139.png"
        />
      )}
    </div>
  );
};

export default React.memo(ChatAvatar);
