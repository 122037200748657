import './styles.scss';

import React, { useMemo, useState } from 'react';

import { Typography } from 'antd';

import CustomImage from '../CustomImage/CustomImage';
import ImageViewer from '../ImageViewer/ImageViewer';

interface Props {
  images: string[];
  maxImagesAllowed?: number;
  containerStyle?: React.CSSProperties;
}

const ImageAttachment: React.FC<Props> = ({
  images,
  maxImagesAllowed = 3,
  containerStyle = {},
}) => {
  const [highlightedImage, setHighlightedImage] = useState<
    string[] | undefined
  >(undefined);
  const paddingHorizontal = images.length > 1 ? 6 : 0;

  const renderImages = useMemo(() => {
    const maxImages = Math.min(images.length, maxImagesAllowed);
    let children: JSX.Element[] = [];
    if (images.length > maxImagesAllowed) {
      children = images
        .slice(0, maxImages - 1)
        .map((image, index) => (
          <CustomImage key={index.toString()} src={image} />
        ));
      children.push(
        <div className="moreImage__wrapper">
          <div className="more-text">
            <Typography.Text>
              +{images.length - maxImagesAllowed}
            </Typography.Text>
          </div>
          <CustomImage key="moreImage" src={images[maxImages - 1]} />
        </div>,
      );
    } else {
      children = images
        .slice(0, maxImages)
        .map((image, index) => (
          <CustomImage key={index.toString()} src={image} />
        ));
    }
    return children;
  }, [images, maxImagesAllowed]);

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setHighlightedImage(images);
        }}
        className="image-attachment"
        style={{
          ...containerStyle,
          padding: paddingHorizontal,
        }}>
        {renderImages}
      </div>
      <ImageViewer
        showModal={highlightedImage !== undefined}
        closeModal={() => setHighlightedImage(undefined)}
        srcSet={highlightedImage || []}
      />
    </>
  );
};

export default React.memo(ImageAttachment);
