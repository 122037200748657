import './styles.scss';
import './Posts/styles.scss';

import { useEffect, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import FeedAPI from '../../api/FeedAPI';
import CustomImage from '../../components/CustomImage/CustomImage';
import DynamicWrapper from '../../components/DynamicWrapper/DynamicWrapper';
import FeedHeader from '../../components/Header/FeedHeader';
import {
  setIsUserProfilePage,
  setSelected,
} from '../../context/activityReducer';
import { checkForPermissions } from '../../context/userReducer';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { ROUTES } from '../../types/routes';
import { UAMPermissions } from '../../types/userTypes';
import AccountWarningCard from './components/AccountWarningCard/AccountWarningCard';
import CreateAPostCard from './components/CreateAPostCard/CreateAPostCard';
import NoMangoComponent from './components/NoMangoComponent/NoMangoComponent';
import RenewMangoes from './components/RenewMangoes/RenewMangoes';
import { IRenewMango } from './components/RenewMangoes/types';
import UnbookedSlotsForConsultation from './components/UnbookedSlotsForConsultation/UnbookedSlotsForConsultation';
import UploadingComponent from './components/UploadingComponent/UploadingComponent';
import PostsList from './Posts/PostsList';
import UpcomingWorkshops from './VideoCall/UpcomingWorkshops';

const Feed: React.FC = () => {
  const location = useLocation();

  const dispatch = useAppDispatch();

  const {
    tags: allTags,
    hostMetadata,
    isCommunityEnabled,
    isTagMango,
    hostMetadata: { appName },
  } = useAppSelector((state) => state.app);
  const { creatorMangoes, ...userDetails } = useAppSelector(
    (state) => state.user,
  );
  const {
    postState,
    selected,
    subscriptions,
    progressData,
    isUserProfilePage,
  } = useAppSelector((state) => state.activity);

  const title = `${userDetails.name || 'User'} - Activity page`;
  const metaTitle = `${
    userDetails.name || 'User'
  }’s activity page on ${appName}`;
  const metaDescription = `${
    userDetails.name || 'User'
  }’s activity page on ${appName}. Check your feed posts, earnings and subscribers.`;

  const [renewMangoes, setRenewMangoes] = useState<IRenewMango[]>([]);

  const loadRenewMangoes = async () => {
    if (selected) {
      try {
        const resp = await FeedAPI.getRenewMangoes(
          selected === 'all' ? undefined : selected,
        );
        if (resp.status === 200 && resp.data.result) {
          setRenewMangoes(resp.data.result);
        }
      } catch (err) {
        console.log('renew mangoes err', err);
      }
    }
  };

  useEffect(() => {
    loadRenewMangoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const tags = useMemo(
    () =>
      Object.entries(allTags)
        .filter(([, value]) => {
          if (!selected || selected === 'all') {
            return true;
          }
          return value.creatorId === selected;
        })
        .map(([key, value]) => ({
          ...value,
          key,
        })),
    [allTags, selected],
  );

  const showWorkspace = useMemo(
    () =>
      Boolean(
        isTagMango &&
          userDetails.type &&
          userDetails.type !== 'details_added' &&
          (subscriptions || []).filter(
            (item) => item.creatorId !== userDetails.id,
          ).length > 0,
      ),
    [isTagMango, subscriptions, userDetails.id, userDetails.type],
  );

  const hasNoMangoes = useMemo(
    () =>
      (!creatorMangoes || creatorMangoes.length === 0) &&
      !postState.loading &&
      selected &&
      (userDetails.type === 'creator_completed' || userDetails.type === 'dual'),
    [creatorMangoes, postState.loading, selected, userDetails.type],
  );

  const isMe = useMemo(
    () =>
      isTagMango
        ? userDetails.id === selected
        : userDetails.id === hostMetadata.creator._id ||
          userDetails.type === 'creator_restricted' ||
          (userDetails.type === 'fan_completed' &&
            isCommunityEnabled &&
            !userDetails.isBlockedFromCommunityEngagement),
    [
      hostMetadata.creator._id,
      isCommunityEnabled,
      isTagMango,
      selected,
      userDetails.id,
      userDetails.isBlockedFromCommunityEngagement,
      userDetails.type,
    ],
  );

  // Refresh the feed when the user navigates from the profile page in White Label Platforms
  useEffect(() => {
    if (!isTagMango && isUserProfilePage) {
      dispatch(setSelected(''));
      dispatch(setIsUserProfilePage(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isTagMango, isUserProfilePage]);

  return (
    <DynamicWrapper path={ROUTES.FEED}>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="dashboard, tagmango, influencers, influencer marketing, video shoutout, How to make money online, online skills, content, creator, Instagram creator, youtube creator,"
        />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
      </Helmet>

      <div className="feed__container">
        <FeedHeader showWorkspace={showWorkspace} tags={tags} />

        <div className="feed__content">
          {hostMetadata.feedBanner ? (
            <div
              className="feed__banner"
              onClick={() => {
                if (hostMetadata.feedBanner?.url) {
                  window.open(hostMetadata.feedBanner.url, '_blank');
                }
              }}>
              <CustomImage src={hostMetadata.feedBanner.thumbnail} />
            </div>
          ) : null}

          {!isTagMango &&
          isCommunityEnabled &&
          !userDetails.isBlockedFromCommunityEngagement ? (
            <CreateAPostCard />
          ) : null}

          {userDetails.type === 'creator_restricted' ? null : (
            <AccountWarningCard />
          )}

          {userDetails.type !== 'creator_restricted' &&
          isTagMango &&
          (userDetails.type === 'creator_completed' ||
            userDetails.type === 'dual') ? (
            <>
              {/* <BookADemo /> */}
              <NoMangoComponent />
            </>
          ) : null}

          {renewMangoes.length > 0 && (
            <RenewMangoes renewMangoes={renewMangoes} />
          )}

          {(userDetails.type !== 'creator_restricted' ||
            checkForPermissions(
              [UAMPermissions.VIDEOCALL_ACCESS],
              userDetails.userAccessPermissions,
            )) &&
          !hasNoMangoes ? (
            <UpcomingWorkshops />
          ) : null}

          <UnbookedSlotsForConsultation />

          {isMe || selected === 'all' ? (
            <UploadingComponent {...progressData} type="activity" />
          ) : null}

          {userDetails.type !== 'creator_restricted' ||
          checkForPermissions(
            [UAMPermissions.FEED_READ],
            userDetails.userAccessPermissions,
          ) ? (
            <PostsList />
          ) : null}
        </div>
      </div>
    </DynamicWrapper>
  );
};

export default Feed;
