import React from 'react';

import { Button, Typography } from 'antd';
import { MdLock } from 'react-icons/md';

interface Props {
  icon?: React.ReactNode;
  title?: string;
  message?: string;
  button?: {
    text: string;
    onClick: () => void;
  };
}

const DripLocked: React.FC<Props> = ({
  icon,
  title = 'This chapter is locked',
  message,
  button,
}) => {
  return (
    <div className="player-wrapper-drm">
      <div className="videoProgress">
        <>
          {icon || (
            <MdLock
              size={40}
              color="#fff"
              style={{
                marginBottom: 12,
              }}
            />
          )}
          <Typography.Text className="videoProgressTitle">
            {title}
          </Typography.Text>
          {message ? (
            <Typography.Text className="videoProgressText">
              {message}
            </Typography.Text>
          ) : null}
          {button ? (
            <Button
              className="siteBtn siteBtnPrimary"
              type="primary"
              onClick={button.onClick}>
              {button.text}
            </Button>
          ) : null}
        </>
      </div>
    </div>
  );
};

export default React.memo(DripLocked);
