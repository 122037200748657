import './styles.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Modal, Typography } from 'antd';
import moment from 'moment';

import ChatAPI from '../../../../api/ChatAPI';
import Header from '../../../../components/Header/Header';
import Loader from '../../../../components/Loader';
import { useAppSelector } from '../../../../shared/hooks';
import { CHAT_NAME_COLORS } from '../../../../shared/Styles';
import { getReadableDateFormat } from '../../../../shared/utils';
import { IMessage } from '../../../../types/chatModels/ChatMessagesResponse';
import { ParticipantListItem } from '../../../../types/chatModels/ParticipantResponse';
import { IMangoRoomDetails } from '../../../../types/messageTypes';
import MessageContents from '../../components/MessageContents/MessageContents';
import ChatParticipantItem from '../MangoChatInfo/ChatParticipantItem';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  message: IMessage;
  roomDetails: IMangoRoomDetails;
}

interface IState {
  participants: ParticipantListItem[];
  loading: boolean;
}

const defaultState: IState = {
  loading: false,
  participants: [],
};

const MessageInfo: React.FC<Props> = ({
  showModal,
  closeModal,
  message,
  roomDetails,
}) => {
  const { _id: messageId } = message;

  const userDetails = useAppSelector((state) => state.user);
  const colorMap = useRef<Record<string, string>>({});

  const [state, setState] = useState<IState>(defaultState);

  const getParticipants = useCallback(
    async (
      participantsList = state.participants,
      loading = state.loading,
      currState = state,
    ) => {
      if (!loading) {
        setState({ ...currState, loading: true });
        try {
          const response = await ChatAPI.getSeenUsersForMessage(messageId);
          const res = response.data;
          let newState = {
            ...currState,
            loading: false,
          };
          if (response.status === 200) {
            if (res.result && res.result.length) {
              const result = response.data.result.filter(
                (i) => i._id !== message.senderId,
              );
              newState = {
                ...newState,
                participants: result,
              };
            } else {
              newState = {
                ...newState,
                participants: [...(participantsList || [])],
              };
            }
          }
          setState({ ...state, ...newState });
        } catch (error: any) {
          setState({ ...state, loading: false });
        }
      }
    },
    [message.senderId, messageId, state],
  );

  const loadParticipants = useCallback(() => {
    if (showModal) {
      const newState: IState = {
        participants: [],
        loading: false,
      };
      getParticipants([], false, newState);
    }
  }, [showModal, getParticipants]);

  useEffect(() => {
    loadParticipants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, showModal]);

  const getNameColor = useCallback(
    (senderId: string) => {
      if (!colorMap.current[senderId]) {
        const color =
          CHAT_NAME_COLORS[Math.floor(Math.random() * CHAT_NAME_COLORS.length)];
        colorMap.current = {
          ...colorMap.current,
          [senderId]: color,
        };
        return color;
      }
      return colorMap.current[senderId];
    },
    [colorMap],
  );

  return (
    <Modal
      open={showModal}
      title={<Header title="Message Info" handleBack={closeModal} />}
      destroyOnClose
      className="messageInfoModal"
      closable={false}
      closeIcon={null}
      footer={null}>
      <div
        className="messageInfoModal__card"
        style={{
          padding: 12,
        }}>
        <div
          className="messageList__dateContainer"
          key={moment(message.createdAt).toISOString()}>
          <Typography.Text className="messageList__date">
            {getReadableDateFormat(message.createdAt).toUpperCase()}
          </Typography.Text>
        </div>
        <>
          {message.senderId === userDetails.id ? (
            <MessageContents
              key={message._id}
              message={message}
              isSenderSelf
              hideSeen={false}
              sending={message.sending}
              time={message.createdAt}
              text={message.message}
              type={message.messageType}
              read={false}
              replyNameColor={
                message.parentMessageSender
                  ? getNameColor(message.parentMessageSender._id)
                  : undefined
              }
              id={message._id}
              contentUrl={message.contentUrl}
              compressedImageUrl={message.compressedImageUrl}
              extraData={message.extraData}
              fileName={message.fileName}
              noOptions
            />
          ) : (
            <MessageContents
              key={message._id}
              message={message}
              isSenderSelf={false}
              sender={{
                isCreator: message.senderId === roomDetails.creator?._id,
                name: message.senderName,
                profilePicUrl: message.senderProfilePic,
              }}
              time={message.createdAt}
              type={message.messageType}
              text={message.message}
              nameColor={getNameColor(message.senderId)}
              replyNameColor={
                message.parentMessageSender
                  ? getNameColor(message.parentMessageSender._id)
                  : undefined
              }
              compressedImageUrl={message.compressedImageUrl}
              contentUrl={message.contentUrl}
              extraData={message.extraData}
              fileName={message.fileName}
              id={message._id}
              noOptions
            />
          )}
        </>
      </div>
      {state.loading ? (
        <Loader />
      ) : state.participants.length > 0 ? (
        <>
          <div className="messageInfoModal__card">
            <Typography.Title
              level={5}
              className="messageInfoModal__card__title">
              VIEWED BY
            </Typography.Title>
            {state.participants.map((participant) => (
              <ChatParticipantItem
                key={participant._id}
                {...participant}
                onClick={() => {}}
              />
            ))}
          </div>
        </>
      ) : (
        <div className="messageInfoModal__card">
          <Typography.Title level={5} className="messageInfoModal__card__title">
            NO VIEWS YET
          </Typography.Title>
        </div>
      )}
    </Modal>
  );
};
export default MessageInfo;
