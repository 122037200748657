import React from 'react';

import { Button } from 'antd';

import ThreeDotSvg from '../../assets/svg/ThreeDotSvg';

interface Props {
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  className?: string;
}

const MenuButton: React.FC<Props> = ({ onClick, className }) => (
  <Button
    className={`siteBtn noStyle circularBgHover ${className}`}
    onClick={onClick}>
    <ThreeDotSvg />
  </Button>
);

export default React.memo(MenuButton);
