import React from 'react';

import { CreatorDetails } from '../../types/userTypes';

interface Props {
  creator: CreatorDetails;
}

const CreatorSocialActions: React.FC<Props> = ({ creator }) => (
  <div className="creatorProfileSocialActions">
    {creator.showfacebook && (
      <a
        href={creator.facebook}
        target="_blank"
        rel="noreferrer"
        className="profileSocialLink">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32">
          <g
            id="_001-facebook"
            data-name="001-facebook"
            transform="translate(0.133 0.469)">
            <rect
              id="Rectangle_361"
              data-name="Rectangle 361"
              width="32"
              height="32"
              rx="6"
              transform="translate(-0.133 -0.469)"
              fill="#3b5998"
            />
            <path
              id="Path_657"
              data-name="Path 657"
              d="M115.1,75.593h2.062V72.547h-2.424v.011c-2.937.1-3.539,1.755-3.592,3.489h-.006v1.521h-2V80.55h2v8h3.014v-8h2.469l.477-2.983h-2.945v-.919A.982.982,0,0,1,115.1,75.593Z"
              transform="translate(-97.149 -64.579)"
              fill="#fff"
            />
          </g>
        </svg>
      </a>
    )}
    {creator.showinstagram && (
      <a
        href={creator.instagram}
        target="_blank"
        rel="noreferrer"
        className="profileSocialLink">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32">
          <g
            id="instagram_1_"
            data-name="instagram (1)"
            transform="translate(-0.045)">
            <rect
              id="Rectangle_362"
              data-name="Rectangle 362"
              width="32"
              height="32"
              rx="6"
              transform="translate(0.045)"
              fill="#e1306c"
            />
            <g
              id="instagram_1_2"
              data-name="instagram (1)"
              transform="translate(6.596 7)">
              <path
                id="Path_783"
                data-name="Path 783"
                d="M18.078,5.2a6.459,6.459,0,0,0-.411-2.145A4.528,4.528,0,0,0,15.081.466,6.476,6.476,0,0,0,12.936.055C11.99.01,11.69,0,9.29,0s-2.7.01-3.643.052A6.461,6.461,0,0,0,3.5.463,4.313,4.313,0,0,0,1.934,1.485,4.352,4.352,0,0,0,.915,3.049,6.477,6.477,0,0,0,.5,5.194C.46,6.14.449,6.44.449,8.84s.01,2.7.052,3.643a6.459,6.459,0,0,0,.411,2.145A4.528,4.528,0,0,0,3.5,17.215a6.477,6.477,0,0,0,2.145.411c.943.042,1.243.052,3.643.052s2.7-.01,3.643-.052a6.458,6.458,0,0,0,2.144-.411,4.522,4.522,0,0,0,2.587-2.586,6.481,6.481,0,0,0,.411-2.145c.041-.943.052-1.243.052-3.643S18.12,6.14,18.078,5.2Zm-1.592,7.217a4.845,4.845,0,0,1-.3,1.64A2.932,2.932,0,0,1,14.5,15.733a4.862,4.862,0,0,1-1.64.3c-.932.042-1.212.052-3.571.052s-2.642-.01-3.571-.052a4.843,4.843,0,0,1-1.64-.3,2.72,2.72,0,0,1-1.015-.66,2.748,2.748,0,0,1-.66-1.015,4.863,4.863,0,0,1-.3-1.64c-.042-.932-.052-1.212-.052-3.571s.01-2.642.052-3.571a4.843,4.843,0,0,1,.3-1.64A2.687,2.687,0,0,1,3.07,2.621a2.744,2.744,0,0,1,1.015-.659,4.866,4.866,0,0,1,1.64-.3c.932-.041,1.212-.052,3.571-.052s2.642.01,3.571.052a4.845,4.845,0,0,1,1.64.3,2.718,2.718,0,0,1,1.015.659,2.748,2.748,0,0,1,.66,1.015,4.865,4.865,0,0,1,.3,1.64c.041.932.052,1.212.052,3.571S16.528,11.482,16.486,12.415Zm0,0"
                fill="#fff"
              />
              <path
                id="Path_784"
                data-name="Path 784"
                d="M129.49,124.5a4.541,4.541,0,1,0,4.541,4.541A4.542,4.542,0,0,0,129.49,124.5Zm0,7.487a2.946,2.946,0,1,1,2.946-2.946A2.946,2.946,0,0,1,129.49,131.987Zm0,0"
                transform="translate(-120.201 -120.201)"
                fill="#fff"
              />
              <path
                id="Path_785"
                data-name="Path 785"
                d="M364.57,89.662a1.06,1.06,0,1,1-1.06-1.06A1.06,1.06,0,0,1,364.57,89.662Zm0,0"
                transform="translate(-349.499 -85.542)"
                fill="#fff"
              />
            </g>
          </g>
        </svg>
      </a>
    )}
    {creator.showlinkedin && (
      <a
        href={creator.linkedin}
        target="_blank"
        rel="noreferrer"
        className="profileSocialLink">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32">
          <g
            id="_002-linkedin"
            data-name="002-linkedin"
            transform="translate(-0.363)">
            <rect
              id="Rectangle_363"
              data-name="Rectangle 363"
              width="32"
              height="32"
              rx="6"
              transform="translate(0.363)"
              fill="#0e76a8"
            />
            <path
              id="Path_659"
              data-name="Path 659"
              d="M81.949,86.814h3V76.828h-3ZM93.621,76.482a4.5,4.5,0,0,0-3.678,1.7V76.8H86.936V86.814h3.007V81.4a2.378,2.378,0,0,1,2.363-2.262c1.314,0,1.638,1.117,1.638,2.234v5.445h3V81.145C96.94,77.208,95.076,76.482,93.621,76.482ZM83.433,75.83a1.5,1.5,0,1,0-1.5-1.5A1.5,1.5,0,0,0,83.433,75.83Z"
              transform="translate(-72.933 -64.83)"
              fill="#fff"
            />
          </g>
        </svg>
      </a>
    )}
    {creator.showtwitter && (
      <a
        href={creator.twitter}
        target="_blank"
        rel="noreferrer"
        className="profileSocialLink">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32">
          <g
            id="_003-twitter"
            data-name="003-twitter"
            transform="translate(0.318)">
            <rect
              id="Rectangle_364"
              data-name="Rectangle 364"
              width="32"
              height="32"
              rx="6"
              transform="translate(-0.318)"
              fill="#26a6d1"
            />
            <path
              id="Path_661"
              data-name="Path 661"
              d="M82.713,83.761a7.968,7.968,0,0,1-2.24.6,3.815,3.815,0,0,0,1.715-2.095,7.905,7.905,0,0,1-2.477.919,3.95,3.95,0,0,0-2.846-1.194,3.842,3.842,0,0,0-3.9,3.783,3.672,3.672,0,0,0,.1.863,11.184,11.184,0,0,1-8.036-3.954,3.676,3.676,0,0,0-.528,1.9,3.752,3.752,0,0,0,1.734,3.149,3.965,3.965,0,0,1-1.767-.474v.048A3.817,3.817,0,0,0,67.6,91.016a4.032,4.032,0,0,1-1.027.133,3.932,3.932,0,0,1-.733-.068,3.889,3.889,0,0,0,3.643,2.628,7.971,7.971,0,0,1-4.843,1.62,8.2,8.2,0,0,1-.931-.053,11.284,11.284,0,0,0,5.978,1.7A10.85,10.85,0,0,0,80.78,86.209l-.011-.49A7.781,7.781,0,0,0,82.713,83.761Z"
              transform="translate(-56.71 -72.982)"
              fill="#fff"
            />
          </g>
        </svg>
      </a>
    )}
    {creator.showyoutube && (
      <a
        href={creator.youtube}
        target="_blank"
        rel="noreferrer"
        className="profileSocialLink">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32">
          <g
            id="_004-youtube"
            data-name="004-youtube"
            transform="translate(-0.001)">
            <rect
              id="Rectangle_365"
              data-name="Rectangle 365"
              width="32"
              height="32"
              rx="6"
              transform="translate(0.001)"
              fill="#d42428"
            />
            <path
              id="Path_662"
              data-name="Path 662"
              d="M92,69.38A16,16,0,1,1,69.376,92.009Z"
              transform="translate(-64.692 -64.695)"
              fill="#d42428"
            />
            <path
              id="Path_663"
              data-name="Path 663"
              d="M152.8,175.067l-7.919-7.919-9.34,1.063-5.649,8.746,10.556,10.556A16.018,16.018,0,0,0,152.8,175.067Z"
              transform="translate(-121.125 -155.862)"
              fill="none"
            />
            <path
              id="Path_664"
              data-name="Path 664"
              d="M136.673,157.7a2.428,2.428,0,0,0-2.427-2.428H122.859a2.428,2.428,0,0,0-2.427,2.428v6.512a2.428,2.428,0,0,0,2.427,2.428h11.387a2.428,2.428,0,0,0,2.427-2.428V157.7Zm-9.745,6v-6.116l4.638,3.058Z"
              transform="translate(-112.301 -144.788)"
              fill="#fff"
            />
          </g>
        </svg>
      </a>
    )}
  </div>
);

export default React.memo(CreatorSocialActions);
