/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import { Button } from 'antd';
import moment from 'moment';

import JoinSvg from '../../../assets/svg/Join';
import CustomImage from '../../../components/CustomImage/CustomImage';
import MenuButton from '../../../components/MenuButton/MenuButton';
import { useTheme } from '../../../context/ThemeProvider';
import { useAppSelector } from '../../../shared/hooks';
import {
  IWebinar,
  IWebinarCreatorDetails,
} from '../../../types/feedTypes';
import Time from './Time';

interface Props {
  data: IWebinar;
  host: IWebinarCreatorDetails;
  onSelect: () => void;
  webinarType?: 'upcoming' | 'completed';
  onJoinBtnClick?: (data: IWebinar) => void;
  onMenuClick?: () => void;
}

const VideoCallCard: React.FC<Props> = ({
  data,
  host,
  onMenuClick,
  onSelect,
  webinarType = 'upcoming',
  onJoinBtnClick,
}) => {
  const { colors } = useTheme();
  const userDetails = useAppSelector((state) => state.user);
  const [showJoinBtn, setShowJoinBtn] = React.useState(false);
  const [meetingStarted, setMeetingStarted] = React.useState(false);

  const timeDiffinMin = () => {
    const fromTime = data?.fromTime;
    if (fromTime) {
      const now = moment(new Date());
      const end = moment(fromTime);
      return moment.duration(end.diff(now)).asMinutes();
    }
    return 20;
  };

  const joinStatus = () => {
    if (moment(data?.toTime).isBefore(moment())) {
      setShowJoinBtn(false);
    } else if (data?.creator === userDetails.id) {
      if (timeDiffinMin() > 30) {
        setTimeout(
          () => {
            joinStatus();
          },
          (timeDiffinMin() - 30) * 60000,
        );
        setShowJoinBtn(false);
      } else {
        setShowJoinBtn(true);
      }
    } else if (timeDiffinMin() > 15) {
      setTimeout(
        () => {
          joinStatus();
        },
        (timeDiffinMin() - 15) * 60000,
      );
      setShowJoinBtn(false);
    } else {
      setShowJoinBtn(true);
    }
  };

  useEffect(() => {
    joinStatus();
    if (
      webinarType === 'upcoming' &&
      moment(data.fromTime).isSameOrAfter(moment())
    ) {
      setTimeout(() => {
        setMeetingStarted(true);
      }, timeDiffinMin() * 60000);
    } else if (
      webinarType === 'upcoming' &&
      moment(data.fromTime).isBefore(moment())
    ) {
      setMeetingStarted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div
        className={`workshopCardOuter ${webinarType}`}
        id={`workshopCardOuter-${data?._id}`}>
        {data.thumbnail ? (
          <CustomImage
            src={data.thumbnail}
            alt={data.title}
            className="workshopCardImage"
          />
        ) : null}
        <div className="workshopCard">
          {onMenuClick ? (
            <MenuButton onClick={onMenuClick} className="moreBtn" />
          ) : null}
          <div className="workshopCardDate">
            <span className="month">
              {moment(data?.fromTime).format('MMM')}
            </span>
            <span className="date">{moment(data?.fromTime).format('DD')}</span>
            {moment(data?.fromTime).format('MMM Do YY') ===
            moment().format('MMM Do YY') ? (
              <span className="day">Today</span>
            ) : (
              <span className="day">
                {moment(data?.fromTime).format('ddd')}
              </span>
            )}
          </div>
          <div className="workshopCardDetails">
            <span className="time">
              {moment(data?.fromTime).format('hh:mm A')} -{' '}
              {moment(data?.toTime).format('hh:mm A')}
            </span>
            <Button
              className="siteBtn noStyle"
              title={data?.title}
              onClick={onSelect}>
              <h5 className="workshopTitle">{data?.title}</h5>
            </Button>
            <span className="host">
              By
              <strong className="hostName">{host.name}</strong>
            </span>
          </div>
        </div>
        {showJoinBtn && (
          <div className="workshopCardAction">
            <span className="timer">
              {meetingStarted ? 'Ends' : 'Starts'} In:{' '}
              <Time time={meetingStarted ? data.toTime : data.fromTime} />
            </span>
            <Button
              className="siteBtn noStyle blueBtnV2 joinBtn"
              icon={
                <JoinSvg
                  width={20}
                  height={20}
                  background={colors.BACKGROUND}
                  foreground={colors.BLUE}
                />
              }
              onClick={() => {
                if (onJoinBtnClick) onJoinBtnClick(data);
              }}>
              JOIN
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(VideoCallCard);
