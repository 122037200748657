import './styles.scss';

import React, { useEffect, useRef } from 'react';

import Loader from '../Loader';

type SwipeButtonPropsType = {
  onSwipe: () => void;
  isLoading?: boolean;

  backgroundColor: string;
  thumbColor: string;
  textColor: string;
  thumbIcon: React.ReactNode;

  text?: string;
};

const SwipeableButton: React.FC<SwipeButtonPropsType> = ({
  onSwipe,
  isLoading = false,

  backgroundColor,
  thumbColor,
  textColor,
  thumbIcon,

  text = 'Swipe to mark',
}) => {
  const slider = useRef<HTMLDivElement>(null);
  const button = useRef<HTMLDivElement>(null);
  const slideText = useRef<HTMLSpanElement>(null);
  // const loading = useRef<HTMLDivElement>(null);

  const initialMouse = useRef(0);
  const slideMovementTotal = useRef(0);
  const mouseIsDown = useRef(false);

  useEffect(() => {
    if (slider.current && button.current) {
      const sliderEl = slider.current;
      const buttonEl = button.current;
      // const loadingEl = loading.current;
      // const arrowsEl = arrows.current;

      const handleMouseDown = (event: MouseEvent | TouchEvent) => {
        mouseIsDown.current = true;
        slideMovementTotal.current =
          buttonEl.offsetWidth - sliderEl.offsetWidth;
        initialMouse.current =
          (event as MouseEvent).clientX ||
          (event as TouchEvent).touches[0].pageX;
      };

      const handleMouseUp = (event: MouseEvent | TouchEvent) => {
        if (!mouseIsDown.current) return;
        mouseIsDown.current = false;
        const currentMouse =
          (event as MouseEvent).clientX ||
          (event as TouchEvent).changedTouches[0].pageX;
        const relativeMouse = currentMouse - initialMouse.current;
        if (relativeMouse < slideMovementTotal.current) {
          // $('.slide-text').fadeTo(300, 1);
          // slider.animate(
          //   {
          //     left: '4px',
          //   },
          //   300,
          // );
          slideText.current?.style.setProperty('opacity', '1');
          sliderEl.style.left = '0px';
          return;
        }
        sliderEl.classList.add('unlocked');
        onSwipe();
        setTimeout(() => {
          buttonEl.classList.add('bg-converted');
          // loadingEl.style.display = 'block';
          // arrowsEl.style.display = 'none';
        }, 250);
        setTimeout(() => {
          sliderEl.addEventListener('click', () => {
            if (!sliderEl.classList.contains('unlocked')) return;
            sliderEl.classList.remove('unlocked');
            buttonEl.classList.remove('bg-converted');
            // loadingEl.style.display = 'none';
            // arrowsEl.style.display = 'block';
            sliderEl.removeEventListener('click', () => {});
          });
        }, 0);
      };

      const handleMouseMove = (event: MouseEvent | TouchEvent) => {
        if (!mouseIsDown.current) return;
        const currentMouse =
          (event as MouseEvent).clientX ||
          (event as TouchEvent).touches[0].pageX;
        const relativeMouse = currentMouse - initialMouse.current;
        const slidePercent = 1 - relativeMouse / slideMovementTotal.current;
        // $('.slide-text').fadeTo(0, slidePercent);
        slideText.current?.style.setProperty(
          'opacity',
          slidePercent.toString(),
        );
        if (relativeMouse <= 0) {
          sliderEl.style.left = '0px';
          return;
        }
        if (relativeMouse >= slideMovementTotal.current) {
          sliderEl.style.left = `${slideMovementTotal.current}px`;
          return;
        }
        sliderEl.style.left = `${relativeMouse}px`;
      };

      sliderEl.addEventListener('mousedown', handleMouseDown);
      sliderEl.addEventListener('touchstart', handleMouseDown);
      document.body.addEventListener('mouseup', handleMouseUp);
      document.body.addEventListener('touchend', handleMouseUp);
      document.body.addEventListener('mousemove', handleMouseMove);
      document.body.addEventListener('touchmove', handleMouseMove);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id="button-background"
      ref={button}
      style={{
        backgroundColor,
      }}>
      <span
        className="slide-text"
        ref={slideText}
        style={{
          color: textColor,
        }}>
        {text}
      </span>
      <div
        id="slider"
        ref={slider}
        style={{
          backgroundColor: thumbColor,
        }}>
        {isLoading ? <Loader size="small" /> : thumbIcon}
      </div>
    </div>
  );
};

export default React.memo(SwipeableButton);
