import React, { useEffect } from 'react';

import { Modal } from 'antd';
import { IoChevronForward, IoVideocam } from 'react-icons/io5';

import FeedAPI from '../../../../api/FeedAPI';
import { useTheme } from '../../../../context/ThemeProvider';
import { useAppSelector } from '../../../../shared/hooks';
import BookOneToOneConsultation from '../../../BookOneToOneConsultation/BookOneToOneConsultation';
import { IConsultationPendingEvent } from '../../../BookOneToOneConsultation/types';

const EventCard: React.FC<{
  event: IConsultationPendingEvent;
  onClick: () => void;
}> = ({ event, onClick }) => {
  const { colors } = useTheme();

  const { title, bookings, noOfSlotsLimit, mango } = event;
  return (
    <div className="feed-card primary" onClick={onClick}>
      <div className="feed-card__icon-bar">
        <IoVideocam color={colors.BACKGROUND} size={20} />
      </div>
      <div className="feed-card__details-wrapper">
        <div className="feed-card-details">
          <div className="feed-card__title">
            You've {noOfSlotsLimit - bookings} out of {noOfSlotsLimit} available
            slots for <b>{title}</b> in{' '}
            <b style={{ fontWeight: 600 }}>{mango.title}</b>
          </div>
        </div>

        <div className="feed-card__arrow">
          <IoChevronForward size={24} color={colors.ICON} />
        </div>
      </div>
    </div>
  );
};

const UnbookedSlotsForConsultation: React.FC = () => {
  const { postState, selected } = useAppSelector((state) => state.activity);

  const [events, setEvents] = React.useState<IConsultationPendingEvent[]>([]);
  const [selectedEvent, setSelectedEvent] =
    React.useState<IConsultationPendingEvent | null>(null);

  const fetchEvents = async () => {
    const resp = await FeedAPI.fetchPendingSlots();
    if (resp.status === 200 && resp.data.result) {
      const newEvents =
        resp.data.result.filter((e) => e.bookings < e.noOfSlotsLimit) || [];
      setEvents(newEvents);
    }
  };

  useEffect(() => {
    if (postState.refreshing) {
      fetchEvents();
    }
  }, [postState.refreshing]);

  useEffect(() => {
    if (selected) {
      fetchEvents();
    }
  }, [selected]);

  return (
    <>
      <div>
        {events.map((event) => (
          <EventCard
            key={event._id}
            event={event}
            onClick={() => {
              setSelectedEvent(event);
            }}
          />
        ))}
      </div>
      <Modal
        open={Boolean(selectedEvent)}
        title={null}
        footer={null}
        closable={false}
        destroyOnClose
        className="postDetailsModal">
        {selectedEvent ? (
          <BookOneToOneConsultation
            event={selectedEvent}
            handleBack={() => {
              setSelectedEvent(null);
            }}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default React.memo(UnbookedSlotsForConsultation);
