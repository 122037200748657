import './styles.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Modal, Typography } from 'antd';
import {
  MdArrowLeft,
  MdArrowRight,
  MdOutlineRestartAlt,
  MdOutlineZoomIn,
  MdOutlineZoomOut,
} from 'react-icons/md';
import { Document, Page, pdfjs } from 'react-pdf';

import CloseModal from '../../assets/svg/CloseModal';
import Loader from '../Loader';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface Props {
  title?: string;
  showModal: boolean;
  closeModal: () => void;
  url: string;
  onLoad?: () => void;
  initalProgress?: number;
  onProgress?: (progress: number, duration: number) => void;
}

const modalMaskStyle: React.CSSProperties = {
  backgroundColor: 'rgba(244, 244, 244, 0.8)',
};

const PdfViewer: React.FC<Props> = ({
  title = 'PDF Viewer',
  showModal,
  closeModal,
  url,
  onLoad,
  onProgress,
}) => {
  const pdfViwerDocumentRef = useRef<HTMLDivElement | null>(null);
  const pagesRef = useRef<Array<HTMLDivElement> | null>(null);

  const [pageNumber, setPageNumber] = React.useState(1);
  const [numPages, setNumPages] = React.useState(5); // no of page to show initially
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
  const [loading, setLoading] = React.useState(true);
  const [scale, setScale] = React.useState(1);

  const onLoadSuccess = (totalPages: number) => {
    if (onProgress) onProgress(pageNumber, totalPages);
    if (onLoad) onLoad();

    setTotalNumberOfPages(totalPages);

    setLoading(false);

    pagesRef.current = Array(totalPages).fill(null);
  };

  const updatePageNumber = (increment: number) => {
    let newPageNumber = pageNumber + increment;
    if (newPageNumber < 1) {
      newPageNumber = 1;
    } else if (newPageNumber > numPages) {
      newPageNumber = numPages;
    }

    // handling smooth scrolling between pages when changing page number
    if (pdfViwerDocumentRef.current && pagesRef.current) {
      const targetPage = pagesRef.current[newPageNumber - 1];
      if (targetPage) {
        pdfViwerDocumentRef.current.scrollTo({
          top: targetPage.offsetTop,
          behavior: 'smooth',
        });
      }
    }

    setPageNumber(newPageNumber);

    if (onProgress) {
      onProgress(newPageNumber, numPages);
    }
  };

  useEffect(() => {
    setScale(1);
  }, [showModal]);

  const getObserverThreshold = useCallback(() => {
    switch (scale) {
      case 1:
        return 0.5;
      case 2:
        return 0.1;
      case 3:
        return 0.08;
      case 4:
        return 0.05;
      case 5:
        return 0.025;
      default:
        return 0.5;
    }
  }, [scale]);

  useEffect(() => {
    if (!pagesRef.current) return;

    const arePagesEmpty = pagesRef.current
      .slice(0, numPages)
      .every((page) => !page);

    if (arePagesEmpty) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const targetPageNumber =
              entry.target.getAttribute('data-page-number');

            if (targetPageNumber) {
              setPageNumber(Number(targetPageNumber));
              if (onProgress) {
                onProgress(Number(targetPageNumber), numPages);
              }
            }
          }
        });
      },
      {
        threshold: getObserverThreshold(),
      },
    );

    pagesRef.current.forEach((page) => {
      if (!page) return;
      observer.observe(page);
    });

    return () => {
      observer.disconnect();
    };
  }, [numPages, onProgress, getObserverThreshold]);

  useEffect(() => {
    if (!pagesRef.current) return;
    // if (!pdfViwerDocumentRef.current) return;

    const arePagesEmpty = pagesRef.current
      .slice(0, numPages)
      .every((page) => !page);

    if (arePagesEmpty) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const targetPageNumber =
              entry.target.getAttribute('data-page-number');

            if (
              Number(targetPageNumber) === numPages &&
              numPages < totalNumberOfPages
            ) {
              setNumPages((prev) => {
                const newNumPages = prev + 1;
                if (newNumPages > totalNumberOfPages) {
                  return totalNumberOfPages;
                }
                return newNumPages;
              });
            }
          }
        });
      },
      {
        threshold: 1,
        root: pdfViwerDocumentRef.current,
      },
    );

    pagesRef.current.forEach((page) => {
      if (!page) return;
      observer.observe(page);
    });

    return () => {
      observer.disconnect();
    };
  }, [numPages, totalNumberOfPages]);

  return (
    <Modal
      title={
        <Typography.Title
          ellipsis={{
            rows: 1,
            expandable: false,
          }}
          level={4}
          className="title"
          style={{ textAlign: 'left' }}>
          {title}
        </Typography.Title>
      }
      open={showModal}
      onOk={closeModal}
      onCancel={closeModal}
      wrapClassName="pdfViewerModal"
      maskStyle={modalMaskStyle}
      centered
      closeIcon={<CloseModal />}
      footer={
        loading ? null : (
          <div className="footerWrapper">
            <div className="buttonWrapper">
              <Button
                className="button"
                onClick={() => setScale((prev) => prev - 1)}
                disabled={scale === 1}>
                <MdOutlineZoomOut />
              </Button>
              <Button
                className="button"
                onClick={() => setScale(1)}
                disabled={scale === 1}>
                <MdOutlineRestartAlt />
              </Button>
              <Button
                className="button"
                onClick={() => setScale((prev) => prev + 1)}
                disabled={scale >= 5}>
                <MdOutlineZoomIn />
              </Button>
            </div>
            <div className="buttonWrapper">
              <Button
                className="button"
                onClick={() => updatePageNumber(-1)}
                disabled={pageNumber === 1}>
                <MdArrowLeft />
              </Button>
              <Typography.Text className="pageNumber">
                {pageNumber} of {totalNumberOfPages}
              </Typography.Text>
              <Button
                className="button"
                onClick={() => updatePageNumber(1)}
                disabled={pageNumber === numPages}>
                <MdArrowRight />
              </Button>
            </div>
          </div>
        )
      }
      width={700}>
      <div ref={pdfViwerDocumentRef} className="pdf-viewer-document-container">
        <Document
          file={url}
          onLoadSuccess={(pdf) => {
            onLoadSuccess(pdf.numPages);
            setNumPages(pdf.numPages);
          }}>
          {loading ? (
            <Loader />
          ) : (
            <div className={`pdf-viewer-pages-container scale${scale}`}>
              {Array.from({ length: numPages }, (_, index) => (
                <div
                  data-page-number={index + 1}
                  ref={(ele) => {
                    if (!ele) return;
                    if (!pagesRef.current) return;
                    pagesRef.current[index] = ele;
                  }}
                  className="pdf-viewer-page-container">
                  <Page key={index} pageNumber={index + 1} scale={scale} />
                </div>
              ))}
            </div>
          )}
        </Document>
      </div>
    </Modal>
  );
};

export default React.memo(PdfViewer);
