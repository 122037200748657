import './styles.scss';

import React from 'react';

import {
  Button,
  Modal,
} from 'antd';

import { nanoid } from '@reduxjs/toolkit';

import Header from '../../../../components/Header/Header';
import { CHAT_NAME_COLORS } from '../../../../shared/Styles';
import { FileData } from '../../screens/ChatInputBox/ChatInputBox';
import ChatMedia from '../ChatMedia/ChatMedia';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  file?: FileData;
  onSendFile: (file: FileData) => void;
}

const FileSelectionModal: React.FC<Props> = ({
  showModal,
  closeModal,
  file,
  onSendFile,
}) => {
  return (
    <Modal
      open={showModal}
      title={<Header title="Select file" handleBack={closeModal} />}
      destroyOnClose
      className="fileSelectorModal"
      closable={false}
      closeIcon={null}
      footer={
        <div className="fileSelectorfooter">
          <Button
            disabled={!file}
            className="siteBtn siteBtnPrimary"
            onClick={() => {
              if (file) onSendFile(file);
            }}>
            Send
          </Button>
        </div>
      }>
      {file && (
        <ChatMedia
          id={nanoid(12)}
          type={file.fileType}
          contentUrl={file.url}
          fileName={file.file.name}
          mediaColor={
            CHAT_NAME_COLORS[
              Math.floor(Math.random() * CHAT_NAME_COLORS.length - 1)
            ]
          }
          extraData={{
            fileSize: `${file.file.size}`,
            ext: file.file.type.split('/')[1],
          }}
          downloadMedia={() => {}}
        />
      )}
    </Modal>
  );
};
export default FileSelectionModal;
