import './styles.scss';

import React from 'react';

import { Typography } from 'antd';
import moment from 'moment';
import TMIcon from 'tm-icons-library';

import CustomReadMoreText
  from '../../../../components/CustomReadMoreText/CustomReadMoreText';
import MenuButton from '../../../../components/MenuButton/MenuButton';
import { useTheme } from '../../../../context/ThemeProvider';
import { DELETED_USER } from '../../../../shared/data';
import { useAppSelector } from '../../../../shared/hooks';
import {
  ExtraData,
  IMessage,
} from '../../../../types/chatModels/ChatMessagesResponse';
import { RoomTypes } from '../../../../types/messageTypes';
import ChatAvatar from '../ChatAvatar/ChatAvatar';
import ChatMedia from '../ChatMedia/ChatMedia';
import ReplyContents from '../ReplyContents/ReplyContents';

interface MessageContentsProps {
  isSenderSelf: boolean;
  sender?: { name: string; profilePicUrl: string; isCreator: boolean };
  time: string;
  text?: string;
  type: string;
  contentUrl?: string;
  compressedImageUrl?: string;
  fileName?: string;
  extraData?: ExtraData;
  id: string;
  read?: boolean;
  hideSeen?: boolean;
  sending?: boolean;
  message: IMessage;
  onClickReply?: (parentMessageId: string) => void;
  onOptionsClick?: () => void;
  downloadMedia?: () => void;
  nameColor?: string;
  replyNameColor?: string;
  roomType?: RoomTypes;
  noOptions?: boolean;
  isDeleted?: boolean;
  deletedByCreator?: boolean;
}

const MessageContents: React.FC<MessageContentsProps> = ({
  id,
  isSenderSelf,
  time,
  type,
  contentUrl,
  compressedImageUrl,
  extraData,
  fileName,
  hideSeen,
  message,
  onClickReply,
  onOptionsClick,
  read,
  sender,
  // sending,
  text,
  nameColor,
  replyNameColor,
  downloadMedia,
  noOptions,
  isDeleted,
  deletedByCreator,
  // roomType,
}) => {
  const { colors } = useTheme();

  const { id: userId } = useAppSelector((state) => state.user);

  // const isMyMessage = useMemo(
  //   () => message.senderId === userDetails.id,
  //   [message.senderId, userDetails.id],
  // );
  // const isMessageRead = useMemo(
  //   () => roomType === 'dm' && message.seenUsers?.length >= 2,
  //   [message.seenUsers, roomType],
  // );

  return (
    <>
      <div
        data-message-id={id}
        className={isSenderSelf ? 'message message-self' : 'message'}>
        {isSenderSelf && !noOptions && !isDeleted && (
          <MenuButton
            className="message-menu-button self"
            onClick={() => {
              if (onOptionsClick) onOptionsClick();
            }}
          />
        )}

        {/* {isSenderSelf && !hideSeen && !isDeleted && (
          <SentSvg
            sending={sending || false}
            width={16}
            height={16}
            color={read ? colors.BLUE : colors.TEXT5}
          />
        )} */}

        {!isSenderSelf && (
          <ChatAvatar
            size={32}
            border={0}
            url={sender?.profilePicUrl}
            name={sender?.name}
          />
        )}
        <div className="message-contents">
          {isDeleted ? (
            <div className="message-contents-text deleted-message">
              <TMIcon name="ban" size={16} color={colors.TEXT4} />{' '}
              {deletedByCreator && !isSenderSelf
                ? 'This message was deleted by the creator.'
                : (isSenderSelf && 'You deleted this message.') ||
                  'This message was deleted.'}
            </div>
          ) : (
            <>
              <div className="message-contents-header">
                <Typography.Text
                  className="message-contents-header-name"
                  style={{
                    color: isSenderSelf
                      ? colors.TEXT1
                      : nameColor || colors.TEXT1,
                  }}>
                  {isSenderSelf ? 'You' : sender?.name || DELETED_USER}
                </Typography.Text>
                {sender?.isCreator && (
                  <Typography.Text className="creator-tag">
                    CREATOR
                  </Typography.Text>
                )}
                {/* <Typography.Text className="message-contents-header-separator">
                  •
                </Typography.Text> */}
                {/* <Typography.Text className="message-contents-header-time">
                  {moment(time || new Date()).format('HH:mm')}
                </Typography.Text> */}
              </div>

              {message.messageRef._id && (
                <div
                  onClick={() => {
                    if (message.messageRef._id && onClickReply)
                      onClickReply(message.messageRef._id);
                  }}
                  className="message-contents-reply"
                  style={{
                    borderLeftColor: replyNameColor || colors.TEXT2,
                  }}>
                  <ReplyContents
                    _id={message.messageRef._id}
                    message={
                      message.messageRef.isDeleted
                        ? 'Deleted message'
                        : message.messageRef.message || ''
                    }
                    messageType={
                      message.messageRef.isDeleted
                        ? 'text'
                        : message.messageRef.messageType || 'text'
                    }
                    senderName={
                      message.parentMessageSender?._id === userId
                        ? 'You'
                        : message.parentMessageSender?.name || ''
                    }
                    nameColor={replyNameColor}
                  />
                </div>
              )}

              {type === 'text' && (
                <div className="message-contents-text" style={{ marginTop: 0 }}>
                  <CustomReadMoreText
                    text={text || ''}
                    seeMoreText="Read more"
                    seeLessText="Read less"
                    className="message-seemore"
                  />
                </div>
              )}

              {['audio', 'video', 'image', 'doc'].includes(type) && (
                <ChatMedia
                  contentUrl={
                    type === 'image' && compressedImageUrl
                      ? compressedImageUrl
                      : contentUrl
                  }
                  fileName={fileName}
                  type={type as any}
                  extraData={extraData}
                  id={id}
                  mediaColor={nameColor}
                  downloadMedia={() => {
                    if (downloadMedia) downloadMedia();
                  }}
                />
              )}

              <div className="message-contents-footer">
                <span className="messageTime">
                  {moment(time || new Date()).format('h:mm A')}
                </span>
                {isSenderSelf && !noOptions && !isDeleted ? (
                  <TMIcon
                    name={
                      message.seenUsers?.length >= 2
                        ? 'checkmark-done'
                        : 'checkmark'
                    }
                    size={16}
                    color={read ? colors.LINK : colors.TEXT3}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
        {!isSenderSelf && !hideSeen && !isDeleted && (
          <MenuButton
            className="message-menu-button"
            onClick={() => {
              if (onOptionsClick) onOptionsClick();
            }}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(MessageContents);
