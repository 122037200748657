import { memo } from 'react';

import {
  Result,
  Typography,
} from 'antd';
import Lottie from 'react-lottie';

import calenderJson from '../../../../assets/json/calendar-error.json';
import emptyJson from '../../../../assets/json/Empty.lottie.json';
import msgJson from '../../../../assets/json/message.json';
import comingsoonjson from '../../../../assets/json/Messages.lottie.json';
import searchJson from '../../../../assets/json/search.lottie.json';
import historyIcon from '../../../../assets/svg/history.svg';

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const dogImage = (
  <Lottie
    options={{ ...defaultOptions, animationData: emptyJson }}
    width={200}
  />
);
const comingsoonImage = (
  <Lottie
    options={{
      ...defaultOptions,
      animationData: comingsoonjson,
      loop: true,
      autoplay: true,
    }}
    width={650}
    height={300}
  />
);

const calenderImage = (
  <Lottie
    options={{
      ...defaultOptions,
      animationData: calenderJson,
      loop: false,
      autoplay: false,
    }}
    width={250}
    height={200}
  />
);

const msgImage = (
  <Lottie
    options={{
      ...defaultOptions,
      animationData: msgJson,
      loop: false,
      autoplay: true,
    }}
    width={450}
    height={248}
  />
);

const searchImage = (
  <Lottie
    options={{
      ...defaultOptions,
      animationData: searchJson,
      loop: true,
      autoplay: true,
    }}
    width={150}
    height={150}
  />
);

const historyImage = <img src={historyIcon} alt="" style={{ width: 64 }} />;

interface Props {
  title?: string;
  subtitle?: string;
  icon?: string;
  style?: React.CSSProperties;
  subtitle2?: string;
  extra?: React.ReactNode;
  showIcon?: boolean;
  iconElement?: React.ReactNode;
  hideTitle?: boolean;
}

// alias for icon ['dog', 'calender']
const NoDataFound: React.FC<Props> = ({
  title,
  subtitle,
  icon,
  style,
  subtitle2,
  extra,
  showIcon = true,
  iconElement,
  hideTitle = false,
}) => {
  const iconMap = {
    dog: dogImage,
    calender: calenderImage,
    comingsoon: comingsoonImage,
    msg: msgImage,
    dashboard: searchImage,
    history: historyImage,
  };
  const iconName = icon || 'dog';
  const image = iconMap[iconName as keyof typeof iconMap];

  return (
    <Result
      className="noDataComponent"
      icon={showIcon ? iconElement || image : null}
      style={style}
      title={
        <div className="noDataFoundTitleWrapper">
          {hideTitle ? null : (
            <Typography.Title level={4} className="noDataFoundTitle">
              {title || 'No data found!'}
            </Typography.Title>
          )}
          {subtitle === subtitle2 ? (
            <Typography.Text className="noDataFoundSubtitle">
              {subtitle}
            </Typography.Text>
          ) : (
            <>
              <Typography.Text className="noDataFoundSubtitle">
                {subtitle}
              </Typography.Text>
              <Typography.Text className="noDataFoundSubtitle">
                {subtitle2}
              </Typography.Text>
            </>
          )}
        </div>
      }
      extra={extra || null}
    />
  );
};

export default memo(NoDataFound);
