import React from 'react';

import Home from '../../layouts/Home/Home';
import { useAppSelector } from '../../shared/hooks';
import { HomeRoutes } from '../../types/routes';

interface Props {
  path: HomeRoutes;
  children: React.ReactNode;
}

const DynamicWrapper: React.FC<Props> = ({ path, children }) => {
  const {
    customRouter: { homeRoutes },
  } = useAppSelector((state) => state.app);

  const route = homeRoutes.find((r) => r.name === path);

  // memoize the home component

  if (route) {
    return <Home>{children}</Home>;
  }
  return children as JSX.Element;
};

export default React.memo(DynamicWrapper);
