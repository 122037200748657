import React, {
  memo,
  useMemo,
} from 'react';

import moment from 'moment';

import { useAppSelector } from '../shared/hooks';

interface Props {
  showWhiteLabelAppName?: boolean;
}

const CopyrightContent: React.FC<Props> = ({
  showWhiteLabelAppName = false,
}) => {
  const { hostMetadata, isTagMango } = useAppSelector((state) => state.app);

  const appName = useMemo(() => {
    if (!isTagMango) {
      return showWhiteLabelAppName
        ? hostMetadata.appName ||
            hostMetadata.brandname ||
            hostMetadata.creator.name
        : '';
    }

    return 'TagMango';
  }, [showWhiteLabelAppName, hostMetadata, isTagMango]);

  return (
    <>
      {appName} {moment().format('YYYY')}. All rights reserved.
    </>
  );
};

export default memo(CopyrightContent);
