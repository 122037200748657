import React, { memo } from 'react';

import { Button, Typography } from 'antd';

import NotificationSvg from '../../../assets/svg/NotificationSvg';
import TickSvg from '../../../assets/svg/TickSvg';
import { useTheme } from '../../../context/ThemeProvider';
import { IAssessmentReview } from '../../../types/assessmentTypes';
import ReviewAnswersheet from '../ReviewAnswersheet/ReviewAnswersheet';

interface Props {
  assessment: IAssessmentReview;
  allowRetaking?: boolean;
  onRetakeClick?: () => void;
}

const CompletedAssessmentItem: React.FC<Props> = ({
  assessment,
  allowRetaking,
  onRetakeClick,
}) => {
  const { colors } = useTheme();

  const [showReviewModal, setShowReviewModal] = React.useState(false);

  return (
    <>
      <div className="status-pill">
        <TickSvg
          width={20}
          height={20}
          circleColor={colors.PRIMARY}
          backgroundColor={colors.BACKGROUND}
          style={{
            marginRight: 8,
          }}
        />
        <Typography.Text className="status-pill__text">
          You&apos;ve successfully submitted.
        </Typography.Text>
      </div>
      <Typography.Title level={4} className="completed_assessment-item__title">
        {assessment?.fetchedAssignment?.title?.trim() || ''}
      </Typography.Title>
      <Typography.Title
        level={5}
        className="completed_assessment-item__subtitle">
        {assessment?.questionsAnswered || 0}/
        {assessment?.questions?.length || 0} Questions Answered
      </Typography.Title>
      <div className="assessment-item__button-container">
        <Button
          className="siteBtn siteBtnPrimary"
          onClick={() => {
            setShowReviewModal(true);
          }}>
          Review Assignment
        </Button>
        {allowRetaking ? (
          <Button className="siteBtn siteBtnSecondary" onClick={onRetakeClick}>
            Retake Assignment
          </Button>
        ) : null}
      </div>
      <div
        className="completed_assessment-item__notification"
        style={{
          backgroundColor: `${colors.BACKGROUND}60`,
        }}>
        <NotificationSvg
          width={16}
          height={16}
          style={{
            marginRight: 8,
          }}
        />
        <Typography.Text
          className="
          completed_assessment-item__notification-text">
          {assessment?.reviewStatus === 'finished'
            ? 'The creator has recently reviewed your submissions.'
            : 'The creator is reviewing your submissions.'}
        </Typography.Text>
      </div>
      <ReviewAnswersheet
        showModal={showReviewModal}
        closeModal={() => setShowReviewModal(false)}
        assignment={assessment}
      />
    </>
  );
};

export default memo(CompletedAssessmentItem);
