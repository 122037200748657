import React from 'react';

import bronzeMedal from '../../../../../../assets/img/bronze.png';
import goldMedal from '../../../../../../assets/img/gold.png';
import silverMedal from '../../../../../../assets/img/silver.png';
import { ILeaderboardItem } from '../../types';
import WinnerCard from './WinnerCard';

interface Props {
  items: ILeaderboardItem[];
}

const TopLeaderboard: React.FC<Props> = ({ items }) => {
  return (
    <div className="top-leaderboard">
      {items.length > 1 ? (
        <WinnerCard medal={silverMedal} item={items[1]} />
      ) : (
        <div />
      )}

      <WinnerCard medal={goldMedal} type="highlight" item={items[0]} />

      {items.length > 2 ? (
        <WinnerCard medal={bronzeMedal} item={items[2]} />
      ) : (
        <div />
      )}
    </div>
  );
};

export default React.memo(TopLeaderboard);
