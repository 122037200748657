import React from 'react';

const DocSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="14.662" height="19.248" viewBox="0 0 14.662 19.248" {...props}>
    <g id="google-docs" transform="translate(-61)">
      <path
        id="Path_126"
        data-name="Path 126"
        d="M75.5,4.676,70.985.165a.565.565,0,0,0-.4-.165H62.692A1.694,1.694,0,0,0,61,1.692V17.556a1.694,1.694,0,0,0,1.692,1.692H73.97a1.694,1.694,0,0,0,1.692-1.692V5.075A.569.569,0,0,0,75.5,4.676ZM71.15,1.925l2.586,2.586H71.714a.565.565,0,0,1-.564-.564ZM73.97,18.12H62.692a.565.565,0,0,1-.564-.564V1.692a.565.565,0,0,1,.564-.564h7.331v2.82a1.694,1.694,0,0,0,1.692,1.692h2.82V17.556A.565.565,0,0,1,73.97,18.12Z"
        fill="#ffbe00"
      />
      <path
        id="Path_127"
        data-name="Path 127"
        d="M158.331,212h-6.767a.564.564,0,1,0,0,1.128h6.767a.564.564,0,1,0,0-1.128Z"
        transform="translate(-86.617 -204.03)"
        fill="#fbc320"
      />
      <path
        id="Path_128"
        data-name="Path 128"
        d="M158.331,272h-6.767a.564.564,0,1,0,0,1.128h6.767a.564.564,0,1,0,0-1.128Z"
        transform="translate(-86.617 -261.775)"
        fill="#fbc320"
      />
      <path
        id="Path_129"
        data-name="Path 129"
        d="M158.331,332h-6.767a.564.564,0,0,0,0,1.128h6.767a.564.564,0,0,0,0-1.128Z"
        transform="translate(-86.617 -319.519)"
        fill="#fbc320"
      />
      <path
        id="Path_130"
        data-name="Path 130"
        d="M156.075,392h-4.511a.564.564,0,1,0,0,1.128h4.511a.564.564,0,1,0,0-1.128Z"
        transform="translate(-86.617 -377.263)"
        fill="#fbc320"
      />
    </g>
  </svg>
);

export default DocSvg;
