import './styles.scss';

import React from 'react';

import { Typography } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';
import { Link } from 'react-router-dom';

import DynamicWrapper from '../../components/DynamicWrapper/DynamicWrapper';
import DynamicHeader from '../../components/Header/DynamicHeader';
import { CustomRouteConfig } from '../../types/baseTypes';
import {
  HomeRoutes,
  ROUTES,
} from '../../types/routes';
import NoDataFound from '../Feed/components/NoDataFound/NoDataFound';

interface Props {
  route: CustomRouteConfig;
  name: HomeRoutes;
}

const CustomApps: React.FC<Props> = ({ route, name }) => {
  return (
    <DynamicWrapper path={name}>
      <div className="pageRoot">
        <DynamicHeader title={route.shortTitle || route.title} path={name} />
        <div className="pageContent custom-apps__list pageContentList">
          {route.apps && route.apps.length > 0 ? (
            <EasyVirtualizedScroller hasMore={false} useParentScrollElement>
              {route.apps.map((item, index) => (
                <Link
                  to={`${ROUTES.CUSTOM_APPS.replace(
                    ':key',
                    route.key,
                  )}?app=${index}`}
                  key={item.name + item.slug}
                  className="custom-apps__item">
                  {/* <div className="custom-apps__item__icon"></div> */}
                  <div className="custom-apps__item__content">
                    <Typography.Title
                      className="custom-apps__item__title"
                      level={5}>
                      {item.name}
                    </Typography.Title>
                    {item.description && item.description.length > 0 ? (
                      <Typography.Paragraph className="custom-apps__item__description">
                        {item.description}
                      </Typography.Paragraph>
                    ) : null}
                  </div>
                  <div className="itemArrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1">
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                          fill="currentColor"
                          fillRule="nonzero"
                          transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                        />
                      </g>
                    </svg>
                  </div>
                </Link>
              ))}
            </EasyVirtualizedScroller>
          ) : (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                margin: '45px 0',
              }}>
              <NoDataFound title={`No ${route.title} found!`} />
            </div>
          )}
        </div>
      </div>
    </DynamicWrapper>
  );
};

export default React.memo(CustomApps);
