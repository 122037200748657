import './styles.scss';

import React, { useMemo } from 'react';

import { Button, Typography } from 'antd';
import HtmlParser from 'html-react-parser';
import { MdLock } from 'react-icons/md';
import ReactLinkify from 'react-linkify';

import { useTheme } from '../../../context/ThemeProvider';
import { getResourceName } from '../../../shared/utils';
import { Chapter, ICourseExtended } from '../../../types/courseTypes';
import NoDataFound from '../../Feed/components/NoDataFound/NoDataFound';
import CourseCommentsSection from '../CourseCommentsSection/CourseCommentsSection';
import QnASection from '../QNASection/QnASection';
import ResourceItem from '../ResourceItem/ResourceItem';

interface ChapterDetailsProps {
  chapter: Chapter;
  course: ICourseExtended;
  chapterIndex: number;
}

enum Tabs {
  About,
  Resources,
  QnA,
}

const ChapterDetails: React.FC<ChapterDetailsProps> = ({
  chapter,
  course,
  chapterIndex,
}) => {
  const { colors } = useTheme();

  const [selectedTab, setSelectedTab] = React.useState<number>(Tabs.About);

  const selectorItem = (
    value: number,
    name: string,
    style?: React.CSSProperties,
  ): JSX.Element => {
    const selected = value === selectedTab;
    return (
      <Button
        key={value}
        className={`siteBtn tab__selector__item ${selected ? 'selected' : ''}`}
        onClick={() => setSelectedTab(value)}
        style={{
          ...style,
        }}>
        <Typography.Text className="tab__selector__item__text">
          {name}
        </Typography.Text>
      </Button>
    );
  };

  const chapterResources = useMemo(() => {
    if (chapter) {
      if (
        chapter.additionalContentWithDetails &&
        chapter.additionalContentWithDetails.length > 0
      ) {
        return chapter.additionalContentWithDetails.map((res) => ({
          url: res.fileUrl,
          name: res.filename,
        }));
      }
      if (chapter.additionalContent && chapter.additionalContent.length > 0) {
        return chapter.additionalContent.map((res) => ({
          url: res,
          name: getResourceName(res),
        }));
      }
    }
    return [];
  }, [chapter]);

  return (
    <div className="chapter__details" style={{ flex: 1 }}>
      {/* Chapter details */}
      <div className="chapter__details__header">
        <Typography.Title level={4} className="chapter__index">
          {(chapterIndex + 1 > 0 ? `0${chapterIndex + 1}` : chapterIndex + 1) ||
            '01'}
        </Typography.Title>
        <div>
          <Typography.Title level={4} className="chapter__title">
            {chapter.title}
          </Typography.Title>
          <Typography.Title level={5} className="chapter__subtitle">
            in{' '}
            {
              course.modules.find((item) =>
                item.chapters.findIndex((ch) => ch._id === chapter?._id) > -1
                  ? true
                  : false,
              )?.title
            }
          </Typography.Title>
        </div>
      </div>

      {/* Tab options (About, Resources and QnA) */}
      <div className="tab__selector">
        {selectorItem(Tabs.About, 'About')}
        {selectorItem(Tabs.Resources, 'Resources', {
          marginLeft: 8,
        })}
        {!course.blockQna
          ? selectorItem(Tabs.QnA, 'Questions', {
              marginLeft: 8,
            })
          : null}
      </div>

      {/* Tab content */}
      {chapter.isLocked || (course.paidCourse && !chapter.isFreeChapter) ? (
        <div className="tab__content">
          <div className="tab__content__locked">
            <MdLock size={100} color={colors.SECONDARY} />
          </div>
        </div>
      ) : (
        <div className="tab__content">
          {selectedTab === Tabs.About && (
            <>
              {chapter.description ? (
                <div>
                  <Typography.Title
                    level={4}
                    className="chapter__section__title">
                    Description
                  </Typography.Title>
                  <Typography.Paragraph
                    className="tab__content__about"
                    ellipsis={{
                      rows: 3,
                      expandable: true,
                      symbol: 'See more',
                    }}>
                    <ReactLinkify>
                      {HtmlParser(chapter.description)}
                    </ReactLinkify>
                  </Typography.Paragraph>
                </div>
              ) : null}
              {!course.blockComment ? (
                <CourseCommentsSection
                  chapterId={chapter._id}
                  creator={course.creator}
                />
              ) : null}
              {!chapter.description && course.blockComment ? (
                <NoDataFound title="No description found!" />
              ) : null}
            </>
          )}
          {selectedTab === Tabs.Resources &&
            (chapterResources.length !== 0 ? (
              <div>
                <Typography.Title level={4} className="chapter__section__title">
                  {chapterResources.length} resources
                </Typography.Title>
                {chapterResources.map((res, index) => (
                  <ResourceItem
                    key={index}
                    onClick={() => {
                      window.open(res.url, '_blank');
                    }}
                    name={res.name}
                    url={res.url}
                    style={{
                      backgroundColor: colors.BACKGROUND,
                      borderBottom: `1px solid ${colors.GRAY_LIGHTER}`,
                    }}
                  />
                ))}
              </div>
            ) : (
              <NoDataFound title="No resources found!" />
            ))}
          {selectedTab === Tabs.QnA && (
            <QnASection chapterId={chapter._id} creator={course.creator} />
          )}
        </div>
      )}
    </div>
  );
};
export default ChapterDetails;
