import * as React from 'react';
import { useState } from 'react';

import { IoChevronForward } from 'react-icons/io5';
import { BottomSheet } from 'react-spring-bottom-sheet';

import ExpiredSvg from '../../../../assets/svg/ExpiredSvg';
import Header from '../../../../components/Header/Header';
import Rupee from '../../../../components/Rupee/Rupee';
import { useTheme } from '../../../../context/ThemeProvider';
import { useAppSelector } from '../../../../shared/hooks';
import { newNumberFormat } from '../../../../shared/utils';
import RenewMangoTile from './RenewMangoTile';
import { IRenewMango } from './types';

interface Props {
  renewMangoes: IRenewMango[];
}

const RenewMangoes: React.FC<Props> = ({ renewMangoes }) => {
  const { colors } = useTheme();

  const {
    hostMetadata: { offeringTitle, offeringTitles },
    deeplinkUrl,
  } = useAppSelector((state) => state.app);

  const [showModal, setShowModal] = useState(false);

  const redirectToMangoCheckout = (mangoId: string) => {
    let url = `${deeplinkUrl}web/checkout/${mangoId}`;
    url += '?purchaseNow=true';
    window.open(url, '_blank');
  };

  const onClick = () => {
    if (renewMangoes.length > 1) {
      setShowModal(true);
    } else {
      redirectToMangoCheckout(renewMangoes[0].mango._id);
    }
  };

  return (
    <>
      <div className={`feed-card danger`} onClick={onClick}>
        <div className="feed-card__icon-bar">
          <ExpiredSvg />
        </div>
        <div className="feed-card__details-wrapper compact">
          <div className="feed-card-details">
            <div className="feed-card__header">
              {offeringTitle.toTitleCase()} Expired!
            </div>

            {renewMangoes.length > 1 ? (
              <div className="feed-card__title">
                {renewMangoes.length} {offeringTitles} have expired. Renew now!
              </div>
            ) : (
              <>
                <div className="feed-card__title">
                  <b>{renewMangoes[0].mango.title.toTitleCase()}</b>
                </div>

                <div className="feed-card__subtitle">
                  Renew now for <Rupee />
                  {newNumberFormat(renewMangoes[0].mango.price)}
                </div>
              </>
            )}
          </div>

          <div className="feed-card__arrow">
            <IoChevronForward size={24} color={colors.ICON} />
          </div>
        </div>
      </div>
      <BottomSheet
        open={showModal}
        onDismiss={() => setShowModal(false)}
        header={
          <Header
            canGoBack={false}
            title={`Renew ${offeringTitles}`}
            noBorder
          />
        }>
        {renewMangoes.map((renewMango) => (
          <RenewMangoTile
            key={renewMango.mango._id}
            renewMango={renewMango}
            onClick={() => {
              redirectToMangoCheckout(renewMango.mango._id);
              setShowModal(false);
            }}
          />
        ))}
      </BottomSheet>
    </>
  );
};

export default React.memo(RenewMangoes);
