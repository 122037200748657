import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {
  Avatar,
  Button,
  Space,
  Typography,
} from 'antd';
import { IoClose } from 'react-icons/io5';
import { MdSend } from 'react-icons/md';
import {
  Mention,
  MentionsInput,
  MentionsInputProps,
  SuggestionDataItem,
} from 'react-mentions';

import FeedAPI from '../../../../api/FeedAPI';
import Loader from '../../../../components/Loader';
import { useTheme } from '../../../../context/ThemeProvider';
import showAppError from '../../../../shared/error';
import { useAppSelector } from '../../../../shared/hooks';
import { ICommentSelected } from '../../../../types/commentTypes';

interface Props {
  sending: boolean;
  postId: string;
  onComment: (comment: string) => void;

  reply: ICommentSelected;
  onReplyClose: () => void;
}

export interface PostCommentInputRefProps {
  clearInput: () => void;
  focusInput: () => void;
}

const PostCommentInput: React.ForwardRefRenderFunction<
  PostCommentInputRefProps,
  Props
> = ({ sending, postId, onComment, reply, onReplyClose }, ref) => {
  const userDetails = useAppSelector((state) => state.user);

  const { colors } = useTheme();

  const [comment, setComment] = useState('');
  const suggestionContainerRef = useRef<HTMLDivElement>(null);
  const [emptySearchResults, setEmptySearchResults] = useState<string[]>([]);

  const inputRef = useRef<React.Component<MentionsInputProps>>(null);

  const postComment = () => {
    if (!comment.trim()) return;

    onComment(comment);
  };

  const updateCommentValue = (
    _: { target: { value: string } },
    newValue: string,
  ) => {
    setComment(newValue);
  };

  const onMentionSearch = async (
    searchText: string,
    callback: (arg0: { id: string; display: string }[]) => void,
  ) => {
    if (searchText.length === 0) {
      callback([]);
      return;
    }
    if (emptySearchResults.find((item) => searchText.startsWith(item))) {
      callback([]);
      return;
    }
    try {
      const resp = await FeedAPI.searchMentionedUser(searchText, postId);
      if (resp.status === 200) {
        if (resp.data.result.length === 0) {
          setEmptySearchResults((prev) => [...prev, searchText]);
        }
        callback(
          resp.data.result.map((user) => ({
            id: user._id,
            display: user.name,
            profilePicUrl: user.profilePicUrl,
          })),
        );
      } else {
        showAppError(resp.data);
      }
    } catch (error) {
      showAppError(error);
    }
  };

  useImperativeHandle(ref, () => ({
    focusInput: () => {
      if (inputRef.current) {
      }
    },
    clearInput: () => {
      setComment('');
    },
  }));

  useEffect(() => {
    if (reply.userId) {
      setComment(`{{${reply.userId}#${reply.userName}}} `);
    } else {
      setComment('');
    }
  }, [reply]);

  return (
    <div className="postInputv2Container">
      {reply.userId ? (
        <div className="postInputv2__reply">
          <div className="postInputv2__reply__content">
            <Typography.Text type="secondary">Replying to</Typography.Text>
            <Typography.Title level={5}>{reply.userName}</Typography.Title>
          </div>
          <div className="postInputv2__reply__close">
            <Button size="small" type="text" onClick={onReplyClose}>
              <IoClose size={24} color={colors.TEXT3} />
            </Button>
          </div>
        </div>
      ) : null}
      <div className="postInputv2__mentions" ref={suggestionContainerRef} />
      <div className="postInputv2Wrapper">
        <Avatar
          src={userDetails.profilePic}
          alt="Author"
          size={36}
          style={{
            flexShrink: 0,
          }}
        />
        <MentionsInput
          className="postInputv2"
          placeholder="Write a comment..."
          singleLine={false}
          allowSpaceInQuery
          ref={inputRef}
          suggestionsPortalHost={suggestionContainerRef.current ?? undefined}
          value={comment}
          onChange={updateCommentValue}>
          <Mention
            trigger="@"
            data={onMentionSearch}
            markup="{{__id__#__display__}}"
            displayTransform={(_: string, display: string) => `@${display}`}
            renderSuggestion={(
              suggestion: SuggestionDataItem,
              _: string,
              highlightedDisplay: React.ReactNode,
            ) => (
              <div className="mention-suggestion">
                <Space size={8}>
                  <Avatar
                    /* @ts-ignore */
                    src={suggestion.profilePicUrl}
                    alt="image"
                    size={32}
                    style={{ flexShrink: 0 }}
                  />
                  <Typography.Text className="mention-suggestion__name">
                    {highlightedDisplay}
                  </Typography.Text>
                </Space>
              </div>
            )}
          />
        </MentionsInput>
        <Button
          disabled={sending}
          className="postInputv2__sendIcon"
          onClick={postComment}>
          {sending ? (
            <Loader size="small" />
          ) : (
            <MdSend size={20} color={colors.BACKGROUND} />
          )}
        </Button>
      </div>
    </div>
  );
};

export default forwardRef(PostCommentInput);
