import * as React from 'react';

import { useTheme } from '../../context/ThemeProvider';

const CopyLinkSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg
      width={21}
      height={21}
      viewBox="0 0 14.737 19.43"
      {...props}
      >
      <path
        id="_003-link"
        data-name="003-link"
        d="M12.948,9.774a2.225,2.225,0,0,1,0,3.148L7.5,18.354a2.234,2.234,0,0,1-3.151,0l-3.2-3.187a2.225,2.225,0,0,1,0-3.15L1.172,12l.677-.631a.742.742,0,0,1,1.012,1.086l-.666.621a.74.74,0,0,0,0,1.04L5.4,17.3a.747.747,0,0,0,1.054,0L11.9,11.872a.741.741,0,0,0,0-1.049L8.726,7.633a.747.747,0,0,0-1.052,0l-.747.753A.742.742,0,0,1,5.873,7.343l.75-.755a2.234,2.234,0,0,1,3.154,0ZM14.43,3.838,11.237.65a2.234,2.234,0,0,0-3.151,0L2.638,6.075a2.225,2.225,0,0,0,0,3.15l3.194,3.188a2.233,2.233,0,0,0,3.149,0l.762-.755A.743.743,0,0,0,8.7,10.6l-.765.757a.747.747,0,0,1-1.054,0l-3.2-3.182a.74.74,0,0,1,0-1.049L9.134,1.7a.747.747,0,0,1,1.054,0l3.193,3.188A.74.74,0,0,1,13.4,5.922l-.654.567a.742.742,0,1,0,.972,1.121l.677-.587.038-.036a2.225,2.225,0,0,0,0-3.15Zm0,0"
        transform="translate(-0.425 0.213)"
        fill={colors.ICON}
        stroke={colors.ICON}
        strokeWidth="0.4"
      />
    </svg>
  );
};

export default CopyLinkSvg;
