import React, { useMemo } from 'react';

import { Avatar, Button, message, Typography } from 'antd';
import moment from 'moment';
import { IoRefreshCircle } from 'react-icons/io5';
import { MdCancel, MdChevronRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

import UserAPI from '../../../api/UserAPI';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal/ConfirmationModal';
import { useTheme } from '../../../context/ThemeProvider';
import { newNumberFormat } from '../../../shared/utils';
import { ISubscription } from '../../../types/userTypes';

// import { getUser } from '../../../utils/localStorage';

interface Props {
  subscription: ISubscription;
  reloadSubscriptions: () => void;
}

const Subscription: React.FC<Props> = ({
  subscription,
  reloadSubscriptions,
}) => {
  const { colors } = useTheme();

  const [isDialogVisible, setIsDialogVisible] = React.useState(false);

  const currencySymbol = () => {
    if (subscription?.orders?.[0]?.subscriberCurrency === 'EUR') {
      return <span className="inr">&#x20AC;</span>;
    }
    if (subscription?.orders?.[0]?.subscriberCurrency === 'INR') {
      return <span className="inr">&#8377;</span>;
    }
    if (subscription?.orders?.[0]?.subscriberCurrency === 'USD') {
      return <span className="inr">&#x24;</span>;
    }
    return <span className="inr">&#8377;</span>;
  };

  const onClickCancelSubscription = () => {
    const m = message.loading('Cancelling subscription...', 0);
    UserAPI.cancelSubscription(subscription._id)
      .then((res) => {
        if (res.status === 200) {
          m();
          message.success('Subscription cancelled successfully');
          reloadSubscriptions();
        }
      })
      .catch((error) => {
        console.log(error);
        // showAppError(error);
      })
      .finally(() => {
        m();
      });
  };

  const handleDownlodInvoice = (invoice?: string) => {
    if (invoice) window.open(invoice, '_blank');
    else {
      message.error('Invoice not available');
    }
  };

  const isExpired =
    subscription.status === 'expired' ||
    subscription.status === 'manualexpired';

  const expiryText = useMemo(() => {
    if (moment(subscription.expiredAt) < moment()) {
      return `Expired on date ${moment(subscription.expiredAt).format(
        'MMM DD, YYYY',
      )}`;
    }
    if (
      subscription.mango?.recurringType === 'onetime' &&
      subscription.expiredAt &&
      !moment(subscription.expiredAt)?.isAfter(
        moment('2029-12-31T12:54:14.987Z').startOf('year'),
      )
    ) {
      return `Valid till ${moment(subscription.expiredAt).format(
        'MMM DD, YYYY',
      )}`;
    }
    if (
      // moment(subscription.expiredAt).diff(moment(), 'days') < 400 &&
      subscription.mango?.recurringType !== 'onetime' &&
      ['active', 'manualactive']?.includes(subscription.status)
    ) {
      if (!subscription.autopayEnabled) {
        return `Next due date ${moment(subscription.expiredAt).format(
          'MMM DD, YYYY',
        )}`;
      }
      if (subscription.nextChargeAt) {
        return `Next due date ${moment(subscription.expiredAt).format(
          'MMM DD, YYYY',
        )}`;
      }
    }
    return `Purchased on ${moment(subscription.subscribedAt).format(
      'MMM DD, YYYY',
    )}`;
  }, [subscription]);

  return (
    <>
      <div className="subscription">
        <Link to="#" className="creatorDetails">
          <Avatar
            src={subscription?.profilePicUrl}
            size={32}
            style={{
              flexShrink: 0,
            }}
          />
          <Typography.Title
            className="creatorName"
            ellipsis={{
              rows: 1,
              expandable: false,
            }}>
            {subscription?.name}
          </Typography.Title>
        </Link>
        <div className="tier">
          <div className="tierOverview">
            <Typography.Text className="tierName" ellipsis>
              {subscription.mango.title}
            </Typography.Text>
            <Typography.Paragraph
              className="tierDetails"
              ellipsis={{ rows: 2, expandable: false, symbol: '...' }}>
              {subscription.mango.description}
            </Typography.Paragraph>
          </div>
          <div className="tierPricing">
            <div className="amount">
              {currencySymbol()}
              {newNumberFormat(
                subscription?.orders?.[0]?.amount ||
                  subscription?.orders?.[0]?.amount === 0
                  ? subscription?.orders?.[0]?.amount
                  : subscription?.mango?.price,
              )}
            </div>
            <span className="paymentCycle">
              {subscription?.mango?.recurringType === 'onetime'
                ? 'one time'
                : subscription?.mango?.recurringType}
            </span>
            {(subscription.status === 'active' ||
              subscription.status === 'manualactive') &&
            subscription.mango.recurringType !== 'onetime' ? (
              <div
                className={`autopay ${
                  subscription?.autopayEnabled ? 'autopayEnabled' : ''
                }`}>
                <IoRefreshCircle color={colors.BACKGROUND} size={14} />
                AutoPay
              </div>
            ) : null}
          </div>
        </div>
        <div className="subscriptionFooter">
          {subscription?.orders?.[0]?.invoice && (
            <Button
              className="siteBtn siteBtnLink green"
              onClick={() =>
                handleDownlodInvoice(subscription?.orders?.[0]?.invoice)
              }>
              Download invoice
              <MdChevronRight color={colors.GREEN} size={22} />
            </Button>
          )}
          <span className="expiryDetails">
            <Typography.Text
              className="expiryDate"
              style={{ color: isExpired ? colors.DANGER : colors.BLUE }}>
              {expiryText}
            </Typography.Text>
          </span>
        </div>
        {(subscription.status === 'active' ||
          subscription.status === 'manualactive') &&
        subscription.mango.recurringType !== 'onetime' &&
        subscription.autopayEnabled ? (
          <Button
            className="siteBtn siteBtnDanger red cancelSubscription"
            onClick={() => {
              setIsDialogVisible(true);
            }}>
            Cancel
            <MdCancel color={colors.DANGER} size={16} />
          </Button>
        ) : null}
      </div>
      <ConfirmationModal
        title="Cancel"
        open={isDialogVisible}
        handleOk={onClickCancelSubscription}
        handleCancel={() => {
          setIsDialogVisible(false);
        }}
        message="Do you really want to cancel the subscription?"
        okayButtonText="Yes"
        cancelButtonText="No"
      />
    </>
  );
};

export default Subscription;
