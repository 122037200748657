import './styles.scss';

import React, { useMemo } from 'react';

import { Avatar, Modal, Typography } from 'antd';

import CloseModal from '../../../../assets/svg/CloseModal';
import TickSvg from '../../../../assets/svg/TickSvg';
import { IDetailsType } from '../../../../types/userTypes';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  subscriptions: any[] | null;
  userDetails: IDetailsType;
  selected: string | null;
  setSelected: (arg: string) => void;
}

const modalMaskStyle: React.CSSProperties = {
  backgroundColor: 'rgba(244, 244, 244, 0.8)',
};

const ManageCreators: React.FC<Props> = ({
  showModal,
  closeModal,
  subscriptions,
  setSelected,
  selected,
  userDetails,
}) => {
  const getCompressedName = useMemo(() => {
    if (subscriptions && subscriptions.length > 0) {
      let str = subscriptions[0].name?.split(' ')[0] || '';
      if (subscriptions.length > 1) {
        str += `, ${subscriptions.length - 1} more`;
      }
      return str;
    }
    return '';
  }, [subscriptions]);

  const getCompressedIcon = useMemo(() => {
    if (subscriptions && subscriptions.length > 0) {
      if (subscriptions.length > 1) {
        const picture1 = subscriptions[0].profilePicUrl;
        const picture2 = subscriptions[1].profilePicUrl;
        return (
          <div className="compressedIconWrapper">
            <Avatar size={26} src={picture1} />
            <Avatar size={26} src={picture2} />
          </div>
        );
      }
      return <Avatar size={36} src={subscriptions[0].profilePicUrl} />;
    }
    return null;
  }, [subscriptions]);

  return (
    <Modal
      title={
        <>
          <div className="titleWrapper">
            <Typography.Title
              level={4}
              className="title"
              style={{ textAlign: 'left' }}>
              Manage Creators
            </Typography.Title>
          </div>
          {subscriptions && subscriptions.length > 1 ? (
            <div
              className="manageCreatorsModal__item header"
              onClick={() => setSelected('all')}>
              {getCompressedIcon}
              <Typography.Title
                className="manageCreatorsModal__item__name"
                ellipsis={{ rows: 1, expandable: false }}
                level={5}
                style={{
                  flex: 1,
                  marginLeft: 12,
                }}>
                {getCompressedName}
              </Typography.Title>
              {selected === 'all' && (
                <TickSvg style={{ marginLeft: 12 }} width={20} height={20} />
              )}
            </div>
          ) : null}
        </>
      }
      open={showModal}
      onOk={closeModal}
      onCancel={closeModal}
      wrapClassName="manageCreatorsModal"
      maskStyle={modalMaskStyle}
      centered
      closeIcon={<CloseModal />}
      footer={null}
      width={475}>
      {subscriptions?.map((item) => (
        <div
          key={item.creatorId}
          className="manageCreatorsModal__item"
          onClick={() => setSelected(item.creatorId)}>
          <Avatar
            size={36}
            src={
              item.creatorId === userDetails.id
                ? userDetails.profilePic
                : item.profilePicUrl
            }
          />
          <Typography.Title
            className="manageCreatorsModal__item__name"
            level={5}
            ellipsis={{ rows: 1, expandable: false }}
            style={{
              flex: 1,
              marginLeft: 12,
            }}>
            {item.creatorId === userDetails.id ? 'Me' : item.name}
          </Typography.Title>
          {selected === item.creatorId && (
            <TickSvg style={{ marginLeft: 12 }} width={20} height={20} />
          )}
        </div>
      ))}
    </Modal>
  );
};

export default React.memo(ManageCreators);
