/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMango } from '../types/feedTypes';
import { IUser, UAMPermissions } from '../types/userTypes';

export const checkForPermissions = (
  permissions: Array<UAMPermissions>,
  actualList?: Array<UAMPermissions>,
  type: 'or' | 'and' = 'or',
) => {
  if (actualList && actualList.length > 0) {
    const result = permissions.filter((permission) =>
      actualList.includes(permission),
    );
    return type === 'or'
      ? result.length > 0
      : result.length === permissions.length;
  }
  return false;
};

interface IUserSliceState extends IUser {
  creatorMangoes: IMango[];
  subscribedMangoes: IMango[];
}

const initialState: IUserSliceState = {
  id: null,
  name: null,
  type: null,
  email: null,
  phone: null,
  profilePic:
    'https://tagmango.com/staticassets/avatar-placeholder.png-1612857612139.png',
  userSlug: null,
  showInbox: null,
  approveStatus: null,
  shortUrl: null,
  creatorMangoes: [],
  subscribedMangoes: [],
  gstNumber: undefined,
  currency: 'INR',
  tmCommission: 0,
  country: undefined,
  countryCode: 'IN',
  isEmailVerified: true,
  requiresPostMigrationVerification: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<Partial<IUser>>) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    },
    setCreatorMangoes: (state, action: PayloadAction<any[]>) => {
      const newList = action.payload.map((item) => ({
        ...item,
      }));
      state.creatorMangoes = newList;
    },
    // setSubscriberMangoes: (state, action: PayloadAction<any[]>) => {
    //   const mangoMap: ISubscriberMangoes = {};
    //   action.payload.forEach((mangoDetails) => {
    //     const val = mangoMap[mangoDetails.creator._id as string];
    //     if (val)
    //       mangoMap[mangoDetails.creator._id] = [...val, mangoDetails.mango];
    //     else mangoMap[mangoDetails.creator._id] = [mangoDetails.mango];
    //   });
    //   state.subscriberMangoes = mangoMap;
    // },
    setSubscribedMangoes: (state, action: PayloadAction<IMango[]>) => {
      const newList = action.payload.map((item) => ({
        ...item,
      }));
      state.subscribedMangoes = newList;
    },
    clearUserDetails: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setUserDetails,
  setCreatorMangoes,
  setSubscribedMangoes,
  clearUserDetails,
} = userSlice.actions;

export default userSlice.reducer;
