import './styles.scss';

import React, {
  useCallback,
  useEffect,
} from 'react';

// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';
import moment from 'moment';

import API from '../../api';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader';
import showAppError from '../../shared/error';
import NoDataFound from '../Feed/components/NoDataFound/NoDataFound';
import NotificationItem from './NotificationItem';

const title = 'Notifications';

const Notifications: React.FC = () => {
  const [state, setState] = React.useState({
    notificationList: [] as any[],
    page: 0,
    perPage: 10,
    isCompleted: false,
  });
  const [loading, setLoading] = React.useState(false);

  const loadData = async (page: number = state.page + 1) => {
    try {
      setLoading(true);
      if (!state.isCompleted) {
        const request = {
          perpage: state.perPage,
          page: page,
        };
        const notifyResp = await API.getNotifications(request);
        if (notifyResp.status === 200) {
          if (request.page === 1) {
            state.notificationList = [];
          }
          Array.prototype.push.apply(
            state.notificationList || [],
            notifyResp.data.result,
          );
          state.page = request.page;
          if (notifyResp.data.result.length < 10) {
            state.isCompleted = true;
          }
          setState({ ...state });
        } else {
          showAppError(notifyResp.data);
        }
      }
    } catch (err) {
      showAppError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationClick = (id: string) => {
    API.markNotificationAsRead(id)
      .then((res) => {
        if (res.status === 200 || res.data.code === 0) {
          const notificationList = state.notificationList.map(
            (notification) => {
              if (notification._id === id) {
                const newNotification = { ...notification, read: true };
                return newNotification;
              }
              return notification;
            },
          );
          setState((prev) => ({ ...prev, notificationList }));
        } else {
          showAppError(res.data);
        }
      })
      .catch((error: any) => {
        showAppError(error);
      });
  };

  const generateLink = useCallback((notification: any) => {
    let toSetlink = '/activity';

    if (notification.type === 'payment_transfer') {
      toSetlink = '/account/earnings';
    }
    if (
      notification.type === 'videocall_request' ||
      notification.type === 'videocall_accepted'
    ) {
      toSetlink = '/video-call';
    }
    if (notification.type === 'new_message') {
      toSetlink = '/messages';
    }
    if (notification.type === 'new_post_update') {
      toSetlink = '/activity';
    }
    if (notification.type === 'likes_comments') {
      toSetlink = '/activity';
    }
    if (notification?.type === 'campaign') {
      toSetlink = '/activity';
    }
    if (notification?.type === 'new_content') {
      toSetlink = '/activity';
    }
    if (notification.type === 'new_subscription') {
      toSetlink = '/account/subscribers';
    }
    if (notification.type === 'webinar_created') {
      toSetlink = '/video-call';
    }
    if (notification.type === 'before_webinar') {
      toSetlink = '/video-call';
    }
    if (notification.type === 'webinar_created') {
      toSetlink = '/video-call';
    }
    if (notification.type === 'webinar_started') {
      toSetlink = '/video-call';
    }
    if (notification.type === 'mention') {
      toSetlink = `/post/${notification.deeplink.split('#')[0]}?commentId=${
        notification.deeplink.split('#')[1]
      }`;
    }
    if (notification.type === 'new_comment') {
      toSetlink = `/post/${notification.deeplink.split('#')[0]}?commentId=${
        notification.deeplink.split('#')[1]
      }`;
    }

    return toSetlink;
  }, []);

  useEffect(() => {
    API.storeLastNotificationOpenTime(new Date());
  }, []);

  return (
    <div className="pageRoot">
      <Header title={title} />
      <div className="pageContent">
        <EasyVirtualizedScroller
          hasMore={!state.isCompleted}
          useParentScrollElement
          onLoadMore={loadData}>
          {state.notificationList.map((item, index) => (
            <div
              key={item._id}
              className="notificationItemWrapper"
              style={
                index !== 0
                  ? {
                      borderTopWidth: 1,
                    }
                  : {}
              }>
              <NotificationItem
                title={item.text}
                date={moment(item.createdAt).format('LLL')}
                isRead={item.read}
                onPress={() => {
                  handleNotificationClick(item._id);
                }}
                link={generateLink(item)}
              />
            </div>
          ))}
        </EasyVirtualizedScroller>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            margin: '45px 0',
          }}>
          {loading ? (
            <Loader />
          ) : (
            <NoDataFound title="That’s it! No more notifications to show." />
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
