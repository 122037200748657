/* eslint-disable no-use-before-define */
import {
  CreatorTierType,
  OnboardingStatus,
  WhitelabelPlanType,
} from '../userTypes';

export interface IEventUserDetails {
  name: string;
  email: string;
  phone: number;
  onboarding: OnboardingStatus;
  host: string;
  whitelabelPlanType: WhitelabelPlanType | 'free';
  userTier: CreatorTierType;
  score: number;
  status: 'active' | 'deactivated';
}

export interface IEventCommonProperties {
  host: string;
  name: string;
  email: string;
  phone: number;
  segment?: PlatformSegmentType;
}

export enum PlatformSegment {
  LOGIN = 'login',
  SIGNUP = 'signup',
  CHECKOUT = 'checkout',
  DASHBOARD = 'dashboard',
  FEED = 'feed',
  COURSES = 'courses',
  WORKSHOPS = 'workshops',
  MESSAGES = 'messages',
  LEVELUP = 'levelup',
}

export type PlatformSegmentType = `${PlatformSegment}`;
