import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '../shared/hooks';
import { ROUTES } from '../types/routes';

interface PrivateRouteProps {
  redirectTo?: string;
  reverse?: boolean;
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  redirectTo = ROUTES.LOGIN,
  reverse = false,
}) => {
  const location = useLocation();
  const { isLoggedIn } = useAppSelector((state) => state.app);
  const { type } = useAppSelector((state) => state.user);

  if (isLoggedIn && type === 'started')
    return (
      <Navigate
        to={ROUTES.REGISTER}
        replace
        state={{
          redirectTo: location.pathname,
          search: location.search,
          ...(location.state || {}),
        }}
      />
    );

  if (reverse ? !isLoggedIn : isLoggedIn) {
    return children;
  }

  return (
    <Navigate
      to={redirectTo}
      replace
      state={{
        redirectTo: location.pathname,
        search: location.search,
        ...(location.state || {}),
      }}
    />
  );
};

export default React.memo(PrivateRoute);
