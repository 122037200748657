import './styles.scss';

import React from 'react';

import { Typography } from 'antd';
import { MdArrowForward } from 'react-icons/md';

import { useTheme } from '../../../context/ThemeProvider';

interface Props {
  name: string;
  url: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const ResourceItem: React.FC<Props> = ({ name, url, style, onClick }) => {
  const { colors } = useTheme();
  // const { cookie } = useAppSelector((state) => state.app);
  return (
    <div
      className="resource-item"
      style={style}
      onClick={() => {
        if (onClick) onClick();
      }}>
      <svg width={27} height={36} viewBox="0 0 26.999 36.102">
        <g id="pdf" transform="translate(-99.773 0)">
          <g id="Vrstva_x0020_1_15_" transform="translate(99.773 0)">
            <path
              id="Path_1194"
              data-name="Path 1194"
              d="M104.473,0h12.07l10.23,10.663V31.4a4.7,4.7,0,0,1-4.691,4.7H104.473a4.7,4.7,0,0,1-4.7-4.7V4.7a4.7,4.7,0,0,1,4.7-4.7Z"
              transform="translate(-99.773 0)"
              fill={colors.SECONDARY}
              fillRule="evenodd"
            />
            <g
              id="Group_4819"
              data-name="Group 4819"
              transform="translate(5.223 0)">
              <path
                id="Path_1195"
                data-name="Path 1195"
                d="M467.219,0V10.582h10.239Z"
                transform="translate(-455.681)"
                fill={colors.BACKGROUND}
                fillRule="evenodd"
                opacity="0.302"
              />
            </g>
          </g>
        </g>
      </svg>
      <div className="resource-item__content">
        <Typography.Title
          className="resource-item__content__title"
          level={4}
          ellipsis={{ rows: 1, expandable: false }}>
          {name.split('.')[0]}
        </Typography.Title>
        <Typography.Title
          level={5}
          className="resource-item__content__subtitle"
          ellipsis={{ rows: 1, expandable: false }}
          style={{ textTransform: 'uppercase' }}>
          {url.split('/').pop()?.split('.').pop()}
        </Typography.Title>
      </div>
      <div
        className="resource-item__action"
        style={{ backgroundColor: `${colors.BLUE}22` }}>
        <MdArrowForward color={colors.BLUE} size={22} />
      </div>
    </div>
  );
};

export default ResourceItem;

// Previous pdf download logic

// downloadFile({
//   downloadUrl: url,
//   fileName:
//     url.split('/').pop() || `course_resource_${nanoid(4)}.pdf`,
//   mediaType: 'doc',
//   rootFolder: 'course',
//   progress: (progress) => {
//     console.log('download progress here', progress);
//   },
//   cookie,
// });
