import React, { useMemo } from 'react';

interface Props {
  className?: string;
  currency?: string;
}

const Rupee: React.FC<Props> = ({ className = '', currency }) => {
  const currencySymbol = useMemo(() => {
    switch (currency) {
      case 'EUR':
        return '€';
      case 'INR':
        return '₹';
      case 'USD':
        return '$';
      default:
        return '₹';
    }
  }, [currency]);

  return <span className={`inr ${className}`}>{currencySymbol}</span>;
};

export default React.memo(Rupee);
