import mixpanel from 'mixpanel-browser';

import {
  IEventCommonProperties,
  IEventUserDetails,
  PlatformSegment,
  PlatformSegmentType,
} from '../types/analyticsEventsTypes/common';
import { SignupEvents } from '../types/analyticsEventsTypes/signup';
import { WorkshopEvents } from '../types/analyticsEventsTypes/workshop';
import config from './config';

export default class AnalyticsEventsAPI {
  static init = () => {
    mixpanel.init(config.MIXPANEL_TOKEN as string, {
      debug: false,
      track_pageview: true,
    });
  };

  static registerUser = (userId: string, userDetails: IEventUserDetails) => {
    mixpanel.identify(userId);
    mixpanel.people.set({
      $name: userDetails.name,
      $email: userDetails.email,
      $phone: userDetails.phone,
      onboarding: userDetails.onboarding,
      host: userDetails.host,
      whitelabelPlanType: userDetails.whitelabelPlanType,
      userTier: userDetails.userTier,
      score: userDetails.score,
    });
  };

  static addCommonEventProperties = (properties: IEventCommonProperties) => {
    mixpanel.register(properties);
  };

  // signup events
  static trackSignupEvents = <Key extends keyof SignupEvents>(
    key: Key,
    value: SignupEvents[Key],
    type: PlatformSegmentType = PlatformSegment.SIGNUP,
  ) => {
    mixpanel.track(key, {
      segment: type,
      ...value,
    });
  };

  // workshop events
  static trackWorkshopEvents = <Key extends keyof WorkshopEvents>(
    key: Key,
    value: WorkshopEvents[Key],
    type: PlatformSegmentType = PlatformSegment.WORKSHOPS,
  ) => {
    mixpanel.track(key, {
      segment: type,
      ...value,
    });
  };
}
