import './styles.scss';

import React from 'react';

import {
  Progress,
  Typography,
} from 'antd';

import CompressingVideo
  from '../../../../components/VideoPlayer/CompressingVideo';
import { IProgressData } from '../../../../types/feedTypes';
import PostContent from '../../Posts/PostItem/PostContent';
import PostHeader from '../../Posts/PostItem/PostHeader';

type Props = {
  type?: 'activity' | null;
};

const UploadingComponent: React.FC<IProgressData & Props> = ({
  message,
  total,
  loaded,
  type,
  post,
  showProgress,
}) => {
  return showProgress ? (
    <div className="uploadingComponent">
      <div className="uploadingComponentContent">
        <Typography.Text className="uploadingComponentContentTitle">
          {message}
        </Typography.Text>
        <Progress
          percent={Math.round((loaded / total) * 100)}
          showInfo={false}
        />
      </div>
      {type === 'activity' && post ? (
        <div className="creatorPostsWrapper feedPost">
          <PostHeader post={post} mode={null} />
          {post?.caption ? (
            <div style={{ paddingBottom: 12 }}>
              <PostContent
                maxLines={post?.contentType !== 'text' ? 2 : 5}
                caption={post.caption}
              />
            </div>
          ) : null}
          {post?.contentType !== 'text' ? (
            <div className="postVideoWrapper">
              <div className="player-wrapper-drm">
                <CompressingVideo
                  title="Your media is being uploaded"
                  subtitle="Please enjoy other content while we make it ready for you."
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  ) : null;
};

export default UploadingComponent;
