import React from 'react';

import { useTheme } from '../../context/ThemeProvider';

const CloseRedSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg width={18.692} height={18.687} viewBox="0 0 18.692 18.687" {...props}>
      <g id="cancel" transform="translate(0.175 0.105)">
        <g id="Group_4331" data-name="Group 4331" transform="translate(0 0.07)">
          <g id="Group_4330" data-name="Group 4330" transform="translate(0 0)">
            <path
              id="Path_786"
              data-name="Path 786"
              d="M15.66,2.752a9.169,9.169,0,1,0,0,12.973A9.185,9.185,0,0,0,15.66,2.752Zm-1,11.968a7.752,7.752,0,1,1,0-10.963A7.762,7.762,0,0,1,14.655,14.72Z"
              transform="translate(0 -0.07)"
              fill={colors.DANGER}
              stroke={colors.DANGER}
              strokeWidth={0.35}
            />
          </g>
        </g>
        <g
          id="Group_4333"
          data-name="Group 4333"
          transform="translate(5.991 5.971)">
          <g id="Group_4332" data-name="Group 4332">
            <path
              id="Path_787"
              data-name="Path 787"
              d="M173.48,170.044l-2.012-2.009,2.012-2.009a.711.711,0,0,0-1-1.005l-2.013,2.01-2.013-2.01a.711.711,0,1,0-1,1.005l2.012,2.009-2.012,2.009a.711.711,0,0,0,1,1.005l2.013-2.01,2.013,2.01a.711.711,0,1,0,1-1.005Z"
              transform="translate(-167.236 -164.813)"
              fill={colors.DANGER}
              stroke={colors.DANGER}
              strokeWidth={0.35}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CloseRedSVG;
