import './styles.scss';

import React, { useEffect, useMemo, useState } from 'react';

import { Badge, Button, Typography } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';
import { MdAdd } from 'react-icons/md';

import DynamicWrapper from '../../components/DynamicWrapper/DynamicWrapper';
import DynamicHeader from '../../components/Header/DynamicHeader';
import Loader from '../../components/Loader';
import { useTheme } from '../../context/ThemeProvider';
import {
  fetchMoreDMRooms,
  fetchMoreMangoRooms,
} from '../../context/thunks/chatThunks';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../shared/hooks';
import { RoomTypes } from '../../types/messageTypes';
import { ROUTES } from '../../types/routes';
import NoDataFound from '../Feed/components/NoDataFound/NoDataFound';
import DMCard from './components/DMCard/DMCard';
import MangoRoomsCard from './components/MangoRoomsCard/MangoRoomsCard';

const Messages: React.FC = () => {
  const { colors } = useTheme();

  // const {
  //   hostMetadata: { offeringTitle },
  // } = useAppSelector((state) => state.app);

  const [activeTab, setActiveTab] = useState<RoomTypes>('mango');

  const { mangoRoomList, dmRoomList, unreadCount } = useAppSelector(
    (state) => state.chat,
  );

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  useEffect(() => {
    if (activeTab === 'mango') dispatch(fetchMoreMangoRooms(true));
    else dispatch(fetchMoreDMRooms(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const selectorItem = (
    value: RoomTypes,
    name: string,
    badgeCount: number,
    style?: React.CSSProperties,
  ): JSX.Element => {
    const selected = value === activeTab;
    return (
      <Button
        key={value}
        className={`siteBtn tab__selector__item ${selected ? 'selected' : ''}`}
        onClick={() => setActiveTab(value)}
        style={{
          ...style,
        }}>
        <Typography.Text className="tab__selector__item__text">
          {name}
        </Typography.Text>
        {badgeCount > 0 && (
          <Badge
            count={badgeCount}
            overflowCount={99}
            style={{
              marginLeft: 8,
            }}
          />
        )}
      </Button>
    );
  };

  const getActiveTab = useMemo((): JSX.Element => {
    switch (activeTab) {
      case 'mango':
        return (
          <>
            <EasyVirtualizedScroller
              useParentScrollElement
              onLoadMore={() => dispatch(fetchMoreMangoRooms())}
              hasMore={mangoRoomList.status === 'idle'}>
              {mangoRoomList.roomList.map((item) => (
                <MangoRoomsCard
                  displayPicture={item.picUrl}
                  displayMessages={item.messages}
                  creator={item.creator}
                  participantCount={item.participants.length}
                  displayParticipants={item.firstParticipants}
                  unreadCount={item.totalUnreadMessages}
                  roomTitle={item.title}
                  isNotificationMuted={item.isNotificationMuted}
                  onClick={() => {
                    navigate(ROUTES.MESSAGE_ROOM, {
                      roomId: item._id,
                    });
                  }}
                />
              ))}
            </EasyVirtualizedScroller>
            {mangoRoomList.status === 'loading' ? (
              <Loader
                style={{
                  width: '100%',
                  textAlign: 'center',
                  marginTop: 16,
                }}
              />
            ) : null}
            {mangoRoomList.roomList.length === 0 &&
            mangoRoomList.status !== 'loading' ? (
              <NoDataFound title="No chats found" />
            ) : null}
          </>
        );
      case 'dm':
        return (
          <>
            <EasyVirtualizedScroller
              useParentScrollElement
              onLoadMore={() => dispatch(fetchMoreDMRooms())}
              hasMore={dmRoomList.status === 'idle'}>
              {dmRoomList.roomList.map((item, index) => (
                <DMCard
                  name={item.otherParticipant?.name}
                  imageUrl={item.otherParticipant?.profilePicUrl}
                  isCreator={item.isOtherParticipantCreator}
                  lastMessage={item.lastMessage}
                  noBorder={index === dmRoomList.roomList.length - 1}
                  unread={item.totalUnreadMessages}
                  isNotificationMuted={item.isNotificationMuted}
                  onClick={() => {
                    navigate(ROUTES.MESSAGE_DM, {
                      roomId: item._id,
                    });
                  }}
                />
              ))}
            </EasyVirtualizedScroller>
            {dmRoomList.status === 'loading' ? (
              <Loader
                style={{
                  width: '100%',
                  textAlign: 'center',
                  marginTop: 16,
                }}
              />
            ) : null}
            {dmRoomList.roomList.length === 0 &&
            dmRoomList.status !== 'loading' ? (
              <NoDataFound title="No chats found" />
            ) : null}
            <Button
              className="fab-btn fab-btn-message"
              onClick={() => {
                navigate(ROUTES.NEW_MESSAGE);
              }}>
              <MdAdd size={30} color={colors.BACKGROUND} />
            </Button>
          </>
        );
      default:
        return <div />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, dmRoomList, mangoRoomList]);

  return (
    <DynamicWrapper path={ROUTES.MESSAGES}>
      <div className="messages__container">
        <DynamicHeader path={ROUTES.MESSAGES}>
          <div className="tab__selector">
            {selectorItem(
              'mango',
              `Communities`,
              unreadCount.mango.unreadConversations,
            )}
            {selectorItem('dm', 'Inbox', unreadCount.dm.unreadConversations, {
              marginLeft: 8,
            })}
          </div>
        </DynamicHeader>
        <div className="messages__content">{getActiveTab}</div>
      </div>
    </DynamicWrapper>
  );
};

export default Messages;
