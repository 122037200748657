import { PlayerAPI } from 'bitmovin-player';
import {
  AirPlayToggleButton,
  AudioQualitySelectBox,
  BufferingOverlay,
  Button,
  CastStatusOverlay,
  CastToggleButton,
  Container,
  ControlBar,
  ErrorMessageOverlay,
  FullscreenToggleButton,
  PictureInPictureToggleButton,
  PlaybackSpeedSelectBox,
  PlaybackTimeLabel,
  PlaybackTimeLabelMode,
  PlaybackToggleButton,
  PlaybackToggleOverlay,
  RecommendationOverlay,
  SeekBar,
  SeekBarLabel,
  SettingsPanel,
  SettingsPanelItem,
  SettingsPanelPage,
  SettingsPanelPageOpenButton,
  SettingsToggleButton,
  Spacer,
  SubtitleOverlay,
  SubtitleSelectBox,
  SubtitleSettingsLabel,
  SubtitleSettingsPanelPage,
  TitleBar,
  UIContainer,
  VideoQualitySelectBox,
  VolumeSlider,
  VolumeToggleButton,
  VRToggleButton,
  Watermark,
} from 'bitmovin-player-ui';

const createUIContainer = (player: PlayerAPI) => {
  // seek interval in seconds
  const interval = 10;

  let rewindButton = new Button({
    cssClass: 'ui-rewindbutton bmpui-ui-button',
  });
  rewindButton.onClick.subscribe(function () {
    player.seek(Math.max(0, player.getCurrentTime() - interval));
  });

  let forwardButton = new Button({
    cssClass: 'ui-forwardbutton bmpui-ui-button',
  });
  forwardButton.onClick.subscribe(function () {
    player.seek(
      Math.min(player.getDuration(), player.getCurrentTime() + interval),
    );
  });

  let subtitleOverlay = new SubtitleOverlay();

  let mainSettingsPanelPage = new SettingsPanelPage({
    components: [
      new SettingsPanelItem('Video Quality', new VideoQualitySelectBox()),
      new SettingsPanelItem(
        'Speed',
        new PlaybackSpeedSelectBox({
          items: [
            {
              key: '1.25',
              sortedInsert: true,
              ariaLabel: '1.25x',
              label: '1.25x',
            },
          ],
        }),
      ),
      new SettingsPanelItem('Audio Quality', new AudioQualitySelectBox()),
    ],
  });

  let settingsPanel = new SettingsPanel({
    components: [mainSettingsPanelPage],
    hidden: true,
  });

  let subtitleSettingsPanelPage = new SubtitleSettingsPanelPage({
    settingsPanel: settingsPanel,
    overlay: subtitleOverlay,
  });

  const subtitleSelectBox = new SubtitleSelectBox();

  let subtitleSettingsOpenButton = new SettingsPanelPageOpenButton({
    targetPage: subtitleSettingsPanelPage,
    container: settingsPanel,
    ariaLabel: 'Subtitles',
    text: 'open',
  });

  mainSettingsPanelPage.addComponent(
    new SettingsPanelItem(
      new SubtitleSettingsLabel({
        text: 'Subtitles',
        opener: subtitleSettingsOpenButton,
      }),
      subtitleSelectBox,
      {
        role: 'menubar',
      },
    ),
  );

  settingsPanel.addComponent(subtitleSettingsPanelPage);

  let controlBar = new ControlBar({
    components: [
      settingsPanel,
      new Container({
        components: [
          new PlaybackTimeLabel({
            timeLabelMode: PlaybackTimeLabelMode.CurrentTime,
            hideInLivePlayback: true,
          }),
          new SeekBar({
            label: new SeekBarLabel(),
          }),
          new PlaybackTimeLabel({
            timeLabelMode: PlaybackTimeLabelMode.TotalTime,
            cssClasses: ['text-right'],
          }),
        ],
        cssClasses: ['controlbar-top'],
      }),
      new Container({
        components: [
          rewindButton,
          new PlaybackToggleButton(),
          forwardButton,
          new VolumeToggleButton(),
          new VolumeSlider(),
          new Spacer(),
          new PictureInPictureToggleButton(),
          new AirPlayToggleButton(),
          new CastToggleButton(),
          new VRToggleButton(),
          new SettingsToggleButton({
            settingsPanel: settingsPanel,
          }),
          new FullscreenToggleButton(),
        ],
        cssClasses: ['controlbar-bottom'],
      }),
    ],
  });

  return new UIContainer({
    components: [
      subtitleOverlay,
      new BufferingOverlay(),
      new PlaybackToggleOverlay(),
      new CastStatusOverlay(),
      controlBar,
      new TitleBar(),
      new RecommendationOverlay(),
      new Watermark(),
      new ErrorMessageOverlay(),
    ],
  });
};

export { createUIContainer };
