import * as React from 'react';

import { useTheme } from '../../../context/ThemeProvider';

const AudioSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg width="20.497" height="18.053" viewBox="0 0 20.497 18.053" {...props}>
      <g id="audio" transform="translate(0 -30.467)">
        <g
          id="Group_4216"
          data-name="Group 4216"
          transform="translate(0 30.466)">
          <path
            id="Path_724"
            data-name="Path 724"
            d="M18.933,40.83V39.15a8.684,8.684,0,1,0-17.367,0v1.68A1.662,1.662,0,0,0,0,42.487v2.6a1.662,1.662,0,0,0,1.66,1.66h.96a1.908,1.908,0,0,0,3.811-.132V40.96a1.908,1.908,0,0,0-2.464-1.825,6.282,6.282,0,1,1,12.563,0,1.908,1.908,0,0,0-2.464,1.825v5.657a1.908,1.908,0,0,0,3.811.132h.96a1.662,1.662,0,0,0,1.66-1.66v-2.6a1.662,1.662,0,0,0-1.564-1.659Z"
            transform="translate(0 -30.466)"
            fill={colors.PRIMARY}
          />
        </g>
      </g>
    </svg>
  );
};

export default AudioSvg;
