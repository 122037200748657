import './styles.scss';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Input,
  message,
} from 'antd';
import {
  IoImage,
  IoMic,
  IoPencil,
  IoPodium,
  IoPricetag,
  IoTrash,
  IoVideocam,
} from 'react-icons/io5';

import FeedAPI from '../../api/FeedAPI';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import HeaderTextAction from '../../components/Header/actions/HeaderTextAction';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader';
import BitmovinPlayer
  from '../../components/VideoPlayer/BitmovinPlayer/BitmovinPlayer';
import { createActivityPost } from '../../context/activityReducer';
import showAppError from '../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../shared/hooks';
import {
  checkVideoFileValidation,
  convertImage,
  getFileExtension,
} from '../../shared/utils';
import { IMango } from '../../types/feedTypes';
import { ROUTES } from '../../types/routes';
import CreatePostAttachment from './components/CreatePostAttachment';
import CreatePostHeader, {
  CreatePostHeaderRef,
} from './components/CreatePostHeader';

type TFileTypes = 'image' | 'audio' | 'video';

interface ISelFile {
  file: File;
  name: string;
  ext: string;
  type: TFileTypes;
  url: string;
}

const mb16 = 16 * 1024 * 1024;

const CreatePost = () => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const {
    hostMetadata: { offeringTitle, communityEnabledMangoes },
  } = useAppSelector((state) => state.app);
  const { creatorMangoes, subscribedMangoes, ...userDetails } = useAppSelector(
    (state) => state.user,
  );

  const createPostHeaderRef = React.useRef<CreatePostHeaderRef>(null);

  const [initLoading, setInitLoading] = useState(false);
  const [hasVideoPermissions, setHasVideoPermissions] = useState(false);
  const [description, setDescription] = useState('');
  const [selFile, setSelFile] = useState<ISelFile | null>();
  const [selectedMangoes, setSelectedMangoes] = useState<IMango[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const mangoesToDisplay = useMemo(() => {
    if (
      ['creator_completed', 'dual', 'creator_restricted'].includes(
        userDetails.type || '',
      )
    ) {
      return creatorMangoes;
    } else if (
      subscribedMangoes.length > 0 &&
      communityEnabledMangoes &&
      communityEnabledMangoes.length > 0 &&
      ['fan_completed'].includes(userDetails.type || '')
    ) {
      const subscribedMangoesWithCommunityEnabled = subscribedMangoes.filter(
        (mango) => communityEnabledMangoes.includes(mango._id),
      );
      return subscribedMangoesWithCommunityEnabled;
    }
    return [];
  }, [
    creatorMangoes,
    userDetails.type,
    subscribedMangoes,
    communityEnabledMangoes,
  ]);

  const isDisabled = useMemo(() => {
    return (
      (description.length === 0 && !selFile?.url) ||
      selectedMangoes.length === 0
    );
  }, [description.length, selFile?.url, selectedMangoes.length]);

  useEffect(() => {
    setInitLoading(true);
    FeedAPI.getVideoUploadPermissions()
      .then((res) => {
        if (res.data.result.permissionEnabled) {
          setHasVideoPermissions(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setInitLoading(false);
      });
  }, []);

  useEffect(() => {
    if (
      selectedMangoes.length === 0 &&
      mangoesToDisplay &&
      mangoesToDisplay.length === 1
    ) {
      setSelectedMangoes([mangoesToDisplay[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mangoesToDisplay]);

  const setFileAndThumbnail = (imageFile: File, url: string) => {
    const ext = getFileExtension(imageFile.name)?.[1].toLowerCase() || '';
    const name = imageFile.name.substring(
      0,
      imageFile.name.length - ext.length - 1,
    );
    setSelFile({
      file: imageFile,
      name,
      ext,
      type: 'image',
      url,
    });
  };

  const beforeUpload = async (fileData: File, fileType: TFileTypes) => {
    let clickedFileType = fileType;
    let isValid = true;
    let url: string | null = null;

    // when image
    if (clickedFileType === 'image') {
      // const isValidImageExtension =
      //     validImageExtensions.indexOf(file.type) !== -1;
      const type = getFileExtension(fileData.name)?.[1].toLowerCase();
      const isImage =
        type === 'jpg' ||
        type === 'png' ||
        type === 'gif' ||
        type === 'webp' ||
        type === 'tiff' ||
        type === 'jpeg' ||
        type === 'heic' ||
        type === 'svg';
      if (!isImage) {
        isValid = false;
        // message.error("You can only valid image type!");
        message.error('You can only put a valid image here!');
        return false;
      }

      const isImageLt2M = fileData.size / 1024 / 1024 < 1024;

      if (!isImageLt2M) {
        isValid = false;
        message.error('Image must smaller than 1024MB!');
        return false;
      }

      convertImage(type, fileData, setFileAndThumbnail);
      return;
    }

    // when video
    if (clickedFileType === 'video') {
      const isVideo = checkVideoFileValidation(fileData);
      if (!isVideo) {
        isValid = false;
        message.error('You can only upload valid video file!');
      }

      if (fileData?.size > 5 * 1024 * 1024 * 1024) {
        message.error('File limit is upto 5GB');
        return false;
      }

      // create video preview
      url = URL.createObjectURL(fileData);
      // videoParentRef?.current?.load();
    }

    // when audio
    if (clickedFileType === 'audio') {
      const fileExtension = getFileExtension(fileData.name)?.[1];
      if (fileExtension === undefined || fileExtension === null) {
        isValid = false;
        message.error('Sorry, something went wrong. Try another audio file.');
      } else {
        const type = fileExtension.toLowerCase();
        const isAudio =
          type === 'mp3' ||
          type === 'm4a' ||
          type === 'aac' ||
          type === 'm3u' ||
          type === 'oga' ||
          type === 'wav';
        if (!isAudio) {
          isValid = false;
          message.error('You can only upload audio file!');
        }
        url = URL.createObjectURL(fileData);
      }
    }

    if (isValid && url) {
      // message.success("File added successfully.");
      const ext = getFileExtension(fileData.name)?.[1].toLowerCase() || '';
      const name = fileData.name.substring(
        0,
        fileData.name.length - ext.length - 1,
      );
      setSelFile({
        file: fileData,
        name,
        ext,
        type: clickedFileType,
        url,
      });
    } else {
      message.error('File upload failed.');
    }
  };

  const onSelect = async (type: TFileTypes) => {
    setSelFile(null);

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    if (type === 'image')
      input.setAttribute(
        'accept',
        '.jpg, .jpeg, .png, .svg, .gif, .bmp, .webp, .tiff, .heic',
      );
    else if (type === 'video')
      input.setAttribute(
        'accept',
        '.video/mp4, .mp4, .video/webm, .webm, .video/quicktime, .quicktime, .video/mov, .mov, .video/mkv, .mkv',
      );
    else if (type === 'audio')
      input.setAttribute('accept', '.mp3, .m4a, .aac, .m3u, .oga, .wav');
    input.click();
    input.addEventListener('change', (e: any) => {
      let evt: React.ChangeEvent<HTMLInputElement> = e as any;
      if (!evt.target?.files) return;
      beforeUpload(evt.target.files[0], type);
    });
  };

  const postContentOptions: {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
  }[] = useMemo(() => {
    const iconSize = 24;
    const items = [
      {
        icon: <IoImage size={iconSize} />,
        label: 'Photo',
        onClick: () => {
          onSelect('image');
        },
      },
      {
        icon: <IoMic size={iconSize} />,
        label: 'Audio',
        onClick: () => {
          onSelect('audio');
        },
      },
      {
        icon: <IoPodium size={iconSize} />,
        label: 'Polls',
        onClick: () => {
          navigate(ROUTES.CREATE_POLL, undefined, {
            replace: true,
          });
        },
      },
      {
        icon: <IoPricetag size={iconSize} />,
        label: 'Tags',
        onClick: () => {
          createPostHeaderRef.current?.openTagModal();
        },
      },
    ];
    if (hasVideoPermissions) {
      items.splice(1, 0, {
        icon: <IoVideocam size={iconSize} />,
        label: 'Video',
        onClick: () => {
          onSelect('video');
        },
      });
    }
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasVideoPermissions, navigate]);

  const renderAttachment = useCallback((file: ISelFile) => {
    if (file.type === 'image') {
      return (
        <div className="createpost__attachment createpost__attachment__image">
          <div className="createpost__attachment__actions">
            <Button
              className="createpost__attachment__actions__button"
              onClick={() => {
                onSelect('image');
              }}>
              <IoPencil size={24} />
            </Button>
            <Button
              className="createpost__attachment__actions__button"
              onClick={() => {
                setSelFile(null);
              }}>
              <IoTrash size={24} />
            </Button>
          </div>
          <img src={file.url} alt={file.name} />
        </div>
      );
    } else if (file.type === 'video') {
      return (
        <div className="createpost__attachment createpost__attachment__video">
          <div className="createpost__attachment__actions">
            <Button
              className="createpost__attachment__actions__button"
              onClick={() => {
                setSelFile(null);
              }}>
              <IoTrash size={24} />
            </Button>
          </div>
          <BitmovinPlayer
            mediaUrl={file.url}
            isCompressDone
            mediaId="create-post-video"
          />
        </div>
      );
    } else if (file.type === 'audio') {
      return (
        <div className="createpost__attachment createpost__attachment__audio">
          <div className="createpost__attachment__actions">
            <Button
              className="createpost__attachment__actions__button"
              onClick={() => {
                setSelFile(null);
              }}>
              <IoTrash size={24} />
            </Button>
          </div>
          <AudioPlayer id="create-post-audio" url={file.url} />
        </div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = () => {
    try {
      setInitLoading(true);
      const formData = new FormData();

      if (selFile) {
        formData.append('file', selFile.file);
        formData.append('contentType', selFile.type);
      } else {
        formData.append('contentType', 'text');
      }

      formData.append('description', description);

      if (selectedMangoes.length > 0) {
        formData.append(
          'mangoArr',
          // [...selectedMangoes.map((mango) => mango._id)],
          selectedMangoes.map((mango) => mango._id).join(','),
        );
        // formData.append('scheduleTime', 'Hello');
      }
      if (selectedTags.length > 0) {
        formData.append('tags', selectedTags.join(','));
      }

      if (selFile?.file.type === '') {
        formData.append('messageType', 'text');
      } else if (selFile?.type === 'image' || selFile?.type === 'video') {
        formData.append('messageType', 'document');
      } else if (selFile && selFile.file.size > mb16) {
        formData.append('messageType', 'document');
      } else {
        formData.append('messageType', 'audio');
      }

      if (description && description.trim().length > 0) {
        formData.append('caption', description);
      }

      dispatch(
        createActivityPost(
          formData,
          selectedMangoes,
          description,
          selFile ? selFile.type : 'text',
          () => {
            navigate.goBack();
          },
        ),
      );
      // navigate.goBack();
    } catch (error) {
      showAppError(error);
    } finally {
      setInitLoading(false);
    }
  };

  const submitPost = async () => {
    if (
      (description?.trim().length > 0 || selFile?.url) &&
      selectedMangoes.length > 0
    ) {
      submit();
    } else {
      if (selectedMangoes.length === 0) {
        message.error(`Please select ${offeringTitle} to publish`);
        return;
      }
      // setProgressData((oldState) => ({ ...oldState, showProgress: false }));
      message.error('Please add some description/ media to your post');
    }
  };

  useEffect(() => {
    let list = [...(document.getElementsByTagName('audio') ?? [])];
    list = [...list, ...(document.getElementsByTagName('video') ?? [])];
    list.forEach((element) => {
      element.pause();
    });
    return () => {
      list.forEach((element) => {
        element.pause();
      });
    };
  }, []);

  return (
    <>
      <div className="createpost__container">
        <Header
          title="Create"
          backType="cross"
          actionItems={[
            <HeaderTextAction
              text="Post"
              disabled={isDisabled}
              onClick={submitPost}
              loadingText="Posting..."
            />,
          ]}
        />
        <div className="scroll__container">
          <CreatePostHeader
            ref={createPostHeaderRef}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            selectedMangoes={selectedMangoes}
            setSelectedMangoes={setSelectedMangoes}
          />
          <div className="createpost__description__container">
            <Input.TextArea
              className="createpost__description"
              bordered={false}
              placeholder="Add a caption..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              autoSize={{ minRows: 3 }}
            />
          </div>
          {selFile ? renderAttachment(selFile) : null}
        </div>
        {!initLoading && <CreatePostAttachment options={postContentOptions} />}
      </div>
      {initLoading ? (
        <div className="loading__overlay">
          <Loader size="large" />
        </div>
      ) : null}
    </>
  );
};

export default CreatePost;
