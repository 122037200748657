import * as React from 'react';

const VideoSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    {...props}>
    <path
      id="video"
      d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm4.212,10.32a.714.714,0,0,1-.321.321h0L8.177,13.5a.714.714,0,0,1-1.034-.643V7.144A.714.714,0,0,1,8.177,6.5l5.715,2.857a.714.714,0,0,1,.321.963Z"
      fill="#de5775"
    />
  </svg>
);

export default VideoSvg;
