import React from 'react';

import PlusSvg from '../../assets/svg/PlusSvg';
import TickSvg from '../../assets/svg/TickSvg';

interface Props {
  name: string;
  selected?: boolean;
  onClick?: () => void;
  noButton?: boolean;
}

const MangoSelectorCard: React.FC<Props> = ({
  name,
  selected,
  onClick,
  noButton,
}) => {
  return (
    <div
      className={`mangoSelectorCard ${selected ? 'selected' : ''}`}
      onClick={onClick}>
      <div className="mangoSelectorCard__name">{name}</div>
      {!noButton && (
        <div className="mangoSelectorCard__button">
          {selected ? (
            <TickSvg width={24} height={24} />
          ) : (
            <PlusSvg width={24} height={24} />
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(MangoSelectorCard);
