import '../styles.scss';

import {
  Card,
  Skeleton,
  Space,
} from 'antd';

const PostSkeleton: React.FC = () => {
  return (
    <div className="creatorPostsWrapper">
      <Card
        style={{
          width: '100%',
        }}
        bordered={false}
        bodyStyle={{ padding: '12px' }}>
        <Space size={30} direction="vertical" style={{ width: '100%' }}>
          <Space size={25} direction="vertical" style={{ width: '100%' }}>
            <Skeleton
              avatar={{ shape: 'circle', size: 45 }}
              title={{ width: '250px' }}
              paragraph={false}
              round
              active
            />
            <Skeleton active round title={false} paragraph={{ rows: 2 }} />
          </Space>
          <Space size={15}>
            <Skeleton.Button active shape="round" size="small" />
            <Skeleton.Button active shape="round" size="small" />
            <Skeleton.Button active shape="round" size="small" />
          </Space>
        </Space>
      </Card>
    </div>
  );
};

export default PostSkeleton;
