import '../../CourseOverview/SectionItem/styles.scss';

import React, { useMemo } from 'react';

import { Progress, Typography } from 'antd';
import {
  IoBook,
  IoGlobeOutline,
  IoHeadset,
  IoImage,
  IoLockClosed,
  IoVideocam,
} from 'react-icons/io5';
import { MdInsertDriveFile, MdQuiz } from 'react-icons/md';

import TickSvg from '../../../assets/svg/TickSvg';
import ShareButton from '../../../components/ShareButton';
import { useTheme } from '../../../context/ThemeProvider';
import { useAppSelector } from '../../../shared/hooks';
import { Creator } from '../../../types/courseTypes';
import { ROUTES } from '../../../types/routes';

const ICON_SIZE = 24;

interface Props {
  creator?: Creator;
  chpaterId?: string;
  index: number;
  title: string;
  extraText: string | string[];
  progress?: number;
  onClick: () => void;
  showProgress?: boolean;
  isLocked?: boolean;
  contentType?: string;
  purchaseRequired?: boolean;
  isPreviewAvailable?: boolean;
}

const SectionChapterItem: React.FC<Props> = ({
  index,
  chpaterId,
  title,
  extraText,
  progress = 0,
  onClick,
  showProgress = true,
  isLocked = false,
  contentType,
  purchaseRequired,
  isPreviewAvailable,
}) => {
  const { colors } = useTheme();

  const { deeplinkUrl } = useAppSelector((state) => state.app);

  const icon = useMemo(() => {
    const iconSize = 16;
    const iconColor = colors.TEXT3;

    if (isLocked || (purchaseRequired && !isPreviewAvailable)) {
      return <IoLockClosed size={iconSize} color={iconColor} />;
    }

    switch (contentType) {
      case 'iframe':
        return <IoGlobeOutline size={iconSize} color={iconColor} />;
      case 'video':
        return <IoVideocam size={iconSize} color={iconColor} />;
      case 'article':
        return <IoBook size={iconSize} color={iconColor} />;
      case 'audio':
        return <IoHeadset size={iconSize} color={iconColor} />;
      case 'image':
        return <IoImage size={iconSize} color={iconColor} />;
      case 'assignment':
        return <MdQuiz size={iconSize} color={iconColor} />;
      default:
        return <MdInsertDriveFile size={iconSize} color={iconColor} />;
    }
  }, [
    colors.TEXT3,
    contentType,
    isLocked,
    isPreviewAvailable,
    purchaseRequired,
  ]);

  return (
    <>
      <div
        onClick={onClick}
        className="section-item"
        style={{
          cursor: 'pointer',
          backgroundColor: 'transparent',
        }}>
        <div className="section-item__index">
          <Typography.Title level={4} className="section-item__index__text">
            {index.toString().padStart(2, '0')}
          </Typography.Title>
        </div>
        <div className="section-item__content">
          <Typography.Title
            ellipsis={{
              rows: 2,
              expandable: false,
            }}
            level={5}
            className="section-item__content__title">
            {title}
          </Typography.Title>
          <Typography.Text className="section-item__content__extra-text">
            {typeof extraText === 'string' ? extraText : extraText.join(' • ')}
          </Typography.Text>
          {purchaseRequired && isPreviewAvailable ? (
            <Typography.Text className="section-item__content__preview">
              Preview
            </Typography.Text>
          ) : null}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}>
          {showProgress &&
            (progress < 100 ? (
              <Progress
                type="circle"
                percent={
                  purchaseRequired && !isPreviewAvailable ? 0 : progress || 0
                }
                className="progress-circle"
                width={32}
                status="active"
                strokeColor={colors.GREEN}
                strokeWidth={8}
                trailColor={
                  purchaseRequired && !isPreviewAvailable
                    ? colors.TERTIARY
                    : colors.PAGE_BG
                }
                format={() => (
                  <div className="progress__icon--background">{icon}</div>
                )}
              />
            ) : (
              <TickSvg
                width={28}
                height={28}
                circleColor={colors.GREEN}
                backgroundColor={colors.BACKGROUND}
              />
            ))}
          <span
            role="button"
            tabIndex={0}
            style={{
              width: ICON_SIZE,
              height: ICON_SIZE,
            }}
            onClick={(e) => e.stopPropagation()}>
            <ShareButton
              url={`${deeplinkUrl}web${ROUTES.CHAPTER_METADATA_VIEW.replace(
                ':chapterId',
                chpaterId || '',
              )}`}
              size={ICON_SIZE}
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default SectionChapterItem;
