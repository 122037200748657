import React, { memo } from 'react';

import { Card, Skeleton } from 'antd';

const styles = {
  cardBody: {
    padding: 0,
  },
  cardInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  topWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  gamificationWrapper: { display: 'flex', alignItems: 'center', gap: 24 },
  badgesWrapper: { display: 'flex', alignItems: 'center', gap: 8 },
  actionWrapper: { display: 'flex', alignItems: 'center', gap: 12 },
};

const UserProfileSkeleton: React.FC = () => {
  const badges = (isBadgesWrapper: boolean) => {
    const leftMargin = isBadgesWrapper ? -32 : 0;

    return (
      <div style={styles.badgesWrapper}>
        <Skeleton
          avatar={{ shape: 'circle', size: 36 }}
          title={false}
          paragraph={false}
          round
          active
        />
        <Skeleton
          avatar={{ shape: 'circle', size: 36 }}
          title={false}
          paragraph={false}
          round
          active
          style={{ marginLeft: leftMargin }}
        />
        <Skeleton
          avatar={{ shape: 'circle', size: 36 }}
          title={false}
          paragraph={false}
          round
          active
          style={{ marginLeft: leftMargin }}
        />
      </div>
    );
  };

  return (
    <div className="user-profile-skeleton">
      <Card
        style={{
          width: '100%',
        }}
        bordered={false}
        bodyStyle={styles.cardBody}>
        <div style={styles.cardInner as React.CSSProperties}>
          <div style={styles.topWrapper as React.CSSProperties}>
            <Skeleton
              avatar={{ shape: 'circle', size: 65 }}
              title={{ width: 250, style: { marginTop: 28 } }}
              paragraph={false}
              round
              active
            />
            <div style={styles.gamificationWrapper as React.CSSProperties}>
              <Skeleton.Button active shape="round" />
              {badges(true)}
            </div>
            <Skeleton
              active
              round
              title={false}
              paragraph={{ rows: 2 }}
              style={{ marginTop: 12 }}
            />
          </div>
          <Skeleton active round title={false} paragraph={{ rows: 1 }} />
          <div style={styles.gamificationWrapper as React.CSSProperties}>
            {badges(false)}
          </div>
          <div style={styles.actionWrapper}>
            <Skeleton.Button active block />
            <Skeleton.Button active block />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default memo(UserProfileSkeleton);
