import React, {
  useEffect,
  useRef,
} from 'react';

interface Props {
  frequencyBandArray: number[];
  getFrequencyData: (callback: (newAmplitudeData: Uint8Array) => void) => void;
  isPlaying: boolean;
  id: string;
}

const VisualDemo: React.FC<Props> = ({
  frequencyBandArray,
  getFrequencyData,
  isPlaying,
  id,
}) => {
  const amplitudeValues = useRef<Uint8Array>();

  function adjustFreqBandStyle(newAmplitudeData: Uint8Array) {
    try {
      amplitudeValues.current = newAmplitudeData;
      const domElements: HTMLElement[] = frequencyBandArray.map((num) =>
        document.getElementById(id + num),
      ) as HTMLElement[];
      if (!domElements) return false;
      for (let i = 0; i < frequencyBandArray.length; i += 1) {
        const num = frequencyBandArray[i];
        if (domElements[num]) {
          domElements[
            num
          ].style.backgroundColor = `rgb(255, ${amplitudeValues.current[num]}, 0)`;
          domElements[num].style.height = `${amplitudeValues.current[num]}px`;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  function runSpectrum() {
    getFrequencyData(adjustFreqBandStyle);
    requestAnimationFrame(runSpectrum);
  }

  useEffect(() => {
    if (isPlaying) {
      runSpectrum();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  return (
    <div className="frequencyBandsContainer">
      {frequencyBandArray.map((num) => (
        <div className="frequencyBands" id={id + num} key={id + num} />
      ))}
    </div>
  );
};

export default React.memo(VisualDemo);
