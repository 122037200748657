import './styles.scss';

import React, { useEffect } from 'react';

import { Typography } from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';

import PlusSvg from '../../assets/svg/PlusSvg';
import TickSvg from '../../assets/svg/TickSvg';
import HeaderTextAction from '../Header/actions/HeaderTextAction';
import Header from '../Header/Header';
import FalseInput from './FalseInput';

export type SINGLE = string;
export type MULTIPLE = string[];

type Props<T> = {
  label: string;
  options: string[];
  message?: {
    type: 'error' | 'success' | 'info';
    text: string;
  };
  selected?: T;
  onChange: (value: T) => void;
  disabled?: boolean;
  selectType?: 'single' | 'multiple';
  getLabel?: (value: string) => string;
};

const Select = <T extends SINGLE | MULTIPLE = SINGLE>({
  label,
  message,
  options,
  selected,
  onChange,
  disabled = false,
  selectType = 'single',
  getLabel,
}: Props<T>) => {
  const [selectedValue, setSelectedValue] = React.useState<MULTIPLE>([]);
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    if (selectType === 'single') {
      if (selected) {
        setSelectedValue([selected as SINGLE]);
      } else {
        setSelectedValue([]);
      }
    } else {
      if (selected) {
        setSelectedValue(selected as MULTIPLE);
      } else {
        setSelectedValue([]);
      }
    }
  }, [selected, selectType]);

  const onSubmit = (value = selectedValue) => {
    if (value.length > 0) {
      if (selectType === 'single') onChange(value[0] as T);
      else onChange(value as T);
    }
    setShowModal(false);
  };

  const handleChange = (option: string) => {
    if (selectType === 'single') {
      setSelectedValue([option]);
      onSubmit([option]);
    } else {
      if (selectedValue.includes(option)) {
        setSelectedValue((prev) => prev.filter((value) => value !== option));
      } else {
        setSelectedValue((prev) => [...prev, option]);
      }
    }
  };

  return (
    <>
      <FalseInput
        label={label}
        message={message}
        onClick={() => {
          setShowModal(true);
        }}
        focused={Boolean(selected)}
        disabled={disabled}>
        <Typography.Text>
          {selectType === 'single'
            ? getLabel
              ? getLabel(selectedValue[0])
              : selectedValue[0]
            : selectedValue.length > 0
              ? selectedValue.join(', ')
              : ''}
        </Typography.Text>
      </FalseInput>
      <BottomSheet
        open={showModal}
        onDismiss={() => setShowModal(false)}
        className="select__bottomSheet"
        header={
          <Header
            canGoBack={false}
            noBorder
            title={`Select ${label}`}
            actionItems={[<HeaderTextAction text="Done" onClick={onSubmit} />]}
          />
        }>
        <ul className="select__options">
          {options.map((option) => (
            <li className="select__option" onClick={() => handleChange(option)}>
              <Typography.Text className="select__option__text">
                {getLabel ? getLabel(option) : option}
              </Typography.Text>
              {selectedValue.includes(option) ? <TickSvg /> : <PlusSvg />}
            </li>
          ))}
        </ul>
      </BottomSheet>
    </>
  );
};

export default Select;
