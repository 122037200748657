import './styles.scss';

import React from 'react';

import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

import { useAppSelector } from '../../shared/hooks';

interface Props {
  children?: React.ReactNode;
}

const Login: React.FC<Props> = ({ children }) => {
  const {
    hostMetadata: { appName, loginPageBackgroundUrl },
  } = useAppSelector((state) => state.app);

  return (
    <>
      <Helmet>
        <title>Login to {appName}</title>
        <meta name="title" content={`Login to ${appName}`} />
        <meta
          name="description"
          content={`Login to your ${appName} account to access your favourite workshops and courses.`}
        />
        <meta
          name="keywords"
          content="tagmango, creator, artist, influencer, educator, trainer, public speaking, youtube, Instagram creator, youtube creator"
        />
      </Helmet>
      <div className="basic--layout__wrapper">
        <div className="basic--layout__content">
          <div
            className="tmLoginWrapper"
            style={
              loginPageBackgroundUrl
                ? {
                    backgroundImage: `url(${loginPageBackgroundUrl})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                  }
                : {}
            }>
            <div className="tmLoginLeft">
              <Outlet />
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
