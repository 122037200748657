import React from 'react';

import { Switch, Typography } from 'antd';

import StopMessages from '../../../../assets/svg/MuteNotification';

interface Props {
  icon?: React.ReactNode;
  title: string;
  onChange: () => void;
  value: boolean;
}

const ChatSettingsItem: React.FC<Props> = ({
  icon,
  title,
  onChange,
  value,
}) => {
  return (
    <div className="chatRoomInfoModal__card__settings">
      {icon || <StopMessages width={32} height={32} />}
      <div className="chatRoomInfoModal__card__settings__inner">
        <Typography.Text className="chatRoomInfoModal__card__settings__inner__text">
          {title}
        </Typography.Text>
        <Switch checked={value} onChange={onChange} />
      </div>
    </div>
  );
};

export default React.memo(ChatSettingsItem);
