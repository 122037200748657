import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  message,
  Typography,
} from 'antd';
import {
  IoStar,
  IoStarOutline,
} from 'react-icons/io5';
import { BottomSheet } from 'react-spring-bottom-sheet';

import CourseAPI from '../../../api/CourseAPI';
import HeaderTextAction
  from '../../../components/Header/actions/HeaderTextAction';
import Header from '../../../components/Header/Header';
import { useTheme } from '../../../context/ThemeProvider';
import showAppError from '../../../shared/error';
import { ICourseExtended } from '../../../types/courseTypes';

interface Props {
  show: boolean;
  onClose: () => void;
  course: ICourseExtended;
  courseRating?: number;
  courseReview?: string;
  onSaved: (rating: number, review?: string) => void;
}

const RatingValues = [1, 2, 3, 4, 5] as const;

const RatingModal: React.FC<Props> = ({
  show,
  onClose,
  course,
  courseRating,
  courseReview,
  onSaved,
}) => {
  const { colors } = useTheme();

  const ratingText = {
    1: {
      title: 'Terrible',
      color: colors.DANGER,
    },
    2: {
      title: 'Bad',
      color: colors.DANGER,
    },
    3: {
      title: 'Okay',
      color: colors.WARNING,
    },
    4: {
      title: 'Liked it!',
      color: colors.GREEN,
    },
    5: {
      title: 'Loved it!',
      color: colors.GREEN,
    },
  } as const;

  const [rating, setRating] = useState<0 | (typeof RatingValues)[number]>(0);
  const [review, setReview] = useState<string>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  useEffect(() => {
    setRating(courseRating || (0 as any));
    setReview(courseReview);
  }, [courseRating, courseReview]);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const response = await CourseAPI.reviewCourse(course._id, rating, review);
      if (response.status === 200) {
        message.success('Review submitted successfully!');
        onSaved(rating, review);
        onClose();
      } else {
        showAppError(response.data);
      }
    } catch (error) {
      showAppError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BottomSheet
      open={show}
      onDismiss={onClose}
      className="rating_sheet"
      header={
        <Header
          title="Review course"
          canGoBack={false}
          noBorder
          actionItems={[
            <HeaderTextAction
              text="Submit"
              loadingText="Wait..."
              onClick={handleSubmit}
              disabled={rating === 0}
              loading={isSubmitting}
            />,
          ]}
        />
      }>
      <div className="rating_sheet__rate-course__container">
        <Typography.Title level={5} className="courseOverview__cardTitle">
          Rating
        </Typography.Title>
        <div className="rating_sheet__rate-course">
          <div className="rating_sheet__rate-course__star__wrapper">
            {RatingValues.map((item, index) => (
              <div
                key={index}
                className="rating_sheet__rate-course__star"
                onClick={() => setRating(item)}>
                {index < rating ? (
                  <IoStar size={24} color={colors.WARNING} />
                ) : (
                  <IoStarOutline size={24} color={colors.ICON} />
                )}
              </div>
            ))}
          </div>
          <Typography.Text
            className="rating_sheet__rate-course__text"
            style={{
              color: rating === 0 ? colors.ICON : ratingText[rating].color,
            }}>
            {rating === 0 ? 'Add a rating' : ratingText[rating].title}
          </Typography.Text>
        </div>
      </div>
      <div className="rating_sheet__review-course__container">
        <Typography.Title level={5} className="courseOverview__cardTitle">
          Review
        </Typography.Title>
        <div className="rating_sheet__review-course">
          <textarea
            placeholder="Add a review"
            className="rating_sheet__review-course__input"
            value={review}
            onChange={(e) => setReview(e.target.value)}
          />
        </div>
      </div>
    </BottomSheet>
  );
};

export default React.memo(RatingModal);
