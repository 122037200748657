import React, { useEffect } from 'react';

import { Outlet } from 'react-router-dom';

import { checkForPermissions } from '../../context/userReducer';
import { useAppNavigate, useAppSelector } from '../../shared/hooks';
import { ROUTES } from '../../types/routes';
import { UAMPermissions } from '../../types/userTypes';

interface Props {
  children?: React.ReactNode;
}

const CourseWrapper: React.FC<Props> = ({ children }) => {
  const userDetails = useAppSelector((state) => state.user);
  const navigate = useAppNavigate();

  useEffect(() => {
    if (
      userDetails.type === 'creator_restricted' &&
      !checkForPermissions(
        [UAMPermissions.COURSE_ACCESS],
        userDetails.userAccessPermissions,
      )
    ) {
      navigate(ROUTES.FEED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  return (
    <>
      <Outlet />
      {children}
    </>
  );
};

export default CourseWrapper;
