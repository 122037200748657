import React from 'react';

import ImageViewer from './ImageViewer';

interface Props {
  children: React.ReactNode;
  src: string;
  title?: string;
}

const Zoom: React.FC<Props> = ({ children, src, title }) => {
  const [zoomed, setZoomed] = React.useState(false);

  return (
    <>
      <div onClick={() => setZoomed(true)}>{children}</div>
      <ImageViewer
        title={title}
        showModal={zoomed}
        closeModal={() => setZoomed(false)}
        srcSet={[src]}
      />
    </>
  );
};

export default React.memo(Zoom);
