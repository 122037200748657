import './styles.scss';

import React from 'react';

import { Typography } from 'antd';
import {
  MdCameraAlt,
  MdDocumentScanner,
  MdMic,
  MdVideocam,
} from 'react-icons/md';

import { useTheme } from '../../../../context/ThemeProvider';
import AudioSvg from '../../assets/AudioSvg';
import DocSvg from '../../assets/DocSvg';
import ImageSvg from '../../assets/ImageSvg';
import VideoSvg from '../../assets/VideoSvg';

interface ReplyContentsProps {
  _id?: string;
  message: string;
  senderName: string;
  messageType: string;
  nameColor?: string;
}

const getIcon = (type: string) => {
  switch (type) {
    case 'video':
      return <VideoSvg />;
    case 'audio':
      return <AudioSvg />;
    case 'image':
      return <ImageSvg />;
    default:
      return <DocSvg />;
  }
};

const ReplyContents: React.FC<ReplyContentsProps> = ({
  message,
  messageType,
  senderName = '',
  nameColor,
}) => {
  const { colors } = useTheme();

  const iconProps = {
    color: colors.TEXT1,
    size: 15,
    style: {
      marginRight: 5,
    },
  };

  const AttachmentTypes = [
    { type: 'image', text: 'photo', icon: <MdCameraAlt {...iconProps} /> },
    { type: 'video', text: 'video', icon: <MdVideocam {...iconProps} /> },
    {
      type: 'audio',
      text: 'audio',
      icon: <MdMic {...iconProps} />,
    },
    {
      type: 'doc',
      text: 'document',
      icon: <MdDocumentScanner {...iconProps} />,
    },
  ];

  return (
    <div className="reply-contents">
      <div className="reply-contents-header">
        <Typography.Title
          level={5}
          className="reply-contents-header-name"
          style={{ color: nameColor || colors.TEXT1 }}
          ellipsis={{ rows: 1, expandable: false }}>
          {senderName}
        </Typography.Title>

        {messageType === 'text' && (
          <div className="reply-contents-header-message">
            <Typography.Paragraph
              ellipsis={{
                rows: 1,
                expandable: false,
              }}>
              {message}
            </Typography.Paragraph>
          </div>
        )}
        {AttachmentTypes.map((t) => t.type).includes(messageType) && (
          <div className="reply-contents-header-message">
            {AttachmentTypes.find((t) => t.type === messageType)?.icon}
            <Typography.Text>
              {AttachmentTypes.find((t) => t.type === messageType)?.text}
            </Typography.Text>
          </div>
        )}
      </div>

      {['audio', 'video', 'doc', 'image'].includes(messageType) && (
        <div
          className="reply-contents-attachment"
          style={{
            backgroundColor: `${colors.SHADOW}27`,
          }}>
          {getIcon(messageType)}
        </div>
      )}
    </div>
  );
};

export default React.memo(ReplyContents);
