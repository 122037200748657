import './styles.scss';

import React from 'react';

import {
  Button,
  Typography,
} from 'antd';

import MangoSvg from './MangoSvg';

interface Props {
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
  showMango?: boolean;
  imageComponent: React.ReactNode;
}

const ActivityCard: React.FC<Props> = ({
  title,
  description,
  buttonText,
  onClick,
  showMango = true,
  imageComponent,
}) => {
  return (
    <div className="activity-card">
      <div className="activity-card__images">
        {imageComponent}
        {showMango && (
          <MangoSvg
            height={35}
            width={35}
            className="activity-card__images__mango"
          />
        )}
      </div>
      <div className="activity-card__content">
        <Typography.Title className="" level={4} style={{ marginBottom: 4 }}>
          {title}
        </Typography.Title>
        <p style={{ marginBottom: 18 }}>{description}</p>
        <Button
          className="siteBtn siteBtnPrimary"
          style={{ width: '100%', alignSelf: 'stretch' }}
          onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(ActivityCard);
