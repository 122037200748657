import { IMessage } from '../types/chatModels/ChatMessagesResponse';
import { ChatStats } from '../types/chatModels/ChatStatsResponse';
import { CommonMangoResult } from '../types/chatModels/CommonMangoesResponse';
import {
  CommonRoom,
  DMRoomListResult,
  MangoRoomListResult,
  NewMessageListResponse,
} from '../types/chatModels/MangoRoomListResponse';
import { ParticipantListItem } from '../types/chatModels/ParticipantResponse';
import { SendMessageResult } from '../types/chatModels/SendMessage';
import {
  UpdateMangoRoomPicture,
} from '../types/chatModels/UpdateMangoRoomPicture';
import {
  IDMRoomDetails,
  IMangoRoomDetails,
} from '../types/messageTypes';
import API from './';
import config from './config';

export default abstract class ChatAPI {
  static getDMRoomList = (page = 1, limit = 10) =>
    API.get<DMRoomListResult[]>(
      `${config.CHAT_ENDPOINT}/get-rooms-by-type?roomType=dm&page=${page}&limit=${limit}`,
    );

  static getMangoRoomList = (page = 1, limit = 10) =>
    API.get<MangoRoomListResult[]>(
      `${config.CHAT_ENDPOINT}/get-rooms-by-type?roomType=mango&page=${page}&limit=${limit}`,
    );

  static getChatsForRoom = (
    roomId: string,
    lastmessage?: string,
    pageSize = 10,
    direction: 'up' | 'down' = 'up',
  ) => {
    let url = `${config.CHAT_ENDPOINT}/get-all-chats/${roomId}?pageSize=${pageSize}&direction=${direction}`;
    if (lastmessage) {
      url += `&lastmessage=${lastmessage}`;
    }
    return API.get<IMessage[]>(url);
  };

  static sendMessage = (data: FormData) =>
    API.post<SendMessageResult[]>(`${config.CHAT_ENDPOINT}/send-message`, data);

  static getExistingRoom = (otherParticipant: string) =>
    API.get<CommonRoom>(
      `${config.CHAT_ENDPOINT}/get-common-room?otherParticipant=${otherParticipant}`,
    );

  static getMangoesInCommon = (otherUser: string) =>
    API.get<CommonMangoResult>(
      `${config.CHAT_ENDPOINT}/get-common-mango-list?otherUser=${otherUser}`,
    );

  static getNewMessageList = (page: number, limit: number) =>
    API.get<NewMessageListResponse>(
      `${config.CHAT_ENDPOINT}/get-new-message-list?page=${page}&limit=${limit}`,
    );

  static searchNewMessageList = (page: number, limit: number, search: string) =>
    API.get<NewMessageListResponse>(
      `${config.CHAT_ENDPOINT}/search-new-people-to-message?search=${search}&page=${page}&limit=${limit}`,
    );

  static updateMessageReadStatus = (room: string, messageId?: string) =>
    messageId
      ? API.post(`${config.CHAT_ENDPOINT}/add-seen-user`, {
          room,
          messageIds: [messageId],
        })
      : API.post(`${config.CHAT_ENDPOINT}/add-seen-user`, {
          room,
        });

  static getAllParticipantsByRoom = (room: string, page?: number) =>
    API.get<ParticipantListItem[]>(
      `${config.CHAT_ENDPOINT}/get-all-participants-by-room?room=${room}${
        page ? `&page=${page}` : ''
      }`,
    );

  static getSeenUsersForMessage = (messageId: string) =>
    API.get<ParticipantListItem[]>(`/get-seen-users-for-message/${messageId}`);

  static getUnreadChatCount = () =>
    API.get<ChatStats>(`${config.CHAT_ENDPOINT}/get-unread-chat-count`);

  static getMessagesAroundParentId = (roomId: string, messageId: string) =>
    API.get<IMessage[]>(
      `${config.CHAT_ENDPOINT}/get-messages-around-id/${roomId}?parentMessage=${messageId}`,
    );

  static blockOrUnblockRoom = (
    user: string,
    room: string,
    pushorpull: 'push' | 'pull',
  ) =>
    API.post(`${config.CHAT_ENDPOINT}/block-room`, {
      room,
      user,
      pushorpull,
    });

  static toggleSubscriberMessagingInRoom = (
    room: string,
    subscribermessaging: boolean,
  ) =>
    API.post(`${config.CHAT_ENDPOINT}/toggle-messaging`, {
      room,
      subscribermessaging,
    });

  static togglePeerToPeerConversationInRoom = (room: string) =>
    API.post(`${config.CHAT_ENDPOINT}/toggle-peer-conversation`, { room });

  static toggleMuteNotificationInRoom = (roomId: string, muteStatus: boolean) =>
    API.post(`${config.CHAT_ENDPOINT}/change-notification-mute-status`, {
      roomId,
      muteStatus,
    });

  static uploadRoomPicture = (roomId: string, data: FormData) =>
    API.put<UpdateMangoRoomPicture>(
      `${config.CHAT_ENDPOINT}/update-room-pic/${roomId}`,
      data,
    );

  static getRoomDetails = <T = IMangoRoomDetails | IDMRoomDetails>(
    roomId: string,
  ) => API.get<T>(`${config.CHAT_ENDPOINT}/get-room-details/${roomId}`);

  static deleteMessage = (data: { messageId: string; roomId: string }) =>
    API.post(`${config.CHAT_ENDPOINT}/delete-message`, data);
}
