import React from 'react';

import {
  Button,
  Typography,
} from 'antd';

interface Props {
  options: {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
  }[];
  title?: string;
  noBorder?: boolean;
}

const CreatePostAttachment: React.FC<Props> = ({
  options,
  title = 'Add to your post',
  noBorder,
}) => {
  return (
    <div
      className={`createpost__attachment__container ${
        noBorder ? 'borderless' : ''
      }`}>
      <Typography.Title level={5} className="createpost__attachment__title">
        {title}
      </Typography.Title>
      <div className="createpost__attachment__options">
        {options.map((option, index) => (
          <div key={index} className="createpost__attachment__option">
            <Button
              className="createpost__attachment__option__icon"
              onClick={option.onClick}>
              {option.icon}
            </Button>
            <Typography.Text className="createpost__attachment__option__label">
              {option.label}
            </Typography.Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(CreatePostAttachment);
