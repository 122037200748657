import * as React from 'react';

import { useTheme } from '../../context/ThemeProvider';

const ViewMoreSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 19.103 12.223"
      {...props}>
      <g id="view" transform="translate(0.117 -92.735)">
        <g id="Group_4349" data-name="Group 4349" transform="translate(0 92.835)">
          <g id="Group_4348" data-name="Group 4348" transform="translate(0 0)">
            <path
              id="Path_799"
              data-name="Path 799"
              d="M18.745,98.479c-.169-.231-4.184-5.644-9.317-5.644S.288,98.249.12,98.479a.622.622,0,0,0,0,.734c.169.231,4.184,5.644,9.317,5.644s9.143-5.414,9.317-5.644A.621.621,0,0,0,18.745,98.479Zm-9.317,5.134c-3.778,0-7.049-3.594-8.017-4.769.967-1.176,4.231-4.769,8.017-4.769s7.049,3.593,8.017,4.769C16.482,100.022,13.217,103.614,9.428,103.614Z"
              transform="translate(0 -92.835)"
              fill={colors.ICON}
              stroke={colors.ICON}
              strokeWidth="0.4"
            />
          </g>
        </g>
        <g
          id="Group_4351"
          data-name="Group 4351"
          transform="translate(5.7 95.115)">
          <g id="Group_4350" data-name="Group 4350">
            <path
              id="Path_800"
              data-name="Path 800"
              d="M158.453,154.725a3.731,3.731,0,1,0,3.731,3.731A3.731,3.731,0,0,0,158.453,154.725Zm0,6.219a2.488,2.488,0,1,1,2.488-2.488A2.488,2.488,0,0,1,158.453,160.944Z"
              transform="translate(-154.722 -154.725)"
              fill={colors.ICON}
              stroke={colors.ICON}
              strokeWidth="0.4"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ViewMoreSvg;
