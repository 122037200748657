import React from 'react';

import { Avatar, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { useAppSelector } from '../../../../shared/hooks';
import { ROUTES } from '../../../../types/routes';

const CreateAPostCard: React.FC = () => {
  const userDetails = useAppSelector((state) => state.user);
  const { hostMetadata } = useAppSelector((state) => state.app);

  return (
    <Link to={ROUTES.CREATE_POST}>
      <div className="create-a-post-card">
        <Avatar
          src={
            userDetails.type === 'creator_restricted'
              ? hostMetadata.creator.profilePicUrl
              : userDetails.profilePic || ''
          }
          alt={
            userDetails.type === 'creator_restricted'
              ? hostMetadata.creator.name
              : userDetails.name || ''
          }
          size={35}
        />
        <div className="create-a-post-card__content">
          <Typography.Text>
            Share something with your community...
          </Typography.Text>
        </div>
      </div>
    </Link>
  );
};

export default React.memo(CreateAPostCard);
