import '../styles.scss';

import React from 'react';

import { Badge } from 'antd';
import TMIcon from 'tm-icons-library';

import { useTheme } from '../../../context/ThemeProvider';
import { TMIconsType } from '../../../types/baseTypes';

interface Props {
  onClick: () => void;
  icon: TMIconsType | JSX.Element;
  iconSize?: number;
  badgeCount?: number;
  error?: boolean;
}

const HeaderIcon: React.FC<Props> = ({
  onClick,
  icon,
  iconSize = 16,
  badgeCount,
  error = false,
}) => {
  const { colors } = useTheme();

  return (
    <Badge count={badgeCount} size="small">
      <div className="header--activity__action" onClick={onClick}>
        {typeof icon === 'string' ? (
          <TMIcon
            name={icon}
            color={error ? colors.DANGER : colors.ICON}
            size={iconSize}
          />
        ) : (
          icon
        )}
      </div>
    </Badge>
  );
};

export default React.memo(HeaderIcon);
