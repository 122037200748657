import React from 'react';

import { useTheme } from '../../context/ThemeProvider';

const ThreeDotSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { colors } = useTheme();
  return (
    <svg viewBox="0 0 15.985 3.197" {...props}>
      <g id="more" transform="translate(0 -170.666)">
        <g
          id="Group_4253"
          data-name="Group 4253"
          transform="translate(0 170.666)">
          <g id="Group_4252" data-name="Group 4252" transform="translate(0 0)" />
          <circle
            id="Ellipse_148"
            data-name="Ellipse 148"
            cx="1.598"
            cy="1.598"
            r="1.598"
            fill={colors.ICON}
          />
        </g>
        <g
          id="Group_4255"
          data-name="Group 4255"
          transform="translate(6.394 170.666)">
          <g id="Group_4254" data-name="Group 4254" transform="translate(0 0)">
            <circle
              id="Ellipse_149"
              data-name="Ellipse 149"
              cx="1.598"
              cy="1.598"
              r="1.598"
              fill={colors.ICON}
            />
          </g>
        </g>
        <g
          id="Group_4257"
          data-name="Group 4257"
          transform="translate(12.788 170.666)">
          <g id="Group_4256" data-name="Group 4256" transform="translate(0 0)">
            <circle
              id="Ellipse_150"
              data-name="Ellipse 150"
              cx="1.598"
              cy="1.598"
              r="1.598"
              fill={colors.ICON}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default React.memo(ThreeDotSvg);
