import * as React from 'react';

import ConfirmationModal
  from '../../../../components/Modals/ConfirmationModal/ConfirmationModal';
import { useAppSelector } from '../../../../shared/hooks';
import ActivityCard from '../ActivityCard/ActivityCard';
import AddPostSvg from '../ActivityCard/AddPostSvg';

interface Props {}

const NoMangoComponent: React.FC<Props> = () => {
  const { creatorMangoes, ...userDetails } = useAppSelector(
    (state) => state.user,
  );
  const {
    hostMetadata: { offeringTitle },
    isTagMango,
  } = useAppSelector((state) => state.app);
  const { postState, selected } = useAppSelector((state) => state.activity);

  const [showModal, setShowModal] = React.useState(false);

  const isVisible =
    (!creatorMangoes || creatorMangoes.length === 0) &&
    !postState.loading &&
    selected &&
    (userDetails.type === 'creator_completed' || userDetails.type === 'dual');
  return isVisible ? (
    <>
      <ActivityCard
        title={`Create Your First Offering (${offeringTitle.toTitleCase()})`}
        description={`Set up your new offering or “${offeringTitle.toTitleCase()}” within 30 seconds! Just put in the title, pricing, a cover, description and Boom! You are all set. You can now create courses or workshop slots and link them to the ${offeringTitle}.`}
        buttonText="Get Started"
        onClick={() => {
          setShowModal(true);
        }}
        imageComponent={<AddPostSvg width={100} height={100} />}
        showMango={isTagMango}
      />
      <ConfirmationModal
        open={showModal}
        showOkayButton={false}
        title="Feature currently unavailable"
        message={`To create a ${offeringTitle} visit desktop website or the app. For further queries contact us at support@tagmango.com`}
        handleOk={() => {
          setShowModal(false);
        }}
        handleCancel={() => {
          setShowModal(false);
        }}
        okayButtonText="Okay"
        cancelButtonText="Okay"
      />
    </>
  ) : null;
};

export default NoMangoComponent;
