import './styles.scss';

import React, {
  Reducer,
  useEffect,
  useReducer,
} from 'react';

import {
  message,
  Switch,
} from 'antd';

import UserAPI from '../../../../api/UserAPI';
import HeaderTextAction
  from '../../../../components/Header/actions/HeaderTextAction';
import Header from '../../../../components/Header/Header';
import Loader from '../../../../components/Loader';
import { useTheme } from '../../../../context/ThemeProvider';
import { setUserDetails } from '../../../../context/userReducer';
import showAppError from '../../../../shared/error';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../shared/hooks';

interface IState {
  email: boolean;
  whatsapp: boolean;
  app: boolean;
}

const titleMap: {
  [key: string]: string;
} = {
  email: 'Email',
  whatsapp: 'WhatsApp',
  app: 'App',
};

const NotificationSettings: React.FC = () => {
  const { colors } = useTheme();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [data, setData] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, action) => ({
      ...state,
      ...action,
    }),
    {
      email: false,
      whatsapp: false,
      app: false,
    },
  );
  const disabledChat = useAppSelector((state) => state.user.disabledChat);
  const dispatch = useAppDispatch();
  const getInitialData = async () => {
    setIsLoading(true);
    UserAPI.getDisabledNotificationStatus('post')
      .then((res) => {
        if (res.status === 200) {
          const { result } = res.data;
          const newData = {
            email: !result.disableEmail,
            whatsapp: !result.disableWhatsapp,
            app: !result.disableNotification,
          };
          setData(newData);
          setIsLoading(false);
        } else {
          showAppError(res.data);
        }
      })
      .catch((error) => {
        showAppError(error);
      });
  };
  const save = async () => {
    setIsSaving(true);
    const { email, whatsapp, app } = data;
    const newData = {
      disableEmail: !email,
      disableWhatsapp: !whatsapp,
      disableNotification: !app,
    };
    UserAPI.disableNotificationStatus('post', newData)
      .then((res) => {
        if (res.status === 200) {
          message.success('Saved Successfully!');
        } else {
          showAppError(res.data);
        }
      })
      .catch((error) => {
        showAppError(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  useEffect(() => {
    getInitialData();
  }, []);
  return (
    <div className="pageRoot">
      <Header
        title="Settings"
        actionItems={[
          <HeaderTextAction
            text="Save"
            onClick={save}
            loadingText="Saving"
            loading={isSaving}
          />,
        ]}
      />
      <div className="pageContent" style={{ padding: 12 }}>
        <div className="notificationSettingsWrapperOuter">
          <div className="notificationSettingsWrapper">
            <div className="settingsItemBlock">
              <div className="notificationSettingsWrapperHeader">
                <div className="notificationSettingsWrapperHeaderInner">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.855"
                    height="21.625"
                    viewBox="0 0 15.855 21.625">
                    <g id="post" transform="translate(-4.27)">
                      <path
                        id="Path_1121"
                        data-name="Path 1121"
                        d="M20.364,15.637a.73.73,0,0,0-.182-.372L18.614,13.7a3.6,3.6,0,0,0-5.088,0l-.081.081a.723.723,0,0,0,1.02,1.02l.081-.081a2.162,2.162,0,0,1,3.054,0l1.318,1.311A2.149,2.149,0,0,1,16.8,17.793H14.871l-2.7-2.7a3.6,3.6,0,0,0-5.095,0l-1.75,1.743a.757.757,0,0,0-.074.932,3.588,3.588,0,0,0,2.9,1.473H16.8a3.6,3.6,0,0,0,3.595-3.426ZM6.851,17.347,8.087,16.11a2.216,2.216,0,0,1,3.054,0L12.83,17.8H8.148a2.142,2.142,0,0,1-1.3-.453Z"
                        transform="translate(-0.277 -4.103)"
                        fill={colors.ICON}
                      />
                      <path
                        id="Path_1122"
                        data-name="Path 1122"
                        d="M4.986,10.926A.723.723,0,0,1,4.27,10.2V3.6A3.608,3.608,0,0,1,7.871,0H16.52a3.608,3.608,0,0,1,3.6,3.6V7.358a.723.723,0,1,1-1.439,0V3.6A2.162,2.162,0,0,0,16.52,1.439H7.871A2.162,2.162,0,0,0,5.709,3.6v6.6a.723.723,0,0,1-.723.723Z"
                        transform="translate(0)"
                        fill={colors.ICON}
                      />
                      <path
                        id="Path_1123"
                        data-name="Path 1123"
                        d="M11.232,9.124a2.162,2.162,0,1,1,2.162-2.162A2.162,2.162,0,0,1,11.232,9.124Zm0-2.885a.723.723,0,1,0,.723.723A.723.723,0,0,0,11.232,6.239Z"
                        transform="translate(-1.557 -1.557)"
                        fill={colors.ICON}
                      />
                      <path
                        id="Path_1124"
                        data-name="Path 1124"
                        d="M20.1,26.509H9.286a.723.723,0,0,1,0-1.439H20.1a.723.723,0,0,1,0,1.439Z"
                        transform="translate(-1.415 -8.131)"
                        fill={colors.ICON}
                      />
                      <path
                        id="Path_1125"
                        data-name="Path 1125"
                        d="M6.086,26.509a.723.723,0,0,1,0-1.439.723.723,0,0,1,0,1.439Z"
                        transform="translate(-0.377 -8.131)"
                        fill={colors.ICON}
                      />
                      <path
                        id="Path_1126"
                        data-name="Path 1126"
                        d="M14.675,31.308H6.019a.723.723,0,1,1,0-1.439h8.656a.723.723,0,1,1,0,1.439Z"
                        transform="translate(-0.31 -9.686)"
                        fill={colors.ICON}
                      />
                      <path
                        id="Path_1127"
                        data-name="Path 1127"
                        d="M24.188,31.308H22.019a.723.723,0,1,1,0-1.439h2.169a.723.723,0,1,1,0,1.439Z"
                        transform="translate(-5.499 -9.686)"
                        fill={colors.ICON}
                      />
                    </g>
                  </svg>
                  <div className="notificationTitleWrapper">
                    <h4>Post Notifications</h4>
                    <span>Push only</span>
                  </div>
                </div>
              </div>
              <div className="notificationSettingWrapperBody">
                <p className="details">
                  These are notifications for posts created by your creator(s).
                </p>
                <h6>Where you receive these notifications</h6>
                <div className="settingList">
                  {!isLoading ? (
                    Object.entries(data).map(([title, value]) => (
                      <div className="settingListItem">
                        <div className="settingsTitleWrapper">
                          {titleMap[title]}
                        </div>
                        <Switch
                          size="small"
                          checked={value}
                          onChange={() => {
                            setData({ [title]: !value });
                          }}
                        />
                      </div>
                    ))
                  ) : (
                    <Loader
                      style={{
                        width: '100%',
                        margin: '12px 0',
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="notificationSettingsWrapper">
            <div className="settingsItemBlock">
              <div className="notificationSettingsWrapperHeader">
                <div className="notificationSettingsWrapperHeaderInner">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="31"
                    viewBox="0 0 34 31">
                    <g
                      id="Layer_3"
                      data-name="Layer 3"
                      transform="translate(-1 -3)">
                      <path
                        id="Path_1304"
                        data-name="Path 1304"
                        d="M24,14H22.9a4.96,4.96,0,0,0-.728-1.755l.778-.778a1,1,0,1,0-1.414-1.414l-.776.776A4.98,4.98,0,0,0,19,10.1V9a1,1,0,0,0-2,0v1.1a4.96,4.96,0,0,0-1.755.728l-.778-.778a1,1,0,1,0-1.414,1.414l.776.776A4.98,4.98,0,0,0,13.1,14H12a1,1,0,0,0,0,2h1.1a4.96,4.96,0,0,0,.728,1.755l-.778.778a1,1,0,1,0,1.414,1.414l.776-.776A4.98,4.98,0,0,0,17,19.9V21a1,1,0,0,0,2,0V19.9a4.96,4.96,0,0,0,1.755-.728l.778.778a1,1,0,1,0,1.414-1.414l-.776-.776A4.98,4.98,0,0,0,22.9,16H24a1,1,0,0,0,0-2Zm-8.118,3.128a3,3,0,1,1,4.246-.01A3.089,3.089,0,0,1,15.882,17.128Z"
                        fill={colors.ICON}
                      />
                      <path
                        id="Path_1305"
                        data-name="Path 1305"
                        d="M31.018,3H4.982A3.986,3.986,0,0,0,1,6.982V23.018A3.986,3.986,0,0,0,4.982,27H8v6a1,1,0,0,0,1.707.707L16.414,27h14.6A3.986,3.986,0,0,0,35,23.018V6.982A3.986,3.986,0,0,0,31.018,3ZM33,23.018A1.984,1.984,0,0,1,31.018,25H16a1,1,0,0,0-.707.293L10,30.586V26a1,1,0,0,0-1-1H4.982A1.984,1.984,0,0,1,3,23.018V6.982A1.984,1.984,0,0,1,4.982,5H31.018A1.984,1.984,0,0,1,33,6.982Z"
                        fill={colors.ICON}
                      />
                    </g>
                  </svg>

                  <div className="notificationTitleWrapper">
                    <h4 style={{ marginBottom: 5 }}>DM Rooms</h4>
                  </div>
                </div>
              </div>
              <div className="notificationSettingWrapperBody">
                {/* <p className="details">These is for the messages you exchange.</p> */}
                <h6>Where you receive these messages</h6>
                <div className="settingList">
                  {!isLoading ? (
                    <div className="settingListItem">
                      <div className="settingsTitleWrapper">
                        Allow people to message you directly
                      </div>
                      <Switch
                        size="small"
                        checked={!disabledChat}
                        onChange={() => {
                          // call the api here
                          dispatch(
                            setUserDetails({ disabledChat: !disabledChat }),
                          );
                          UserAPI.updateAccountDetails({
                            disabledChat: !disabledChat,
                          })
                            .then((res) => {
                              console.log(res.data);
                              message.success('Message settings updated');
                            })
                            .catch(() =>
                              dispatch(
                                setUserDetails({ disabledChat: !disabledChat }),
                              ),
                            );
                        }}
                      />
                    </div>
                  ) : (
                    <Loader
                      style={{
                        width: '100%',
                        margin: '12px 0',
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
