import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';

import { message as ToastMessage } from 'antd';
import {
  MdDelete,
  MdDownload,
  MdInfo,
  MdReply,
  MdReport,
} from 'react-icons/md';
import { BottomSheet } from 'react-spring-bottom-sheet';

import ChatAPI from '../../../api/ChatAPI';
import CopyText from '../../../assets/svg/CopyText';
import BottomSheetTile from '../../../components/BottomSheetTile/BottomSheetTile';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal/ConfirmationModal';
import Report from '../../../components/Report/Report';
import {
  updateChatRoomData,
  updateDMRoomData,
  updateMangoRoomData,
} from '../../../context/chatReducer';
import { useTheme } from '../../../context/ThemeProvider';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { copyToClipboard, formatBytes } from '../../../shared/utils';
import { IMessage } from '../../../types/chatModels/ChatMessagesResponse';
import {
  IDMRoomDetails,
  IMangoRoomDetails,
  RoomTypes,
} from '../../../types/messageTypes';
import MessageInfo from '../screens/MessageInfo/MessageInfo';

export interface MessageOptionsRefProps {
  showMenu: () => void;
}

interface Props {
  isSenderSelf: boolean;
  message: IMessage;
  onAddReply?: () => void;
  downloadMedia: () => void;
  roomType?: RoomTypes;
  roomDetails: IMangoRoomDetails | IDMRoomDetails;
  isCreator?: boolean;
}

const MessageOptions: ForwardRefRenderFunction<
  MessageOptionsRefProps,
  Props
> = (
  {
    isSenderSelf,
    message,
    onAddReply,
    downloadMedia,
    roomType = 'mango',
    roomDetails,
    isCreator = false,
  },
  ref,
) => {
  const { colors } = useTheme();

  const dispatch = useAppDispatch();

  const {
    isTagMango,
    hostMetadata: {
      creator: { _id: hostCreatorId },
    },
  } = useAppSelector((state) => state.app);
  const userDetails = useAppSelector((state) => state.user);
  const { chatRoomData, mangoRoomList, dmRoomList } = useAppSelector(
    (state) => state.chat,
  );

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState<boolean>(false);
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] =
    useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [isMessageInfoVisible, setIsMessageInfoVisible] = useState(false);

  const selectedRoomCreatorId = (roomDetails as any)?.creator?._id;
  const isCreatorMessage = isTagMango
    ? message.senderId === selectedRoomCreatorId
    : hostCreatorId && message.senderId === hostCreatorId;
  const isDeleteMessageAllowed = isSenderSelf || isCreator;
  const isReportMessageAllowed =
    !isSenderSelf &&
    (isCreatorMessage
      ? false
      : message.senderId !== userDetails.id &&
        ['fan_completed', 'dual'].includes(userDetails.type as string));

  useImperativeHandle(ref, () => ({
    showMenu: () => {
      setIsBottomSheetOpen(true);
    },
  }));

  const onDeleteMessage = async () => {
    if (message._id) {
      const roomId = roomDetails._id;
      const toast = ToastMessage.loading('Deleting message...');
      try {
        const res = await ChatAPI.deleteMessage({
          messageId: message._id,
          roomId,
        });
        toast();
        if (res.status === 200) {
          ToastMessage.success('Message deleted successfully');
          const newChatRoomData = { ...chatRoomData };
          dispatch(
            updateChatRoomData({
              ...newChatRoomData,
              messages: newChatRoomData?.messages?.map((msg) => {
                if (msg._id === message._id) {
                  return {
                    ...msg,
                    isDeleted: true,
                    deletedByCreator: isCreator,
                  };
                }
                if (msg.messageRef && msg.messageRef._id === message._id) {
                  return {
                    ...msg,
                    messageRef: {
                      ...msg.messageRef,
                      isDeleted: true,
                    },
                  };
                }
                return msg;
              }),
            }),
          );
          if (roomType === 'dm') {
            const newDmRoomList = { ...dmRoomList };
            const newRoomList = [...newDmRoomList.roomList].map((room) => {
              if (
                room._id === roomId &&
                room.lastMessage?._id === message._id
              ) {
                return {
                  ...room,
                  lastMessage: {
                    ...room.lastMessage,
                    isDeleted: true,
                    deletedByCreator: isCreator,
                  },
                };
              }
              return room;
            });
            dispatch(
              updateDMRoomData({ ...newDmRoomList, roomList: newRoomList }),
            );
          } else {
            const newMangoRoomList = { ...mangoRoomList };
            const newRoomList = [...newMangoRoomList.roomList].map((room) => {
              if (room._id === roomId) {
                return {
                  ...room,
                  messages: [...room.messages].map((msg) => {
                    if (msg._id === message._id) {
                      return {
                        ...msg,
                        isDeleted: true,
                        deletedByCreator: isCreator,
                      };
                    }
                    return msg;
                  }),
                };
              }
              return room;
            });
            dispatch(
              updateMangoRoomData({
                ...newMangoRoomList,
                roomList: newRoomList,
              }),
            );
          }
        } else {
          ToastMessage.error("Couldn't delete message");
        }
      } catch (error: any) {
        console.log(error?.response);
        ToastMessage.error("Couldn't delete message");
      } finally {
        toast();
      }
    }
  };

  return (
    <>
      <BottomSheet
        open={isBottomSheetOpen}
        onDismiss={() => setIsBottomSheetOpen(false)}>
        <BottomSheetTile
          title="Reply"
          icon={<MdReply color={colors.ICON} size={24} />}
          onClick={() => {
            setIsBottomSheetOpen(false);
            if (onAddReply) onAddReply();
          }}
        />
        {message.messageType === 'text' && (
          <BottomSheetTile
            title="Copy Text"
            icon={<CopyText width={20} height={20} />}
            onClick={() => {
              setIsBottomSheetOpen(false);
              copyToClipboard(message?.message || '');
            }}
          />
        )}
        {message.messageType !== 'text' && (
          <BottomSheetTile
            title={`Download ${message?.messageType.toTitleCase()} (${formatBytes(
              parseInt(`${message?.extraData?.fileSize}`, 10),
            )})`}
            icon={<MdDownload color={colors.ICON} size={24} />}
            onClick={() => {
              setIsBottomSheetOpen(false);
              downloadMedia();
            }}
          />
        )}
        {isSenderSelf && roomType === 'mango' && (
          <BottomSheetTile
            onClick={() => {
              setIsBottomSheetOpen(false);
              setIsMessageInfoVisible(true);
            }}
            title="Message info"
            icon={<MdInfo color={colors.ICON} size={24} />}
          />
        )}
        {isReportMessageAllowed ? (
          <BottomSheetTile
            icon={<MdReport color={colors.DANGER} size={24} />}
            warning
            alignIcon="flex-start"
            title="Report message"
            description="This message will be reported"
            onClick={() => {
              setIsBottomSheetOpen(false);
              setIsDialogVisible(true);
            }}
          />
        ) : null}
        {isDeleteMessageAllowed ? (
          <>
            <hr className="dropdownDivider" />
            <BottomSheetTile
              icon={<MdDelete color={colors.DANGER} size={24} />}
              warning
              alignIcon="flex-start"
              title="Delete message"
              description="This message will be deleted"
              onClick={() => {
                setIsBottomSheetOpen(false);
                setIsDeleteDialogVisible(true);
              }}
            />
          </>
        ) : null}
      </BottomSheet>
      <ConfirmationModal
        open={isDialogVisible}
        message={`Do you really want to report the message?`}
        title="Report"
        okayButtonText="Report"
        cancelButtonText="Cancel"
        handleOk={() => {
          setShowReportModal(true);
          setIsDialogVisible(false);
        }}
        handleCancel={() => {
          setIsDialogVisible(false);
        }}
      />
      <ConfirmationModal
        open={isDeleteDialogVisible}
        message={`Do you really want to delete the message?`}
        title="Delete"
        okayButtonText="Delete"
        cancelButtonText="Cancel"
        handleOk={() => {
          onDeleteMessage();
          setIsDeleteDialogVisible(false);
        }}
        handleCancel={() => {
          setIsDeleteDialogVisible(false);
        }}
      />
      <Report
        showModal={showReportModal}
        closeModal={() => {
          setShowReportModal(false);
        }}
        type="message"
        message={message}
      />
      {isSenderSelf && roomType === 'mango' && message && (
        <MessageInfo
          message={message}
          showModal={isMessageInfoVisible}
          closeModal={() => setIsMessageInfoVisible(false)}
          roomDetails={roomDetails as IMangoRoomDetails}
        />
      )}
    </>
  );
};

export default forwardRef(MessageOptions);
